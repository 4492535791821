import { Country } from "../model/country";

export const countries: Country[] =

  [
    {
      "code": "af",
      "name": "Afghanistan",
      "phoneCode": "+93",
      "defaultCurrency": "AFN"
    },
    {
      "code": "ax",
      "name": "Aland Islands",
      "phoneCode": "+358",
      "defaultCurrency": "EUR"
    },
    {
      "code": "al",
      "name": "Albania",
      "phoneCode": "+355",
      "defaultCurrency": "ALL"
    },
    {
      "code": "dz",
      "name": "Algeria",
      "phoneCode": "+213",
      "defaultCurrency": "DZD"
    },
    {
      "code": "as",
      "name": "American Samoa",
      "phoneCode": "+1684",
      "defaultCurrency": "USD"
    },
    {
      "code": "ad",
      "name": "Andorra",
      "phoneCode": "+376",
      "defaultCurrency": "EUR"
    },
    {
      "code": "ao",
      "name": "Angola",
      "phoneCode": "+244",
      "defaultCurrency": "AOA"
    },
    {
      "code": "ai",
      "name": "Anguilla",
      "phoneCode": "+1264",
      "defaultCurrency": "XCD"
    },
    {
      "code": "aq",
      "name": "Antarctica",
      "phoneCode": "+672",
      "defaultCurrency": ""
    },
    {
      "code": "ag",
      "name": "Antigua and Barbuda",
      "phoneCode": "+1268",
      "defaultCurrency": "XCD"
    },
    {
      "code": "ar",
      "name": "Argentina",
      "phoneCode": "+54",
      "defaultCurrency": "ARS"
    },
    {
      "code": "am",
      "name": "Armenia",
      "phoneCode": "+374",
      "defaultCurrency": "AMD"
    },
    {
      "code": "aw",
      "name": "Aruba",
      "phoneCode": "+297",
      "defaultCurrency": "AWG"
    },
    {
      "code": "ac",
      "name": "Ascension Island",
      "phoneCode": "+247",
      "defaultCurrency": ""
    },
    {
      "code": "au",
      "name": "Australia",
      "phoneCode": "+61",
      "defaultCurrency": "AUD"
    },
    {
      "code": "at",
      "name": "Austria",
      "phoneCode": "+43",
      "defaultCurrency": "EUR"
    },
    {
      "code": "az",
      "name": "Azerbaijan",
      "phoneCode": "+994",
      "defaultCurrency": "AZN"
    },
    {
      "code": "bs",
      "name": "Bahamas",
      "phoneCode": "+1242",
      "defaultCurrency": "BSD"
    },
    {
      "code": "bh",
      "name": "Bahrain",
      "phoneCode": "+973",
      "defaultCurrency": "BHD"
    },
    {
      "code": "bd",
      "name": "Bangladesh",
      "phoneCode": "+880",
      "defaultCurrency": "BDT"
    },
    {
      "code": "bb",
      "name": "Barbados",
      "phoneCode": "+1246",
      "defaultCurrency": "BBD"
    },
    {
      "code": "by",
      "name": "Belarus",
      "phoneCode": "+375",
      "defaultCurrency": "BYN"
    },
    {
      "code": "be",
      "name": "Belgium",
      "phoneCode": "+32",
      "defaultCurrency": "EUR"
    },
    {
      "code": "bz",
      "name": "Belize",
      "phoneCode": "+501",
      "defaultCurrency": "BZD"
    },
    {
      "code": "bj",
      "name": "Benin",
      "phoneCode": "+229",
      "defaultCurrency": "XOF"
    },
    {
      "code": "bm",
      "name": "Bermuda",
      "phoneCode": "+1441",
      "defaultCurrency": "BMD"
    },
    {
      "code": "bt",
      "name": "Bhutan",
      "phoneCode": "+975",
      "defaultCurrency": "BTN"
    },
    {
      "code": "bo",
      "name": "Bolivia",
      "phoneCode": "+591",
      "defaultCurrency": "BOB"
    },
    {
      "code": "ba",
      "name": "Bosnia and Herzegovina",
      "phoneCode": "+387",
      "defaultCurrency": "BAM"
    },
    {
      "code": "bw",
      "name": "Botswana",
      "phoneCode": "+267",
      "defaultCurrency": "BWP"
    },
    {
      "code": "br",
      "name": "Brazil",
      "phoneCode": "+55",
      "defaultCurrency": "BRL"
    },
    {
      "code": "io",
      "name": "British Indian Ocean Territory",
      "phoneCode": "+246",
      "defaultCurrency": ""
    },
    {
      "code": "bn",
      "name": "Brunei Darussalam",
      "phoneCode": "+673",
      "defaultCurrency": "BND"
    },
    {
      "code": "bg",
      "name": "Bulgaria",
      "phoneCode": "+359",
      "defaultCurrency": "BGN"
    },
    {
      "code": "bf",
      "name": "Burkina Faso",
      "phoneCode": "+226",
      "defaultCurrency": "XOF"
    },
    {
      "code": "bi",
      "name": "Burundi",
      "phoneCode": "+257",
      "defaultCurrency": "BIF"
    },
    {
      "code": "kh",
      "name": "Cambodia",
      "phoneCode": "+855",
      "defaultCurrency": "KHR"
    },
    {
      "code": "cm",
      "name": "Cameroon",
      "phoneCode": "+237",
      "defaultCurrency": "XAF"
    },
    {
      "code": "ca",
      "name": "Canada",
      "phoneCode": "+1",
      "defaultCurrency": "CAD"
    },
    {
      "code": "cv",
      "name": "Cape Verde",
      "phoneCode": "+238",
      "defaultCurrency": "CVE"
    },
    {
      "code": "ky",
      "name": "Cayman Islands",
      "phoneCode": "+1345",
      "defaultCurrency": "KYD"
    },
    {
      "code": "cf",
      "name": "Central African Republic",
      "phoneCode": "+236",
      "defaultCurrency": "XAF"
    },
    {
      "code": "td",
      "name": "Chad",
      "phoneCode": "+235",
      "defaultCurrency": "XAF"
    },
    {
      "code": "cl",
      "name": "Chile",
      "phoneCode": "+56",
      "defaultCurrency": "CLP"
    },
    {
      "code": "cn",
      "name": "China",
      "phoneCode": "+86",
      "defaultCurrency": "CNY"
    },
    {
      "code": "cx",
      "name": "Christmas Island",
      "phoneCode": "+61",
      "defaultCurrency": "AUD"
    },
    {
      "code": "cc",
      "name": "Cocos (Keeling) Islands",
      "phoneCode": "+61",
      "defaultCurrency": "AUD"
    },
    {
      "code": "co",
      "name": "Colombia",
      "phoneCode": "+57",
      "defaultCurrency": "COP"
    },
    {
      "code": "km",
      "name": "Comoros",
      "phoneCode": "+269",
      "defaultCurrency": "KMF"
    },
    {
      "code": "cg",
      "name": "Congo",
      "phoneCode": "+242",
      "defaultCurrency": "XAF"
    },
    {
      "code": "ck",
      "name": "Cook Islands",
      "phoneCode": "+682",
      "defaultCurrency": "NZD"
    },
    {
      "code": "cr",
      "name": "Costa Rica",
      "phoneCode": "+506",
      "defaultCurrency": "CRC"
    },
    {
      "code": "hr",
      "name": "Croatia",
      "phoneCode": "+385",
      "defaultCurrency": "HRK"
    },
    {
      "code": "cu",
      "name": "Cuba",
      "phoneCode": "+53",
      "defaultCurrency": "CUP"
    },
    {
      "code": "cy",
      "name": "Cyprus",
      "phoneCode": "+357",
      "defaultCurrency": "EUR"
    },
    {
      "code": "cz",
      "name": "Czech Republic",
      "phoneCode": "+420",


      "defaultCurrency": "CZK"
    },
    {
      "code": "cd",
      "name": "Democratic Republic of the Congo",
      "phoneCode": "+243",
      "defaultCurrency": "CDF"
    },
    {
      "code": "dk",
      "name": "Denmark",
      "phoneCode": "+45",
      "defaultCurrency": "DKK"
    },
    {
      "code": "dj",
      "name": "Djibouti",
      "phoneCode": "+253",
      "defaultCurrency": "DJF"
    },
    {
      "code": "dm",
      "name": "Dominica",
      "phoneCode": "+1767",
      "defaultCurrency": "XCD"
    },
    {
      "code": "do",
      "name": "Dominican Republic",
      "phoneCode": "+1809",
      "defaultCurrency": "DOP"
    },
    {
      "code": "tl",
      "name": "East Timor",
      "phoneCode": "+670",
      "defaultCurrency": "USD"
    },
    {
      "code": "ec",
      "name": "Ecuador",
      "phoneCode": "+593",
      "defaultCurrency": "USD"
    },
    {
      "code": "eg",
      "name": "Egypt",
      "phoneCode": "+20",
      "defaultCurrency": "EGP"
    },
    {
      "code": "sv",
      "name": "El Salvador",
      "phoneCode": "+503",
      "defaultCurrency": "USD"
    },
    {
      "code": "gq",
      "name": "Equatorial Guinea",
      "phoneCode": "+240",
      "defaultCurrency": "XAF"
    },
    {
      "code": "er",
      "name": "Eritrea",
      "phoneCode": "+291",
      "defaultCurrency": "ERN"
    },
    {
      "code": "ee",
      "name": "Estonia",
      "phoneCode": "+372",
      "defaultCurrency": "EUR"
    },
    {
      "code": "et",
      "name": "Ethiopia",
      "phoneCode": "+251",
      "defaultCurrency": "ETB"
    },
    {
      "code": "fk",
      "name": "Falkland Islands (Malvinas)",
      "phoneCode": "+500",
      "defaultCurrency": "FKP"
    },
    {
      "code": "fo",
      "name": "Faroe Islands",
      "phoneCode": "+298",
      "defaultCurrency": "DKK"
    },
    {
      "code": "fj",
      "name": "Fiji",
      "phoneCode": "+679",
      "defaultCurrency": "FJD"
    },
    {
      "code": "fi",
      "name": "Finland",
      "phoneCode": "+358",
      "defaultCurrency": "EUR"
    },
    {
      "code": "fr",
      "name": "France",
      "phoneCode": "+33",
      "defaultCurrency": "EUR"
    },
    {
      "code": "gf",
      "name": "French Guiana",
      "phoneCode": "+594",
      "defaultCurrency": "EUR"
    },
    {
      "code": "pf",
      "name": "French Polynesia",
      "phoneCode": "+689",
      "defaultCurrency": "XPF"
    },
    {
      "code": "ga",
      "name": "Gabon",
      "phoneCode": "+241",
      "defaultCurrency": "XAF"
    },
    {
      "code": "gm",
      "name": "Gambia",
      "phoneCode": "+220",
      "defaultCurrency": "GMD"
    },
    {
      "code": "ge",
      "name": "Georgia",
      "phoneCode": "+995",
      "defaultCurrency": "GEL"
    },
    {
      "code": "de",
      "name": "Germany",
      "phoneCode": "+49",
      "defaultCurrency": "EUR"
    },
    {
      "code": "gh",
      "name": "Ghana",
      "phoneCode": "+233",
      "defaultCurrency": "GHS"
    },
    {
      "code": "gi",
      "name": "Gibraltar",
      "phoneCode": "+350",
      "defaultCurrency": "GIP"
    },
    {
      "code": "gr",
      "name": "Greece",
      "phoneCode": "+30",
      "defaultCurrency": "EUR"
    },
    {
      "code": "gl",
      "name": "Greenland",
      "phoneCode": "+299",
      "defaultCurrency": "DKK"
    },
    {
      "code": "gd",
      "name": "Grenada",
      "phoneCode": "+1473",
      "defaultCurrency": "XCD"
    },
    {
      "code": "gp",
      "name": "Guadeloupe",
      "phoneCode": "+590",
      "defaultCurrency": "EUR"
    },
    {
      "code": "gu",
      "name": "Guam",
      "phoneCode": "+1671",
      "defaultCurrency": "USD"
    },
    {
      "code": "gt",
      "name": "Guatemala",
      "phoneCode": "+502",
      "defaultCurrency": "GTQ"
    },
    {
      "code": "gn",
      "name": "Guinea",
      "phoneCode": "+224",
      "defaultCurrency": "GNF"
    },
    {
      "code": "gw",
      "name": "Guinea-Bissau",
      "phoneCode": "+245",
      "defaultCurrency": "XOF"
    },
    {
      "code": "gy",
      "name": "Guyana",
      "phoneCode": "+592",
      "defaultCurrency": "GYD"
    },
    {
      "code": "ht",
      "name": "Haiti",
      "phoneCode": "+509",
      "defaultCurrency": "HTG"
    },
    {
      "code": "hn",
      "name": "Honduras",
      "phoneCode": "+504",
      "defaultCurrency": "HNL"
    },
    {
      "code": "hk",
      "name": "Hong Kong",
      "phoneCode": "+852",
      "defaultCurrency": "HKD"
    },
    {
      "code": "hu",
      "name": "Hungary",
      "phoneCode": "+36",
      "defaultCurrency": "HUF"
    },
    {
      "code": "is",
      "name": "Iceland",
      "phoneCode": "+354",
      "defaultCurrency": "ISK"
    },
    {
      "code": "in",
      "name": "India",
      "phoneCode": "+91",
      "defaultCurrency": "INR"
    },
    {
      "code": "id",
      "name": "Indonesia",
      "phoneCode": "+62",
      "defaultCurrency": "IDR"
    },
    {
      "code": "ir",
      "name": "Iran",
      "phoneCode": "+98",
      "defaultCurrency": "IRR"
    },
    {
      "code": "iq",
      "name": "Iraq",
      "phoneCode": "+964",
      "defaultCurrency": "IQD"
    },
    {
      "code": "ie",
      "name": "Ireland",
      "phoneCode": "+353",
      "defaultCurrency": "EUR"
    },
    {
      "code": "il",
      "name": "Israel",
      "phoneCode": "+972",
      "defaultCurrency": "ILS"
    },
    {
      "code": "it",
      "name": "Italy",
      "phoneCode": "+39",
      "defaultCurrency": "EUR"
    },
    {
      "code": "ci",
      "name": "Ivory Coast",
      "phoneCode": "+225",
      "defaultCurrency": "XOF"
    },
    {
      "code": "jm",
      "name": "Jamaica",
      "phoneCode": "+1876",
      "defaultCurrency": "JMD"
    },
    {
      "code": "jp",
      "name": "Japan",
      "phoneCode": "+81",
      "defaultCurrency": "JPY"
    },
    {
      "code": "jo",
      "name": "Jordan",
      "phoneCode": "+962",
      "defaultCurrency": "JOD"
    },
    {
      "code": "kz",
      "name": "Kazakhstan",
      "phoneCode": "+7",
      "defaultCurrency": "KZT"
    },
    {
      "code": "ke",
      "name": "Kenya",
      "phoneCode": "+254",
      "defaultCurrency": "KES"
    },
    {
      "code": "ki",
      "name": "Kiribati",
      "phoneCode": "+686",
      "defaultCurrency": "AUD"
    },
    {
      "code": "xk",
      "name": "Kosovo",
      "phoneCode": "+383",
      "defaultCurrency": "EUR"
    },
    {
      "code": "kw",
      "name": "Kuwait",
      "phoneCode": "+965",
      "defaultCurrency": "KWD"
    },
    {


      "code": "kg",
      "name": "Kyrgyzstan",
      "phoneCode": "+996",
      "defaultCurrency": "KGS"
    },
    {
      "code": "la",
      "name": "Laos",
      "phoneCode": "+856",
      "defaultCurrency": "LAK"
    },
    {
      "code": "lv",
      "name": "Latvia",
      "phoneCode": "+371",
      "defaultCurrency": "EUR"
    },
    {
      "code": "lb",
      "name": "Lebanon",
      "phoneCode": "+961",
      "defaultCurrency": "LBP"
    },
    {
      "code": "ls",
      "name": "Lesotho",
      "phoneCode": "+266",
      "defaultCurrency": "LSL"
    },
    {
      "code": "lr",
      "name": "Liberia",
      "phoneCode": "+231",
      "defaultCurrency": "LRD"
    },
    {
      "code": "ly",
      "name": "Libya",
      "phoneCode": "+218",
      "defaultCurrency": "LYD"
    },
    {
      "code": "li",
      "name": "Liechtenstein",
      "phoneCode": "+423",
      "defaultCurrency": "CHF"
    },
    {
      "code": "lt",
      "name": "Lithuania",
      "phoneCode": "+370",
      "defaultCurrency": "EUR"
    },
    {
      "code": "lu",
      "name": "Luxembourg",
      "phoneCode": "+352",
      "defaultCurrency": "EUR"
    },
    {
      "code": "mo",
      "name": "Macau",
      "phoneCode": "+853",
      "defaultCurrency": "MOP"
    },
    {
      "code": "mk",
      "name": "Macedonia",
      "phoneCode": "+389",
      "defaultCurrency": "MKD"
    },
    {
      "code": "mg",
      "name": "Madagascar",
      "phoneCode": "+261",
      "defaultCurrency": "MGA"
    },
    {
      "code": "mw",
      "name": "Malawi",
      "phoneCode": "+265",
      "defaultCurrency": "MWK"
    },
    {
      "code": "my",
      "name": "Malaysia",
      "phoneCode": "+60",
      "defaultCurrency": "MYR"
    },
    {
      "code": "mv",
      "name": "Maldives",
      "phoneCode": "+960",
      "defaultCurrency": "MVR"
    },
    {
      "code": "ml",
      "name": "Mali",
      "phoneCode": "+223",
      "defaultCurrency": "XOF"
    },
    {
      "code": "mt",
      "name": "Malta",
      "phoneCode": "+356",
      "defaultCurrency": "EUR"
    },
    {
      "code": "mh",
      "name": "Marshall Islands",
      "phoneCode": "+692",
      "defaultCurrency": "USD"
    },
    {
      "code": "mq",
      "name": "Martinique",
      "phoneCode": "+596",
      "defaultCurrency": "EUR"
    },
    {
      "code": "mr",
      "name": "Mauritania",
      "phoneCode": "+222",
      "defaultCurrency": "MRU"
    },
    {
      "code": "mu",
      "name": "Mauritius",
      "phoneCode": "+230",
      "defaultCurrency": "MUR"
    },
    {
      "code": "yt",
      "name": "Mayotte",
      "phoneCode": "+262",
      "defaultCurrency": "EUR"
    },
    {
      "code": "mx",
      "name": "Mexico",
      "phoneCode": "+52",
      "defaultCurrency": "MXN"
    },
    {
      "code": "fm",
      "name": "Micronesia",
      "phoneCode": "+691",
      "defaultCurrency": "USD"
    },
    {
      "code": "md",
      "name": "Moldova",
      "phoneCode": "+373",
      "defaultCurrency": "MDL"
    },
    {
      "code": "mc",
      "name": "Monaco",
      "phoneCode": "+377",
      "defaultCurrency": "EUR"
    },
    {
      "code": "mn",
      "name": "Mongolia",
      "phoneCode": "+976",
      "defaultCurrency": "MNT"
    },
    {
      "code": "me",
      "name": "Montenegro",
      "phoneCode": "+382",
      "defaultCurrency": "EUR"
    },
    {
      "code": "ms",
      "name": "Montserrat",
      "phoneCode": "+1664",
      "defaultCurrency": "XCD"
    },
    {
      "code": "ma",
      "name": "Morocco",
      "phoneCode": "+212",
      "defaultCurrency": "MAD"
    },
    {
      "code": "mz",
      "name": "Mozambique",
      "phoneCode": "+258",
      "defaultCurrency": "MZN"
    },
    {
      "code": "mm",
      "name": "Myanmar",
      "phoneCode": "+95",
      "defaultCurrency": "MMK"
    },
    {
      "code": "na",
      "name": "Namibia",
      "phoneCode": "+264",
      "defaultCurrency": "NAD"
    },
    {
      "code": "nr",
      "name": "Nauru",
      "phoneCode": "+674",
      "defaultCurrency": "AUD"
    },
    {
      "code": "np",
      "name": "Nepal",
      "phoneCode": "+977",
      "defaultCurrency": "NPR"
    },
    {
      "code": "nl",
      "name": "Netherlands",
      "phoneCode": "+31",
      "defaultCurrency": "EUR"
    },
    {
      "code": "nc",
      "name": "New Caledonia",
      "phoneCode": "+687",
      "defaultCurrency": "XPF"
    },
    {
      "code": "nz",
      "name": "New Zealand",
      "phoneCode": "+64",
      "defaultCurrency": "NZD"
    },
    {
      "code": "ni",
      "name": "Nicaragua",
      "phoneCode": "+505",
      "defaultCurrency": "NIO"
    },
    {
      "code": "ne",
      "name": "Niger",
      "phoneCode": "+227",
      "defaultCurrency": "XOF"
    },
    {
      "code": "ng",
      "name": "Nigeria",
      "phoneCode": "+234",
      "defaultCurrency": "NGN"
    },
    {
      "code": "nu",
      "name": "Niue",
      "phoneCode": "+683",
      "defaultCurrency": "NZD"
    },
    {
      "code": "nf",
      "name": "Norfolk Island",
      "phoneCode": "+672",
      "defaultCurrency": "AUD"
    },
    {
      "code": "kp",
      "name": "North Korea",
      "phoneCode": "+850",
      "defaultCurrency": "KPW"
    },
    {
      "code": "mp",
      "name": "Northern Mariana Islands",
      "phoneCode": "+1670",
      "defaultCurrency": "USD"
    },
    {
      "code": "no",
      "name": "Norway",
      "phoneCode": "+47",
      "defaultCurrency": "NOK"
    },
    {
      "code": "om",
      "name": "Oman",
      "phoneCode": "+968",
      "defaultCurrency": "OMR"
    },
    {
      "code": "pk",
      "name": "Pakistan",
      "phoneCode": "+92",
      "defaultCurrency": "PKR"
    },
    {
      "code": "pw",
      "name": "Palau",
      "phoneCode": "+680",
      "defaultCurrency": "USD"
    },
    {
      "code": "ps",
      "name": "Palestine",
      "phoneCode": "+970",
      "defaultCurrency": "ILS"
    },
    {
      "code": "pa",
      "name": "Panama",
      "phoneCode": "+507",
      "defaultCurrency": "PAB"
    },
    {
      "code": "pg",
      "name": "Papua New Guinea",
      "phoneCode": "+675",
      "defaultCurrency": "PGK"
    },
    {
      "code": "py",
      "name": "Paraguay",
      "phoneCode": "+595",
      "defaultCurrency": "PYG"
    },
    {
      "code": "pe",
      "name": "Peru",
      "phoneCode": "+51",
      "defaultCurrency": "PEN"
    },
    {
      "code": "ph",
      "name": "Philippines",
      "phoneCode": "+63",
      "defaultCurrency": "PHP"
    },
    {
      "code": "pn",
      "name": "Pitcairn",
      "phoneCode": "+64",
      "defaultCurrency": "NZD"
    },
    {
      "code": "pl",
      "name": "Poland",
      "phoneCode": "+48",
      "defaultCurrency": "PLN"
    },
    {
      "code": "pt",
      "name": "Portugal",
      "phoneCode": "+351",
      "defaultCurrency": "EUR"
    },
    {
      "code": "pr",
      "name": "Puerto Rico",
      "phoneCode": "+1787",
      "defaultCurrency": "USD"
    },
    {
      "code": "qa",
      "name": "Qatar",
      "phoneCode": "+974",
      "defaultCurrency": "QAR"
    },
    {
      "code": "re",
      "name": "Réunion",
      "phoneCode": "+262",
      "defaultCurrency": "EUR"
    },
    {
      "code": "ro",
      "name": "Romania",
      "phoneCode": "+40",
      "defaultCurrency": "RON"
    },
    {
      "code": "ru",
      "name": "Russia",
      "phoneCode": "+7",
      "defaultCurrency": "RUB"
    },
    {
      "code": "rw",
      "name": "Rwanda",
      "phoneCode": "+250",
      "defaultCurrency": "RWF"
    },
    {
      "code": "bl",
      "name": "Saint Barthélemy",
      "phoneCode": "+590",
      "defaultCurrency": "EUR"
    },
    {
      "code": "sh",
      "name": "Saint Helena",
      "phoneCode": "+290",
      "defaultCurrency": "SHP"
    },
    {
      "code": "kn",
      "name": "Saint Kitts and Nevis",
      "phoneCode": "+1869",
      "defaultCurrency": "XCD"
    },
    {
      "code": "lc",
      "name": "Saint Lucia",
      "phoneCode": "+1758",
      "defaultCurrency": "XCD"
    },
    {
      "code": "mf",
      "name": "Saint Martin (French part)",
      "phoneCode": "+590",
      "defaultCurrency": "EUR"
    },
    {
      "code": "pm",
      "name": "Saint Pierre and Miquelon",
      "phoneCode": "+508",
      "defaultCurrency": "EUR"
    },
    {
      "code": "vc",
      "name": "Saint Vincent and the Grenadines",
      "phoneCode": "+1784",
      "defaultCurrency": "XCD"
    },
    {
      "code": "ws",
      "name": "Samoa",
      "phoneCode": "+685",
      "defaultCurrency": "WST"
    },
    {
      "code": "sm",
      "name": "San Marino",
      "phoneCode": "+378",
      "defaultCurrency": "EUR"
    },
    {
      "code": "st",
      "name": "Sao Tome and Principe",
      "phoneCode": "+239",
      "defaultCurrency": "STN"
    },
    {
      "code": "sa",
      "name": "Saudi Arabia",
      "phoneCode": "+966",
      "defaultCurrency": "SAR"
    },
    {
      "code": "sn",
      "name": "Senegal",
      "phoneCode": "+221",
      "defaultCurrency": "XOF"
    },
    {
      "code": "rs",
      "name": "Serbia",
      "phoneCode": "+381",
      "defaultCurrency": "RSD"
    },
    {
      "code": "sc",
      "name": "Seychelles",
      "phoneCode": "+248",
      "defaultCurrency": "SCR"
    },
    {
      "code": "sl",
      "name": "Sierra Leone",
      "phoneCode": "+232",
      "defaultCurrency": "SLL"
    },
    {
      "code": "sg",
      "name": "Singapore",
      "phoneCode": "+65",
      "defaultCurrency": "SGD"
    },
    {
      "code": "sk",
      "name": "Slovakia",
      "phoneCode": "+421",
      "defaultCurrency": "EUR"
    },
    {
      "code": "si",
      "name": "Slovenia",
      "phoneCode": "+386",
      "defaultCurrency": "EUR"
    },
    {
      "code": "sb",
      "name": "Solomon Islands",
      "phoneCode": "+677",
      "defaultCurrency": "SBD"
    },
    {
      "code": "so",
      "name": "Somalia",
      "phoneCode": "+252",
      "defaultCurrency": "SOS"
    },
    {
      "code": "za",
      "name": "South Africa",
      "phoneCode": "+27",
      "defaultCurrency": "ZAR"
    },
    {
      "code": "kr",
      "name": "South Korea",
      "phoneCode": "+82",
      "defaultCurrency": "KRW"
    },
    {
      "code": "ss",
      "name": "South Sudan",
      "phoneCode": "+211",
      "defaultCurrency": "SSP"
    },
    {
      "code": "es",
      "name": "Spain",
      "phoneCode": "+34",
      "defaultCurrency": "EUR"
    },
    {
      "code": "lk",
      "name": "Sri Lanka",
      "phoneCode": "+94",
      "defaultCurrency": "LKR"
    },
    {
      "code": "sd",
      "name": "Sudan",
      "phoneCode": "+249",
      "defaultCurrency": "SDG"
    },
    {
      "code": "sr",
      "name": "Suriname",
      "phoneCode": "+597",
      "defaultCurrency": "SRD"
    },
    {
      "code": "sz",
      "name": "Swaziland",
      "phoneCode": "+268",
      "defaultCurrency": "SZL"
    },
    {
      "code": "se",
      "name": "Sweden",
      "phoneCode": "+46",
      "defaultCurrency": "SEK"
    },
    {
      "code": "ch",
      "name": "Switzerland",
      "phoneCode": "+41",
      "defaultCurrency": "CHF"
    },
    {
      "code": "sy",
      "name": "Syria",
      "phoneCode": "+963",
      "defaultCurrency": "SYP"
    },
    {
      "code": "tw",
      "name": "Taiwan",
      "phoneCode": "+886",
      "defaultCurrency": "TWD"
    },
    {
      "code": "tj",
      "name": "Tajikistan",
      "phoneCode": "+992",
      "defaultCurrency": "TJS"
    },
    {
      "code": "tz",
      "name": "Tanzania",
      "phoneCode": "+255",
      "defaultCurrency": "TZS"
    },
    {
      "code": "th",
      "name": "Thailand",
      "phoneCode": "+66",
      "defaultCurrency": "THB"
    },
    {
      "code": "tg",
      "name": "Togo",
      "phoneCode": "+228",
      "defaultCurrency": "XOF"
    },
    {
      "code": "tk",
      "name": "Tokelau",
      "phoneCode": "+690",
      "defaultCurrency": "NZD"
    },
    {
      "code": "to",
      "name": "Tonga",
      "phoneCode": "+676",
      "defaultCurrency": "TOP"
    },
    {
      "code": "tt",
      "name": "Trinidad and Tobago",
      "phoneCode": "+1868",
      "defaultCurrency": "TTD"
    },
    {
      "code": "tn",
      "name": "Tunisia",
      "phoneCode": "+216",
      "defaultCurrency": "TND"
    },
    {
      "code": "tr",
      "name": "Turkey",
      "phoneCode": "+90",
      "defaultCurrency": "TRY"
    },
    {
      "code": "tm",
      "name": "Turkmenistan",
      "phoneCode": "+993",
      "defaultCurrency": "TMT"
    },
    {
      "code": "tc",
      "name": "Turks and Caicos Islands",
      "phoneCode": "+1649",
      "defaultCurrency": "USD"
    },
    {
      "code": "tv",
      "name": "Tuvalu",
      "phoneCode": "+688",
      "defaultCurrency": "AUD"
    },
    {
      "code": "ug",
      "name": "Uganda",
      "phoneCode": "+256",
      "defaultCurrency": "UGX"
    },
    {
      "code": "ua",
      "name": "Ukraine",
      "phoneCode": "+380",
      "defaultCurrency": "UAH"
    },
    {
      "code": "ae",
      "name": "United Arab Emirates",
      "phoneCode": "+971",
      "defaultCurrency": "AED"
    },
    {
      "code": "gb",
      "name": "United Kingdom",
      "phoneCode": "+44",
      "defaultCurrency": "GBP"
    },
    {
      "code": "us",
      "name": "United States",
      "phoneCode": "+1",
      "defaultCurrency": "USD"
    },
    {
      "code": "uy",
      "name": "Uruguay",
      "phoneCode": "+598",
      "defaultCurrency": "UYU"
    },
    {
      "code": "uz",
      "name": "Uzbekistan",
      "phoneCode": "+998",
      "defaultCurrency": "UZS"
    },
    {
      "code": "vu",
      "name": "Vanuatu",
      "phoneCode": "+678",
      "defaultCurrency": "VUV"
    },
    {
      "code": "va",
      "name": "Vatican City",
      "phoneCode": "+39",
      "defaultCurrency": "EUR"
    },
    {
      "code": "ve",
      "name": "Venezuela",
      "phoneCode": "+58",
      "defaultCurrency": "VES"
    },
    {
      "code": "vn",
      "name": "Vietnam",
      "phoneCode": "+84",
      "defaultCurrency": "VND"
    },
    {
      "code": "wf",
      "name": "Wallis and Futuna",
      "phoneCode": "+681",
      "defaultCurrency": "XPF"
    },
    {
      "code": "eh",
      "name": "Western Sahara",
      "phoneCode": "+212",
      "defaultCurrency": "MAD"
    },
    {
      "code": "ye",
      "name": "Yemen",
      "phoneCode": "+967",
      "defaultCurrency": "YER"
    },
    {
      "code": "zm",
      "name": "Zambia",
      "phoneCode": "+260",
      "defaultCurrency": "ZMW"
    },
    {
      "code": "zw",
      "name": "Zimbabwe",
      "phoneCode": "+263",
      "defaultCurrency": "ZWL"
    }
  ];
//  [
//    {
//        code: "af",
//        name: "Afghanistan",
//        phoneCode: "+93"
//    },
//    {
//        code: "ax",
//        name: "Aland Islands",
//        phoneCode: "+358"
//    },
//    {
//        code: "al",
//        name: "Albania",
//        phoneCode: "+355"
//    },
//    {
//        code: "dz",
//        name: "Algeria",
//        phoneCode: "+213"
//    },
//    {
//        code: "as",
//        name: "American Samoa",
//        phoneCode: "+1684"
//    },
//    {
//        code: "ad",
//        name: "Andorra",
//        phoneCode: "+376"
//    },
//    {
//        code: "ao",
//        name: "Angola",
//        phoneCode: "+244"
//    },
//    {
//        code: "ai",
//        name: "Anguilla",
//        phoneCode: "+1264"
//    },
//    {
//        code: "aq",
//        name: "Antarctica",
//        phoneCode: "+672"
//    },
//    {
//        code: "ag",
//        name: "Antigua and Barbuda",
//        phoneCode: "+1268"
//    },
//    {
//        code: "ar",
//        name: "Argentina",
//        phoneCode: "+54"
//    },
//    {
//        code: "am",
//        name: "Armenia",
//        phoneCode: "+374"
//    },
//    {
//        code: "aw",
//        name: "Aruba",
//        phoneCode: "+297"
//    },
//    {
//        code: "ac",
//        name: "Ascension Island",
//        phoneCode: "+247"
//    },
//    {
//        code: "au",
//        name: "Australia",
//        phoneCode: "+61"
//    },
//    {
//        code: "at",
//        name: "Austria",
//        phoneCode: "+43"
//    },
//    {
//        code: "az",
//        name: "Azerbaijan",
//        phoneCode: "+994"
//    },
//    {
//        code: "bs",
//        name: "Bahamas",
//        phoneCode: "+1242"
//    },
//    {
//        code: "bh",
//        name: "Bahrain",
//        phoneCode: "+973"
//    },
//    {
//        code: "bd",
//        name: "Bangladesh",
//        phoneCode: "+880"
//    },
//    {
//        code: "bb",
//        name: "Barbados",
//        phoneCode: "+1246"
//    },
//    {
//        code: "by",
//        name: "Belarus",
//        phoneCode: "+375"
//    },
//    {
//        code: "be",
//        name: "Belgium",
//        phoneCode: "+32"
//    },
//    {
//        code: "bz",
//        name: "Belize",
//        phoneCode: "+501"
//    },
//    {
//        code: "bj",
//        name: "Benin",
//        phoneCode: "+229"
//    },
//    {
//        code: "bm",
//        name: "Bermuda",
//        phoneCode: "+1441"
//    },
//    {
//        code: "bt",
//        name: "Bhutan",
//        phoneCode: "+975"
//    },
//    {
//        code: "bo",
//        name: "Bolivia",
//        phoneCode: "+591"
//    },
//    {
//        code: "ba",
//        name: "Bosnia and Herzegovina",
//        phoneCode: "+387"
//    },
//    {
//        code: "bw",
//        name: "Botswana",
//        phoneCode: "+267"
//    },
//    {
//        code: "br",
//        name: "Brazil",
//        phoneCode: "+55"
//    },
//    {
//        code: "io",
//        name: "British Indian Ocean Territory",
//        phoneCode: "+246"
//    },
//    {
//        code: "bn",
//        name: "Brunei Darussalam",
//        phoneCode: "+673"
//    },
//    {
//        code: "bg",
//        name: "Bulgaria",
//        phoneCode: "+359"
//    },
//    {
//        code: "bf",
//        name: "Burkina Faso",
//        phoneCode: "+226"
//    },
//    {
//        code: "bi",
//        name: "Burundi",
//        phoneCode: "+257"
//    },
//    {
//        code: "kh",
//        name: "Cambodia",
//        phoneCode: "+855"
//    },
//    {
//        code: "cm",
//        name: "Cameroon",
//        phoneCode: "+237"
//    },
//    {
//        code: "ca",
//        name: "Canada",
//        phoneCode: "+1"
//    },
//    {
//        code: "cv",
//        name: "Cape Verde",
//        phoneCode: "+238"
//    },
//    {
//        code: "ky",
//        name: "Cayman Islands",
//        phoneCode: "+1345"
//    },
//    {
//        code: "cf",
//        name: "Central African Republic",
//        phoneCode: "+236"
//    },
//    {
//        code: "td",
//        name: "Chad",
//        phoneCode: "+235"
//    },
//    {
//        code: "cl",
//        name: "Chile",
//        phoneCode: "+56"
//    },
//    {
//        code: "cn",
//        name: "China",
//        phoneCode: "+86"
//    },
//    {
//        code: "cx",
//        name: "Christmas Island",
//        phoneCode: "+61"
//    },
//    {
//        code: "cc",
//        name: "Cocos (Keeling) Islands",
//        phoneCode: "+61"
//    },
//    {
//        code: "co",
//        name: "Colombia",
//        phoneCode: "+57"
//    },
//    {
//        code: "km",
//        name: "Comoros",
//        phoneCode: "+269"
//    },
//    {
//        code: "cg",
//        name: "Congo",
//        phoneCode: "+242"
//    },
//    {
//        code: "ck",
//        name: "Cook Islands",
//        phoneCode: "+682"
//    },
//    {
//        code: "cr",
//        name: "Costa Rica",
//        phoneCode: "+506"
//    },
//    {
//        code: "hr",
//        name: "Croatia",
//        phoneCode: "+385"
//    },
//    {
//        code: "cu",
//        name: "Cuba",
//        phoneCode: "+53"
//    },
//    {
//        code: "cy",
//        name: "Cyprus",
//        phoneCode: "+357"
//    },
//    {
//        code: "cz",
//        name: "Czech Republic",
//        phoneCode: "+420"
//    },
//    {
//        code: "cd",
//        name: "Democratic Republic of the Congo",
//        phoneCode: "+243"
//    },
//    {
//        code: "dk",
//        name: "Denmark",
//        phoneCode: "+45"
//    },
//    {
//        code: "dj",
//        name: "Djibouti",
//        phoneCode: "+253"
//    },
//    {
//        code: "dm",
//        name: "Dominica",
//        phoneCode: "+1767"
//    },
//    {
//        code: "do",
//        name: "Dominican Republic",
//        phoneCode: "+1849"
//    },
//    {
//        code: "ec",
//        name: "Ecuador",
//        phoneCode: "+593"
//    },
//    {
//        code: "eg",
//        name: "Egypt",
//        phoneCode: "+20"
//    },
//    {
//        code: "sv",
//        name: "El Salvador",
//        phoneCode: "+503"
//    },
//    {
//        code: "gq",
//        name: "Equatorial Guinea",
//        phoneCode: "+240"
//    },
//    {
//        code: "er",
//        name: "Eritrea",
//        phoneCode: "+291"
//    },
//    {
//        code: "ee",
//        name: "Estonia",
//        phoneCode: "+372"
//    },
//    {
//        code: "sz",
//        name: "Eswatini",
//        phoneCode: "+268"
//    },
//    {
//        code: "et",
//        name: "Ethiopia",
//        phoneCode: "+251"
//    },
//    {
//        code: "fk",
//        name: "Falkland Islands (Malvinas)",
//        phoneCode: "+500"
//    },
//    {
//        code: "fo",
//        name: "Faroe Islands",
//        phoneCode: "+298"
//    },
//    {
//        code: "fj",
//        name: "Fiji",
//        phoneCode: "+679"
//    },
//    {
//        code: "fi",
//        name: "Finland",
//        phoneCode: "+358"
//    },
//    {
//        code: "fr",
//        name: "France",
//        phoneCode: "+33"
//    },
//    {
//        code: "gf",
//        name: "French Guiana",
//        phoneCode: "+594"
//    },
//    {
//        code: "pf",
//        name: "French Polynesia",
//        phoneCode: "+689"
//    },
//    {
//        code: "ga",
//        name: "Gabon",
//        phoneCode: "+241"
//    },
//    {
//        code: "gm",
//        name: "Gambia",
//        phoneCode: "+220"
//    },
//    {
//        code: "ge",
//        name: "Georgia",
//        phoneCode: "+995"
//    },
//    {
//        code: "de",
//        name: "Germany",
//        phoneCode: "+49"
//    },
//    {
//        code: "gh",
//        name: "Ghana",
//        phoneCode: "+233"
//    },
//    {
//        code: "gi",
//        name: "Gibraltar",
//        phoneCode: "+350"
//    },
//    {
//        code: "gr",
//        name: "Greece",
//        phoneCode: "+30"
//    },
//    {
//        code: "gl",
//        name: "Greenland",
//        phoneCode: "+299"
//    },
//    {
//        code: "gd",
//        name: "Grenada",
//        phoneCode: "+1473"
//    },
//    {
//        code: "gp",
//        name: "Guadeloupe",
//        phoneCode: "+590"
//    },
//    {
//        code: "gu",
//        name: "Guam",
//        phoneCode: "+1671"
//    },
//    {
//        code: "gt",
//        name: "Guatemala",
//        phoneCode: "+502"
//    },
//    {
//        code: "gg",
//        name: "Guernsey",
//        phoneCode: "+44"
//    },
//    {
//        code: "gn",
//        name: "Guinea",
//        phoneCode: "+224"
//    },
//    {
//        code: "gw",
//        name: "Guinea-Bissau",
//        phoneCode: "+245"
//    },
//    {
//        code: "gy",
//        name: "Guyana",
//        phoneCode: "+592"
//    },
//    {
//        code: "ht",
//        name: "Haiti",
//        phoneCode: "+509"
//    },
//    {
//        code: "va",
//        name: "Holy See (Vatican City State)",
//        phoneCode: "+379"
//    },
//    {
//        code: "hn",
//        name: "Honduras",
//        phoneCode: "+504"
//    },
//    {
//        code: "hk",
//        name: "Hong Kong",
//        phoneCode: "+852"
//    },
//    {
//        code: "hu",
//        name: "Hungary",
//        phoneCode: "+36"
//    },
//    {
//        code: "is",
//        name: "Iceland",
//        phoneCode: "+354"
//    },
//    {
//        code: "in",
//        name: "India",
//        phoneCode: "+91"
//    },
//    {
//        code: "id",
//        name: "Indonesia",
//        phoneCode: "+62"
//    },
//    {
//        code: "ir",
//        name: "Iran",
//        phoneCode: "+98"
//    },
//    {
//        code: "iq",
//        name: "Iraq",
//        phoneCode: "+964"
//    },
//    {
//        code: "ie",
//        name: "Ireland",
//        phoneCode: "+353"
//    },
//    {
//        code: "im",
//        name: "Isle of Man",
//        phoneCode: "+44"
//    },
//    {
//        code: "it",
//        name: "Italy",
//        phoneCode: "+39"
//    },
//    {
//        code: "ci",
//        name: "Ivory Coast / Cote d'Ivoire",
//        phoneCode: "+225"
//    },
//    {
//        code: "jm",
//        name: "Jamaica",
//        phoneCode: "+1876"
//    },
//    {
//        code: "jp",
//        name: "Japan",
//        phoneCode: "+81"
//    },
//    {
//        code: "je",
//        name: "Jersey",
//        phoneCode: "+44"
//    },
//    {
//        code: "jo",
//        name: "Jordan",
//        phoneCode: "+962"
//    },
//    {
//        code: "kz",
//        name: "Kazakhstan",
//        phoneCode: "+77"
//    },
//    {
//        code: "ke",
//        name: "Kenya",
//        phoneCode: "+254"
//    },
//    {
//        code: "ki",
//        name: "Kiribati",
//        phoneCode: "+686"
//    },
//    {
//        code: "kp",
//        name: "Korea, Democratic People's Republic of Korea",
//        phoneCode: "+850"
//    },
//    {
//        code: "kr",
//        name: "Korea, Republic of South Korea",
//        phoneCode: "+82"
//    },
//    {
//        code: "xk",
//        name: "Kosovo",
//        phoneCode: "+383"
//    },
//    {
//        code: "kw",
//        name: "Kuwait",
//        phoneCode: "+965"
//    },
//    {
//        code: "kg",
//        name: "Kyrgyzstan",
//        phoneCode: "+996"
//    },
//    {
//        code: "la",
//        name: "Laos",
//        phoneCode: "+856"
//    },
//    {
//        code: "lv",
//        name: "Latvia",
//        phoneCode: "+371"
//    },
//    {
//        code: "lb",
//        name: "Lebanon",
//        phoneCode: "+961"
//    },
//    {
//        code: "ls",
//        name: "Lesotho",
//        phoneCode: "+266"
//    },
//    {
//        code: "lr",
//        name: "Liberia",
//        phoneCode: "+231"
//    },
//    {
//        code: "ly",
//        name: "Libya",
//        phoneCode: "+218"
//    },
//    {
//        code: "li",
//        name: "Liechtenstein",
//        phoneCode: "+423"
//    },
//    {
//        code: "lt",
//        name: "Lithuania",
//        phoneCode: "+370"
//    },
//    {
//        code: "lu",
//        name: "Luxembourg",
//        phoneCode: "+352"
//    },
//    {
//        code: "mo",
//        name: "Macau",
//        phoneCode: "+853"
//    },
//    {
//        code: "mg",
//        name: "Madagascar",
//        phoneCode: "+261"
//    },
//    {
//        code: "mw",
//        name: "Malawi",
//        phoneCode: "+265"
//    },
//    {
//        code: "my",
//        name: "Malaysia",
//        phoneCode: "+60"
//    },
//    {
//        code: "mv",
//        name: "Maldives",
//        phoneCode: "+960"
//    },
//    {
//        code: "ml",
//        name: "Mali",
//        phoneCode: "+223"
//    },
//    {
//        code: "mt",
//        name: "Malta",
//        phoneCode: "+356"
//    },
//    {
//        code: "mh",
//        name: "Marshall Islands",
//        phoneCode: "+692"
//    },
//    {
//        code: "mq",
//        name: "Martinique",
//        phoneCode: "+596"
//    },
//    {
//        code: "mr",
//        name: "Mauritania",
//        phoneCode: "+222"
//    },
//    {
//        code: "mu",
//        name: "Mauritius",
//        phoneCode: "+230"
//    },
//    {
//        code: "yt",
//        name: "Mayotte",
//        phoneCode: "+262"
//    },
//    {
//        code: "mx",
//        name: "Mexico",
//        phoneCode: "+52"
//    },
//    {
//        code: "fm",
//        name: "Micronesia, Federated States of Micronesia",
//        phoneCode: "+691"
//    },
//    {
//        code: "md",
//        name: "Moldova",
//        phoneCode: "+373"
//    },
//    {
//        code: "mc",
//        name: "Monaco",
//        phoneCode: "+377"
//    },
//    {
//        code: "mn",
//        name: "Mongolia",
//        phoneCode: "+976"
//    },
//    {
//        code: "me",
//        name: "Montenegro",
//        phoneCode: "+382"
//    },
//    {
//        code: "ms",
//        name: "Montserrat",
//        phoneCode: "+1664"
//    },
//    {
//        code: "ma",
//        name: "Morocco",
//        phoneCode: "+212"
//    },
//    {
//        code: "mz",
//        name: "Mozambique",
//        phoneCode: "+258"
//    },
//    {
//        code: "mm",
//        name: "Myanmar",
//        phoneCode: "+95"
//    },
//    {
//        code: "na",
//        name: "Namibia",
//        phoneCode: "+264"
//    },
//    {
//        code: "nr",
//        name: "Nauru",
//        phoneCode: "+674"
//    },
//    {
//        code: "np",
//        name: "Nepal",
//        phoneCode: "+977"
//    },
//    {
//        code: "nl",
//        name: "Netherlands",
//        phoneCode: "+31"
//    },
//    {
//        code: "an",
//        name: "Netherlands Antilles",
//        phoneCode: "+599"
//    },
//    {
//        code: "nc",
//        name: "New Caledonia",
//        phoneCode: "+687"
//    },
//    {
//        code: "nz",
//        name: "New Zealand",
//        phoneCode: "+64"
//    },
//    {
//        code: "ni",
//        name: "Nicaragua",
//        phoneCode: "+505"
//    },
//    {
//        code: "ne",
//        name: "Niger",
//        phoneCode: "+227"
//    },
//    {
//        code: "ng",
//        name: "Nigeria",
//        phoneCode: "+234"
//    },
//    {
//        code: "nu",
//        name: "Niue",
//        phoneCode: "+683"
//    },
//    {
//        code: "nf",
//        name: "Norfolk Island",
//        phoneCode: "+672"
//    },
//    {
//        code: "mk",
//        name: "North Macedonia",
//        phoneCode: "+389"
//    },
//    {
//        code: "mp",
//        name: "Northern Mariana Islands",
//        phoneCode: "+1670"
//    },
//    {
//        code: "no",
//        name: "Norway",
//        phoneCode: "+47"
//    },
//    {
//        code: "om",
//        name: "Oman",
//        phoneCode: "+968"
//    },
//    {
//        code: "pk",
//        name: "Pakistan",
//        phoneCode: "+92"
//    },
//    {
//        code: "pw",
//        name: "Palau",
//        phoneCode: "+680"
//    },
//    {
//        code: "ps",
//        name: "Palestine",
//        phoneCode: "+970"
//    },
//    {
//        code: "pa",
//        name: "Panama",
//        phoneCode: "+507"
//    },
//    {
//        code: "pg",
//        name: "Papua New Guinea",
//        phoneCode: "+675"
//    },
//    {
//        code: "py",
//        name: "Paraguay",
//        phoneCode: "+595"
//    },
//    {
//        code: "pe",
//        name: "Peru",
//        phoneCode: "+51"
//    },
//    {
//        code: "ph",
//        name: "Philippines",
//        phoneCode: "+63"
//    },
//    {
//        code: "pn",
//        name: "Pitcairn",
//        phoneCode: "+872"
//    },
//    {
//        code: "pl",
//        name: "Poland",
//        phoneCode: "+48"
//    },
//    {
//        code: "pt",
//        name: "Portugal",
//        phoneCode: "+351"
//    },
//    {
//        code: "pr",
//        name: "Puerto Rico",
//        phoneCode: "+1939"
//    },
//    {
//        code: "qa",
//        name: "Qatar",
//        phoneCode: "+974"
//    },
//    {
//        code: "re",
//        name: "Reunion",
//        phoneCode: "+262"
//    },
//    {
//        code: "ro",
//        name: "Romania",
//        phoneCode: "+40"
//    },
//    {
//        code: "ru",
//        name: "Russia",
//        phoneCode: "+7"
//    },
//    {
//        code: "rw",
//        name: "Rwanda",
//        phoneCode: "+250"
//    },
//    {
//        code: "bl",
//        name: "Saint Barthelemy",
//        phoneCode: "+590"
//    },
//    {
//        code: "sh",
//        name: "Saint Helena, Ascension and Tristan Da Cunha",
//        phoneCode: "+290"
//    },
//    {
//        code: "kn",
//        name: "Saint Kitts and Nevis",
//        phoneCode: "+1869"
//    },
//    {
//        code: "lc",
//        name: "Saint Lucia",
//        phoneCode: "+1758"
//    },
//    {
//        code: "mf",
//        name: "Saint Martin",
//        phoneCode: "+590"
//    },
//    {
//        code: "pm",
//        name: "Saint Pierre and Miquelon",
//        phoneCode: "+508"
//    },
//    {
//        code: "vc",
//        name: "Saint Vincent and the Grenadines",
//        phoneCode: "+1784"
//    },
//    {
//        code: "ws",
//        name: "Samoa",
//        phoneCode: "+685"
//    },
//    {
//        code: "sm",
//        name: "San Marino",
//        phoneCode: "+378"
//    },
//    {
//        code: "st",
//        name: "Sao Tome and Principe",
//        phoneCode: "+239"
//    },
//    {
//        code: "sa",
//        name: "Saudi Arabia",
//        phoneCode: "+966"
//    },
//    {
//        code: "sn",
//        name: "Senegal",
//        phoneCode: "+221"
//    },
//    {
//        code: "rs",
//        name: "Serbia",
//        phoneCode: "+381"
//    },
//    {
//        code: "sc",
//        name: "Seychelles",
//        phoneCode: "+248"
//    },
//    {
//        code: "sl",
//        name: "Sierra Leone",
//        phoneCode: "+232"
//    },
//    {
//        code: "sg",
//        name: "Singapore",
//        phoneCode: "+65"
//    },
//    {
//        code: "sx",
//        name: "Sint Maarten",
//        phoneCode: "+1721"
//    },
//    {
//        code: "sk",
//        name: "Slovakia",
//        phoneCode: "+421"
//    },
//    {
//        code: "si",
//        name: "Slovenia",
//        phoneCode: "+386"
//    },
//    {
//        code: "sb",
//        name: "Solomon Islands",
//        phoneCode: "+677"
//    },
//    {
//        code: "so",
//        name: "Somalia",
//        phoneCode: "+252"
//    },
//    {
//        code: "za",
//        name: "South Africa",
//        phoneCode: "+27"
//    },
//    {
//        code: "gs",
//        name: "South Georgia and the South Sandwich Islands",
//        phoneCode: "+500"
//    },
//    {
//        code: "ss",
//        name: "South Sudan",
//        phoneCode: "+211"
//    },
//    {
//        code: "es",
//        name: "Spain",
//        phoneCode: "+34"
//    },
//    {
//        code: "lk",
//        name: "Sri Lanka",
//        phoneCode: "+94"
//    },
//    {
//        code: "sd",
//        name: "Sudan",
//        phoneCode: "+249"
//    },
//    {
//        code: "sr",
//        name: "Suriname",
//        phoneCode: "+597"
//    },
//    {
//        code: "sj",
//        name: "Svalbard and Jan Mayen",
//        phoneCode: "+47"
//    },
//    {
//        code: "se",
//        name: "Sweden",
//        phoneCode: "+46"
//    },
//    {
//        code: "ch",
//        name: "Switzerland",
//        phoneCode: "+41"
//    },
//    {
//        code: "sy",
//        name: "Syrian Arab Republic",
//        phoneCode: "+963"
//    },
//    {
//        code: "tw",
//        name: "Taiwan",
//        phoneCode: "+886"
//    },
//    {
//        code: "tj",
//        name: "Tajikistan",
//        phoneCode: "+992"
//    },
//    {
//        code: "tz",
//        name: "Tanzania, United Republic of Tanzania",
//        phoneCode: "+255"
//    },
//    {
//        code: "th",
//        name: "Thailand",
//        phoneCode: "+66"
//    },
//    {
//        code: "tl",
//        name: "Timor-Leste",
//        phoneCode: "+670"
//    },
//    {
//        code: "tg",
//        name: "Togo",
//        phoneCode: "+228"
//    },
//    {
//        code: "tk",
//        name: "Tokelau",
//        phoneCode: "+690"
//    },
//    {
//        code: "to",
//        name: "Tonga",
//        phoneCode: "+676"
//    },
//    {
//        code: "tt",
//        name: "Trinidad and Tobago",
//        phoneCode: "+1868"
//    },
//    {
//        code: "tn",
//        name: "Tunisia",
//        phoneCode: "+216"
//    },
//    {
//        code: "tr",
//        name: "Turkey",
//        phoneCode: "+90"
//    },
//    {
//        code: "tm",
//        name: "Turkmenistan",
//        phoneCode: "+993"
//    },
//    {
//        code: "tc",
//        name: "Turks and Caicos Islands",
//        phoneCode: "+1649"
//    },
//    {
//        code: "tv",
//        name: "Tuvalu",
//        phoneCode: "+688"
//    },
//    {
//        code: "ug",
//        name: "Uganda",
//        phoneCode: "+256"
//    },
//    {
//        code: "ua",
//        name: "Ukraine",
//        phoneCode: "+380"
//    },
//    {
//        code: "ae",
//        name: "United Arab Emirates",
//        phoneCode: "+971"
//    },
//    {
//        code: "gb",
//        name: "United Kingdom",
//        phoneCode: "+44"
//    },
//    {
//        code: "us",
//        name: "United States",
//        phoneCode: "+1"
//    },
//    {
//        code: "uy",
//        name: "Uruguay",
//        phoneCode: "+598"
//    },
//    {
//        code: "uz",
//        name: "Uzbekistan",
//        phoneCode: "+998"
//    },
//    {
//        code: "vu",
//        name: "Vanuatu",
//        phoneCode: "+678"
//    },
//    {
//        code: "ve",
//        name: "Venezuela, Bolivarian Republic of Venezuela",
//        phoneCode: "+58"
//    },
//    {
//        code: "vn",
//        name: "Vietnam",
//        phoneCode: "+84"
//    },
//    {
//        code: "vg",
//        name: "Virgin Islands, British",
//        phoneCode: "+1284"
//    },
//    {
//        code: "vi",
//        name: "Virgin Islands, U.S.",
//        phoneCode: "+1340"
//    },
//    {
//        code: "wf",
//        name: "Wallis and Futuna",
//        phoneCode: "+681"
//    },
//    {
//        code: "ye",
//        name: "Yemen",
//        phoneCode: "+967"
//    },
//    {
//        code: "zm",
//        name: "Zambia",
//        phoneCode: "+260"
//    },
//    {
//        code: "zw",
//        name: "Zimbabwe",
//        phoneCode: "+263"
//    }
//];
