export enum ReportPeriod {
  // ThisMonth = '1',
  // LastMonth = '2',
  // ThisQuarter = '3',
  // LastQuarter = '4',
  // ThisFinancialYear = '5',
  // LastFinancialYear = '6',
  // CustomRange = '7'
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  ThisQuarter = 'This Quarter',
  LastQuarter = 'Last Quarter',
  ThisFinancialYear = 'This Financial Year',
  LastFinancialYear = 'Last Financial Year',
  CustomRange = 'Custom Range'
}

