import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookkeepingRoutingModule } from './bookkeeping-routing.module';
import { BookkeepingComponent } from './bookkeeping.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ClipboardModule } from 'ngx-clipboard';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule,  } from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { AddNewInvoicePageComponent } from './add-new-invoice-page/add-new-invoice-page.component';
import { UploadDocumentComponent } from './add-new-invoice-page/upload-document/upload-document.component';
import { TableComponent } from './components/table/table.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FilterSidebarComponent } from './filter-sidebar/filter-sidebar-component';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { DateTextInputDirective } from './directives/date-text-input.directive';
import { UploadSidebarComponent } from './components/upload-sidebar/upload-sidebar.component';
import { SettingsSidebarComponent } from './components/settings-sidebar/settings-sidebar.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { AccountNumberPipe } from './pipes/account-number.pipe';
import { TablePlaceholderComponent } from './components/table-placeholder/table-placeholder.component';
import { DocumentPlaceholderComponent } from './add-new-invoice-page/document-placeholder/document-placeholder.component';
import { SharedService } from './services/shared-service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploadInvoiceFormComponent } from './add-new-invoice-page/upload-invoice-form/upload-invoice-form.component';
import { ActivityComponent } from './add-new-invoice-page/activity/activity.component';
import { InvoicePaymentStatusPipe } from './pipes/invoice-payment-status.pipe';
import { SupplierContanctsComponent } from './add-new-invoice-page/supplier-contancts/supplier-contancts.component';
import { AddLinesComponent } from './add-new-invoice-page/add-lines/add-lines.component';
import { AddLinesOneLineComponent } from './add-new-invoice-page/add-lines-one-line/add-lines-one-line.component';
import { TotalsLineNewComponent } from './add-new-invoice-page/totals-line-new/totals-line-new.component';
import { TotalsLineHeaderNewComponent } from './add-new-invoice-page/totals-line-header-new/totals-line-header-new.component';
import { ShortLogoPipe } from './pipes/short-logo.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { KeyValuePairsComponent } from './add-new-invoice-page/key-value-pairs/key-value-pairs.component';
import { ProcessingErrorComponent } from './components/processing-error/processing-error.component';
import { BookkeepingFilterComponent } from './components/bookkeeping-filter/bookkeeping-filter.component';
import { RelatedComponent } from './add-new-invoice-page/related/related.component';
import { RelatedContactComponent } from './add-new-invoice-page/related-contact/related-contact.component';
import { AddSymbolPipe } from './pipes/add-symbol.pipe';
import { SharedModule } from '../shared/shared.module';
import { NotesComponent } from './add-new-invoice-page/notes/notes.component';
import { MentionModule } from 'angular-mentions';  
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BankTransactionDetailComponent } from './add-new-invoice-page/bank-transaction-detail/bank-transaction-detail.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RelatedTasksComponent } from './components/related-tasks/related-tasks.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { InvoicePreviewComponent } from './add-new-invoice-page/invoice-preview/invoice-preview.component';

@NgModule({
  declarations: [
    BookkeepingComponent,
    SidebarComponent,
    FilterSidebarComponent,
    AddNewInvoicePageComponent,
    UploadDocumentComponent,
    TableComponent,
    PdfViewerComponent,
    DateTextInputDirective,
    UploadSidebarComponent,
    SettingsSidebarComponent,
    AccountNumberPipe,
    TablePlaceholderComponent,
    DocumentPlaceholderComponent,
    UploadInvoiceFormComponent,
    ActivityComponent,
    InvoicePaymentStatusPipe,
    SupplierContanctsComponent,
    AddLinesComponent,
    AddLinesOneLineComponent,
    TotalsLineNewComponent,
    TotalsLineHeaderNewComponent,
    ShortLogoPipe,
    KeyValuePairsComponent,
    ProcessingErrorComponent,
    BookkeepingFilterComponent,
    RelatedComponent,
    RelatedContactComponent,
    AddSymbolPipe,
    NotesComponent,
    BankTransactionDetailComponent,
    RelatedTasksComponent,
    InvoicePreviewComponent
  ],
  imports: [
    CarouselModule.forRoot(),
    CommonModule,
    BookkeepingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxFileDropModule,
    PdfViewerModule,
    ModalModule.forRoot(),
    DateRangePickerModule,
    MatRadioModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatProgressSpinnerModule, 
    DropDownListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    TypeaheadModule.forRoot(),
    NgSelectModule,
    InlineSVGModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    SharedModule,
    MentionModule,
    PickerModule,
    EmojiModule ,
    MatStepperModule,
    MatIconModule
  ],
  exports: [
    UploadDocumentComponent,
    SidebarComponent,
    FilterSidebarComponent,
    ShortLogoPipe,
    UploadInvoiceFormComponent,
    SupplierContanctsComponent,
    AddSymbolPipe,
    ActivityComponent,
    NotesComponent,
    RelatedTasksComponent,
    InvoicePreviewComponent
  ],
  providers: [SharedService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BookkeepingModule { }
