<!-- <div id="email_modal" class="email_modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md">

        <div class="modal-content" style="padding: 20px 30px 18px 30px;;text-align: center;">
            <div class="modal-body">
                <button type="button" class="btn-close " (click)="Selectno()" aria-label="Close"></button>
                <div class="dm_head">
                    <h3>Send Email</h3>
                </div>
                <div class="dm_content" style="margin-top:20px">
                    <div class="col-md-12" style="text-align: left;">
                        <label>Select User Name :</label>
                        <select id="username" class="form-control" style="margin-top: 10px;cursor:pointer" (change)="SetUserEmail($event.target.value)">
                            <option value="">Select User Name
                            </option>
                            <option value="{{user.id}}" *ngFor="let user of userList">
                                {{user.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-12" style="text-align: left;margin-top: 20px;">
                        <label>To :</label>
                        <input id="useremail" readonly class="form-control" style="margin-top: 10px;">
                    </div>
                    <div class="modal_btns" style="margin-top: 20px;">
                        <button class="custom_button green " style="cursor:pointer" (click)="SendEmailToUser()" [class.spinner]="loading" [disabled]="loading">Send</button>
                        <a class="custom_button yellow" style="cursor:pointer" (click)="Selectno()">Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div [ngClass]="showsendEmailFilter ? 'sidenav sidenav-show' : 'sidenav'">
    <a [routerLink]="" class="closebtn" (click)="Selectno()">&times;</a>
    <div class="sidenav_in">
        <div class="reportg">
            <div class="heading headingTitle">SEND REPORT FOR REVIEW</div>
            <div class="report_period">
                <span class="font14">Select User</span>
                <select id="username" class="select send_email_select report__select  selectUser" style="margin-top: 10px;cursor:pointer" (change)="SetUserEmail($event.target.value)">
                    <option value="" class="placeholderColor" style="color: #A9A9A9;" selected disabled>Select User
                       </option>
                       <option class="font14" value="{{user.id}}" *ngFor="let user of userList">
                        {{user.name}} {{" - "}} {{user.email}}</option>
                </select>
            </div>
                <h4 class="font14">Message </h4>
                <textarea id="txtMessageId" disabled="disabled" placeholder="Add message for reviewer" class="form-control send_email_input font14 txtaresize" style="margin-top: 10px;"></textarea>
                <!-- <input id="useremail" readonly class="form-control send_email_input" style="margin-top: 10px;"> -->
        </div>
        <div class="add_bank_sidenav_action">
            <button class="custom_button yellow font14 sendBtn" [class.spinner]="loading" [disabled]="loading" (click)="SendEmailToUser()">Send</button>
            <button class="custom_button font14" (click)="Selectno()" style="background-color: transparent;">Cancel</button>
        </div>
    </div>
</div>