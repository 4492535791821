<div class="sidenav_in">
	<div class="reportg reportg-custom">
		<div class="close-row">
			<div class="close-icon" matTooltip="Close Upload Sidebar" [matTooltipShowDelay]="showToolTipDelay"
				(click)="cancel()">
				<img src="../../../../../assets/img/close-icon.svg" alt="">
			</div>
		</div>
			<div class="drop-zone" *ngIf="!isLoaded">
					<ngx-file-drop (onFileDrop)="dropped($event)">
							<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
									<img src="../../../../../assets/img/upload-sidebar/upload-icon.svg" alt="upload-icon" class="upload-icon">
									<div class="send-to" *ngIf="organizationEmail">
											<div class="desc">Forward your invoices to</div>
											<div class="email">{{organizationEmail}}</div>
											<div class="or">OR</div>
									</div>
									<div class="text" [ngClass]="organizationEmail ? '' : 'no-email-margine'">
											<div>
													<span class="link" (click)="filesrc.click()">Upload</span>
															&nbsp;/ Drag & Drop your <br> document here
											</div>
									</div>
									<input hidden #filesrc type="file" accept=".pdf, .png, .jpg, .jpeg" (change)="added($event)">
							</ng-template>
					</ngx-file-drop>
			</div>

			<div class="file-preview" *ngIf="isLoaded">
					<div class="file-name">
							<div class="name" [title]="file.name">{{file.name}}</div>
							<div class="close" (click)="deleteFile()"><i class="fas fa-times"></i></div>
					</div>
					<app-pdf-viewer *ngIf="isPdf" [tempFile]="tempFile" [showButtons]=false></app-pdf-viewer>
					<img *ngIf="!isPdf" class="image" alt="invoice" [src]="tempFile">
			</div>
	</div>

	<div class="reportg_btn reportnew">
		<button (click)="save()" style="position:relative;" class="run report__run__button btn-text upload-btn" [class.spinner]="loading" [disabled]="isDisabled">
			Submit
		</button> 
	</div>
</div>
