export enum AccountingIntegration {
  xero,
  QBO,
  None
}

export enum DomainName {
  Xero,
  QuickBooks,
  None
}
