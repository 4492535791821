export class InvoiceTotals {
    amountDue?: number;
    totalTax?: number;
    subTotal?: number;

    constructor(amountDue: number,totalTax: number, subTotal: number ){
        this.amountDue = amountDue;
        this.totalTax = totalTax;
        this.subTotal = subTotal;
    }
}