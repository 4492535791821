import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortLogo'
})
export class ShortLogoPipe implements PipeTransform {
    transform = (value?: string): string => {
        if (value == null || value == undefined || value.trim() == '') {
          return '#';
        }
        let words = value.split(' ');
        let result = '';

        for (let w of words) {
            result += w[0];
        }

        return result.slice(0, 3).toUpperCase();;
    };
}