<div class="emails">
  <div class="reports">
    <div class="report_history">
      <div class="rh_outer">
        <div class="rh_in bill_to_pay">
          <app-pagesidebar [savedFilters]="savedFilters"
                           [isInbox]="true"
                           (changeTabEvent)="changeTab($event)"
                           (changeFilterEvent)="addSelectedFilter($event)"
                           (closeFilter)="closeFilter($event)"
                           (toggleSidebarEvt)="toggleSidebar($event)"
                           (searchEvt)="onSearchChange($event)"
                           (addFilter)="addFilter()" class="sidebar-page"
                           [invoiceCount]="filteredInProcessingInvoices.length">
          </app-pagesidebar>
          <div class="detailpanel gridView sidebar-wrap" [ngClass]="showDetailPanel ? 'gridview-shrink':'gridview-expand'">
            <div class="email-grid">
              <div>
                <ng-container>
                  <app-table-placeholder *ngIf="(isInProcessingTab || isProcessedTab) && loading"
                                         [screenBrakepoint]="screenBrakepoint">
                  </app-table-placeholder>
                  <app-table *ngIf="isInProcessingTab && !loading"
                             [invoices]="filteredInProcessingInvoices"
                             [status]="statuses.inProcessing"
                             [screenBrakepoint]="screenBrakepoint"
                             [selectedTrackingCategories]="selectedTrackingCategories"
                             [emptyMessage]="emptyMessage"
                             (deleteTagEvent)="deleteTag($event)"
                             (rowCheckboxClickEvent)="rowCheckboxClickEvent()">
                  </app-table>

                  <app-table *ngIf="isProcessedTab && !loading"
                             [invoices]="filteredProcessedInvoices"
                             [status]=statuses.processed
                             [maxCount]="!isFilterShown ? processedTotalAmount : filteredTotalAmount"
                             [pageSize]="processedPageSize"
                             [page]="processedPage"
                             [screenBrakepoint]="screenBrakepoint"
                             [selectedTrackingCategories]="selectedTrackingCategories"
                             [emptyMessage]="!isFilterShown ? 'You\'re all up to date. No documents to process 👍' : 'The filter values selected show no records'"
                             (pageSelect)="pageSelect($event)"
                             (sortTableEvent)="sortTable($event)"
                             (deleteTagEvent)="deleteTag($event)"
                             (rowCheckboxClickEvent)="rowCheckboxClickEvent()">
                  </app-table>

                  <app-processing-error *ngIf="isProcessingError" [records]="processingErrorRecords"></app-processing-error>
                </ng-container>
              </div>
              <div id="info" *ngIf="!isProcessingError && isThreeDotsMenuShown">
                <ul style="margin: -9px !important;" *ngIf="isThreeDotsMenuShown">
                  <li class="menu-wrapper">
                    <mat-nav-list fxLayout="row" class="inbox-menu">
                      <button class="mat-list-item" mat-list-item>
                        <span>{{isInProcessingTab ? checkedInProcessingInvoices?.length : checkedProcessedInvoices?.length}} Selected</span>
                      </button>
                      <!-- Main manu items -->
                      <button mat-button [matMenuTriggerFor]="menuTag" mat-list-item class="mat-list-item"
                              matTooltip="Add tags to invoices" [matTooltipShowDelay]="showToolTipDelay"
                              (click)="showAddTagMenu(); $event.stopPropagation();">
                        <img src="../../../assets/img/bookkeeping/add-tag.svg" alt="">
                        <span>Add Tag</span>
                      </button>
                      <button mat-list-item class="mat-list-item"
                              matTooltip="Dowload selected invoices" [matTooltipShowDelay]="showToolTipDelay"
                              (click)="downloadCheckedInvoices()">
                        <img src="../../../assets/img/bookkeeping/archive.svg" alt="">
                        <span>Download</span>
                      </button>
                      <button mat-list-item mat-button [matMenuTriggerFor]="menuExport"
                              matTooltip="Export invoices" [matTooltipShowDelay]="showToolTipDelay" class="mat-list-item"
                              (click)="showExportMenu(); $event.stopPropagation();">
                        <img src="../../../assets/img/bookkeeping/exportcsv.png" alt="">
                        <span>Export</span>
                      </button>
                      <button mat-list-item *ngIf=" isInProcessingTab" class="mat-list-item"
                              matTooltip="Delete invoices" [matTooltipShowDelay]="showToolTipDelay" (click)="showDeleteConfirm();">
                        <img src="../../../assets/img/bookkeeping/delete.png" alt="">
                        <span>Delete</span>
                      </button>
                      <mat-menu #menuTag="matMenu">
                        <!-- Add a tag menu items -->
                        <div class="add-tag-list">
                          <div class="search-tag">
                            <input class="search-input" type="text" placeholder="Search Tag"
                                   (click)="$event.stopPropagation()" [ngModel]="tagSearchTerm" (ngModelChange)="filterTags($event)" (blur)="clearTagSearchValue()">
                          </div>
                          <div class="add-tag">
                            <div class="tag-list-item" *ngFor="let tagMenuItem of filteredTagList" (click)="addTagToInvoices(tagMenuItem)">
                              <span class="tag" [ngStyle]="{'color': tagMenuItem.textColor, 'background': tagMenuItem.backColor}">{{tagMenuItem.tagName}}</span>
                            </div>
                            <!-- If now filtered tags -->
                            <div class="tag-list-item" *ngIf="isAddTagMenuItemShown" (click)="createNewTag()">
                              + New Tag ‘{{this.tagSearchTerm}}’
                            </div>
                          </div>
                        </div>
                      </mat-menu>
                      <!-- Export items -->
                      <mat-menu #menuExport="matMenu">
                        <div class="export-list">
                          <mat-radio-group aria-labelledby="example-radio-group-label"
                                           class="example-radio-group"
                                           [(ngModel)]="exportOption" (click)="$event.stopPropagation()">
                            <mat-radio-button class="example-radio-button" [value]="1">
                              Export Selected
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button" [value]="0">
                              Export All
                            </mat-radio-button>
                          </mat-radio-group>
                          <div class="export-list-item" *ngIf="isExportMenuShown" (click)="exportRecords()">
                            Export
                          </div>
                        </div>
                      </mat-menu>
                    </mat-nav-list>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div class="detailpanel emailbody-wrap" *ngIf="showDetailPanel"
               [ngStyle]="{'width': showDetailPanel ? '34%' : '0%','height': showDetailPanel ? 'calc(100vh - 85px)' : '0'}">
            <div class="sidenav_in">
              <app-sidebar [isSidebarVisible]=isSidebarVisible [isSettingsSidebar]=isSettingsSidebar [isUploadSidebar]=isUploadSidebar
                           (isClosed)="toggleSidebar($event)">
              </app-sidebar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-sidebar [isSidebarVisible]=isSidebarVisible [isSettingsSidebar]=isSettingsSidebar [isUploadSidebar]=isUploadSidebar
             (isClosed)="toggleSidebar($event)">
</app-sidebar>
<div [ngClass]="showFilterPanel ? 'sidenav sidenav-show' : 'sidenav'">
  <div class="sidenav_in">
    <div class="form-group">
      <app-filter-sidebar *ngIf="(isInProcessingTab || isProcessedTab)"
                          [isSidebarVisible]=showFilterPanel
                          [filterId]="selectedFilterId"
                          (closeEvent)="toggleFilterSidebar()"
                          (deleteEvent)="deletedFilterRemove()"
                          (newItemEvent)="toggleFilterSearch($event)"
                          [tags]="tagList"
                          [accounts]="accounts"
                          [trackingCategories]="trackingCategories"
                          [selectedTrackingCategories]="selectedTrackingCategories"
                          [isEditMode]="isEditMode"
                          [invoiceStatus]="isInProcessingTab ? statuses.inProcessing : isProcessedTab ? statuses.processed : null">
      </app-filter-sidebar>
    </div>
  </div>
</div>
