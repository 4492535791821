import { Pipe, PipeTransform } from '@angular/core';
import { InvoicePaymentStatus } from '../enums/payment-status';

@Pipe({
    name: 'invoicePaymentStatus'
})
export class InvoicePaymentStatusPipe implements PipeTransform {
    transform = (value?: any): string => {
        if (value == null || value == undefined) {
          return 'Undefined';
        }

        switch (value as InvoicePaymentStatus) {
          case InvoicePaymentStatus.AwaitingProcessing: return 'Awaiting Processing';
          case InvoicePaymentStatus.AwaitingPayment: return 'Awaiting Payment';
          case InvoicePaymentStatus.Archived: return 'Archived';
          case InvoicePaymentStatus.Paid: return 'Paid';
          case InvoicePaymentStatus.Void: return 'Void';
          default: return 'Awaiting Processing';
        }
    };
}