import { Injectable } from '@angular/core';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {  

  constructor(private _httpService: HttpInternalService) { }  
  
  getIntegrationData(url: string, organizationId: string, userId: string) {
    return this._httpService.getRequest(`${url + "?OrganisationId=" + organizationId + "&userId=" + userId}`);
  }

  getIntegrationList() {
    return this._httpService.getRequest('Organisation/OrganisationIntegrationData');
  }

  getGmailAccountList(organizationId: string, userId: string) {
    return this._httpService.getRequest('Organisation/OrganisationConnectedEmail' + "?organisationId=" + organizationId + "&userId=" + userId);
  }
}
