import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewInvoicePageComponent } from './add-new-invoice-page/add-new-invoice-page.component';
import { BookkeepingComponent } from './bookkeeping.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: BookkeepingComponent },
      { path: 'editInvoice/:id', component: AddNewInvoicePageComponent },
      { path: 'viewInvoice/:id', component: AddNewInvoicePageComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookkeepingRoutingModule { }
