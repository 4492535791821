<div>
  <div class="banktransdetail">
    <div class="transaction">{{activities[curIdx].description}}
      <button class="xero-button " [ngClass]="activities[curIdx].status.toLowerCase()" style="background: transparent; line-height:1.7; height: 22px; font-size: 12px; border-radius: 5px" >
        <span *ngIf="activities !=null"> {{activities[curIdx].status}}</span>
      </button>
    </div>
    <div class="col-sm-12" style="display: flex;justify-content: space-between;">
    <div class="col-sm-6" style="padding: 9px 0;width: 60%;">
      <div class="lbltrans">Transaction Date</div>
      <div class="valtrans" *ngIf="activities !=null">{{activities[curIdx].transactionDate | formatedDate}}</div>
    </div>
    <div class="col-sm-6" style="padding: 9px 0;width: 40%;">
      <div class="lbltrans">Amount</div>
      <div class="valtrans" *ngIf="activities !=null">

        <span *ngIf="activities[curIdx].amount > 0" style="color:#00A008"> +{{ activities[curIdx].amount | number:'1.2-2'}}</span>
        <span *ngIf="activities[curIdx].amount < 0" style="color:red"> ({{ activities[curIdx].amount * -1 | number : '1.2-2'}})</span>
        <span *ngIf="activities[curIdx].amount == 0">-</span>
      </div>
    </div>
  </div>
    <div class="col-sm-6"  style="padding: 9px 0;">
      <div class="lbltrans">Assigned To</div>
      <div class="input-select ng-select" [ngClass]="ismention?'open':''" style="position: relative;">
        <input type="text" [(ngModel)]="assignTo" (input)="onChange($event)" (focus)="ismention=true"
          placeholder="Assign User" class="form-control custom-control assignUser" />
        <span class="ng-arrow-wrapper"><span class="ng-arrow"></span>
        </span>
        <div class="mention-wrap assignUser" [ngClass]="ismention?'open':''" *ngIf="usersList !=null  && usersList.length > 0" >
          <ng-container *ngFor="let usr of usersList;let $index=index">
            <div class="uservalue assignUser" [ngClass]="idx==$index?'selected':''" *ngIf="$index < 3"
              (click)="idx=$index;selectItem(usr) ;ismention=false;">
              <span class="nameinit" *ngIf="usr.name != null">{{getinit(usr.name)}}</span> {{usr.name}}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
