import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

import { AuthUtils } from '../../util/auth.utils'

import { LoginModel } from '../../model/login-model';
import { RecoverModel } from '../../../data/model/recover-model';
import { HttpInternalService } from 'src/app/data/service/http.service';
import { Title } from '@angular/platform-browser';
import { NotificationsHubService } from '../hubs/notifications-hub.service';

import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authenticated: boolean;

  constructor(
    private _httpService: HttpInternalService,
    private readonly _titleService: Title,
    private readonly _notificationsHubService: NotificationsHubService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
  ) { }

  set accessToken(token: string) {
    localStorage.setItem('AccessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('AccessToken');
  }

  set accessUpUserId(upUserId: string) {
    localStorage.setItem('UpUserId', upUserId);
  }

  get accessUpUserId(): string {
    return localStorage.getItem('UpUserId');
  }
  idleState = 'Not started.';
  timedOut = false;
  signIn(loginModel: LoginModel): Observable<any> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError({ message: 'User is already logged in.' });
    }

    return this._httpService.postRequest('token', loginModel).pipe(
      switchMap((response: any) => {

        if (response.status == 0) {
          var token = response.data;
          if (token) {
            this.accessToken = token.access_token;
            this.accessUpUserId = token.user.id;
            this.authenticated = true;
            console.log(new Date());
            // sets an idle timeout of 30 mins.
            this.idle.setIdle(1800);
            // sets a timeout period of 10 secs after 10 seconds of inactivity, the user will be considered timed out.
            this.idle.setTimeout(10);
            this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

            //this.idle.onIdleEnd.subscribe(() => {
            //  this.idleState = 'No longer idle.'; 
            //});
            this.idle.watch();
            this.idle.onTimeout.subscribe(() => {
              this.idleState = 'Timed out!';
              this.timedOut = true;
              console.log(new Date());
              this.signOut();
              this.router.navigate(['auth/login']); 
            });
            //this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
            //this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

            // sets the ping interval to 15 seconds
            //this.keepalive.interval(15);

            this.keepalive.onPing.subscribe(() => console.log(new Date()));
          }
        }

        // Return a new observable with the response
        return of(response);
      })
    );

  }

  signOut(): Observable<any> {

    // Set the authenticated flag to false
    this.authenticated = false;
    this._notificationsHubService.stop();

    // Remove the access token from the local storage
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('UpUserId');
    localStorage.removeItem('SelectedOrganization');
    localStorage.removeItem('SelectedOrganizationName');
    localStorage.removeItem('UpUserDetails');
    localStorage.removeItem('accountRemember');
    localStorage.removeItem('UserName');
    localStorage.removeItem('UserId');
    localStorage.removeItem('Id');
    localStorage.removeItem('InvoceIdsStorage');
    localStorage.removeItem('IsLogedin');
    localStorage.removeItem('IsXeroDirect');
    sessionStorage.clear();

    // Set the correct page title
    this._titleService.setTitle("Synced");

    // Return the observable
    return of(true);
  }

  check(): Observable<boolean> {
    if (!this.accessToken) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    if (this.isTokenExpired(this.accessToken)) {
      this.authenticated = false;
      return of(false);
    }

    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }

    return of(true);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded["exp"] === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded["exp"]);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.accessToken;
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  recoverPassword(recoverModel: RecoverModel): Observable<any> {
    return this._httpService.postRequest('Account/ForgotPassword', recoverModel).pipe(
      switchMap((response: any) => {

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  resetPassword(recoverModel: RecoverModel): Observable<any> {
    return this._httpService.postRequest('Account/SyncedForgotPassword', recoverModel).pipe(
      switchMap((response: any) => {

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  savePassword(model): Observable<any> {
    return this._httpService.postRequest('Account/SavePassword', model).pipe(
      switchMap((response: any) => {

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  ApproveSigninWithToken(model): Observable<any> {
    return this._httpService.postRequest('Account/ApproveSigninWithToken', model).pipe(
      switchMap((response: any) => {

        // Return a new observable with the response
        return of(response);
      })
    );
  }
}
