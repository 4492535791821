import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/service/shared.service';
import { AuthService } from '../../core/service/auth/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/data/service/user.service';
import { Subscription } from 'rxjs';
import * as $ from "jquery";
import { User } from '../../modules/bookkeeping/models/user/user';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})

export class SidebarMenuComponent implements OnInit {

  userRole: number;
  roleId: string;
  subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    public sharedService: SharedService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.roleId = localStorage.getItem("roleId");
    // hide tab link from outstanding amount
    //  $('.outstandingAmountTab').hide(); // call class from payable.component
    //  $('.outstandingPaymentTab').hide(); // call class from payable.component
    //  $('.outstandingSupplierTab').hide(); // call class from payable.component
    this.sharedService.isShowSideNav = false;
    this.subscriptions.push(this.userService.userRole$.subscribe(data => {
      if (data)
        this.userRole = data;
      else {
        let user = JSON.parse(this.userService.upUserDetails) as User;
        this.userService.getUserGetById(user.id).subscribe(data => {
          if (data.data) {
            this.userService.setUserRoleData(data.data.role);
            this.userRole = data.data.role;
          }
        });
      }
    }));
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['auth/login'], {skipLocationChange: true});
  }

  RedirectToPaperwork() {
    this.router.navigate(['/paperwork/missing-paperwork'], { replaceUrl: true });
  }

  RedirectToPayable() {
    var style = $('#service').css('display');

    //if(style === "block")
    //{
    //  $("#service").hide();
    //  $(".PayableText").css("color","");
    //  $("#payableDrpdwn").attr("class","");
    //  $("#imgId").attr("src","../../../assets/img/Graydown.png");

    //}
    //else
    //{
    //  $("#service").show();
    //  $("#imgId").attr("src","../../../assets/img/down.png");
    //  $(".PayableText").css("color","white");
    //  $("#payableDrpdwn").attr("class","active");
    //  this.router.navigate(['/payable/outstanding-amounts']);
    //}
    sessionStorage.IsExecutedPayableReport = false
    sessionStorage.IsFromPaymentTab = false;
    sessionStorage.start = "";
    sessionStorage.SelectedPayableReportId = "";
  }
  RedirectToReport() {
    sessionStorage.IsExecutedPayableReport = false
    sessionStorage.IsFromPaymentTab = false;
    sessionStorage.start = "";
    sessionStorage.SelectedPayableReportId = "";
  }
}
