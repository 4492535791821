import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'accountNumber'
})
export class AccountNumberPipe implements PipeTransform {
    private _asterisk: string = '*';
    private _minDigitsNumber: number = 4;

    transform = (accountNumber: string): string | null => {
        if (accountNumber.length <= this._minDigitsNumber)
            return accountNumber;

        const asteriskCount: number = accountNumber.length - this._minDigitsNumber;
        return this._asterisk.repeat(asteriskCount) + accountNumber.slice(accountNumber.length - this._minDigitsNumber);
    };
}