import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { ToasterTypes } from '../../../../core/enum/toaster-type.enum';
import { Note } from '../../../../data/model/note';
import { NotesService } from '../../../../data/service/notes.service';
import { OrganizationService } from '../../../../data/service/organization.service';
import { UserService } from '../../../../data/service/user.service';
import { ToasterMessageService } from '../../../../shared/toaster-message/toaster-message.service';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji'
import { SidebarType } from '../../enums/sidebar-type';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
@Injectable({
  providedIn: 'root'
})
export class NotesComponent extends BaseComponent implements OnInit {
  @Input() noteType: string = '';
  @Input() recordId: string = '';
  @Input() relatedID: string = '';
  @Input() relatedName: string = '';
  @Input() isBankTransMode: boolean = false;
  @Output() transNote = new EventEmitter<object>();
  @Output() uploadedFile = new EventEmitter<object>();
  organizationId: string = null;
  notesList = [] as Note[];
  noteForm: FormGroup;
  usersList: any[] = [];
  noteMentions: any[] = [];
  showEmojiList = false;
  @ViewChild('noteinput') noteinput: ElementRef;
  @ViewChild('sendbtninput') sendbtninput: ElementRef;

  get noteFormControl() { return this.noteForm.controls; }

  constructor(
    private _notesService: NotesService,
    private _userService: UserService,
    private readonly _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private activatedRoute: ActivatedRoute,
    private _invoiceService: InvoiceService,
    private formBuilder: FormBuilder,
    private router: Router

  ) { super(); }


  hideEmoji(event) {
    if (this.showEmojiList && event.target?.parentNode.className != 'smileyicon'&& event.target?.parentElement.className != 'emoji-mart-emoji ng-star-inserted') {
      this.showEmojiList = false;
    }
  }

  addEmoji($event) {
    this.noteinput.nativeElement.focus();
    const selectionStart = this.noteinput.nativeElement.selectionStart ?? 0;
    const currentvalue = this.noteinput.nativeElement.value;
    const newvalue = currentvalue.substring(0, selectionStart) + $event.emoji.native + currentvalue.substring(selectionStart);
    this.noteForm.setValue({ noteDetail: newvalue });
    // the following 2 lines set the caret position behind the emoji
    this.noteinput.nativeElement.selectionStart = selectionStart + $event.emoji.native.length;
    this.noteinput.nativeElement.selectionEnd = selectionStart + $event.emoji.native.length;
  }

  ngOnInit(): void {
    this.organizationId = this._organizationService.selectedOrganization;
    this.getNotes(false);
    this.getUsers();
    // Create the form
    this.noteForm = this.formBuilder.group({
      noteDetail: [null, [Validators.required]]
    });

    this.noteForm.valueChanges.subscribe(value => {
      this.noteinput.nativeElement.style.height = 'auto';
      this.noteinput.nativeElement.style.height = `${this.noteinput.nativeElement.scrollHeight  }px`;
    });
  }

  getUsers() {
    this._userService.getUsersByOrganizationId(this.organizationId).subscribe((val) => {
      val.data.forEach((value, key) => {
        var user = {
          'id': value["id"],
          'name': value["name"],
          'emailId': value["email"],
        }
        this.usersList.push(user);
      });
    });
  }

  getNotes(isUpdate:boolean) {
    if (this.recordId != undefined && this.organizationId != undefined) {
      this._notesService.getNotesByOrganizationId(this.recordId, this.organizationId)
        .pipe(take(1))
        .subscribe(
          (notes: Note[]) => {
            this.notesList = notes;
            if(isUpdate)
            {
              this.transNote.emit({id:this.recordId,notes:this.notesList});
            }
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          }
        );
    }
  }

  removeDuplicateUsers<T>(array: T[]): T[] {
    const map = new Map();
    for (const item of array) {
      map.set(item, item);
    }
    return Array.from(map.values());
  }

  saveNotes() {
    this.noteMentions.forEach((x) => {
      var name = '@' + x.name;

      if (this.noteForm.value.noteDetail.indexOf(name) != -1) {
        this.noteForm.value.noteDetail = this.noteForm.value.noteDetail.replaceAll(name, '<b>' + name + '</b>')
      }
    })
    this.showEmojiList = false;
    this.noteForm.markAllAsTouched();
    // stop here if form is invalid
    if (this.noteForm.invalid) {
      return;
    }
    var uniquementions = this.removeDuplicateUsers(this.noteMentions);
    var viewNoteURL = this.router.url + "?organizationId=" + this.organizationId + "&recordId="+ this.recordId;
    let note = {
      details: this.noteForm.value.noteDetail,
      type: this.noteType,
      organizationID: this.organizationId,
      userID: sessionStorage.Id,
      recordID: this.recordId,
      noteMentions: uniquementions,
      createdBy: sessionStorage.UserName,
      viewNoteURL: viewNoteURL
    } as Note;
    console.log(note)
    this.createNote(note);
  }

  createNote(note) {
    this._notesService.createNote(note)
      .pipe(take(1))
      .subscribe(
        (createdNote: Note) => {
          this.noteForm.reset();
          this.getNotes(true);

          this.noteMentions = [];
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  datePipe: DatePipe = new DatePipe(this.getNavigatorLanguage());
  getFormattedDate(date: Date) {
    var createdDate = this.datePipe.transform(date + 'Z', 'dd/MM/yy', Intl.DateTimeFormat().resolvedOptions().timeZone, this.getNavigatorLanguage());

    var todaydate = new Date();
    var currentDate = this.datePipe.transform(todaydate + 'Z', 'dd/MM/yy');
    if (createdDate == currentDate) {

      return "Today at " + this.datePipe.transform(date + 'Z', 'h:mm a', Intl.DateTimeFormat().resolvedOptions().timeZone, this.getNavigatorLanguage());
    }
    else {
      return createdDate + " at " + this.datePipe.transform(date + 'Z', 'h:mm a', Intl.DateTimeFormat().resolvedOptions().timeZone, this.getNavigatorLanguage());
    }
  }

  getNavigatorLanguage() {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    }
    else {
      return "en-US";
    }
  }

  private getNowUTC() {
    const now = new Date();
    return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
  }

  checkNoteUser(userId) {
    if (userId == sessionStorage.Id) {
      return true;
    }
    else false;
  }

  itemSelected(event: any) {

    this.noteMentions.push(event)

  }

  isSidebarVisible: boolean = false;
  isSettingsSidebar: boolean = false;
  isUploadSidebar: boolean = false;
  types = SidebarType;
  toggleSidebar(sidebar: SidebarType) {
    this.isUploadSidebar = !this.isUploadSidebar;
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  fileObj:any;
  isFileProcessing:boolean=false;
  uploadFile(fileObj:any)
  {

    this.fileObj=fileObj;
    this.relatedID =null;
    //this.fileObj=file;
    //this.relatedID=file.id;

    //this.relatedName=fileObj.file.name;
    //this.uploadedFile.emit(file);
    this.uploadedFile.emit(fileObj);
  }

  added(event: Event) {
   // this.loading = true;
    const [uploadedFile] = Array.from((event.target as HTMLInputElement).files as FileList);
    console.log(uploadedFile);
    let  fileObj={name:uploadedFile.name,file:uploadedFile};
    this.relatedName=fileObj.file.name;
    this.fileObj=fileObj;
    this.relatedID =null;
    this.sendFile();

    //this.handleFileInput(uploadedFile, this.validFileTypes);
    //this.loading = false;
    //this.isDisabled = false;
  }


  dropped(uploadedFiles: NgxFileDropEntry[]) {
    const fileEntry = uploadedFiles[0].fileEntry.isFile ? uploadedFiles[0].fileEntry as FileSystemFileEntry : null;
    let  fileObj:any;//={name:fileEntry.name,file:fileEntry};
    fileEntry?.file((files: File) => {
      fileObj={name:files.name,file:files};
      this.fileObj=fileObj;
      this.relatedName=fileObj.file.name;
      this.relatedID =null;
      this.sendFile();
    });
  }

  // handleFileInput(file: File, types: string[]) {
  //   if (endsWithAny(types, file.type)) {
  //     this.file = file;
  //     this._setPreview(file);

  //   }
  //   else {
  //     this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Invalid file type!');
  //   }
  // }



  async sendFile(){
    this.isFileProcessing=true;
    // setTimeout(() => {
    //   this.isFileProcessing=false;
    // }, 5000);
   await this._invoiceService.uploadInvoice({ organisationId: this._organizationService.selectedOrganization, invoice: this.fileObj.file, recordId: this.recordId, isBankTransMode: this.isBankTransMode });

    this._recognizedInvoiceService.invoiceState$.pipe(take(1))
        .subscribe(
          (invoice: any) => {
            this.relatedID =invoice.id;
            this.relatedName=this.fileObj.file.name;
            this.uploadedFile.emit({name:this.fileObj.file.name,id:invoice.id});
            this.isFileProcessing=false;
          },
          (e: Error) => {

            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
            this.isFileProcessing=false;
          }
        );
  }


}
