import { Injectable } from '@angular/core';
import { InvoiceActivity, SupplierActivity } from 'src/app/modules/bookkeeping/models/activity/activity';
import { NewSupplier } from 'src/app/modules/bookkeeping/models/supplier/new-supplier';
import { SupplierAccountCreated } from 'src/app/modules/bookkeeping/models/supplier/supplier-account-created';
import { SupplierAliasCreated } from 'src/app/modules/bookkeeping/models/supplier/supplier-alias-created';
import { SupplierGroup } from 'src/app/modules/bookkeeping/models/supplier/supplier-group';
import { SupplierShort } from 'src/app/modules/bookkeeping/models/supplier/supplier-short';
import { NewSupplierFull } from 'src/app/modules/suppliers/models/supplier/new-supplier-full';
import { SupplierOnboarding } from 'src/app/modules/suppliers/models/supplier/supplier-onboarding';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(private _httpService: HttpInternalService) { }

  GetSuppliersDetailsByOrganizationid(OrganizationId: string, page: number, pageSize: number, sort: string = null, searchText : string = null) {
    return this._httpService.getRequest<any>(`Organisation/GetSuppliersDetailsByOrganizationid?OrganizationId=${OrganizationId}&page=${page}&pageSize=${pageSize}&sort=${sort}&searchText=${searchText}`);
  }

  RefreshsupplierXeroData(contactID, OrganizationId, userid) {
    return this._httpService.getRequest<any>(`${"Xero/RefreshsupplierXeroData" + '?contactID=' + contactID + '&OrganizationId=' + OrganizationId + '&userid=' + userid}`);
  }

  AddandUpdatesupplierXeroData(OrganisationID, email, type) {
    return this._httpService.getRequest<any>(`${"Xero/AddandUpdatesupplierXeroData" + '?OrganisationID=' + OrganisationID + '&email=' + email + '&type=' + type}`);
  }

  AddandUpdatesupplierXeroDataInitial(OrganisationID, email, type) {
    return this._httpService.getRequest<any>(`${"Xero/AddandUpdatesupplierXeroDataInitial" + '?OrganisationID=' + OrganisationID + '&email=' + email + '&type=' + type}`);
  }

  UpdateSigupStep(OrganisationID, onBoardingStatus) {
    return this._httpService.getRequest<any>(`${"Xero/UpdateSigupStep" + '?OrganisationID=' + OrganisationID + '&onBoardingStatus=' + onBoardingStatus}`);
  }

  AddandUpdateSupplierGroupType(supplierGroupType) {
    return this._httpService.postRequest<any>('Reporting/InsertSupplierGroupType', JSON.stringify(supplierGroupType));
  }

  GetSupplierGroup(organizationId) {
    return this._httpService.getRequest<any>(`${"Reporting/GetSupplierGroupList" + '?OrganizationId=' + organizationId}`);
  }

  getSupplierByLink(link: string) {
    return this._httpService.getRequest<SupplierOnboarding>(`Suppliers/getSupplierByLink?link=${link}`);
  }

  updateSupplier(supplier: NewSupplierFull) {
    return this._httpService.postClearRequest('Suppliers/updateSupplierByLink', supplier);
  }

  createSupplierInvitation(supplier: any) {
    return this._httpService.postClearRequest('Suppliers/createSupplierInvitation', supplier);
  }

  createSupplier(supplier: NewSupplier | NewSupplierFull) {
    return this._httpService.postClearRequest<{ supplierId: string, message :string}>('Suppliers/createSupplier', supplier);
  }

  addDefaultAccount(supplier: SupplierAccountCreated) {
    return this._httpService.postClearRequest('Suppliers/updateSupplierDefaultAccount', supplier);
  }

  getSuppliers(organizationId: string) {
    return this._httpService.getRequest<SupplierShort[]>(`Suppliers/getSuppliers?organizationId=${organizationId}`);
  }

  GetSuppliersGroups(organizationId: string) {
    return this._httpService.getRequest<SupplierGroup[]>(`Suppliers/getSuppliersGroups?organizationId=${organizationId}`);
  }

  GetQBOAccountsNameList(organisationId: string) {
    return this._httpService.getRequest<any>(`${"Reporting/GetQBOAccountsName?organisationId=" + organisationId}`);
  }

  saveSupplierAlias(supplier: SupplierAliasCreated) {
    return this._httpService.postClearRequest(`Suppliers/saveSupplierAlias`, supplier);
  }

  AddSupplierToGroup(supplierGroupType) {
    return this._httpService.postRequest<any>('Reporting/AddSupplierToGroup', JSON.stringify(supplierGroupType));
  }

  getSupplierById(organizationId: string,SupplierId: string) {
    return this._httpService.getRequest<NewSupplier>(`Suppliers/getSupplierById?organizationId=${organizationId}&SupplierId=${SupplierId}`);
  }

  getSupplierActivityById(organizationId: string, supplierId: string) {
    return this._httpService.getRequest<InvoiceActivity[]>(`Suppliers/getSupplierActivityById?organizationId=${organizationId}&supplierId=${supplierId}`);
  }
}
