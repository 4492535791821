<!-- <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ message }}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div> -->

<div class="reports_data">
    <div class="data_modal">
        <div class="">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close Close" style="cursor:pointer;" aria-label="Close" (click)="dismiss()"></button>
                    <div class="dm_head">
                        <h3>{{ title }}</h3>
                    </div>
                    <div class="dm_content">
                        <h4 style="text-align: center;">{{message}}</h4>
                        <div class="modal_btns">
                            <a class="custom_button green" style="cursor:pointer;" (click)="decline()">{{ btnCancelText }}</a>
                            <a class="custom_button yellow" style="cursor:pointer;" (click)="accept()">{{ btnOkText }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
