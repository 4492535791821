import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from './spinner/spinner.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SpinnerService } from './spinner/spinner.service';
import { ToasterMessageService } from './toaster-message/toaster-message.service';
import { CommunicationService } from './communication/communication.service';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { ShortLogoPipe } from './pipes/short-logo';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,    
    NgbModule,    
    NgxSpinnerModule,    
    ToastrModule.forRoot(), 
  ],
  declarations: [
    SpinnerComponent,
    ConfirmationDialogComponent,
    ShortLogoPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,       
    ShortLogoPipe,
    SpinnerComponent,
    ConfirmationDialogComponent
  ],
  providers: [
    SpinnerService,
    ToasterMessageService,
    CommunicationService,
    ConfirmationDialogService
  ]
})
export class CommonSharedModule {
  constructor() {    
  }
}
