import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CommunicationService {
  // Registreation Page
  private emitChangeSource = new Subject<any>();

  changeEmitted = this.emitChangeSource.asObservable();

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  // Reports -> Profit Loss Report
  private filter = new BehaviorSubject<any>(null);
  currentFilter = this.filter.asObservable();

  changeFilter(filter: any) {
    this.filter.next(filter)
  }

  constructor(
  ) { }

}
