export class UpdatedSettings {
  organizationId: string;
  taxBasis: number;
  taxRateId: string;
  taxExclusiveRateId: string = '';
  trackingCategoriesIds: string[];

  constructor(organizationId: string, taxBasis: number, taxRateId: string, trackingCategoriesIds: string[], taxExclusiveRateId: string = '') {
    this.organizationId = organizationId;
    this.taxBasis = taxBasis;
    this.taxRateId = taxRateId;
    this.taxExclusiveRateId = taxExclusiveRateId;
    this.trackingCategoriesIds = trackingCategoriesIds;
  }
}
