import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {
    private readonly _pattern = /^[0-9\s]*$/;

    @HostListener('input', ['$event']) input(event) {
        if (!this._pattern.test(event.target.value)) {
          event.target.value = event.target.value.replace(/[^0-9\s]/g, "");
        }
    }
}