import { Injectable } from '@angular/core';
import { inProcessingRecord } from 'src/app/modules/bookkeeping/data/in-processing-record';
import { Invoice } from 'src/app/modules/bookkeeping/models/invoice/invoice';
import { RecognizedInvoiceService } from 'src/app/modules/bookkeeping/services/recognized-invoice.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ToasterTypes } from '../../enum/toaster-type.enum';
import { HubNotification } from '../../model/notification';
import { BaseHubService } from './base-hub.service';
import { HubFactoryService } from './hub-factory.service';
import * as $ from "jquery";
import { QuickBookConnection, QuickBookLink, xeroParamsForConnection } from '../../../../environments/environment';
import { DomainName } from '../../../data/enums/accounting-integration';

@Injectable({
  providedIn: 'root'
})
export class NotificationsHubService extends BaseHubService {
  protected readonly hubUrl = 'hub/notifications';

  constructor(
    hubFactory: HubFactoryService,
    toasterMessageService: ToasterMessageService,
    private readonly _recognizedInvoiceService: RecognizedInvoiceService
  ) {
    super(hubFactory, toasterMessageService);
  }

  protected onStart(): void | Promise<void> {
    console.log('onStart');
    this.hubConnection.on('SendNotification', (data: HubNotification) => {
      switch (data?.eventType) {
        case 'InvoicePublished':
          this.publishedInvoiceNotification(data);
          break;
        case 'InvoiceInProcessing':
          this.invoiceProcessingNotification(data);
          break;
        case 'InvoiceProcessed':
          this.invoiceProcessedNotification(data);
          break;
        case 'ExpensePublished':
          this.expensePublishedNotification(data);
          break;
        case 'InvoiceLimitExceed':
          this.invoiceLimitExceedNotification(data);
        case 'ErrorOnInvoicePublish':
          this.handleErrorOnInvoicePublish(data);
          break;
      }
    });
  }

  async stop() {
    if (this.hubConnection?.state == 'Connected') {
      this.hubConnection?.off('SendNotification');
      await this.hubConnection?.stop()
        .then(() => { console.log('Connection Stoped') });
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private publishedInvoiceNotification(data: HubNotification) {
    this._toasterMessageService.displayToasterState(ToasterTypes.SuccessWithHtmlAsync, null,
      `Bill ${data?.data} processed for payment. View <a  class=\"toastr-link\" href=\"/payable/outstanding-amounts\">Bills to Pay</a>`);
  }

  private invoiceProcessingNotification(data: HubNotification) {
    if (data?.organizationId == localStorage.SelectedOrganization) {
      this._recognizedInvoiceService.addInProcessingRecord(inProcessingRecord);
    }
  }

  private invoiceProcessedNotification(data: HubNotification) {
    if (data?.organizationId == localStorage.SelectedOrganization) {
      let invoice = data?.data as Invoice;

      if (invoice) {
        this._toasterMessageService.displayToasterState(ToasterTypes.SuccessWithHtmlAsync, 'New Invoice Processed',
          `An invoice from ${invoice.supplierName} was just processed. <a  class=\"toastr-link\" href=\"/inbox/editInvoice/${invoice.id}\">View invoice</a>`);
        if ($(".card-2") != undefined) {
          $(".card-2 .number span").html(parseInt($(".card-2 .number span").html()) + 1);
        }
        if (!data.justMessageShow) {
          this._recognizedInvoiceService.send(invoice);
        }
      }
    }
  }
  private expensePublishedNotification(data: HubNotification) {
    if (data?.data?.accType == "QuickBooks") {
      let type = "";
      if (data?.data?.paymentType.toLowerCase() == "check") {
        type = "check"
      }
      else {
        type = "expense"
      }
      this._toasterMessageService.displayToasterState(ToasterTypes.SuccessWithHtmlAsync, null,
        `Receipt ${data?.data?.ref} sent to <a  class=\"toastr-link\" target=\"_blank\"
       href=\"${QuickBookLink}/${type}?txnId=${data?.data?.id}">QuickBooks</a>`);
    }
    else {
      this._toasterMessageService.displayToasterState(ToasterTypes.SuccessWithHtmlAsync, null,
        `Receipt ${data?.data?.ref} sent to <a  class=\"toastr-link\" target=\"_blank\"
       href=\"https://go.xero.com/organisationlogin/default.aspx?shortcode=${data?.data?.orgCode}&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=${data?.data?.id}">Xero</a>`);
    }
  }
  private invoiceLimitExceedNotification(data: HubNotification) {
    this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
      `You’ve exceeded your monthly document limit. Upgrade your subscription. <a  class=\"toastr-link\" href=\"/setting/billing\">Upgrade</a>`);
  }

  private handleErrorOnInvoicePublish(data: HubNotification) {

    if (data?.title == 'ConnectionLost') {
      if (data?.data?.type == DomainName.QuickBooks) {
        this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
          `QuickBooks connection lost. Please <a  class=\"toastr-link\" href=\"${QuickBookConnection.QuickBookOauth2Link}\">refresh</a> connection`);
      } else {
        this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
          `Xero connection lost. Please <a  class=\"toastr-link\" href=\"${xeroParamsForConnection.xeroOauth2Link}\">refresh</a> connection`);
      }
    } else {
      let message = data.content + ` <a  class=\"toastr-link\" href=\"/inbox/editInvoice/${data?.data?.id}\">View Invoice</a> `
      this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, "Error", message);
    }
  }
}
  
