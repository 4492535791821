import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addSymbol'
})
export class AddSymbolPipe implements PipeTransform {
    transform = (value?: any): any => {
        if (value == null || value == undefined ) {
          return '$0';
        }

        return '$' + value;
    };
}