import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationService } from 'src/app/data/service/organization.service';

@Pipe({
  name: 'formatedDateTime'
})
export class FormatedDateTimePipe implements PipeTransform {
  constructor(
    private readonly _datePipe: DatePipe,
    private readonly _organizationService: OrganizationService,
    ) {
  }

  transform(value: any, ...args: string[]): any {
    if (!value)
      return null;

    let enUSFormat = "MM/dd/yy h:mm:ss a"; 
    let enGBFormat = "dd/MM/yy h:mm:ss a"; 

    if (args[0] && args[0] != '') {
      enUSFormat = enUSFormat.replace(/[\/]/g, args[0]);
      enGBFormat = enGBFormat.replace(/[\/]/g, args[0]);
    }

    if (args[1] && args[1] != '') {
      enUSFormat = enUSFormat.replace('yy', args[1]);
      enGBFormat = enGBFormat.replace('yy', args[1]);
    }

    const orgDateFormat = this._organizationService.selectedOrganizationDateFormat;

    switch (orgDateFormat) {
      case 'en-US': 
        return this._datePipe.transform(new Date(value), enUSFormat); 
      default: 
        return this._datePipe.transform(new Date(value), enGBFormat); 
    } 
  }
}
