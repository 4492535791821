<div class="viewer-conteiner">
    <pdf-viewer class="viewer" 
            [src]="tempFile" 
            [render-text]="false"
            [original-size]="false" 
            [show-all]="true"
            [zoom]="pdfZoom" 
            [fit-to-page]="true" 
            [autoresize] = true
            (after-load-complete)="afterLoadComplete($event)">
    </pdf-viewer>

    <div class="controls"> 
        <div class="control">
            <span (click)="zoomIn()" matTooltip="Zoom In" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-zoom-in"></span>
        </div>
        <div class="control">
            <span class="percentage">{{pageScale}}%</span>
        </div>
        <div class="control">
            <span (click)="zoomOut()" matTooltip="Zoom Out" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-zoom-out"></span>
        </div>
        <div class="control">
            <span (click)="zoomReset()" matTooltip="Reset Zoom" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-reset"></span>
        </div>
        <div class="control">
            <span (click)="downloadPdf()" matTooltip="Download this document" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-download"></span>
        </div>
    </div>
</div>




