import { Component, OnInit } from '@angular/core';

import { CommunicationService } from '../../shared/communication/communication.service'

@Component({
  selector: 'app-registration-layout',
  templateUrl: './registration-layout.component.html',
  styleUrls: ['./registration-layout.component.scss']
})

export class RegistrationLayoutComponent implements OnInit {

  public currentStep: number = 1;

  constructor(private communicationService: CommunicationService) {
    this.communicationService.changeEmitted.subscribe(
      data => {
        this.currentStep = data;
      });
  }

  ngOnInit() {
  }

}
