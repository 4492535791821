import { Injectable } from '@angular/core';
import { ErrorRecord } from 'src/app/modules/bookkeeping/models/error/error-record';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  constructor(
    private _httpService: HttpInternalService,
  ) { }

  getErrorRecordsByOrganizationId(organizationId: string) {
    return this._httpService.getRequest<ErrorRecord[]>(`Errors/errorRecords?organizationId=${organizationId}`);
  }

  deleteErrorRecordsByOrganizationId(recordId: string) {
    return this._httpService.deleteRequest(`Errors/deleteErrorRecord?recordId=${recordId}`);
  }
}
