<div class="header-preview">
    <div style="display: flex; justify-content: space-between; background-color: #eaebf0;">
        <a [routerLink]="" class="closebtn" (click)="ClosePreviewSidebar()">&times;</a>
      </div>
</div>
<div [ngClass]="!isHorizontalDirection ? 'container' : 'container horizontal'">
    <app-document-placeholder class="preview" [isHorizontalDirection]=isHorizontalDirection *ngIf="!isLoaded">
    </app-document-placeholder>
    <div class="upload-document" [ngClass]="!isPdf ? 'preview' : 'pdf-preview'" *ngIf="isLoaded">
        <app-pdf-viewer *ngIf="isPdf" [tempFile]="tempFile"  [zoom]="pdfZoom" [fit-to-page]="true" [render-text]="true"
            (downloadButtonClick)="downloadPdfInvoice()"></app-pdf-viewer>
        <div *ngIf="!isPdf" [ngClass]="!isHorizontalDirection ? 'img-wrapper' : 'img-wrapper img-horizontal'" class="image-viewer">
            <img class="image" alt="invoice" [src]="tempFile" [ngStyle]="{'width': pageScale + '%'}">
        </div>
        <div class="controls" *ngIf="!isPdf"> 
            <div class="control">
                <span (click)="zoomIn()" matTooltip="Zoom In" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-zoom-in"></span>
            </div>
            <div class="control">
                <span class="percentage">{{pageScale}}%</span>
            </div>
            <div class="control">
                <span (click)="zoomOut()" matTooltip="Zoom Out" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-zoom-out"></span>
            </div>
            <div class="control">
                <span (click)="zoomReset()" matTooltip="Reset Zoom" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-reset"></span>
            </div>
            <div class="control">
                <span (click)="downloadPdfInvoice()" matTooltip="Download this document" [matTooltipShowDelay]="showToolTipDelay" class="icon icon-download"></span>
            </div>
        </div>
    </div>
</div>