<div class="notedetail-container">
  <ngx-file-drop (onFileDrop)="dropped($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <input hidden #filesrc type="file" accept=".pdf, .png, .jpg, .jpeg" (change)="added($event)">

      <div class="history" *ngIf="notesList?.length > 0">
        <div class="activity-card" *ngFor="let note of notesList">
          <ng-container *ngIf="checkNoteUser(note.userID)">
            <div class="desc">
              <div class="actor-date col-sm-4 stage p-0 " *ngIf="note.createdAt">
                <span class="date-span date-font"> {{getFormattedDate(note.createdAt) }}</span>
                <span class="date-font" *ngIf="note.name">&nbsp;By {{note.name}}&nbsp;</span>
              </div>
              <div class="row" style="align-items: center;">

                <div class="col img-right">
                  <div class="label sup_img_box_2">
                    <span>
                      {{note.name ? note.name[0].toUpperCase() : 'U'}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="detail bg-blue">
                <div class="note" [innerHTML]="note.details"> </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!checkNoteUser(note.userID)">
            <div class="label sup_img_box_1">
              <span>
                {{note.name ? note.name[0].toUpperCase() : 'U'}}
              </span>
            </div>
            <div class="desc detail">
              <div class="note-date">
                <span class="date-font name-left" *ngIf="note.name">&nbsp;By {{note.name}}&nbsp;</span>
                <span class="date-span">
                  {{getFormattedDate(note.createdAt)}}
                </span>
              </div>
              <div class="detail bg-gray">
                <div class="note" [innerHtml]="note.details"></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="relatedName">
        <!-- <ng-container *ngIf="relatedID">
          <a href="/inbox/editInvoice/{{this.relatedID}}" target="_blank">
            <span class="clsAttachCard">
              <img src="../../../../../assets/img/extention/{{this.relatedName.split('.')[1].toLowerCase()}}.svg"
                   style="width: 30px;">&nbsp;{{this.relatedName}}
            </span>
          </a>
        </ng-container> -->
          <!-- <a href="/inbox/editInvoice/{{this.relatedID}}" target="_blank"> -->

        <ng-container *ngIf="relatedID==null">
          <span class="clsAttachCard" (click)="sendFile()" [ngClass]="isFileProcessing?'load':''">
            <span class="fileProcess " style="padding-left: 40px;line-height: 36px;"
                  [ngClass]="isFileProcessing?'active load':''">
              Processing
            </span>
            <span class="fileProcess " [ngClass]="!isFileProcessing?'active':''">
              <img src="../../../../../assets/img/extention/{{this.relatedName.split('.')[1].toLowerCase()}}.svg"
                   style="width: 30px;">&nbsp;{{this.relatedName}}
            </span>
          </span>
          <!-- </a> -->
        </ng-container>
      </div>

      <form class="note-form" [formGroup]="noteForm" role="form" name="noteForm" (ngSubmit)="saveNotes()">
        <div class="input-group">
          <textarea placeholder="Write comments here" #noteinput class="form-control custom-control note-textarea"
            rows="2" formControlName="noteDetail" [mention]="usersList" contenteditable="true"
            [mentionListTemplate]="mentionListTemplate" (itemSelected)="itemSelected($event)"
            [mentionConfig]="{triggerChar:'@',maxItems:3,labelKey:'name' }"></textarea>
        </div>
        <span class="input-group-btn">
          <button class="btn btn-primary btn-yellow" #sendbtninput>
            <span>Send</span>
          </button>
          <span class="smileyicon" (click)="showEmojiList = !showEmojiList">
            <i class="fa fa-smile pt-1"></i>
          </span>
          <svg (click)="filesrc.click()" *ngIf="isBankTransMode" width="51" height="49" viewBox="0 0 51 49" fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="cursor: pointer;float: right;margin-right: 10px;width: 25px; height: 30px;">
            <g clip-path="url(#clip0_6291_4090)">
              <path
                d="M26.5625 44.9141C20.1025 44.9141 14.875 39.8916 14.875 33.6849L14.875 12.2474C14.875 7.73531 18.6787 4.08073 23.375 4.08073C28.0712 4.08073 31.875 7.73531 31.875 12.2474L31.875 29.6016C31.875 32.4191 29.495 34.7057 26.5625 34.7057C23.63 34.7057 21.25 32.4191 21.25 29.6016V14.2891H25.5V29.7853C25.5 30.9082 27.625 30.9082 27.625 29.7853V12.2474C27.625 10.0016 25.7125 8.16407 23.375 8.16407C21.0375 8.16407 19.125 10.0016 19.125 12.2474V33.6849C19.125 37.6253 22.4612 40.8307 26.5625 40.8307C30.6637 40.8307 34 37.6253 34 33.6849V14.2891H38.25V33.6849C38.25 39.8916 33.0225 44.9141 26.5625 44.9141Z"
                fill="#4F4F4F" />
            </g>
            <defs>
              <clipPath id="clip0_6291_4090">
                <rect width="49" height="51" fill="white" transform="matrix(0 -1 1 0 0 49)" />
              </clipPath>
            </defs>
          </svg>
          <!-- <svg   (click)="toggleSidebar(types.upload)" *ngIf="isBankTransMode" width="51" height="49" viewBox="0 0 51 49" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;float: right;margin-right: 10px;width: 25px; height: 30px;">
                <g clip-path="url(#clip0_6291_4090)">
                <path d="M26.5625 44.9141C20.1025 44.9141 14.875 39.8916 14.875 33.6849L14.875 12.2474C14.875 7.73531 18.6787 4.08073 23.375 4.08073C28.0712 4.08073 31.875 7.73531 31.875 12.2474L31.875 29.6016C31.875 32.4191 29.495 34.7057 26.5625 34.7057C23.63 34.7057 21.25 32.4191 21.25 29.6016V14.2891H25.5V29.7853C25.5 30.9082 27.625 30.9082 27.625 29.7853V12.2474C27.625 10.0016 25.7125 8.16407 23.375 8.16407C21.0375 8.16407 19.125 10.0016 19.125 12.2474V33.6849C19.125 37.6253 22.4612 40.8307 26.5625 40.8307C30.6637 40.8307 34 37.6253 34 33.6849V14.2891H38.25V33.6849C38.25 39.8916 33.0225 44.9141 26.5625 44.9141Z" fill="#4F4F4F"/>
                </g>
                <defs>
                <clipPath id="clip0_6291_4090">
                <rect width="49" height="51" fill="white" transform="matrix(0 -1 1 0 0 49)"/>
                </clipPath>
                </defs>
                </svg> -->
        </span>
        <div #emojidiv>
          <emoji-mart class="customemoji" size="1em" set="apple" [hideObsolete]="true" (emojiSelect)="addEmoji($event)"
            [hideRecent]="false" *ngIf="showEmojiList"></emoji-mart>
        </div>
      </form>
    </ng-template>

  </ngx-file-drop>
</div>
<ng-template let-item="item" #mentionListTemplate>
  <div class="row">
    <div class="label sup_img_box_2">
      <span class="lblimage">
        {{item?.name | shortLogo}}
      </span>
    </div>
    &nbsp;&nbsp;&nbsp;{{item.name}}
  </div>
</ng-template>
<app-sidebar [recordId]="recordId" [isBankTransMode]="isBankTransMode" [isSidebarVisible]=isSidebarVisible
  [isSettingsSidebar]=isSettingsSidebar [isUploadSidebar]=isUploadSidebar (isClosed)="toggleSidebar($event)"
  (uploadedFile)="uploadFile($event)">
</app-sidebar>
