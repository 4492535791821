// export enum DocumentType {
//   supplierInvoice = "ACCPAY"
// }

export enum DocumentType {
  "Supplier Invoice" = "ACCPAY",
  "Credit Note"="ACCPAYCREDIT",
  "Expense Claim" = "EXPCLAIMS",
  "Receipt" ="EXP"
}
