import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import EventEmitter from 'events';
import { ClipboardService } from 'ngx-clipboard';
import { forkJoin } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { AccountingIntegration, DomainName } from 'src/app/data/enums/accounting-integration';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { UpdatedSettings } from 'src/app/modules/bookkeeping/models/settings/updated-settings';
import { TaxRate } from 'src/app/modules/bookkeeping/models/taxRate';
import { TrackingCategory } from 'src/app/modules/bookkeeping/models/tracking-categories.ts/trackingCategory';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { QuickBookParam, xeroParams } from '../../../../../environments/environment';
import { LoginModel } from '../../../../core/model/login-model';
import { SignUpFinalModel, SignUpFullModel } from '../../../../core/model/signup-model';
import { AuthService } from '../../../../core/service/auth/auth.service';
import { NotificationsHubService } from '../../../../core/service/hubs/notifications-hub.service';
import { OnBoardingStatus } from '../../../../data/enums/common-enum';
import { IntegrationService } from '../../../../data/service/integration.service';
import { InvoiceService } from '../../../../data/service/invoice.service';
import { RegisterService } from '../../../../data/service/register.service';
import { SuppliersService } from '../../../../data/service/suppliers.service';
import { UserService } from '../../../../data/service/user.service';
import { ConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { SelectTaxBasis } from '../../../bookkeeping/data/common';
import { TaxBasis } from '../../../bookkeeping/enums/taxBasis';
import { BankAccount } from '../../models/bank-account';
import { Country } from '../../../../data/model/country';
import { countries } from '../../../../data/data/countries';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

enum WizzardTab {
  Data,
  Inbox,
  Bookkeeping,
  PaymentSettings,
  Done
}

@Component({
  selector: 'app-signup-wizzard',
  templateUrl: './signup-wizzard.component.html',
  styleUrls: ['./signup-wizzard.component.scss']
})
export class SignupWizzardComponent extends BaseComponent implements OnInit {
  readonly maxSelectedTrackingCategories: number = 2;
  @Input() public organizationIds: Array<string>;
  OrganisationId: string;
  NewOrganisationId: string;
  //qboTaxDefault = { id: "0", name: "Ignore Tax", rate: 0 , taxType : ""};
  taxRates: TaxRate[] = [];
  taxRatesExclusive: TaxRate[] = [];
  defaultBankAccount: BankAccount = null;
  bankAccounts = [] as BankAccount[];
  selectedTrackingCategoriesIds: string[] = [];
  trackingCategories: TrackingCategory[] = [];
  organisationName: string = null;
  IsQuickBook: boolean = false;
  currentTab: WizzardTab = WizzardTab.Data;
  wizzardTabs = WizzardTab;
  buttonsCaption: string = 'Inbox Settings';
  isAnimationShown: boolean = false;
  stages = [] as number[];
  stage = 1;
  tab = 1;
  tabs = [] as number[];
  bsbNumber: string;
  bankAccountNumber: string;
  showToolTipDelay: number = 400;
  isSavingProcessGoing: boolean = false;
  dataLoading: boolean = true;
  allowedTabs = [1] as number[];
  isDataDawloaded: boolean = false;
  taxBasisItem = SelectTaxBasis;
  selectedTaxBasis: number;
  selectedTaxRateId: string = null;
  selectedTaxExclusiveRateId: string = null;
  isPaymentBtnDisable: boolean = true;
  countryCode: string = "";
  connectedToXero: boolean = false;
  connectedToQBO: boolean = false;
  isWarningShown: boolean = false;
  SignupFullModel: SignUpFullModel | null;
  signupModel: SignUpFinalModel;
  domainName: string;
  private xeroWindow: any;
  private QuickBookWindow: any;
  loading: boolean = false;
  loginModel: LoginModel | null;
  @Output() public onSetOrganizationIds = new EventEmitter();
  loadTab: boolean = false;
  accountingSoftware: number;
  isShowSyncingTab: boolean = false;
  showNextButton: boolean = true;
  //organizationShortData: OrganizationShortData = null;
  isConnectionDone: boolean = false;
  isSpinnerShown: boolean = false;
  isXeroDirectSignup: string;
  isUserLogedin: string;
  integrationList: any = [];
  addonList: any = [];
  syncedGmailAccountList: any = []
  orgEmail: string;
  currentUser: any;
  registerModel: any;
  IsAddNewBankAccount: boolean = false;
  SupplierManagementForm: FormGroup;
  modelTitle: any;
  @ViewChild('SupplierManagementModel') SupplierManagementModel: ModalDirective;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
    private _clipboardService: ClipboardService,
    private registerService: RegisterService,
    private authService: AuthService,
    private suppliersService: SuppliersService,
    private invoiceService: InvoiceService,
    private _userService: UserService,
    private readonly _notificationsHubService: NotificationsHubService,
    private integrationService: IntegrationService,
    private confirmationDialogService: ConfirmationDialogService,
    private formBuilder: FormBuilder
  ) {
    super();
    const wizzardIsShown = sessionStorage.getItem('wizzardIsShown');
    const wizzardTab = sessionStorage.getItem('wizzardTab');
    const animationIsShown = sessionStorage.getItem('animationIsShown');

    if (wizzardIsShown == 'true' && wizzardTab == 'done') {
      this.router.navigateByUrl('/signed-in-redirect');
    } else {
      sessionStorage.setItem('wizzardIsShown', 'true');

      if (animationIsShown == 'true') {
        this.isDataDawloaded = true;
        this.isAnimationShown = true;
        this.stage = 0;
        this.stages = [1, 2, 3, 4, 5];
        this.tab = 0;
        this.tabs = [0, 1, 2, 3, 4];
      }
    }
  }

  get SupplierManagementFormControl() { return this.SupplierManagementForm.controls; }

  ngOnInit(): void {
    if (!this.isAnimationShown) {
      setTimeout(() => {
        this.showAnimation();
      }, 3000);
    }

    this.SupplierManagementForm = this.formBuilder.group({
      accountnumber: [null, Validators.required],
      name: [null, Validators.required],
      acctype: [null],
      description: [null],
    });

    this.activatedRoute.params.subscribe(param => {
      if (param.organizationId && window.opener) {
        window.opener.xeroData = param.organizationId;
        window.opener.quickbooksData = param.organizationId;
        window.opener.domainName = param.domain;
      }
    });

    this.setInitialParams();
  }

  getIntegrationList() {
    this.integrationService.getIntegrationList()
      .subscribe((data: any) => {
        if (data) {
          this.integrationList = data.filter(x => x.name == 'Xero' || x.name == 'QBO');
          this.addonList = data.filter(x => x.name == 'Gmail' || x.name == 'Outlook');
        } else {
          this.integrationList = [];
          this.addonList = [];
        }
      }, err => {
        this.integrationList = [];
        this.addonList = [];
        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.statusText);
      });
  }

  getGmailAccountList() {
    this.currentUser = JSON.parse(this._userService.upUserDetails);

    this.integrationService.getGmailAccountList(this._organizationService.selectedOrganization, this.currentUser.id)
      .subscribe((data: any) => {
        if (data) {
          this.syncedGmailAccountList = data.filter(x => x.integration?.toLowerCase() == 'gmail' || x.integration?.toLowerCase() == 'outlook');
        } else {
          this.syncedGmailAccountList = [];
        }
      }, err => {
        this.syncedGmailAccountList = [];
        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.statusText);
      });
  }
  showDeleteConfirmation(domain: string, id: number = 0) {
    this.confirmationDialogService.confirm('Confirm', 'Do you want to disconnect ' + domain + ' from Synced?', 'Yes', 'No', '')
      .then((confirm) => {
        if (confirm) {
          this.RemoveXeroConnection(domain, id);
        }
      });
  }

  RemoveXeroConnection(organisationType: string, id: number = 0) {
    this._organizationService.RemoveXeroConnection(this._organizationService.selectedOrganization, organisationType, this.currentUser.id, id).pipe().subscribe((response: any) => {
      if (response.status == 0) {
        if (id > 0) {
          this.syncedGmailAccountList.find(x => x.id == id).isConnected = false;
        } else {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
        }
      }
    }, err => {

      this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.statusText);
    });
  }
  connect(org) {
    if (org.name == 'Gmail') {
      window.open((org.connectionURL + '&return_uri=' + encodeURI(location.pathname + location.search.replace('&', 'and'))).replace("user_id_val", this.currentUser.id).replace("org_id_val", this.OrganisationId), "_self");
    }
    else if (org.name == 'Outlook') {
      window.open((org.connectionURL + '&return_uri=' + encodeURI(location.pathname + location.search.replace('&', 'and'))).replace("user_id_val", this.currentUser.id).replace("org_id_val", this.OrganisationId), "_self");
    }
  }
  selectTab(tab: WizzardTab) {
    switch (tab) {
      case WizzardTab.Data:
        this.currentTab = tab;
        break;
      case WizzardTab.Inbox:
        if (this.allowedTabs.includes(1))
          this.currentTab = tab;
        break;
      case WizzardTab.Bookkeeping:
        if (this.allowedTabs.includes(2))
          this.currentTab = tab;
        break;
      case WizzardTab.PaymentSettings:
        if (this.allowedTabs.includes(3))
          this.currentTab = tab;
        break;
    }
  }

  backButtonClick() {
    if (this.isNoAccounting) {
      this.isNoAccounting = !this.isNoAccounting;
    }
    else {
      this.selectTab(this.currentTab - 1);
    }
  }

  nextButtonClick() {
    switch (this.currentTab) {
      case WizzardTab.Data:
        this.getAllData();
        this.currentTab = WizzardTab.Inbox;
        this.selectTab(this.currentTab);
        this.setTabNumber(1);
        this.setonBoardingStatus(this.currentTab);
        this.allowedTabs.push(1);
        break;
      case WizzardTab.Inbox:
        this.currentTab = WizzardTab.Bookkeeping;
        this.selectTab(this.currentTab);
        this.setTabNumber(2);
        this.setonBoardingStatus(this.currentTab);
        this.allowedTabs.push(2);
        break;
      case WizzardTab.Bookkeeping:
        if (this.selectedTaxBasis == TaxBasis.DefaultRate && (this.selectedTaxRateId == null || this.selectedTaxRateId == '')) {
          this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
          return;
        }
        else if (this.selectedTaxBasis == TaxBasis.ExtractedAmount && (!this.IsQuickBook && ((this.selectedTaxRateId == null || this.selectedTaxRateId == '') || (this.selectedTaxExclusiveRateId == null || this.selectedTaxExclusiveRateId == '')))) {
          this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
          return;
        }
        if (this.selectedTaxBasis != null) {
          this.createDefaultInvoice();
        }
        this.currentTab = WizzardTab.PaymentSettings;
        this.selectTab(this.currentTab);
        this.setTabNumber(3);
        this.setonBoardingStatus(this.currentTab);
        this.allowedTabs.push(3);
        break;
      case WizzardTab.PaymentSettings:
        this.setTabNumber('done');
        this.setonBoardingStatus(4);
        this.router.navigateByUrl('/signed-in-redirect');
        break;
    }
  }

  setAccountDetails(account: BankAccount) {
    if (account) {
      this.defaultBankAccount = account;
      this.bsbNumber = account.bsbNumber;
      this.bankAccountNumber = account.bankAccountNumber;
      this._organizationService.SetDefaultAccount(account.accountID, this._organizationService.selectedOrganization)
        .pipe(this.untilThis)
        .subscribe(
          (response: any) => {
            if (response.status == 1) {
              // this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', response.Message);
            } else {
              //this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.Message);
            }
          }
        );
    }
  }

  saveTaxAndTrackingCategories() {
    if (!this.isSavingProcessGoing) {
      this.isSavingProcessGoing = true;
      this.isPaymentBtnDisable = false;
      if (this.selectedTaxBasis == TaxBasis.IgnoreTax) {
        this.selectedTaxRateId = null;
        this.selectedTaxExclusiveRateId = null;
        this.callUpdateSettings();
        return;
      }
      else if (this.selectedTaxBasis == TaxBasis.DefaultRate && (this.selectedTaxRateId == null || this.selectedTaxRateId == '')) {
        //this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
        this.isSavingProcessGoing = false;
        this.isPaymentBtnDisable = true;
        this.selectedTaxRateId = null;
        return;
      }
      else if (this.selectedTaxBasis == TaxBasis.ExtractedAmount && (!this.IsQuickBook && ((this.selectedTaxRateId == null || this.selectedTaxRateId == '') || (this.selectedTaxExclusiveRateId == null || this.selectedTaxExclusiveRateId == '')))) {
        //this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
        this.isSavingProcessGoing = false;
        this.isPaymentBtnDisable = true;
        return;
      }
      else {
        if (this.IsQuickBook && this.selectedTaxBasis == TaxBasis.ExtractedAmount)
          this.selectedTaxExclusiveRateId = "-1";
        else if (this.selectedTaxBasis == TaxBasis.DefaultRate) {
          this.selectedTaxExclusiveRateId = null;
        }
        else {
          this.selectedTaxExclusiveRateId = this.selectedTaxExclusiveRateId ?? null;
        }
        this.callUpdateSettings();
      }


    }
  }

  callUpdateSettings() {
    this.isPaymentBtnDisable = false;
    this.updateSettings(new UpdatedSettings(
      this._organizationService.selectedOrganization,
      this.selectedTaxBasis,
      this.selectedTaxRateId ?? null,
      this.selectedTrackingCategoriesIds,
      this.selectedTaxExclusiveRateId ?? null));
  }
  updateSettings(updatedSettings: UpdatedSettings) {
    this._organizationService.updateOrganizationSettings(updatedSettings)
      .pipe(
        this.untilThis,
        finalize(() => { this.isSavingProcessGoing = false; }))
      .subscribe(
        () => {
          //this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Settings updated!');
        },
        (e: Error) => {
          //this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  copyEmail() {
    this._clipboardService.copyFromContent(this.orgEmail);
    //this._toasterMessageService.displayToasterState(ToasterTypes.Info, '', 'Email copied to clipboard!');
  }

  setInitialParams() {
    this.getIntegrationList();
    this.getGmailAccountList();
    this.isXeroDirectSignup = localStorage.getItem('IsXeroDirect');
    this.isUserLogedin = localStorage.getItem('IsLogedin');
    this.activatedRoute.queryParams.subscribe(params => {
      //param comes from xero/qbo sign up redirect link or user loges back after createprofile
      if (params['OrganisationId'] && params['Domain']) {
        var orgsParam = params['OrganisationId'];

        this.organizationIds = orgsParam.split(' ');
        if (this.organizationIds.length > 0) {
          this.OrganisationId = this.organizationIds[0];
        }
        this.domainName = params['Domain'];
        this.isConnectionDone = true;
      } else if (location.search.indexOf('andDomain') > 0) {
        if (params['OrganisationId']) {
          let mainParam = params['OrganisationId'].replace('andDomain=', '&');
          var splitVar = mainParam.split('&');
          if (splitVar.length > 0) {
            var orgsParam = splitVar[0];
            this.organizationIds = orgsParam.split(' ');
            if (this.organizationIds.length > 0) {
              this.OrganisationId = this.organizationIds[0];
            }
            this.domainName = splitVar[1];
            this.isConnectionDone = true;
          }
        }
      }
      if (this.isConnectionDone) {
        this.isSpinnerShown = true;

        this.AfterSingUp();
      }
      else if (this.isUserLogedin == 'true') {
        if (this.OrganisationId != null || this.isXeroDirectSignup == 'true' || localStorage.getItem('SelectedOrganization') != null) {
          this.getOrganizationData();
        }
        else {
          //if user loged in but did not connected with xero/qbo
          this.isShowSyncingTab = false;
          this.loadTab = true;
        }
      }
      else {
        //user created profile but not connected with xero/qbo show first xer/qbo button
        this.hideDataTab();
      }
    });
    if (this.domainName === DomainName.Xero.toString()) {
      this.connectedToXero = true;
    } else if (this.domainName === DomainName.QuickBooks.toString()) {
      this.connectedToQBO = true;
    }
    else if (this.domainName === "None") {
      this.isNoAccounting = true;
    }
  }

  getOrganizationData() {
    this._organizationService.getOrganizationShortData(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.IsQuickBook = data?.accountingIntegration == 1;
          this.organisationName = data?.name;
          this.currentTab = data?.onboardingStatus;
          this.orgEmail = data?.generatedEmail;
          if (data?.onboardingStatus == OnBoardingStatus.Done) {
            this.router.navigateByUrl('/signed-in-redirect');
          } 
          else if (data?.onboardingStatus == OnBoardingStatus.Data && data?.accountingIntegration != 2) {
            if (this.isConnectionDone) {
              this.showDataTab();
            } else {
              if (this.isXeroDirectSignup == 'true') {
                //sync data done on Xero-signup so only load data here
                this.showDataTab();
                setTimeout(() => {
                  this.getAllData();
                }, 3000);
              } else {
                this.hideDataTab();
              }
            }
          } else {

            //set allowed tabs to show green checkmarks on completed tabs
            this.allowedTabs = [];
            for (var i = 1; i <= data?.onboardingStatus; i++) {
              this.allowedTabs.push(i);
            }
            this.setTabNumber(data?.onboardingStatus);
            this.selectTab(data?.onboardingStatus as any as WizzardTab);
            this.getAllData();
            this.hideDataTab();
          }
        },
        (e: Error) => {
          // this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }
  loadNextButton() {
    if (this.currentTab == this.wizzardTabs.Data && !this.isShowSyncingTab && !this.isDataDawloaded) {
      this.showNextButton = false;
    }
    else if (this.currentTab == this.wizzardTabs.PaymentSettings) {
      this.showNextButton = false;
    }
    return this.showNextButton;
  }
  showDataTab() {
    this.isShowSyncingTab = true;
    this.loadTab = true;
    this.isSpinnerShown = false;
  }

  hideDataTab() {
    this.isShowSyncingTab = false;
    this.loadTab = true;
    this.isSpinnerShown = false;
  }

  private getAllData() {

    const taxRates$ = this._organizationService.getTaxRates(this._organizationService.selectedOrganization, true);
    const selectedTaxRate$ = this._organizationService.getOrganizationTaxRateId(this._organizationService.selectedOrganization);
    const trackingCategories$ = this._organizationService.getTrackingCategories(this._organizationService.selectedOrganization);
    const selectedTrackingCategories$ = this._organizationService.getOrganizationTrackingCategories(this._organizationService.selectedOrganization);
    const bankAccounts$ = this._organizationService.GetBankAccountDetails("Organisation/GetBankAccountDetails", this._organizationService.selectedOrganization);
    const forkJoin$ = forkJoin({
      taxRates: taxRates$,
      selectedTaxRate: selectedTaxRate$,
      trackingCategories: trackingCategories$,
      selectedTrackingCategories: selectedTrackingCategories$,
      bankAccounts: bankAccounts$,
    });

    forkJoin$.subscribe(
      (data) => {

        this.selectedTaxBasis = data?.selectedTaxRate?.taxBasis;

        if (data?.selectedTaxRate?.taxBasis != TaxBasis.IgnoreTax) {
          this.selectedTaxRateId = data?.selectedTaxRate?.taxRateId;
          this.selectedTaxExclusiveRateId = data?.selectedTaxRate?.taxExclusiveRateId;
          this.isPaymentBtnDisable = (this.selectedTaxRateId || this.selectedTaxExclusiveRateId) ? false : true;
        }
        else {
          this.isPaymentBtnDisable = false;
        }
        this.taxRates = data?.taxRates;
        this.taxRatesExclusive = data?.taxRates.filter((x) => x.rate == 0);
        this.trackingCategories = data?.trackingCategories;
        this.selectedTrackingCategoriesIds = data?.selectedTrackingCategories;
        this.bankAccounts = data?.bankAccounts?.data;
        this.defaultBankAccount = this.bankAccounts?.find(a => a.isDefaultAccount == true);
        if(this.defaultBankAccount == undefined) {
          this.defaultBankAccount = this.bankAccounts[0];
        }

        if (this.defaultBankAccount) {
          this.bsbNumber = this.defaultBankAccount.bsbNumber;
          this.bankAccountNumber = this.defaultBankAccount.bankAccountNumber;
        }
        if (!this.isNoAccounting) {
          this._organizationService.getOrganizationCountry(this._organizationService.selectedOrganization)
            .pipe(take(1))
            .subscribe(
              (response) => {
                this.countryCode = response.countryCode;
              },
              (e: Error) => {
                // this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
              }
            )
        }
        else {
          this.selectedCountryCode = data?.selectedTaxRate?.countryCode;
        }
        if (this.selectedCountryCode) {
          this.selectedCountry = this.countryList.find(x => x.code.toLowerCase() == this.selectedCountryCode.toLowerCase())?.name
          if (this.selectedCountry) {
            this.isShowSyncingTab = true;
            this.showNoAccountingSection = false;
            this.isNoAccounting = true;
          }
        }
        this.dataLoading = false;
        if (this.currentTab == this.wizzardTabs.Done) {
          this.router.navigateByUrl('/signed-in-redirect');
        }
      });
  }

  private showAnimation() {
    this.stage = 1;

    const interval = setInterval(() => {
      this.stages.push(this.stage);
      if (this.stage < 6) {
        this.stage += 1;
      } else {

        sessionStorage.setItem('animationIsShown', 'true');
        clearInterval(interval);
      }
      if (this.stage == 5 && !this.IsQuickBook) {
        this.isDataDawloaded = true;
      }
      else if (this.stage == 4 && this.IsQuickBook) {
        this.isDataDawloaded = true;
      }
    }, 2000);
  }

  openXeroWindow() {
    if (this.xeroWindow) {
      this.xeroWindow.close();
    }
    this.xeroWindow = window.location.replace(xeroParams.xeroOauth2Link);
    const interval = setInterval(() => {
      if (this.xeroWindow.closed) {
        clearInterval(interval);
        this.xeroWindow = null;
      }
      var data = this.xeroWindow;
      if ((<any>window).xeroData !== undefined) {
        const orgIds = (<any>window).xeroData.split(' ');
        if (orgIds.length > 0) {
          this.accountingSoftware = AccountingIntegration.xero;
          this.connectedToXero = true;
          this.xeroWindow.close();
          clearInterval(interval);
          this.xeroWindow = null;
          this.organizationIds = orgIds;
          this.onSetOrganizationIds.emit(orgIds);
          //this.onNextSlide.emit();
        }
      }
    }, 1000);
  }

  openQuickBookWindow() {
    if (this.QuickBookWindow) {
      this.QuickBookWindow.close();
    }

    this.QuickBookWindow = window.location.replace(QuickBookParam.QuickBookOauth2Link);

    const interval = setInterval(() => {
      if (this.QuickBookWindow.closed) {
        clearInterval(interval);
        this.QuickBookWindow = null;
      }
      this.accountingSoftware = AccountingIntegration.QBO;
      var data = this.QuickBookWindow;
      if ((<any>window).quickbooksData !== undefined) {
        const orgIds = (<any>window).quickbooksData.split(' ');
        const domain = (<any>window).domainName.split('');
        if (orgIds.length > 0) {
          this.connectedToQBO = true;
          this.QuickBookWindow.close();
          clearInterval(interval);
          this.QuickBookWindow = null;
          this.organizationIds = orgIds;
          this.onSetOrganizationIds.emit(orgIds);
        }
      }
    }, 1000);
  }

  AfterSingUp() {
    this.SignupFullModel = JSON.parse(sessionStorage.getItem("newuser"));
    if (this.SignupFullModel) {
      this.SignupFullModel.id = sessionStorage.Id;
      this.signupModel = {
        domain: this.domainName,
        user: this.SignupFullModel,
        organisationIds: this.organizationIds.toString(),
        invitedUsers: null,
        organisationId: sessionStorage.organizationId
      }

      if (this.signupModel != null && this.signupModel != undefined) {
        this.loading = true;
        this.registerService.signUpNew('Account/SignUp-New', this.signupModel)
          .subscribe((response) => {
            if (response.status === 0) {
              this.loading = false;
              this.OrganisationId = response?.otherData?.organizationid;
              this._organizationService.selectedOrganization = this.OrganisationId;
              sessionStorage.organizationId = this.OrganisationId;
              sessionStorage.selectedOrganization = this.OrganisationId;
              localStorage.setItem('SelectedOrganization', this.OrganisationId);
              var temp = JSON.parse(this._userService.upUserDetails);
              if (temp) {
                temp.organizationId = this.OrganisationId;
              }
              this._userService.upUserDetails = JSON.stringify(temp);
              this.getOrganizationData();
            }
            else {
              this.loading = false;
              //this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
            }
          }, (error) => {
            //this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', 'Error inviting users');
          });
      }
    }
    else {
      this.router.navigate(['auth/login'], { replaceUrl: true });
    }
  }

  setonBoardingStatus(step: number) {
    this.suppliersService.UpdateSigupStep(this._organizationService.selectedOrganization, step)
      .pipe(take(1))
      .subscribe(() => {

      });
  }

  private setTabNumber(tabNumber: number | string) {
    sessionStorage.setItem('wizzardTab', tabNumber.toString());
  }

  createDefaultInvoice() {
    this.invoiceService.createDefaultInvoice(this._organizationService.selectedOrganization).pipe(take(1))
      .subscribe(() => { });
  }
  isNoAccounting: boolean = false;
  selectedCountry: string;
  selectedCountryCode: string;
  countryList: Country[] = countries;
  showNoAccountingSection: boolean = false;
  continueWOAccounting() {
    this.isNoAccounting = true;
    this.isShowSyncingTab = false;
    this.showNoAccountingSection = true;
  }

  nextButtonClickWOAccounting() {   
    // this.showNoAccountingSection = false;
    // this.isShowSyncingTab = true;
    switch (this.currentTab) {
      case WizzardTab.Data:
        this.AfterSingUpForNoAccounting();
        
        break;
      case WizzardTab.Inbox:
        this.currentTab = WizzardTab.Bookkeeping;
        this.selectTab(this.currentTab);
        this.setTabNumber(2);
        this.setonBoardingStatus(this.currentTab);
        this.allowedTabs.push(2);
        break;
      case WizzardTab.Bookkeeping:
        if (this.selectedTaxBasis == TaxBasis.DefaultRate && (this.selectedTaxRateId == null || this.selectedTaxRateId == '')) {
          this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
          return;
        }
        else if (this.selectedTaxBasis == TaxBasis.ExtractedAmount && (!this.IsQuickBook && ((this.selectedTaxRateId == null || this.selectedTaxRateId == '') || (this.selectedTaxExclusiveRateId == null || this.selectedTaxExclusiveRateId == '')))) {
          this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
          return;
        }
        if (this.selectedTaxBasis != null) {
          this.createDefaultInvoice();
        }
        this.currentTab = WizzardTab.PaymentSettings;
        this.selectTab(this.currentTab);
        this.setTabNumber(3);
        this.setonBoardingStatus(this.currentTab);
        this.allowedTabs.push(3);
        break;
      case WizzardTab.PaymentSettings:
        this.setTabNumber('done');
        this.setonBoardingStatus(4);
        this.router.navigateByUrl('/signed-in-redirect');
        break;
      }
  }


  AfterSingUpForNoAccounting() {
    var country = this.countryList.find(x => x.name == this.selectedCountry);
    this.SignupFullModel = JSON.parse(sessionStorage.getItem("newuser"));
    if (this.SignupFullModel) {
      this.SignupFullModel.id = sessionStorage.Id;
      var signupModel = {
        domain: this.domainName,
        user: this.SignupFullModel,
        organisationIds: null,
        invitedUsers: null,
        organisationId: sessionStorage.organizationId,
        country: this.selectedCountry,
        defaultCurrency: country.defaultCurrency,
        countryCode: country.code.toUpperCase()
      }

      if (signupModel != null && signupModel != undefined) {
        this.loading = true;
        this.registerService.signUpNew('Account/SignUp-NoAccounting', signupModel)
          .subscribe((response) => {
            if (response.status === 0) {
              this.loading = false;
              this.OrganisationId = response?.otherData?.organizationid;
              this._organizationService.selectedOrganization = this.OrganisationId;
              sessionStorage.organizationId = this.OrganisationId;
              sessionStorage.selectedOrganization = this.OrganisationId;
              localStorage.setItem('SelectedOrganization', this.OrganisationId);
              var temp = JSON.parse(this._userService.upUserDetails);
              if (temp) {
                temp.organizationId = this.OrganisationId;
              }
              this._userService.upUserDetails = JSON.stringify(temp);
              this.getOrganizationData();
              this.countryCode = country.code.toUpperCase();
              this.currentTab = WizzardTab.Inbox;
              this.selectTab(this.currentTab);
              this.setTabNumber(1);
              this.setonBoardingStatus(this.currentTab);
              this.allowedTabs.push(1);
              this.getAllData();
            }
            else {
              this.loading = false;
              //this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
            }
          }, (error) => {
            //this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', 'Error inviting users');
          });
      }
    }
    else {
      this.router.navigate(['auth/login'], { replaceUrl: true });
    }
  }
  
  AddBankAccount() {
    var accnum = "1" + (Math.floor(Math.random() * 900) + 100).toString();
    this.registerModel = {
      name: '',
      acctype: 'BANK',
      accountnumber: accnum,
      description: '',
    };
    this.IsAddNewBankAccount = true;
    this.modelTitle = 'Add Bank Account';
    this.SupplierManagementForm.reset();
    this.SupplierManagementForm.patchValue(this.registerModel);
    this.SupplierManagementModel.show();
  }
  closeModel() {
    this.SupplierManagementModel.hide();
  }

  onSubmit() {
    this.SupplierManagementForm.markAllAsTouched();
    if (this.SupplierManagementForm.invalid) {
      return;
    }
      this.CreateBankAccountDetails()

  }
  CreateBankAccountDetails() {
    var bankAccountModel = {
      accountNumber: this.SupplierManagementFormControl.accountnumber.value,
      type: this.SupplierManagementFormControl.acctype.value,
      description: this.SupplierManagementFormControl.description.value,
      OrganisationId: this.OrganisationId,
      accountName: this.SupplierManagementFormControl.name.value
    };
    this.loading = true;
    this._organizationService.CreateSyncedAccountDetails(bankAccountModel)
      .pipe(take(1),
        finalize(() => { this.loading = false; }))
      .subscribe(() => {
        this._toasterMessageService.displayToasterState(ToasterTypes.Success, '', 'Created!');
        this.closeModel();
        this._organizationService.GetBankAccountDetails("Organisation/GetBankAccountDetails", this._organizationService.selectedOrganization).subscribe((data) => {
          this.bankAccounts = data.data;
          this.defaultBankAccount = this.bankAccounts?.find(a => a.isDefaultAccount == true);
          if(this.defaultBankAccount == undefined) {
            this.defaultBankAccount = this.bankAccounts[0];
          }
        })
      }, err => {
        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.message);
        this.closeModel();
      });
  }
}
