import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AuthService } from '../service/auth/auth.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthUtils } from '../util/auth.utils';
import { id } from '@swimlane/ngx-datatable';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public authService: AuthService,
        public spinnerService: SpinnerService,
        public router: Router,      
        private activatedRoute: ActivatedRoute) {        
            var  initial_url = router.url;
            var url = initial_url.split('?');  
            if(url[0] == "/report/save-reports")
            {
                sessionStorage.Url = router.url;       
            }           
        }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {       
  
        if (this.authService.accessToken && !AuthUtils.isTokenExpired(this.authService.accessToken)) {
            request = request.clone({
                setHeaders: {
                    'authorization': `Bearer ${this.authService.accessToken}`,
                    'Access-Control-Expose-Headers': 'authorization'
                }
            });
        }

        return next.handle(request).pipe(
            
        
            catchError((error) => {
                
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this.authService.signOut()
                    .pipe()
                    .subscribe((response: any) => {  
                        const redirectURL = this.activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/auth/login';
                        this.router.navigate([redirectURL], { skipLocationChange: true});              
                    });

                    // Reload the app
                    //location.reload();
                    
                }

                return throwError(error);
            })
        );
    }
}