import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { UserService } from '../../data/service/user.service';
import { ReportService } from '../../data/service/report.service';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../data/service/organization.service';
import * as $ from "jquery";
import { EmailRequestModal } from 'src/app/data/model/emailRequest-model';
import { environment } from '../../../environments/environment';
import { ToasterMessageService } from '../../shared/toaster-message/toaster-message.service';
import { ToasterTypes } from '../../core/enum/toaster-type.enum';
@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
  organizationId: string;
  ReportId: any;
  subscriptions: Subscription[] = [];
  userList: any = [];
  userEmail: any;
  ReportName: any;
  loading = false;
  userName: any;
  emailRequestModal: EmailRequestModal | null;
  userId: any;
  showsendEmailFilter: boolean = true;

  constructor(public dialogRef: MatDialogRef<EmailModalComponent>,

    public spinnerService: SpinnerService,
    private userService: UserService,
    private reportService: ReportService,
    private organizationService: OrganizationService,
    private toasterMessageService: ToasterMessageService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.ReportName = data.ReportName;
    this.ReportId = data.ReportId
  }

  ngOnInit(): void {
    this.subscriptions.push(this.organizationService.selectedOrganizationId.subscribe(data => {
      if (data) {
        this.organizationId = data;
      }
    }));

    this.userId = sessionStorage.UserId;
    this.userService.getAllUsersByOrganizationId(this.organizationId, this.userId).subscribe(data => {
      if (data.status == 0) {
        this.userList = data.data || [];

      }
      else {
        this.userList = [];

      }
    });
  }
  Selectno() {
    this.dialogRef.close();
  }
  SetUserEmail(value) {
    for (var j = 0; j <= this.userList.length - 1; j++) {
      if (this.userList[j].id == value) {
        this.userEmail = this.userList[j].email;
        this.userName = this.userList[j].name;
        //$("#useremail").val(this.userEmail);
        $("#txtMessageId").removeAttr("disabled");
        $("#txtMessageId").css("background-color","#fff");
        break;
      }
    }


  }
  SendEmailToUser() {
    this.loading = true;

    // this.emailRequestModal = new EmailRequestModal();
    var AssignedToUserID = $("#username").val();
    var reporturl = "report/save-reports?ReportId=" + this.ReportId + "&ReportName=" + this.ReportName + "&OrganizationId=" + this.organizationId;
    var emailRequestModal =
    {
      UserName: this.userName,
      EmailId: this.userEmail,
      ReportName: this.ReportName,
      ReportId: this.ReportId,
      ReportUrl: reporturl,
      AssignedToUserID: AssignedToUserID,
      AssignedByuserID: sessionStorage.Id,
      OrganisationID: this.organizationId,
      Comment : $("#txtMessageId").val()
    };

    this.reportService.sendEmailToUser(emailRequestModal).subscribe(data => {
      this.loading = false;

      if (data.status == 0) {

        this.userList = data.data || [];

      }
      else {
        this.toasterMessageService.displayToasterState(ToasterTypes.Success, '', data.message);
        this.userList = [];
        this.dialogRef.close();

      }
    });
  }

  togglesendEmailFilter() {
    this.showsendEmailFilter = !this.showsendEmailFilter;
  }
}
