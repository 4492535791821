import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BankAccount } from 'src/app/modules/bookkeeping/models/account/bank-account';
import { PaymentAccount } from 'src/app/modules/bookkeeping/models/account/payment-account';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {
  private _accountSubject = new Subject<BankAccount>();

  accountState$ = this._accountSubject.asObservable();

  constructor(private _httpService: HttpInternalService) { }

  updateBankAccount(account: BankAccount) {
    this._accountSubject.next(account);
  }

  getPaymentAccounts(id: string) {
    return this._httpService.getRequest<PaymentAccount[]>(`BankAccounts/getPaymentAccounts?organizationId=${id}`);
  }

  getBankAccountBySupplierId(id: string, organizationId: string) {
    return this._httpService.getRequest<BankAccount>(`BankAccounts/getBankAccountBySupplierId?id=${id}&organizationId=${organizationId}`);
  }
}
