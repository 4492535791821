import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import * as $ from "jquery";


@Injectable({
  providedIn: 'root'
})
export class ToasterMessageService {
  private toastrTimeOutInMiliseconds: number = 5000;
  private toastrPosition: string = 'toast-bottom-right';
  private successToastClass: string = 'ngx-toastr toast-success toast-success-custom';
  private errorToastClass: string = 'ngx-toastr toast-error toast-error-custom';
  private infoToastClass: string = 'ngx-toastr toast-info toast-info-custom';
  private warningToastClass: string = 'ngx-toastr toast-info toast-warning-custom';
  private successInboxToastClass: string = 'InboxToaster ngx-toastr-inbox toast-success-inbox';
  private errorInboxToastClass: string = 'InboxToaster ngx-toastr-inbox toast-error-inbox';

  private toasterState = new Subject<{ type: any, title: any, textValue: any }>();

  toasterConfig: any = {
    closeButton: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
  };

  toasterConfigWithHtml: any = {
    closeButton: true,
    enableHtml: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
  };

  toasterConfigInbox: any = {
    closeButton: true,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  };

  constructor(private toastr: ToastrService) {
    toastr.toastrConfig.timeOut = this.toastrTimeOutInMiliseconds;
    toastr.toastrConfig.positionClass = this.toastrPosition;

    this.registerToasterStateListener();
  }

  displayToasterState(type: any, title: any, textValue: any) {
    if(type == ToasterTypes.Error && textValue && textValue.toLowerCase().includes("500 internal server error")){
      title = "Error";
      type = ToasterTypes.ErrorWithHtml;
      this.toastr.toastrConfig.timeOut = 10000;
      textValue= "Contact Support to resolve. <a class='toastr-link supportlink'>Support</a>"
    }
    this.toasterState.next({ type: type, title: title, textValue: textValue });
  }

  private registerToasterStateListener() {
    this.toasterState.subscribe((toasterState) => {
      if (toasterState) {
        if (toasterState.type == ToasterTypes.Success) {
          this.toastr.success(toasterState.textValue, toasterState.title, { ...this.toasterConfig, toastClass: this.successToastClass});
        }
        else if (toasterState.type == ToasterTypes.Error) {
          this.toastr.error(toasterState.textValue, toasterState.title, { ...this.toasterConfig, toastClass: this.errorToastClass});
        }
        else if (toasterState.type == ToasterTypes.Warning) {
          this.toastr.warning(toasterState.textValue, toasterState.title, { ...this.toasterConfig, toastClass: this.warningToastClass});
        }
        else if (toasterState.type == ToasterTypes.Info) {
          this.toastr.info(toasterState.textValue, toasterState.title, { ...this.toasterConfig, toastClass: this.infoToastClass});
        }
        else if (toasterState.type == ToasterTypes.SuccessWithHtml) {
          this.toastr.success(toasterState.textValue, toasterState.title,  { ...this.toasterConfigWithHtml, toastClass: this.successToastClass});
        }
        else if (toasterState.type == ToasterTypes.ErrorWithHtml) {
          if(toasterState.textValue.includes("supportlink")) {
            setTimeout(() => {
              $(".supportlink").on("click", function () {
              (<any>window).Intercom('show');
              });
              }, 400);
          }
          this.toastr.success(toasterState.textValue, toasterState.title,  { ...this.toasterConfigWithHtml, toastClass: this.errorToastClass});
        }
        else if (toasterState.type == ToasterTypes.SuccessWithHtmlAsync) {
          this.toastr.success(toasterState.textValue, toasterState.title,  { ...this.toasterConfigWithHtml, toastClass: this.successToastClass, onActivateTick: true});
        }
        else if (toasterState.type == ToasterTypes.ErrorInbox) {
          this.toastr.toastrConfig.timeOut = 3000;
          this.toastr.success(toasterState.textValue, toasterState.title,  { ...this.toasterConfigInbox, toastClass: this.errorToastClass});
        }
        else if (toasterState.type == ToasterTypes.SuccessInbox) {
          this.toastr.toastrConfig.timeOut = 3000;
          this.toastr.success(toasterState.textValue, toasterState.title,  { ...this.toasterConfigInbox, toastClass: this.successInboxToastClass, onActivateTick: true});
        }
      }
    });
  }
}
