export class SupplierAccountCreated {
    id: string;
    name: string;
    organizationId: string;
    defaultAccountCode: string;

    constructor(id: string, name: string, organizationId: string, defaultAccountCode: string) {
        this.id = id;
        this.name = name;
        this.organizationId = organizationId;
        this.defaultAccountCode = defaultAccountCode;
    }
}