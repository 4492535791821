import { Guid } from "guid-typescript";

export class PaymentAccount {
    id: string;
    name?: string;
    code: string;
    accountID:string;
    checked?: boolean;

    constructor() {
        this.id = Guid.EMPTY;
        this.name = '';
        this.code = '';
    }
}