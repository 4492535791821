import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { BookkeepingFilter } from '../../models/filter/bookkeeping-filter';
import { InvoiceShort } from '../../models/invoice/invoice-short';
import { Location } from '@angular/common'; 
import { dateToString } from 'src/app/core/util/date-converter';
import { InvoiceStatus } from '../../enums/invoice-status';
import { SupplierShort } from '../../models/supplier/supplier-short';
import { PaymentAccount } from '../../models/account/payment-account';
import { Tag } from 'src/app/data/model/tag';
import { DateRange } from '@angular/material/datepicker';
import { TrackingCategory } from '../../models/tracking-categories.ts/trackingCategory';
import { TrackingCategoryOption } from '../../models/tracking-categories.ts/tracking-category-option';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { take } from 'rxjs/operators';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';

enum FilterMenuSection {
  Main,
  Date,
  Category,
  Supplier,
  Tag,
  TrackingCategory1,
  TrackingCategory2
}

@Component({
  selector: 'app-bookkeeping-filter',
  templateUrl: './bookkeeping-filter.component.html',
  styleUrls: ['./bookkeeping-filter.component.scss']
})
export class BookkeepingFilterComponent implements OnInit {
  @Input() invoices: InvoiceShort[] = [];
  @Input() filter = new BookkeepingFilter();
  @Input() invoiceStatus: InvoiceStatus | null;
  @Input() tags: Tag[] = [];
  @Input() isFilterShown: boolean = false;
  @Input() loading: boolean = false;
  @Input() trackingCategories: TrackingCategory[] = [];
  @Input() selectedTrackingCategories: TrackingCategory[] = [];
  @Input() accounts: PaymentAccount[] = [];

  @Output() updateInvoices: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  suppliers: SupplierShort[] = [];


  unassignedTag = {
    backColor: '',
    tagId: 'unassigned',
    organizationId: '',
    textColor: '',
    checked: false,
    tagName: 'Unassigned tags'
  } as Tag;

  filterUrlPattern: string = '';

  dateFormat: string = null;
  currentFilterMenuSection: FilterMenuSection = FilterMenuSection.Main;
  filterMenuSections = FilterMenuSection;

  filterCategories: PaymentAccount[] = [];
  filterSuppliers: SupplierShort[] = [];
  filterTags: Tag[] = [];
  filterTrackingOptions1: TrackingCategoryOption[] = [];
  filterTrackingOptions2: TrackingCategoryOption[] = [];
  filterDateRange: DateRange<Date> = null;

  tagSearchTerm: string = null;
  searchedSuppliers: SupplierShort[];
  searchedAccounts: PaymentAccount[];
  searchedTags: Tag[];
  searchedTrackingOprions1: TrackingCategoryOption[];
  searchedTrackingOprions2: TrackingCategoryOption[];

  private status = InvoiceStatus;

  constructor(
    private readonly _location: Location,
    private readonly _suppliersService: SuppliersService,
    private readonly _organizationService: OrganizationService,
    private readonly _toasterMessageService: ToasterMessageService,
  ) 
  {
    this.dateFormat = sessionStorage.getItem('orgDateFormat');
  }

  ngOnInit(): void {
    this.getSuppliers();
  }

  getSuppliers() {
    this._suppliersService.getSuppliers(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.suppliers = data.sort((a, b) => a.name.localeCompare(b.name)) || [];
  
          this.searchedSuppliers = this.suppliers;
          this.searchedTags = this.tags;
          this.searchedAccounts = this.accounts;
  
          if (this.selectedTrackingCategories && this.selectedTrackingCategories.length > 0) {
            this.searchedTrackingOprions1 = this.selectedTrackingCategories[0].options;
            if (this.selectedTrackingCategories.length > 1) {
              this.searchedTrackingOprions2 = this.selectedTrackingCategories[1].options;
            }
          }
  
          this.showTagsFromAddressBar();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  showFilterSection(section: FilterMenuSection) {
    this.currentFilterMenuSection = section;
    this.clearSearchValue();
  }

  clearSearchValue() {
     this.searchedSuppliers = this.suppliers;
     this.searchedAccounts = this.accounts;
     this.searchedTags = this.tags;
     this.searchedTrackingOprions1 = this.selectedTrackingCategories && this.selectedTrackingCategories.length > 0 ? this.selectedTrackingCategories[0].options : null;
     this.searchedTrackingOprions2 = this.selectedTrackingCategories && this.selectedTrackingCategories.length > 1 ? this.selectedTrackingCategories[1].options : null;
     this.tagSearchTerm = null;
  }

  menuClosed() {
    this.currentFilterMenuSection = this.filterMenuSections.Main;
    this.clearSearchValue();
  }

  filterSearchList(filterMenuSection: FilterMenuSection, event: string) {
    this.tagSearchTerm = event;

    switch (filterMenuSection) {
      case FilterMenuSection.Supplier:
        this.searchedSuppliers = this.suppliers.filter(s => s.name.toLowerCase().includes(this.tagSearchTerm.toLowerCase()));
        break;
      case FilterMenuSection.Category:
        this.searchedAccounts = this.accounts.filter(s => s.name.toLowerCase().includes(this.tagSearchTerm.toLowerCase()));
        break;
      case FilterMenuSection.Tag:
        this.searchedTags = this.tags.filter(s => s.tagName.toLowerCase().includes(this.tagSearchTerm.toLowerCase()));
        break;
      case FilterMenuSection.TrackingCategory1:
        this.searchedTrackingOprions1 = this.selectedTrackingCategories[0].options?.filter(s => 
          s.name.toLowerCase().includes(this.tagSearchTerm.toLowerCase()));
        break;
      case FilterMenuSection.TrackingCategory2:
        this.searchedTrackingOprions2 = this.selectedTrackingCategories[1].options?.filter(s => 
          s.name.toLowerCase().includes(this.tagSearchTerm.toLowerCase()));
        break;
    }
  }

  updateFilter(field: string, value: any) {
    this.filter[field] = value;

    let supplierPart = this.filter.suppliers?.length > 0 ? this.filter.suppliers?.map(s => `suppliers=${s}&`).join('').slice(0, -1) : 'suppliers=';
    let categoryPart = this.filter.categories?.length > 0 ? this.filter.categories?.map(c => `categories=${c}&`).join('').slice(0, -1) : 'categories=';
    let tagPart = this.filter.tags?.length > 0 ? this.filter.tags?.map(t => `tags=${t}&`).join('').slice(0, -1) : 'tags=';

    let dateStartPart = this.filter.invoiceDateRange ? `dateStart=${dateToString(this.filter.invoiceDateRange[0]) ?? ''}` : 'dateStart=';
    let dateEndPart = this.filter.invoiceDateRange ? `dateEnd=${dateToString(this.filter.invoiceDateRange[1]) ?? ''}` : 'dateEnd=';
    let statusPart = `status=${this.invoiceStatus == this.status.inProcessing ? 'false' : 'true'}`;

    let trackingOptions1Part = this.filter.trackingOptions1?.length > 0 ? this.filter.trackingOptions1?.map(o => `track1=${o}&`).join('').slice(0, -1) : 'track1=';
    let trackingOptions2Part = this.filter.trackingOptions2?.length > 0 ? this.filter.trackingOptions2?.map(o => `track2=${o}&`).join('').slice(0, -1) : 'track2=';

    this.filterUrlPattern = `?${supplierPart}&${dateStartPart}&${dateEndPart}&${categoryPart}&${tagPart}&${trackingOptions1Part}&${trackingOptions2Part}&${statusPart}`;

    if (this.filter.categories?.length > 0 
      || this.filter.suppliers?.length > 0 
      || this.filter.tags?.length > 0 
      || (this.filter.invoiceDateRange && this.filter.invoiceDateRange[0])
      || this.filter.trackingOptions1?.length > 0 
      || this.filter.trackingOptions2?.length > 0
    ) {
      this._location.replaceState(`/inbox${this.filterUrlPattern}`);
      this.filterInvoices();
    } else {
      this.closeFilter.emit(false);
    }    
  }

  clickCategoryCheckBox(account: PaymentAccount, event) {
    if (event.target.checked) {
      this.filterCategories.push(account);
    } else {
      this.filterCategories = this.filterCategories.filter(c => c.id != account.id);
    }

    this.updateFilter('categories', this.filterCategories.map(c => c.name.replace('&', '%26')));
  }

  clickSupplierCheckBox(supplier: SupplierShort, event) {
    if (event.target.checked) {
      this.filterSuppliers.push(supplier);
    } else {
      this.filterSuppliers = this.filterSuppliers.filter(c => c.id != supplier.id);
    }

    this.updateFilter('suppliers', this.filterSuppliers.map(s => s.name.replace('&', '%26')));
  }

  clickTagCheckBox(tag: Tag, event) {
    if (event.target.checked) {
      this.filterTags.push(tag);
    } else {
      this.filterTags = this.filterTags.filter(c => c.tagId != tag.tagId);
    }
    if (tag == this.unassignedTag) {
      this.unassignedTag.checked = event.target.checked;
    }

    this.updateFilter('tags', this.filterTags.map(t => t.tagName));
  }

  clickTrackingCategoryCheckBox(filterMenuSection: FilterMenuSection, option: TrackingCategoryOption, event) {
    if (event.target.checked) {
      if (filterMenuSection == FilterMenuSection.TrackingCategory1) {
        this.filterTrackingOptions1.push(option);
      } else {
        this.filterTrackingOptions2.push(option);
      }
    } else {
      if (filterMenuSection == FilterMenuSection.TrackingCategory1) {
        this.filterTrackingOptions1 = this.filterTrackingOptions1.filter(c => c.name != option.name);
      } else {
        this.filterTrackingOptions2 = this.filterTrackingOptions2.filter(c => c.name != option.name);
      }
    }

    if (filterMenuSection == FilterMenuSection.TrackingCategory1) {
      this.updateFilter('trackingOptions1', this.filterTrackingOptions1.map(p => p.name));
    } else {
      this.updateFilter('trackingOptions2', this.filterTrackingOptions2.map(p => p.name));
    }
  }

  deleteFilterTag(filterMenuSection: FilterMenuSection) {
    switch (filterMenuSection) {
      case FilterMenuSection.Date:
        this.filterDateRange = null;
        this.updateFilter('invoiceDateRange', null);
        break;
      case FilterMenuSection.Supplier:
        this.filterSuppliers = [] as SupplierShort[];
        this.searchedSuppliers.forEach(element => {
          element.checked = false;
        });
        this.updateFilter('suppliers', null);
        break;
      case FilterMenuSection.Category:
        this.filterCategories = [] as PaymentAccount[];
        this.searchedAccounts.forEach(element => {
          element.checked = false;
        });
        this.updateFilter('categories', null);
        break;
      case FilterMenuSection.Tag:
        this.filterTags = [] as Tag[];
        this.searchedTags.forEach(element => {
          element.checked = false;
        });
        this.updateFilter('tags', null);
        break;
      case FilterMenuSection.TrackingCategory1:
        this.filterTrackingOptions1 = [] as TrackingCategoryOption[];
        this.searchedTrackingOprions1.forEach(element => {
          element.checked = false;
        });
        this.updateFilter('trackingOptions1', null);
        break;
      case FilterMenuSection.TrackingCategory2:
        this.filterTrackingOptions2 = [] as TrackingCategoryOption[];
        this.searchedTrackingOprions2.forEach(element => {
          element.checked = false;
        });
        this.updateFilter('trackingOptions2', null);
        break;
      default:
        this.clearFilter();
        this.closeFilter.emit(false);
        break;
    }
  }

  clearFilter() {
    this.filter = null;
    this.filterDateRange = null;
    this.filterSuppliers = [] as SupplierShort[];
    this.filterCategories = [] as PaymentAccount[];
    this.filterTags = [] as Tag[];
    this.filterTrackingOptions1 = [] as TrackingCategoryOption[];
    this.filterTrackingOptions2 = [] as TrackingCategoryOption[];
        
    this.searchedSuppliers?.forEach(element => {
      element.checked = false;
    });

    this.searchedAccounts?.forEach(element => {
      element.checked = false;
    });

    this.searchedTags?.forEach(element => {
      element.checked = false;
    });

    this.searchedTrackingOprions1?.forEach(element => {
      element.checked = false;
    });

    this.searchedTrackingOprions2?.forEach(element => {
      element.checked = false;
    });
  }

  setDateRange(event) {
    this.filterDateRange = event;
    this.currentFilterMenuSection = FilterMenuSection.Main;
    this.updateFilter('invoiceDateRange', [this.filterDateRange.start, this.filterDateRange.end]);
  }

  private filterInvoices() {
    this.updateInvoices.emit(this.filterUrlPattern);
  }

  private showTagsFromAddressBar() {
    this.filterSuppliers =  this.suppliers?.filter(supplier => this.filter.suppliers?.some(s => s == supplier.name));
    this.filterCategories =  this.accounts?.filter(category => this.filter.categories?.some(c => c == category.name));
    this.filterDateRange = this.filter.invoiceDateRange?.length > 0 ? new DateRange<Date>(this.filter.invoiceDateRange[0], this.filter.invoiceDateRange[1]) : null;
    this.filterTags = this.tags?.filter(tag => this.filter.tags?.some(t => t == tag.tagName));
    this.filterTrackingOptions1 = this.selectedTrackingCategories?.find(tc => tc.options?.some(option => this.filter.trackingOptions1?.includes(option.name)))?.options;
    this.filterTrackingOptions2 = this.selectedTrackingCategories?.find(tc => tc.options?.some(option => this.filter.trackingOptions2?.includes(option.name)))?.options;

    if (this.filter.tags?.includes(this.unassignedTag.tagName)) {
      this.filterTags = [this.unassignedTag, ...this.filterTags];
    }

    this.filterSuppliers?.forEach(element => {
      element.checked = true;
    });

    this.filterCategories?.forEach(element => {
      element.checked = true;
    });

    this.filterTags?.forEach(element => {
      element.checked = true;
    });

    this.filterTrackingOptions1?.forEach(element => {
      element.checked = true;
    });

    this.filterTrackingOptions2?.forEach(element => {
      element.checked = true;
    });
  } 
}
