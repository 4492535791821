  <ng-select class="select-account" [searchable]="false" placeholder="{{placeholder}}" [(ngModel)]="selectedDateRangeOption"
    (ngModelChange)="onSelectType($event)">
    <ng-option value="0">Today</ng-option>
    <ng-option value="1">This Week</ng-option>
    <ng-option value="2">This Month</ng-option>
    <ng-option value="3">Today and Older</ng-option>
    <ng-option value="4">Custom Range</ng-option>
  </ng-select>

<mat-form-field style="width:100% !important; height: 25px !important;" [floatLabel]="'never'" *ngIf="selectedDateRangeOption == '4'">
  <mat-label>Select Custom Date Range</mat-label>
  <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
    <input matStartDate #dateRangeStart formControlName="start" (focus)="picker.open()">
    <input matEndDate #dateRangeEnd formControlName="end" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
           (focus)="picker.open()">
  </mat-date-range-input>
  <button class="cal-clear-btn" mat-button *ngIf="dateRangeStart.value && dateRangeEnd.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="dateRangeStart.value = ''; dateRangeEnd.value = ''; dateRangeChange(null, null)">
    <mat-icon>x</mat-icon>
  </button>
  <mat-date-range-picker #picker md-open-on-focus></mat-date-range-picker>
</mat-form-field>  

