import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { NewSupplier } from '../models/supplier/new-supplier';

@Injectable()
// This service is used to show the Supplier create and Edit form.
export class SharedService {
  invoiceId:string = "";
  private _existingSupplierSubject = new BehaviorSubject<NewSupplier>(null);
  private _existingActionbarSubject = new BehaviorSubject(false);
  private _actionbarSubject = new BehaviorSubject(false);

  isVisibleSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  existingSupplierState$ = this._existingSupplierSubject.asObservable();
  existingActionbarState$ = this._existingActionbarSubject.asObservable();
  actionbarState$ = this._actionbarSubject.asObservable();

  constructor() { }

  sendExistingSupplier(supplier: NewSupplier) {
    this._existingSupplierSubject.next(supplier);
  }

  closeActionBar(isVisible: boolean) {
    this._existingActionbarSubject.next(isVisible);
  }

  disableActionBar(isVisible: boolean) {
    this._actionbarSubject.next(isVisible);
  }
}