import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { StepOneComponent } from './steps/step-one/step-one.component';
import { StepTwoComponent } from './steps/step-two/step-two.component';
import { StepThreeComponent } from './steps/step-three/step-three.component';
import { XeroSignUpComponent } from './steps/xero-sign-up/xero-sign-up.component';
import { SignupWizzardComponent } from './wizzard/signup-wizzard/signup-wizzard.component';
import { AuthGuard } from 'src/app/core/guard/auth.guard';
import { StepVerifyEmailComponent } from './steps/step-verify-email/step-verify-email.component';
import { StepConfirmEmailComponent } from './steps/step-confirm-email/step-confirm-email.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/signup/step-one',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'step-one',
        component: StepOneComponent,
        pathMatch: 'full'
      },
      {
        path: 'step-two',
        component: StepTwoComponent,
        pathMatch: 'full'
      },
      {
        path: 'steps-verify-email/:id',
        component: StepVerifyEmailComponent,
        pathMatch: 'full'
      },
      {
        path: "steps-confirm-email/:id",
        component: StepConfirmEmailComponent
      },
      {
        path: 'step-three',
        component: StepThreeComponent,
        pathMatch: 'full'
      },
      {
        path: 'xero-sign-up',
        component: XeroSignUpComponent,
        pathMatch: 'full'
      },
      {
        path: 'wizzard',
        canActivate: [AuthGuard],
        component: SignupWizzardComponent,
        pathMatch: 'full'
      }
    ]
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignupRoutingModule { }
