import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-placeholder',
  templateUrl: './document-placeholder.component.html',
  styleUrls: ['./document-placeholder.component.scss']
})
export class DocumentPlaceholderComponent {
  @Input() isHorizontalDirection: boolean = false;

}
