<div class="custom-mat-spinner sidebar-spinner" *ngIf="isSpinnerShown" [ngClass]="{'inbox-sidebar-spinner': !billsToPayMode && isSpinnerShown , 'billspay-sidebar': billsToPayMode && isSpinnerShown}">
  <mat-spinner></mat-spinner>
  <div class="text">Data loading...</div>
</div>
<div class="form-container" id="main-page-div" [ngClass]="{'form-container-bills-to-pay': billsToPayMode && isSpinnerShown  }">
  <div class="back-area" *ngIf="returnBackToText" (click)="closeFormOnBillsToPay()">
    <div class="shevron">
      <img src="../../../../../assets/img/invoiceForm/shevron-white.svg" alt="">
    </div>
    <div class="text">Back to {{returnBackToText}}</div>
  </div>

  <div *ngIf="!isSpinnerShown">
    <!-- Controls -->
    <div class="close-icon mobile-close" matTooltip="Hide Side Bar" [matTooltipShowDelay]="showToolTipDelay"
         (click)="closeFormOnBillsToPay()" *ngIf="isInboxMobileMode && !isPreviewVisible">
      <img src="../../../../../assets/img/close-icon.svg" alt="">
    </div>
    <div class="controls" *ngIf="currentPannel != panels.ContactForm && currentPannel != panels.InvoiceDetails">
      <section class="bill-header" [ngClass]="billsToPayMode ? 'bills-to-pay-mode' : ''">
        <div class="logo-container">
          <div class="logo {{hexColorClass}}">
            <span>{{(currentSupplier.name ? currentSupplier.name : invoice.supplierName) | shortLogo}}</span>
          </div>
        </div>
        <div class="desc">
          <div class="supplier" [ngClass]="billsToPayMode ? 'supplier-wider' : ''">
            {{currentSupplier.name ? currentSupplier.name : invoice.supplierName ? invoice.supplierName : 'Supplier' }}
          </div>
          <div class="bill-ref">{{invoice.invoiceNumber}}</div>
          <div *ngIf="(billsToPayMode && invoice.hasAttachments) || isInboxMobileMode" class="view-invoice" (click)="viewInvoice()">View Invoice</div>
        </div>
        <div class="arrows" *ngIf="!billsToPayMode">
          <div class="control">
            <button class="icon icon-left" [disabled]="isPrevioustDisabled" [ngClass]="isPrevioustDisabled ? 'disabled' : 'enabled'"
                    matTooltip="Previous invoice" [matTooltipShowDelay]="showToolTipDelay" (click)="clickPreviousArrow()">
              <img src="../../../../../assets/img/invoiceForm/arrow-left.svg" alt="">
            </button>
          </div>
          <div class="control">
            <button class="icon icon-right" [disabled]="isNextDisabled" [ngClass]="isNextDisabled ? 'disabled' : 'enabled'"
                    matTooltip="Next invoice" [matTooltipShowDelay]="showToolTipDelay" (click)="clickNextArrow()">
              <img src="../../../../../assets/img/invoiceForm/arrow-right.svg" alt="">
            </button>
          </div>
        </div>

        <div class="dots-menu" *ngIf="!billsToPayMode">
          <a mat-button [matMenuTriggerFor]="menu" class="three-dots" matTooltip="Menu" [matTooltipShowDelay]="showToolTipDelay">
            <img src="../../../../assets/img/invoiceForm/menu.svg" alt="">
          </a>
          <mat-menu #menu="matMenu" class="invoice-menu">
            <button mat-menu-item matTooltip="Archive this invoice" [matTooltipShowDelay]="showToolTipDelay"
                    (click)="archiveInvoice()" *ngIf="!invoice?.archived && isEditable">
              <img src="../../../../assets/img/invoiceForm/archive.svg" alt="">
              <span>Archive</span>
            </button>
            <button mat-menu-item matTooltip="Unarchive this invoice" [matTooltipShowDelay]="showToolTipDelay"
                    (click)="unarchiveInvoice()" *ngIf="invoice?.archived">
              <img src="../../../../assets/img/invoiceForm/unarchive.svg" alt="">
              <span>Unarchive</span>
            </button>
            <button mat-menu-item matTooltip="Delete this invoice" [matTooltipShowDelay]="showToolTipDelay"
                    (click)="showDeleteInvoiceConfirmation()" *ngIf="isEditable">
              <img src="../../../../assets/img/invoiceForm/delete.svg" alt="">
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
        <div class="close-icon" matTooltip="Hide Side Bar" [matTooltipShowDelay]="showToolTipDelay"
             (click)="closeFormOnBillsToPay()" *ngIf="(billsToPayMode && !returnBackToText)">
          <img src="../../../../../assets/img/close-icon.svg" alt="">
        </div>
      </section>

      <section class="tab-header">
        <div class="tab" [ngClass]="currentPannel == panels.DetailsTab ? 'active' : ''" (click)="tabClick(panels.DetailsTab)">
          <div class="caption">Details</div>
          <div class="underline"></div>
        </div>
        <div class="tab" [ngClass]="currentPannel == panels.ActivityTab ? 'active' : ''" (click)="tabClick(panels.ActivityTab)">
          <div class="caption">Activity</div>
          <div class="underline"></div>
        </div>
        <div class="tab" [ngClass]="currentPannel == panels.RelatedTab ? 'active' : ''" (click)="tabClick(panels.RelatedTab)">
          <div class="caption">Related</div>
          <div class="underline"></div>
        </div>
      </section>
    </div>

    <!-- Invoice Form -->
    <form id="main-form" [formGroup]="form" *ngIf="currentPannel == panels.DetailsTab" [ngStyle]="calculateDivHeight()">
      <div id="main-section">
        <!-- Invoice Fields -->
        <section class="main-section">
          <!-- Supplier -->
          <div class="form-group">
            <div class="field-label suplier-field">
              Supplier <i class="fas fa-exclamation-triangle show-warning-icon" *ngIf="isAliasWarningShown" (mouseenter)="showSupplierTooltip = true"></i>
              <div *ngIf="showSupplierTooltip" [ngClass]="{'gray-add-box': showSupplierTooltip}">
                <div class="warning account-warning">
                  <div class="warning-text">
                    Do you want to <span class="link" (click)="addAlias()">add</span>
                    this alias for {{currentSupplier.name}}?
                  </div>
                  <span class="close" (click)="toggleAliasWarning()"><i class="fas fa-times"></i></span>
                </div>
              </div>
              <span class="new" *ngIf="isNewSupplier" (click)="addNewSupplier(newSupplier)" [disabled]="isNewSupplierLoaded">
                <span>+New</span>
              </span>

            </div>
            <div class="field-input">
              <div class="field-input custome_group_wrapper" [ngClass]="isEditable ? 'select-actions' : ''">
                <span class="verified" title="Matched to Xero" *ngIf="!isNewSupplier && isNewSupplierLoaded">&#xf058;</span>
                <ng-select [(ngModel)]="invoice.supplierName" [items]="suppliers" formControlName="supplier"
                           [searchable]="true" [clearable]="false" bindLabel="name" (change)="onSelectSupplier($event)"
                           (keyup)="handleSupplierName($event.target.value)" [readonly]="!isEditable"
                           [placeholder]="!isEditable ? '': 'Select Supplier'" [minlength]="isEditable ? 0 : 1"
                           class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                           (blur)="handleSupplierBlur()" [closeOnSelect]="true" id="ddlsupplier">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="tax-item" [innerHTML]="item.name">
                    </div>
                  </ng-template>
                  <ng-template ng-footer-tmp *ngIf="isCreateSupplierItemShown || (isNewSupplier && isCreateSupplierItemShown)">
                    <div class="create-new-supplier" (click)="createNewSupplier()">
                      <span class="spinner-wrapper" *ngIf="creatingNewSupplier" style="margin-right: 5px;">
                        <mat-spinner [diameter]="18"></mat-spinner>
                      </span>
                      <span *ngIf="!creatingNewSupplier">+ Create '{{newSupplier.name}}'</span>
                      <span *ngIf="creatingNewSupplier" style="padding-top: 2px;">Adding '{{newSupplier.name}}'</span>
                    </div>
                  </ng-template>
                </ng-select>
                <span class="error" [ngClass]="form.controls['supplier'] ? 'error-underline' : ''" *ngIf="form && form.controls['supplier'].invalid && showPublishValidation && isEditable">Please select a supplier</span>
              </div> 
            </div>
           
          </div>

          <!-- Type -->
          <div class="form-group">
            <div class="field-label">Type</div>
            <div class="field-input">
              <div class="field-input custome_group_wrapper" [ngClass]="isEditable ? 'select-actions' : ''">
                <ng-select [(ngModel)]="invoiceSelectedType" [items]="types" formControlName="type"
                           [searchable]="true" [clearable]="false" bindLabel="text" (change)="onSelectType($event.key)"
                           [readonly]="!isEditable" *ngIf="isEditable"
                           [placeholder]="!isEditable ? '': 'Add Type'" [minlength]="isEditable ? 0 : 1"
                           class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                           [closeOnSelect]="true" id="ddltype">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="tax-item">
                      {{item.text}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <input [(ngModel)]="invoiceSelectedType" class="frm-input" [ngModelOptions]="{standalone: true}"
                     [placeholder]="!isEditable ? '': 'Add Details (Optional)'" type="text" [readonly]="!isEditable"
                     *ngIf="!isEditable">
              <span class="error" [ngClass]="form.controls['account'] ? 'error-underline' : ''" *ngIf="form.controls['type'].invalid && showPublishValidation && isEditable">Please select a type</span>
            </div>
          </div>
          <!-- Ref -->
          <div class="form-group">
            <div class="field-label">
              Ref <i class="fas fa-exclamation-triangle show-warning-icon" *ngIf="isDuplicateWarningShown" (mouseenter)="showRefTooltip = true"></i>
              <div *ngIf="showRefTooltip" [ngClass]="{'gray-add-box': showRefTooltip}">
                <div class="warning account-warning">
                  <div class="warning-text">
                    A document with this reference already exists. Do you want to
                    <span class="link" (click)="showDeleteInvoiceConfirmation()" matTooltip="Click to delete this invoice">delete</span>
                    this duplicate?

                  </div>
                  <span class="close" (click)="toggleDuplicateWarning()"><i class="fas fa-times" matTooltip="Close"></i></span>
                </div>
              </div>
            </div>
            <div class="field-input">
              <input formControlName="invoiceNumber" id="refField" [(ngModel)]="invoice.invoiceNumber" class="frm-input"
                     [maxlength]="IsQuickBook ? 21 : 250"
                     type="text" [placeholder]="!isEditable ? '': 'No Ref'" [readonly]="!isEditable"
                     [ngClass]="isEditable ? 'frm-input-active' : ''" (focusout)="saveOnLostFocus('Ref')" (change)="fieldWasChanged=true">
              <span class="error" [ngClass]="form.controls['invoiceNumber'] ? 'error-underline' : ''" *ngIf="form.controls['invoiceNumber'].invalid && showPublishValidation && isEditable">Please enter reference</span>
            </div>

          </div>
          <!-- Date -->
          <div class="form-group">
            <div class="field-label">Date</div>
            <div class="field-input">
              <mat-form-field *ngIf="isEditable" appearance="none" class="new-frm-input">
                <input type="text" matInput [matDatepicker]="datepicker" [readonly]="!isEditable"
                       [(ngModel)]="invoice.date" formControlName="date" 
                       (ngModelChange)="onDateChange()" [ngClass]="isEditable ? 'frm-input-active ' : ''"
                       [placeholder]="!isEditable ? '': 'Add Date'"
                       (click)="datepicker.open()">
                <button class="cal-clear-btn" mat-button *ngIf="invoice.date" matSuffix mat-icon-button aria-label="Clear" (click)="invoice.date=''">
                  <mat-icon>x</mat-icon>
                </button>
                <mat-datepicker #datepicker></mat-datepicker>
              </mat-form-field>
              <input type="text" formControlName="date" [ngModel]="invoice.date | formatedDate: '' : 'yyyy'"
                     [readonly]="!isEditable" *ngIf="!isEditable"
                     class="frm-input" name="date" [ngClass]="isEditable ? 'frm-input-active' : ''">
              <span class="error" [ngClass]="form.controls['account'] ? 'error-underline' : ''" *ngIf="form.controls['date'].invalid && showPublishValidation">Please select a date</span>
            </div>
          </div>

          <!-- Due Date -->
          <div class="form-group" [hidden]="(isReceipt || isExpClaim || (invoice.type == 'Credit Note' || invoice.type == 'ACCPAYCREDIT'||invoice.type == 'EXP'||invoice.type == 'Receipt'))">
            <div class="field-label">Due Date </div>
            <div class="field-input">
              <mat-form-field *ngIf="isEditable" appearance="none" class="new-frm-input">
                <input matInput [matDatepicker]="duedatepicker" [readonly]="!isEditable"
                       [(ngModel)]="invoice.dueDate" formControlName="dueDate" (click)="duedatepicker.open()"
                       (ngModelChange)="onDueDateChange()" [ngClass]="isEditable ? 'frm-input-active' : ''"
                       [placeholder]="!isEditable ? '': 'Add Due Date'">
                <button class="cal-clear-btn" mat-button *ngIf="invoice.dueDate" matSuffix mat-icon-button aria-label="Clear" (click)="invoice.dueDate=''">
                  <mat-icon>x</mat-icon>
                </button>
                <mat-datepicker #duedatepicker></mat-datepicker>
              </mat-form-field>
              <input type="text" [ngModel]="invoice.dueDate | formatedDate: '' : 'yyyy'" formControlName="dueDate"
                     [readonly]="!isEditable" *ngIf="!isEditable"
                     class="frm-input" [ngModelOptions]="{standalone: true}" 
                     [ngClass]="isEditable ? 'frm-input-active' : ''">
              <span class="error" [ngClass]="form.controls['account'] ? 'error-underline' : ''" *ngIf="form.controls['dueDate'].invalid && showPublishValidation">Please select a due date</span>
            </div>
          </div>

          <!-- Currency -->
          <div class="form-group row field currency">
            <div class="field-label">Currency</div>
            <div class="field-input custome_group_wrapper">
              <ng-select [(ngModel)]="invoice.currency" [items]="currencies"
                         formControlName="currency"
                         [searchable]="true" [clearable]="false" (change)="saveInvoiceWithDelay('Currency')"
                         [readonly]="!isEditable"
                         [placeholder]="!isEditable ? '': 'Select Payment Currency'"
                         [minlength]="isEditable ? 0 : 1"
                         class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                         [ngClass]="isEditable ? 'frm-select-active' : ''"
                         [closeOnSelect]="true" id="currency">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="tax-item">
                    {{item}}
                  </div>
                </ng-template>
              </ng-select>
              <span class="error" [ngClass]="form.controls['account'] ? 'error-underline' : ''" *ngIf="form.controls['currency'].invalid && showPublishValidation">Please select a currency</span>
            </div>
          </div>

          <!-- Account -->
          <div class="form-group" *ngIf="invoice.invoiceLines?.length < 2 && !billsToPayMode">
            <div class="field-label">
              <div class="show-gray-add-box">
                Account
                <i class="fas fa-exclamation-triangle show-warning-icon" *ngIf="isAccountWarningShown && account.name" (mouseenter)="showAccountTooltip = true"></i>
                <div *ngIf="showAccountTooltip" [ngClass]="{'gray-add-box': showAccountTooltip}">
                  <div class="warning account-warning">
                    <span class="spinner-wrapper" *ngIf="addDefaultAccountLoading"><mat-spinner [diameter]="18"></mat-spinner></span>
                    <div class="warning-text">
                      Do you want to <span class="link" (click)="addDefaultAccount(false)">add</span>
                      this as the default account for {{invoice.supplierName}}?
                    </div>
                    <span class="close" (click)="toggleAccountWarning()"><i class="fas fa-times"></i></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="field-input">
              <div class="field-input custome_group_wrapper" *ngIf="accounts" [ngClass]="isEditable ? 'select-actions' : ''">
                <ng-select [ngModel]="account ?account.name : ''" [items]="accounts" bindLabel="name" [searchable]="true"
                           [clearable]="true" (change)="onSelectAccount($event)" [readonly]="!isEditable"
                           [placeholder]="!isEditable ? '': 'Select Account'" [minlength]="isEditable ? 0 : 1"
                           class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                           [ngClass]="isEditable ? 'frm-select-active' : ''"
                           style="padding-bottom: 0px;" formControlName="account">
                </ng-select>
                <span class="error" [ngClass]="form.controls['account'] ? 'error-underline' : ''" *ngIf="form.controls['account'].invalid && showPublishValidation">Please select an account</span>
              </div>
            </div>
          </div>

          <!-- Details -->
          <div class="form-group" *ngIf="(isEditable && !billsToPayMode && invoice.invoiceLines?.length < 2) || (!isEditable && !billsToPayMode && invoice.invoiceLines?.length < 2 && invoice.description)">
            <div class="field-label">Details</div>
            <div class="field-input">
              <textarea matInput [(ngModel)]="invoice.description" class="frm-input" [ngModelOptions]="{standalone: true}" 
                     [placeholder]="!isEditable ? '': 'Add Details (Optional)'" type="text" [readonly]="!isEditable" matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="10"
                     [ngClass]="isEditable ? 'frm-input-active' : ''" (focusout)="saveOnLostFocus('Details')" (change)="fieldWasChanged=true"></textarea>
            </div>
          </div>

          <!-- Tracking category -->
          <div class="form-group" *ngIf="(isEditable && !billsToPayMode && trackingCategories.length >
            0 && invoice.invoiceLines?.length <= 1)
            || (!isEditable && !billsToPayMode && invoice.invoiceLines[0]?.trackingOptions?.length > 0)">
            <div class="field-label">{{trackingCategories[0].name}}</div>
            <div class="field-input">
              <div class="field-input custome_group_wrapper" [ngClass]="isEditable ? 'select-actions' : ''">
                <ng-select [(ngModel)]="selectedTrackingCategories[0]" [items]="trackingCategories[0]?.options"
                           [searchable]="true" [clearable]="false" bindLabel="name" (change)="onSelectCategory($event)"
                           [readonly]="!isEditable"
                           [placeholder]="!isEditable ? '': 'Select '+ trackingCategories[0].name + ' (Optional)'"
                           [minlength]="isEditable ? 0 : 1"
                           class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                           [ngClass]="isEditable ? 'frm-select-active' : ''"
                           [closeOnSelect]="true" id="ddlTrackingCat1">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="tax-item">
                      {{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-group"
               *ngIf="(isEditable && trackingCategories.length >
            1 && invoice.invoiceLines?.length <= 1)
            || (!isEditable && invoice.invoiceLines[0]?.trackingOptions?.length > 1)">
            <div class="field-label">{{trackingCategories[1].name}}</div>
            <div class="field-input">
              <div class="field-input custome_group_wrapper" [ngClass]="isEditable ? 'select-actions' : ''">
                <ng-select [(ngModel)]="selectedTrackingCategories[1]" [items]="trackingCategories[1]?.options"
                           [searchable]="true" [clearable]="false" bindLabel="name" (change)="onSelectCategory($event)"
                           [readonly]="!isEditable"
                           [placeholder]="!isEditable ? '': 'Select '+ trackingCategories[1].name + ' (Optional)'"
                           [minlength]="isEditable ? 0 : 1"
                           class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                           [ngClass]="isEditable ? 'frm-select-active' : ''"
                           [closeOnSelect]="true" id="ddlTrackingCat2">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="tax-item">
                      {{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>

          <!-- Totals (IF: invoice.invoiceLines.length <= 1) -->
          <div class="form-group" [ngClass]="isEditable ? 'add-lines-hover' : ''" *ngIf="invoice.invoiceLines?.length <= 1 || billsToPayMode">
            <div class="field-label">
              Totals
              <span class="add-lines" matTooltip="Add new tax lines" (click)="addLinesShow()"></span>
            </div>

            <div class="totals-details" [ngClass]="billsToPayMode && (invoice.amountCredited && !invoice.amountPaid || !invoice.amountCredited && invoice.amountPaid) ? 'totals-details-bills-to-pay' :
            billsToPayMode && invoice.amountCredited && invoice.amountPaid ? 'totals-details-five-rows' : ''">
              <!-- Net Amount -->
              <div class="sub-title">Net Amount</div>
              <input [ngModel]="invoice.subTotal | number: '1.0-2'" [ngModelOptions]="{standalone: true}"
                     (ngModelChange)="invoice.subTotal=converter.stringToFloat($event,'|')" class="frm-input" readonly
                     [ngClass]="isEditable ? 'bg-gray' : ''">
              <div><!-- Don't delete this div --></div>

              <!-- Tax -->
              <div class="sub-title">Tax</div>
              <input [ngModel]="invoice.totalTax | number: '1.0-2'" [ngModelOptions]="{standalone: true}"
                     (ngModelChange)="invoice.totalTax=converter.stringToFloat($event,'|')" class="frm-input bottom-border" readonly
                     [ngClass]="billsToPayMode ? 'show-tax' : 'hide-tax'">

              <!-- Tax Rate -->
              <div class="sub-title custome_group_wrapper tax tax-rate" [ngClass]="isEditable ? 'select-actions show-tax-select' : billsToPayMode ? 'hide-tax-select' : 'select-actions show-tax-select'">
                <ng-select [placeholder]="isEditable ? 'Select Tax Rate' : ''"
                           [searchable]="false"
                           [items]="taxRates"
                           [clearable]="false"
                           [ngModel]="selectedTaxRateId"
                           bindLabel="name"
                           bindValue="id"
                           (ngModelChange)="updateTaxRate($event)"
                           formControlName="selectedTaxRate"
                           (change)="saveInvoiceWithDelay('Tax Rate')"
                           [readonly]="!isEditable"
                           style="width: 95%;"
                           class="frm-ng-select">
                  <!-- Value Template -->
                  <ng-template ng-label-tmp let-item="item">
                    <div class="tax-item" style="padding-top:6px">
                      <span class="tax-value">{{(invoice.amountDue - (100 * invoice.amountDue) / (100 + item.rate)) | number : '1.0-2'}}</span>
                      <span class="tax-name">{{item.name}}</span>
                    </div>
                  </ng-template>

                  <!-- Option Template -->
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="tax-item">
                      <span class="tax-value">{{(invoice.amountDue - (100 * invoice.amountDue) / (100 + item.rate)) | number : '1.0-2'}}</span>
                      <span class="tax-name">{{item.name}}</span>
                    </div>
                  </ng-template>
                </ng-select>
                <div class="error" *ngIf="form.controls['selectedTaxRate'].invalid && showPublishValidation">
                  Please enter a Tax Rate
                </div>
              </div>

              <!-- Credits (for Bills to Pay page only) -->
              <div class="sub-title" *ngIf="billsToPayMode && invoice.amountCredited">Credits</div>
              <div class="frm-input bottom-border" *ngIf="billsToPayMode && invoice.amountCredited"
                   style="width: 100%; text-align: center; display: block !important">
                ({{invoice.amountCredited | number: '1.0-2'}})
              </div>
              <div *ngIf="billsToPayMode && invoice.amountCredited"><!-- Don't delete this div --></div>

              <!-- AmountPaid (for Bills to Pay page only) -->
              <div class="sub-title" *ngIf="billsToPayMode && invoice.amountPaid">Payments</div>
              <div class="frm-input bottom-border" *ngIf="billsToPayMode && invoice.amountPaid"
                   style="width: 100%; text-align: center; display: block !important">
                ({{invoice.amountPaid | number: '1.0-2'}})
              </div>
              <div *ngIf="billsToPayMode && invoice.amountPaid"><!-- Don't delete this div --></div>

              <!-- Total -->
              <div class="sub-title">Total</div>
              <input type="number" min="0" [ngModel]="invoice.amountDue | positiveNumber: '1.0-2'" class="frm-input" formControlName="amountDue"
                     [ngModelOptions]="{standalone: true}" (ngModelChange)="updateCalculatedTotals($event)" [readonly]="!isEditable"
                     [ngClass]="isEditable ? 'frm-input-active' : ''" (focusout)="saveOnLostFocus('Total Amount')" (change)="fieldWasChanged=true"
                     [style.border-top]="billsToPayMode ? '1px solid #8F8F8F': 'none'"
                     [style.padding-top]="billsToPayMode ? '7px': '0'" (keyup)="decimalFilter($event)">
            </div>

            <span class="error" *ngIf="form.controls['amountDue'].invalid && showPublishValidation">Please enter a total value</span>
            <div class="warning account-warning" *ngIf="isEditable && isDifferentTaxAmount">
              <i class="fas fa-exclamation-triangle"></i>
              <div class="warning-text">
                The tax amount on the invoice is different to the calculated rate. Do you want to
                <span class="link" (click)="addLinesShow()">add line items</span>?
              </div>
              <span class="close" (click)="toggleTaxAmountWarning()"><i class="fas fa-times"></i></span>
            </div>
          </div>

          <!-- Totals (IF: invoice.invoiceLines.length > 1) -->
          <div *ngIf="invoice.invoiceLines?.length > 1 && !billsToPayMode" class="splited-totals" [ngClass]="isEditable ? 'add-lines-hover' : ''">
            <div class="field-label">
              Totals
              <span class="add-lines" matTooltip="Add or edit new tax lines" (click)="addLinesShow()"></span>
            </div>
            <div class="header-section">
              <div class="caption account">
                <span>Account</span>
              </div>
              <div class="caption amount">
                <span>Amount</span>
              </div>
            </div>
            <app-totals-line-new *ngFor="let line of invoice.invoiceLines; let i = index" [invoiceLine]="line" [taxRates]=taxRates
                                 [accounts]=accounts [trackingCategories]=trackingCategories [index]="i"></app-totals-line-new>
            <div class="total-summary">
              <div></div>
              <div class="caption top-padding">Tax</div>
              <div class="value top-line top-padding">{{invoice.totalTax | number : '1.2-2'}}</div>
              <div></div>
              <div class="caption">Total</div>
              <div class="value">{{invoice.amountDue | number : '1.2-2'}}</div>
            </div>
          </div>
        </section>
        <!-- Payment Fields -->
        <section class="payment">
          <div class="payment-title">Payment</div>
          <div *ngIf="isExpClaim">
            <!-- Paid By User -->
            <div class="form-group" *ngIf="isExpClaim">
              <div class="field-label">Paid By</div>
              <div class="field-input custome_group_wrapper" [ngClass]="isEditable ? 'select-actions' : ''" *ngIf="users">
                <ng-select [(ngModel)]="invoice.paidBy" [items]="users" bindLabel="name" bindValue="id" [searchable]="true" formControlName="paidBy"
                           [clearable]="true" [readonly]="!isEditable || (isEditable && userRole == 3)" (change)="onSelectPaidBy($event)"
                           [placeholder]="!isEditable ? '': 'Select User'" [minlength]="isEditable ? 0 : 1"
                           class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                           [ngClass]="isEditable ? 'frm-select-active' : ''"
                           style="padding-bottom: 0px;" name="expUser">
                </ng-select> 
                <span class="error" [ngClass]="form.controls['paidBy'] ? 'error-underline' : ''" *ngIf="form.controls['paidBy'].invalid && showPublishValidation">Please select Paid By</span>
              </div>
            </div>
            <div class="form-group" *ngIf="isExpClaim && invoice.paidBy!= null">
              <div class="field-label">Report Name</div>
              <div class="field-input">
                <div class="field-input custome_group_wrapper">
                  <!-- <span class="verified" title="Matched to Xero" *ngIf="!isNewSupplier && isNewSupplierLoaded">&#xf058;</span> -->
                  <ng-select [(ngModel)]="invoice.expenseReportId" [(items)]="expClaims" formControlName="expenseReportId"
                             [searchable]="true" [clearable]="true" bindLabel="reportName" bindValue="id" (change)="onSelectExpenseReport($event)"
                             (keyup)="handleExpenseReport($event.target.value)" [readonly]="!isEditable"
                             [placeholder]="!isEditable ? '': 'Add Report'" [minlength]="isEditable ? 0 : 1"
                             class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                             [ngClass]="isEditable ? 'frm-select-active' : ''"
                             (blur)="handleExpenseReportBlur()">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="tax-item">
                        {{item.reportName}}
                      </div>
                    </ng-template>

                    <ng-template ng-footer-tmp *ngIf="isCreateExpReportItemShown || (isNewExpReport && isCreateExpReportItemShown)">
                      <div class="create-new-supplier" (click)="createNewExpReport()">
                        <span class="spinner-wrapper" *ngIf="creatingNewExpReport" style="margin-right: 5px;">
                          <mat-spinner [diameter]="18"></mat-spinner>
                        </span>
                        <span *ngIf="!creatingNewExpReport">+ Create '{{newExpReport.reportName}}'</span>
                        <span *ngIf="creatingNewExpReport" style="padding-top: 2px;">Adding '{{newExpReport.reportName}}'</span>
                      </div>
                    </ng-template>
                  </ng-select> 
                  <span class="error" [ngClass]="form.controls['expenseReportId'] ? 'error-underline' : ''" *ngIf="form.controls['expenseReportId'].invalid && showPublishValidation">Please select report</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Status -->
          <div class="form-group" *ngIf="(!billsToPayMode && !isExpClaim && type != 'EXP')">
            <div class="field-label">Status</div>
            <div class="field-input custome_group_wrapper">
              <ng-select [(ngModel)]="invoice.paymentStatus" [items]="paymentStatusItem"
                         bindLabel="name" bindValue="value" formControlName="paymentStatus"
                         [searchable]="true" [clearable]="false" (change)="onChangePaymentStatus()"
                         [readonly]="!isEditable"
                         [placeholder]="'Payment Status'" [disabled]="!isEditable"
                         [minlength]="isEditable ? 0 : 1"
                         class="frm-ng-select" [ngModelOptions]="{standalone: true}"
                         [ngClass]="isEditable ? 'frm-select-active' : ''"
                         [closeOnSelect]="true" id="paymentStatus">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="tax-item">
                    {{item.name}}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="form-group" *ngIf="(invoice.paymentStatus == 1 || (isReceipt && invoice.type == 'Receipt')) && (invoice.type !== 'EXPCLAIMS' || type !== 'EXPCLAIMS')">
            <div class="field-label">Paid From</div> 
            <div class="field-input custome_group_wrapper">
              <ng-select [(ngModel)]="invoice.paymentAccountNumber" [items]="bankAccountList" [searchable]="true" [disabled]="!isEditable"
                         formControlName="paymentAccountNumber"
                         [clearable]="false" bindLabel="name" bindValue="accountID" [placeholder]="!isEditable ? '': 'Select Payment Account'"
                         class="frm-ng-select" style="padding-bottom: 0px;" (change)="onSelectPaymentAccount($event)" [ngModelOptions]="{standalone: true}">
              </ng-select>
              <ng-container  >
                <span class="error" [ngClass]="form.controls['paymentAccountNumber'] ? 'error-underline' : ''" *ngIf="form.controls['paymentAccountNumber'].invalid &&  showPublishValidation">Please select paid from</span>
              </ng-container>
            </div>
          </div>
          <div class="form-group" *ngIf="(!isReceipt && invoice.type !== 'Receipt') && invoice.paymentStatus == 1 && (invoice.type !== 'EXPCLAIMS' || type !== 'EXPCLAIMS')">
            <div class="field-label">Payment Date</div>
            <div class="field-input">
              <mat-form-field *ngIf="isEditable" appearance="none" class="new-frm-input">
                <input type="text" matInput [matDatepicker]="paymentDatepicker" [readonly]="!isEditable"
                       [(ngModel)]="invoice.paymentDate" formControlName="paymentDate" 
                       (ngModelChange)="savePaymentDateWhenFocusout($event)" [ngClass]="isEditable ? 'frm-input-active ' : ''"
                       [placeholder]="!isEditable ? '': 'Add Date'"
                       (click)="paymentDatepicker.open()">
                <button class="cal-clear-btn" mat-button *ngIf="invoice.paymentDate" matSuffix mat-icon-button aria-label="Clear" (click)="invoice.paymentDate=''">
                  <mat-icon>x</mat-icon>
                </button>
                <mat-datepicker #paymentDatepicker></mat-datepicker>
              </mat-form-field>
              <ng-container *ngIf="(invoice.type == 'ACCPAY' && (invoice.paymentStatus == 1 || invoice.paymentStatus == 2 )) && !isExpClaim">
                <span class="error" [ngClass]="form.controls['paymentDate'] ? 'error-underline' : ''" *ngIf="form.controls['paymentDate'].invalid  && showPublishValidation">Please select payment date</span>
              </ng-container>
              <input type="text" [ngModel]="invoice.paymentDate | formatedDate: '' : 'yyyy'"
                     [readonly]="!isEditable" *ngIf="!isEditable"
                     class="frm-input" [ngModelOptions]="{standalone: true}"
                     [ngClass]="isEditable ? 'frm-input-active' : ''">
            </div>
          </div>
          <div *ngIf="!isExpClaim">
            <!-- Details -->
            <div *ngIf="(invoice.paymentStatus != 1 && invoice.paymentStatus != 2 )">
              <div class="form-group" style="margin-top: 15px;" *ngIf="!isEditable && paymentViewStateValue == paymentViewStateValues.AddPaymentAccount">
                <div class="field-label">Details</div>
                <div class="field-label" style="color: #8F8F8F">No bank details on file</div>
              </div>
              <div class="form-group" style="margin-top: 15px;" *ngIf="countryCode == 'AU' && isEditable && paymentViewStateValue == paymentViewStateValues.AddPaymentAccount && !showPaymentAccountFields">
                <div class="field-label">Details</div>
                <div class="field-label add-bank-account" matTooltip="Add supplier's BSB and Account Number" [matTooltipShowDelay]="showToolTipDelay"
                     (click)="addPaymentAccount()">Add Payment Account</div>
              </div>
            </div>
            <div class="form-group" *ngIf="countryCode == 'AU' && (invoice.paymentStatus != 1 && invoice.paymentStatus != 2 ) && (paymentViewStateValue != paymentViewStateValues.AddPaymentAccount || showPaymentAccountFields)">
              <div class="field-label">Details</div>
              <div class="details-row field-input">
                <div class="subtitle">BSB:</div>
                <input type="text" class="data" [(ngModel)]="bankAccount.BSBNumber" [ngModelOptions]="{standalone: true}"
                       [ngClass]="isEditable ? 'frm-input-active' : ''" style="width: 71%;" (change)="bankAccountChanged($event.target.value,countryCode)"
                       (ngModelChange)="updateBSB($event)" name="BSB" #BSB="ngModel" pattern="\d{3}-\d{3}" [readonly]="!isEditable">
              </div>
              <div *ngIf="BSB.invalid && (BSB.dirty || BSB.touched)" class="error font-12px">
                <div *ngIf="BSB.errors?.pattern">
                  BSB must be 6 digits long and have the format: XXX-XXX
                </div>
              </div>
              <div class="details-row field-input">
                <div class="subtitle">Account No:</div>
                <input type="text" class="data" [(ngModel)]="bankAccount.accountNumber" [ngModelOptions]="{standalone: true}"
                       [ngClass]="isEditable ? 'frm-input-active' : ''" style="width: 60%;" (change)="bankAccountChanged($event.target.value)"
                       name="accountNo" #accountNo="ngModel" pattern="^[0-9]*$" [readonly]="!isEditable">
              </div>
              <div *ngIf="accountNo.invalid && (accountNo.dirty || accountNo.touched)" class="error font-12px">
                <div *ngIf="accountNo.errors?.pattern">
                  Account Number must have only digits
                </div>
              </div>
            </div>
            <div class="form-group" style="margin-top: 15px;" *ngIf="countryCode == 'US' && isEditable && paymentViewStateValue == paymentViewStateValues.AddPaymentAccount && !showUSPaymentAccountFields && (invoice.paymentStatus != 1 && invoice.paymentStatus != 2 )">
              <div class="field-label">Details</div>
              <div class="field-label add-bank-account" matTooltip="Add supplier's Routing and Account Number" [matTooltipShowDelay]="showToolTipDelay"
                   (click)="addUSPaymentAccount()">
                Add Payment Account
              </div>
            </div>
            <div class="form-group" *ngIf="countryCode == 'US' && (invoice.paymentStatus != 1 && invoice.paymentStatus != 2 ) && (paymentViewStateValue != paymentViewStateValues.AddPaymentAccount || showUSPaymentAccountFields)">
              <div class="field-label">Details</div>
              <div class="details-row field-input">
                <div class="subtitle">Routing No.:</div>
                <input type="text" class="data" [(ngModel)]="bankAccount.routingNumber" [ngModelOptions]="{standalone: true}"
                       [ngClass]="isEditable ? 'frm-input-active' : ''" style="width: 71%;" (change)="bankAccountUSChanged($event.target.value)"
                       (ngModelChange)="updateRouting($event)" (keypress)="numberOnly($event)" name="Routing" #Routing="ngModel" pattern="\d{9}" maxLength="9" [readonly]="!isEditable">
              </div>
              <div *ngIf="Routing.invalid && (Routing.dirty || Routing.touched)" class="error font-12px">
                <div *ngIf="Routing.errors?.pattern">
                  Routing Number must be 9 digits long and have the format: XXXXXXXXX
                </div>
              </div>
              <div class="details-row field-input">
                <div class="subtitle">Account No:</div>
                <input type="text" class="data" [(ngModel)]="bankAccount.accountNumberUS" [ngModelOptions]="{standalone: true}"
                       [ngClass]="isEditable ? 'frm-input-active' : ''" style="width: 60%;" (change)="bankAccountUSChanged($event.target.value)"
                       name="accountNoUS" #accountNoUS="ngModel" pattern="^[0-9]*$" [readonly]="!isEditable">
              </div>
              <div *ngIf="accountNoUS.invalid && (accountNoUS.dirty || accountNoUS.touched)" class="error font-12px">
                <div *ngIf="accountNoUS.errors?.pattern">
                  Account Number must have only digits
                </div>
              </div>
            </div>
          </div>
        </section>
        <carousel itemsPerSlide="1" [interval]="false">
          <slide *ngIf="countryCode == 'US' && isEditable && paymentViewStateValue != paymentViewStateValues.HideWarning &&
              paymentViewStateValue != paymentViewStateValues.AddPaymentAccount && warningIsReadyToBeShown">
            <div class="slide">
              <div class="content">
                <div class="bsb-logic">
                  <div class="prompt">
                    <div class="icon">
                      <img src="../../../../assets/img/invoiceForm/warning.svg" alt="" *ngIf="paymentViewStateValue != paymentViewStateValues.SameValues">
                      <img src="../../../../assets/img/invoiceForm/check.svg" alt="" *ngIf="paymentViewStateValue == paymentViewStateValues.SameValues">
                    </div>
                    <div class="content" *ngIf="paymentViewStateValue == paymentViewStateValues.SameValues">
                      <span>Bank account details on the invoice have been checked and are the same as what is on file</span>
                    </div>
                    <div class="content" *ngIf="paymentViewStateValue == paymentViewStateValues.DifferentValues">
                      <div class="text">The bank account details on the invoice are different to what is on file</div>
                      <div class="text">
                        Routing No: {{
invoice.routingNumber
                        }}
                      </div>
                      <div class="text">Account No: {{invoice.accountNumberUS}}</div>
                      <div class="control-link" (click)="updateBankDetails(countryCode)" matTooltip="Accept recognized Routing No and Account Number from the invoice" [matTooltipShowDelay]="showToolTipDelay">Update Bank Details</div>
                    </div>
                    <div class="content" *ngIf="paymentViewStateValue == paymentViewStateValues.SaveNewValues">
                      <div class="text">Bank account details have been extracted on the invoice. Do you want to save them for {{currentSupplier?.name ? currentSupplier.name : invoice?.supplierName}}?</div>
                      <div class="control-link" (click)="saveBankDetails(countryCode)" matTooltip="Save recognized Routing No and Account Number for the current Supplier" [matTooltipShowDelay]="showToolTipDelay">Save Bank Details</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <slide *ngIf="countryCode == 'AU' && isEditable && paymentViewStateValue != paymentViewStateValues.HideWarning &&
              paymentViewStateValue != paymentViewStateValues.AddPaymentAccount && warningIsReadyToBeShown">
            <div class="slide">
              <div class="content">
                <div class="bsb-logic">
                  <div class="prompt">
                    <div class="icon">
                      <img src="../../../../assets/img/invoiceForm/warning.svg" alt="" *ngIf="paymentViewStateValue != paymentViewStateValues.SameValues">
                      <img src="../../../../assets/img/invoiceForm/check.svg" alt="" *ngIf="paymentViewStateValue == paymentViewStateValues.SameValues">
                    </div>
                    <div class="content" *ngIf="paymentViewStateValue == paymentViewStateValues.SameValues">
                      <span>Bank account details on the invoice have been checked and are the same as what is on file</span>
                    </div>
                    <div class="content" *ngIf="paymentViewStateValue == paymentViewStateValues.DifferentValues">
                      <div class="text">The bank account details on the invoice are different to what is on file</div>
                      <div class="text">BSB: {{invoice.bsbNumber}}</div>
                      <div class="text">Account No: {{invoice.accountNumber}}</div>
                      <div class="control-link" (click)="updateBankDetails(countryCode)" matTooltip="Accept recognized BSB and Account Number from the invoice" [matTooltipShowDelay]="showToolTipDelay">Update Bank Details</div>
                    </div>
                    <div class="content" *ngIf="paymentViewStateValue == paymentViewStateValues.SaveNewValues">
                      <div class="text">Bank account details have been extracted on the invoice. Do you want to save them for {{currentSupplier?.name ? currentSupplier.name : invoice?.supplierName}}?</div>
                      <div class="control-link" (click)="saveBankDetails(countryCode)" matTooltip="Save recognized BSB and Account Number for the current Supplier" [matTooltipShowDelay]="showToolTipDelay">Save Bank Details</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </form>

    <!-- Split Lines Functionality -->
    <app-add-lines *ngIf="currentPannel == panels.SplitLinesPanel" (moveBack)="addLinesShow()"
                   [accounts]="accounts"
                   [invoiceLines]="invoice.invoiceLines"
                   [taxRates]="taxRates"
                   [selectedTaxRateId]="organizationTaxRateId"
                   [trackingCategories]="trackingCategories"
                   [supplierName]="currentSupplier.name ? currentSupplier.name : invoice.supplierName"
                   [invoiceAmountDue]="invoice.amountDue"
                   (updateLines)="updateInvoiceLines($event)">
    </app-add-lines>

    <!-- Activity Panel -->
    <app-activity *ngIf="currentPannel == panels.ActivityTab"
                  (moveBack)="showActivityPanel()"
                  [invoiceColorNumber]="invoiceColorNumber"
                  [invoiceId]="invoice.id == '00000000-0000-0000-0000-000000000000' ? invoice.referenceId : invoice.id"
                  [supplierName]="currentSupplier.name ? currentSupplier.name : invoice.supplierName">
    </app-activity>

    <!-- Related -->
    <app-related *ngIf="currentPannel == panels.RelatedTab"
                 [invoice]="invoice"
                 [supplierId]="invoice?.supplierId"
                 (showContactForm)="toggleCreateSupplierForm($event)"
                 (clickInvoiceEvent)="closeContactForm($event)"
                 [invoiceColorNumber]="invoiceColorNumber"
                 [hexColorClass]="hexColorClass"
                 [billsToPayMode]="billsToPayMode">
    </app-related>

    <!-- Contact From -->
    <app-supplier-contancts *ngIf="currentPannel == panels.ContactForm"
                            [supplier]="newSupplier"
                            [account]="bankAccount"
                            [accounts]="accounts"
                            [billsToPayMode]="billsToPayMode"
                            [isEditable]="true"
                            [invoiceColorNumber]="invoiceColorNumber"
                            (newSupplier)="addNewSupplier($event)"
                            (isClosed)="toggleCreateSupplierForm($event)"
                            (closeFormEvent)="closeContactForm($event)">
    </app-supplier-contancts>

     <!-- Invoice Document Preview -->
     <div class="preview-invoice">
      <app-invoice-preivew *ngIf="isPreviewVisible" 
            [isHorizontalDirection]=isHorizontalDirection
            [invoice]="invoice"
            (isClosed)="toggleCreateSupplierForm($event)"
            (closeFormEvent)="closeInvoicePreviewForm($event)">
      </app-invoice-preivew>
    </div>
  </div>
</div>
<ng-container *ngIf="isPageLoaded">
  <div id="floating-div" class="floating-button-div" *ngIf="(!billsToPayMode || approvalMode) && (currentPannel == panels.DetailsTab) ">
    <div class="match-mobile-view" *ngIf="showMatchDialog">
      <ng-container *ngIf="invoice?.unreconciledData?.length >0">
        <div class="close-icon match-close" matTooltip="Close Match" [matTooltipShowDelay]="showToolTipDelay"
            (click)="closeReportMatch()">
          <img src="../../../../../assets/img/close-icon.svg" alt="">
        </div>
        <div class="match-title ">Possible Match Found</div>
        <div style="max-height: 150px; overflow-y: scroll;">
        <ng-container *ngFor="let item of invoice.unreconciledData">
              <div class="bg-grey">
                <div class="col-sm-12">
                  <div class="col-sm-9 match-label">
                    {{item.description}}
                  </div>
                  <div class="col-sm-3 match-red-label" *ngIf="item.amount > 0">
                    +{{item.amount | number : '1.2-2' }}
                  </div>
                  <div class="col-sm-3 match-red-label" *ngIf="item.amount < 0">
                    ({{ item.amount * -1 | number : '1.2-2' }})
                  </div>
                  <div class="col-sm-3 match-red-label" *ngIf="item.amount == 0">
                    -
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="col-sm-3  match-italic-label">
                    {{item.transactionDate | formatedDate }}
                  </div>
                </div>
                <div class="match-footer">
                  <div class="col-sm-12">
                    <div class="col-sm-9 match-label">
                      Match Transaction
                    </div>
                    <div class="col-sm-3 match-red-label" style="margin-top:-10px !important; margin-right: 15px;">
                      <label class="switch">
                        <input type="checkbox" [checked]="item.selected" (change)="changeSelectedMatch(item.selected, item.unreconciledReportId)">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
        </ng-container>
      </div>
      </ng-container>
    </div>
    <!-- Buttons -->
    <div class="buttons">
      <button id="saveButton" type="button" class="btn-save" style="position:relative" (click)="save(fieldName)" *ngIf="isEditable"></button>
      <div [matTooltip]="!invoice.required_Fields ? 'Complete all required fields above' : ''">
      <button type="button" class="btn-publish" style="position:relative" (click)="submit()" [matTooltipShowDelay]="showToolTipDelay"
              [disabled]="loading || (!invoice.required_Fields)" 
              [matTooltip]="IsQuickBook ? 'Publish to QuickBooks' : 'Publish to Xero'"
              *ngIf="isEditable || isMatchPublish"
              [ngClass]="loading || (!invoice.required_Fields) ? 'disabled' : ''">
        <span class="btn-text" *ngIf="!loading && !isMatchPublish && isEditable">Publish</span>
        <span class="btn-text" *ngIf="!loading && isMatchPublish && isEditable">Match & Publish</span>
        <span class="btn-text" *ngIf="!loading && isMatchPublish && !isEditable">Match</span>
        <span class="btn-text" *ngIf="loading && !isMatchPublish && isEditable">Publishing...</span>
        <span class="btn-text" *ngIf="loading && isMatchPublish">Matching...</span>
        <span class="spinner-wrapper" *ngIf="loading"><mat-spinner [diameter]="18"></mat-spinner></span>
      </button></div>
      <button *ngIf="approvalMode && isApproveButtonShown" class="btn-publish approval-button" [disabled]="isApproving" style="position:relative"
              matTooltip="Approve the invoice" [matTooltipShowDelay]="showToolTipDelay" (click)="approveCurrentInvoice()">
        <span *ngIf="!isApproving" class="btn-text">Approve</span>
        <span *ngIf="isApproving" class="btn-text">Approving</span>
        <span class="spinner-wrapper" *ngIf="isApproving"><mat-spinner [diameter]="18"></mat-spinner></span>
      </button>
    </div> 
  </div>
</ng-container>
