import { Injectable } from '@angular/core';
import { Observable, interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ResponseModel } from '../model/response-model';
import { RecoverModel } from '../model/recover-model';
import { SignUpModel } from '../model/sign-up-model';
import { HttpInternalService } from './http.service';
import { SignUpFinalModel } from 'src/app/core/model/signup-model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private _httpService: HttpInternalService) { }

  searchUserByEmail(url: string, recoverModel: RecoverModel) {
    return timer(1000)
    .pipe(
      switchMap(() => {
        return this._httpService.postRequest<ResponseModel>(url, JSON.stringify(recoverModel));
      })
    );        
  }

  signUp(url: string, signUpModel: SignUpModel) {
    return this._httpService.postRequest<ResponseModel>(url, JSON.stringify(signUpModel));
  }

  signUpNew(url: string, signUpModel: any) {
    return this._httpService.postRequest<ResponseModel>(url, JSON.stringify(signUpModel));
  }

  pollIsConfirmedFlagValue(intervalTime: number, apiURL: string): Observable<any> {
    return interval(intervalTime).pipe(
      switchMap(() => this._httpService.getRequest<{ flag: boolean }>(apiURL))
    );
  }

  checkIsConfirmedAccount(apiURL: string) {
    return this._httpService.getRequest<{ flag: boolean }>(apiURL)
  }

  resendConfimationMail(apiURL: string) {
    return this._httpService.getRequest<ResponseModel>(apiURL);
  }

  confimMail(apiURL: string) {
    return this._httpService.getRequest<ResponseModel>(apiURL);
  }
}
