<div class="related-container">
  <div class="custom-mat-spinner" *ngIf="isSpinnerShown"> 
    <mat-spinner></mat-spinner>
    <div class="text">Data loading...</div>
  </div>

  <div  *ngIf="!isSpinnerShown">
    <!-- Company -->
    <section class="block">
      <div class="block-title">
        <span (click)="isCompanyExpanded = !isCompanyExpanded">Company</span>
        <span  class="shevron" (click)="isCompanyExpanded = !isCompanyExpanded">
          <img [ngClass]="isCompanyExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
      </div>
      <div class="block-content" *ngIf="!supplierId" [ngClass]="isCompanyExpanded ? 'block-expanded padding-top' : ''">
        <span class="no-company">No company selected</span>
      </div>
      <div class="block-content" *ngIf="relatedData?.company?.name" [ngClass]="isCompanyExpanded ? 'block-expanded' : ''">
        <div class="type-1 hover-gray" style="cursor: pointer;" *ngIf="isCompanyExpanded" (click)="showContactPanel()"
          matTooltip="Click to see supplier details" [matTooltipShowDelay]="showToolTipDelay">
          <div class="logo-container">
            <div class="logo {{hexColorClass}}">
              <span>{{relatedData?.company?.name | shortLogo}}</span>
            </div>
          </div>
          <div class="desc">
            <div class="title">{{relatedData?.company?.name}}</div>
            <div class="subtitle" *ngIf="relatedData?.company?.firstName || relatedData?.company?.lastName || relatedData?.company?.email">
              <span *ngIf="relatedData?.company?.firstName || relatedData?.company?.lastName">{{relatedData.company.firstName}}&nbsp;{{relatedData.company.lastName}}</span>
              <span *ngIf="relatedData?.company?.email">&nbsp;-&nbsp;{{relatedData.company.email}}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Approvals -->
    <section class="block" *ngIf="supplierId">
      <div class="block-title">
        <span (click)="isApprovalsExpanded = !isApprovalsExpanded">Approvals</span>
        <span class="shevron" (click)="isApprovalsExpanded = !isApprovalsExpanded">
          <img [ngClass]="isApprovalsExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
        <span class="plus" *ngIf="!relatedData?.approvals?.approved
          && !relatedData?.approvals?.sentForApproval" matTooltip="Click to approve the invoice" [matTooltipShowDelay]="showToolTipDelay"
          (click)="isApprovedPanelExpanded = !isApprovedPanelExpanded">
          <img src="../../../../assets/img/invoiceForm/plus.svg" alt="">
        </span>
      </div>
      <div class="block-content" [ngClass]="isApprovalsExpanded || isApprovedPanelExpanded ? 'block-expanded' : ''">
        <div class="type-1" *ngIf="isApprovalsExpanded && relatedData?.approvals?.approved && !relatedData?.approvals?.sentForApproval">
          <div class="logo-container">
            <div class="logo">
              <span>{{relatedData?.approvals?.approvedBy?.name | shortLogo}}</span>
            </div>
          </div>
          <div class="desc">
            <div class="title">
              <span>Approved</span>
            </div>
            <div class="subtitle" *ngIf="relatedData?.approvals?.approvedBy?.name || relatedData?.approvals?.approvedAt">
              <span>By {{relatedData?.approvals?.approvedBy?.name}}&nbsp;</span>
              <span>({{relatedData?.approvals?.approvedAt | formatedDate}})</span>
            </div>
          </div>
        </div>
        <div class="type-1" *ngIf="isApprovalsExpanded && !relatedData?.approvals?.approved && relatedData?.approvals?.sentForApproval">
          <div class="logo-container">
            <div class="logo">
              <span>{{relatedData?.approvals?.approvedBy?.name | shortLogo}}</span>
            </div>
          </div>
          <div class="desc">
            <div class="title">
              <span>Sent for Approval</span>
            </div>
            <div class="subtitle" *ngIf="relatedData?.approvals?.approvedBy?.name || relatedData?.approvals?.approvedAt">
              <span>{{relatedData?.approvals?.approvedBy?.name}}&nbsp;</span>
              <span>({{relatedData?.approvals?.approvedAt | formatedDate}})</span>
            </div>
          </div>
        </div>
        <div class="type-2 no-company" [ngClass]="isApprovedPanelExpanded ? 'approved-expanded' : ''"
          *ngIf="isApprovalsExpanded && !relatedData?.approvals?.approved && !relatedData?.approvals?.sentForApproval">
          <span>No approvals</span>
        </div>
        <div class="approved-panel" *ngIf="isApprovedPanelExpanded 
          && !relatedData?.approvals?.approved && !relatedData?.approvals?.sentForApproval" [ngClass]="isApprovedPanelExpanded ? 'approved-expanded' : ''">
          <div class="approved">
            <div matTooltip="Click to approve the invoice" [matTooltipShowDelay]="showToolTipDelay" (click)="approveInvoice()">
              Approve
            </div>
          </div>
          <div class="users" *ngIf="relatedData?.approvals?.users?.length > 0">
            <div class="title">Send for Approval</div>
            <ul>
              <li class="user" *ngFor="let user of relatedData?.approvals?.users" (click)="sendEmailToUser(user)">
                {{user.name}} - {{user.email}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- Accounting -->
    <section class="block" *ngIf="supplierId && (relatedData?.invoice?.processed || this.billsToPayMode)">
      <div class="block-title">
        <span  (click)="isAccountingExpanded = !isAccountingExpanded">
          <span *ngIf="!relatedData?.organization?.isQBO">Xero</span>
          <span *ngIf="relatedData?.organization?.isQBO">QuickBooks</span>
        </span>
        <span class="shevron" (click)="isAccountingExpanded = !isAccountingExpanded">
          <img [ngClass]="isAccountingExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
      </div>
      <div class="block-content" *ngIf="relatedData?.company?.name" [ngClass]="isAccountingExpanded ? 'block-expanded' : ''">
        <div class="type-3" *ngIf="isAccountingExpanded">
          <div class="logo-container">
            <div class="accounting-logo">
              <img *ngIf="!relatedData?.organization?.isQBO" class="act-logo" src="../../../../assets/img/company/XeroLogo.svg" alt=""/>
              <img *ngIf="relatedData?.organization?.isQBO" class="act-logo" src="../../../../assets/img/company/QBOLogo.png" alt=""/>
            </div>
          </div>
          <div class="desc">
            <div class="title">{{relatedData?.company?.name}}</div>
            <div class="subtitle">
              <span>{{invoice?.invoiceNumber}}</span>
              <span *ngIf="invoice?.invoiceNumber">&nbsp;-&nbsp;</span>
              <span>{{relatedData?.invoice?.status}}</span>
            </div>
          </div>
          <a class="img-goto" *ngIf="link" href="{{link}}" target="_blank" [matTooltip]="relatedData?.organization?.isQBO ? 'Go to QuickBooks' : 'Go to Xero'"
            [matTooltipShowDelay]="showToolTipDelay">
            <img src="../../../../assets/img/invoiceForm/goto.svg" alt=""/>
          </a>
        </div>
      </div>
    </section>

    <!-- Tags -->
    <section class="block">
      <div class="block-title">
        <span (click)="isTagsExpanded = !isTagsExpanded">Tags</span>
        <span class="shevron" (click)="isTagsExpanded = !isTagsExpanded">
          <img [ngClass]="isTagsExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
        <span class="plus" matTooltip="Click to add a tag to the invoice" [matTooltipShowDelay]="showToolTipDelay"
          mat-button [matMenuTriggerFor]="menu">
          <img src="../../../../assets/img/invoiceForm/plus.svg" alt="">
        </span>
        <mat-menu #menu="matMenu" class="inbox-menu" (closed)="menuClosed()">
          <!-- Add a tag menu items -->
          <div class="add-tag-list">
            <div class="search-tag">
              <input class="search-input" type="text" placeholder="Search Tag"
                (click)="$event.stopPropagation()" [ngModel]="tagSearchTerm" (ngModelChange)="filterTags($event)" (blur)="clearTagSearchValue()">
            </div>
            <div class="add-tag">
              <div class="tag-list-item" *ngFor="let tagMenuItem of filteredTagList" (click)="addTagToInvoices(tagMenuItem)">
                <span class="tag" [ngStyle]="{'color': tagMenuItem.textColor, 'background': tagMenuItem.backColor}">{{tagMenuItem.tagName}}</span>
              </div>
              <!-- If now filtered tags -->
              <div class="tag-list-item" *ngIf="isAddTagMenuItemShown" (click)="createNewTag()">
                + New Tag ‘{{this.tagSearchTerm}}’
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
      <div class="block-content" *ngIf="relatedData.invoice.tags?.length > 0" [ngClass]="isTagsExpanded ? 'block-expanded' : ''">
        <div class="tags-container">
          <span class="tag" *ngFor="let tag of relatedData.invoice?.tags" [ngStyle]="{'color': tag.textColor, 'background': tag.backColor}">
            <span>{{tag.tagName}}</span>
            <span class="del-icon" (click)="deleteTagFromInvoice(tag)"
              matTooltip="Delete from the invoice" [matTooltipShowDelay]="showToolTipDelay">
              <i class="fas fa-close" [ngStyle]="{'color': tag.textColor}"></i>
            </span>
          </span>
        </div>
      </div>
      <div class="block-content" *ngIf="relatedData.invoice.tags?.length == 0" [ngClass]="isTagsExpanded ? 'block-expanded padding-top' : ''">
        <span class="no-company">No tags</span>
      </div>
    </section>

    <!-- Bills -->
    <section class="block">
      <div class="block-title" style="margin-bottom: 15px">
        <span (click)="isBillsExpanded = !isBillsExpanded">Bills</span>
        <span  class="shevron" (click)="isBillsExpanded = !isBillsExpanded">
          <img [ngClass]="isBillsExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
      </div>
      <div class="block-content" *ngIf="isBillsExpanded"  [ngClass]="isBillsExpanded ? 'block-expanded' : ''">
        <div class="subgroup-title">Outstanding</div>
        <div class="invoices">
          <div class="placeholder" *ngIf="outstandingInvoices?.length == 0">No bills to display</div>
          <div class="invoice" *ngFor="let invoice of outstandingInvoices" (click)="showInvoice(invoice)">
            <div class="name">
              <div class="reference"> Inv {{invoice.invoiceNumber}}</div>
              <div class="status">{{invoice.status}}</div>
            </div>
            <div class="value" *ngIf="invoice.amountDue >= 0">{{invoice.amountDue | number: '1.2-2'}}</div>
            <div class="value" *ngIf="invoice.amountDue < 0">({{-1 * invoice.amountDue  | number: '1.2-2'}})</div>
          </div>
        </div>

        <div class="total-row" *ngIf="outstandingInvoices?.length != 0">
          <div></div>
          <div class="total" *ngIf="outstandingTotal >= 0">{{outstandingTotal | number: '1.2-2'}}</div>
          <div class="total" *ngIf="outstandingTotal < 0">({{-1 * outstandingTotal | number: '1.2-2'}})</div>
        </div>

        <div class="subgroup-title" style="margin-top: 20px" *ngIf="paidInvoices?.length > 0 && isPaidInvoiceShown">Paid</div>
        <div class="invoices" *ngIf="paidInvoices?.length > 0 && isPaidInvoiceShown">
          <div class="invoice" *ngFor="let invoice of paidInvoices" (click)="showInvoice(invoice)">
            <div class="name">
              <div class="reference"> Inv {{invoice.invoiceNumber}}</div>
              <div class="status">{{invoice.status}}</div>
            </div>
            <div class="value" *ngIf="invoice.amountDue >= 0">{{invoice.amountDue | number: '1.2-2'}}</div>
            <div class="value" *ngIf="invoice.amountDue < 0">({{-1 * invoice.amountDue  | number: '1.2-2'}})</div>
          </div>
        </div>

        <div class="show-more" *ngIf="paidInvoices?.length > 0" (click)=" isPaidInvoiceShown = !isPaidInvoiceShown">
          <span *ngIf="!isPaidInvoiceShown">Show Paid</span>
          <span *ngIf="isPaidInvoiceShown">Hide Paid</span>
        </div>
      </div>
    </section>

    <!-- Metadata -->
    <!-- <section class="block" *ngIf="supplierId != null">
      <div class="block-title">
        <span (click)="isMetadataExpanded = !isMetadataExpanded">Metadata</span>
        <span class="shevron" (click)="isMetadataExpanded = !isMetadataExpanded">
          <img [ngClass]="isMetadataExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
      </div>
      <div class="block-content" [ngClass]="isMetadataExpanded ? 'block-expanded' : ''">
        <app-key-value-pairs [invoiceId]="invoice?.id"></app-key-value-pairs>
      </div>
    </section> -->
  </div>
</div>
