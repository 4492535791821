import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {

  spinnerState = new Subject<{ show: boolean }>();

  changeSpinnerState(state: boolean) {
    this.spinnerState.next({ show: state });
  }

  constructor() {
  }
}
