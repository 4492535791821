import { LineAmountTypes } from "../../enums/line-amount-items";
import { CurrencyCode } from "../../enums/currency-code";
import { LineItems } from "../../models/credit-note/line-item";
import { Contact } from "../../models/credit-note/contact";
import { Guid } from "guid-typescript";
import { Organization } from "src/app/modules/suppliers/models/organization/organization";
import { BankAccount } from "../account/bank-account";
import { InvoiceLine } from "../invoice/invoice-line";
import { InvoicePaymentStatus } from "../../enums/payment-status";

export class BankTransactionDTO {
  invoiceID: Guid;
  type: string;
  status?: string;
  lineAmountTypes?: string;
  currency?: CurrencyCode;
  contact: Contact;
  bankAccount: BankTransactionAccount;
  date?: Date;
  lineItems: InvoiceLine[] = [];
  subTotal?: number;
  totalTax?: number;
  total?: number;
  invoiceId?: string;
  fullyPaidOnDate?: Date;
  creditNoteNumber?: string;
  invoiceNumber?: string;
  sentToContact?: boolean;
  currencyRate?: number;
  currencyCode: string;
  remainingCredit?: number;
  brandingThemeID?: Guid;
  hasAttachments?: boolean;
  hasErrors?: boolean;
  validationErrors?: string[];
  OrganisationId?: string;
  PdfUrl?: string;
  InvoiceOrCreditNote: string;
  paymentAccountNumber: string;
  paymentDate?: Date = null;
  paymentStatus?: InvoicePaymentStatus;
  unreconciledReportIds: string = '';
  constructor(organisationId: string
  ) {

    this.OrganisationId = organisationId,
      this.type = 'EXP',
      this.contact = null,
      this.bankAccount = null,
      this.lineAmountTypes = LineAmountTypes.Exclusive,
      this.status = "AUTHORISED",
      this.lineItems = [],
      this.currency = CurrencyCode.AUD,
      this.currencyCode = CurrencyCode.AUD,
      this.date = null;
  }
}

export class BankTransactionAccount {
  accountID: string;
  name: string;
  currencyCode: string;
  type: string;
  bankAccountNumber: string;
}
