export enum ToasterTypes {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  SuccessWithHtml = 'successWithHtml',
  ErrorWithHtml = 'errorWithHtml',
  SuccessWithHtmlAsync = 'SuccessWithHtmlAsync',
  ErrorInbox = "errorInbox",
  SuccessInbox = "successInbox"
}
