import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BillsToPaySupplierDetailsRequest } from '../../bookkeeping/models/invoice/bills-to-pay-invoice-details-request';

@Injectable({
  providedIn: 'root'
})
export class RecognizedSupplierService {
  private _supplierSubject = new Subject<any>();
  isupplierState$ = this._supplierSubject.asObservable();


  private _supplierEditSubject = new Subject<any>();
  isupplierEditState$ = this._supplierEditSubject.asObservable();

  private _supplierHideSubject = new Subject<any>();
  isupplierHideState$ = this._supplierHideSubject.asObservable();

  private _supplierContactFormSubject = new BehaviorSubject<BillsToPaySupplierDetailsRequest>(null);
  supplierContactFormSubject$ = this._supplierContactFormSubject.asObservable();

  constructor() { }

  send(supplier: any) {
    this._supplierSubject.next(supplier);
  }

  hide(acton: boolean) {
    this._supplierHideSubject.next(acton);
  }

  editSupplierInfo(supplier: any) {
    this._supplierEditSubject.next(supplier);
  }

  sendSupplierToContactForm(supplierRequest: BillsToPaySupplierDetailsRequest) {
    this._supplierContactFormSubject.next(supplierRequest);
  }
}
