import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneMasksDirective } from './directives/phone-mask.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { FormatedDatePipe } from './pipes/formated-date.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateTimePickerComponent } from './components/custom-date-time-picker/custom-date-time-picker.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule,  } from '@angular/material/core';
import { PositiveNumberPipe } from './pipes/positive-number.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormatedDateTimePipe } from './pipes/formated-datetime.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { PageSidebarComponent } from './components/page-sidebar/page-sidebar.component';

@NgModule({
  declarations: [
    OnlyNumbersDirective,
    PhoneMasksDirective,
    FormatedDatePipe,
    FormatedDateTimePipe,
    PositiveNumberPipe,
    CustomDateTimePickerComponent,
    DateTimePickerComponent,
    PageSidebarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DateRangePickerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NgSelectModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  exports: [
    OnlyNumbersDirective,
    PhoneMasksDirective,
    FormatedDatePipe,
    FormatedDateTimePipe,
    PositiveNumberPipe,
    CustomDateTimePickerComponent,
    DateTimePickerComponent,
    PageSidebarComponent
  ]
})
export class SharedModule { }
