<div _ngcontent-myv-c167="" class="mobnav_icon imgSearch" style="width:210px;">
  <div _ngcontent-myv-c167="" class="mobnav_button" style="display: flex;" (click)="isSidebar=true">
    <div _ngcontent-myv-c167="" id="mobnav_button_icon" class="mobnav_button-icon" style="width:20px">
      <span _ngcontent-myv-c167=""></span><span _ngcontent-myv-c167="" style="top:6px"></span><span _ngcontent-myv-c167="" style="top:12px"></span>
    </div>
    &nbsp;<span *ngIf="isInbox">Inbox</span><span *ngIf="isBillsToPay">Payables</span>
   
  </div>
  <div style="margin: 7px 0px 7px 0px;">
    <div class="search-form" style="z-index:9999">
      <input type="search" id="searchtext" #searchtext name="searchtext" placeholder="Search" class="search-bar" (click)="onSearchChange($event)" (keyup)="onSearchChange($event)" />
    </div>
  </div>
</div>
<div class="detailpanel sidebar-wrap" [ngClass]="isSidebar?'active':''">
  <span style="font-size: 20px;display: inline-block;" *ngIf="isInbox">Inbox</span>
  <span style="font-size: 20px;display: inline-block;" *ngIf="isBillsToPay">Payables</span>
  <span class="closebtn" (click)="isSidebar=false" style="cursor: pointer;">×</span>
  <div class="top-bar-container">
    <ul class="tab pages">
      <li *ngIf="!isProcessingError">
        <div>
          <div class="search-form" style="z-index:9999">
            <input type="search" id="searchtext" #searchtext name="searchtext" placeholder="Search" class="search-bar" (click)="onSearchChange($event)" (keyup)="onSearchChange($event)" />
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div style="color: #717171;">
    <div style="margin-top: 10px;">
      <div class="sidebar-list">
        <span class="sidebar-title" *ngIf="isInbox">Status</span>
        <ng-container *ngIf="isInbox">
          <span class="statustype" [ngClass]="{ 'filter' : isInProcessingTab == true}" (click)="changeTab('InProcessing')">For Review ({{invoiceCount}})</span>
          <span class="statustype" [ngClass]="{ 'filter' : isProcessedTab == true}" (click)="changeTab('Processed')">Processed</span>
          <span class="statustype" [ngClass]="{ 'filter' : isProcessingError == true}" (click)="changeTab('ProcessingError')" *ngIf="roleId == '1'">Errors</span>
        </ng-container>
        <ng-container *ngIf="isBillsToPay">
          <span class="statustype" [ngClass]="{ 'filter' : isBillsToPayTab == true}" (click)="changeTab('BillsToPay')">Bills To Pay</span>
        </ng-container>
      </div>
    </div>
    <div style="margin-top: 30px;">
      <span class="sidebar-title">Filters</span>
      <div class="sidebar-list add-scroll">
        <ng-container *ngFor="let item of displaySavedFilters">
          <span class="email-type" [ngClass]="{ 'filter' : item.checked == true}"
                [matTooltip]="item.name" [matTooltipShowDelay]="showToolTipDelay"
                (click)="selectFilters(item)">
            {{item.name.length > 25 ? item.name.replace('"','').slice(0,25) + '...' : item.name}}
          </span>
        </ng-container>
        <span class="email-type" style="color: #6C9EEE;" (click)="showMore()" *ngIf="isShowMore">Show More</span>
        <span class="email-type" style="color: #6C9EEE;" (click)="showLess()" *ngIf="isShowLess">Show Less</span>
        <span class="email-type" style="color: #6C9EEE;" title="Add Filter" (click)="addFilters()">+ Add Filter</span>
      </div>
    </div>
  </div>
  <div class="bottom_menu" *ngIf="isInbox">
    <ul>
      <li (click)="toggleSidebar(types.settings)" style="cursor: pointer;">
        <img class="setting"  src="../../../assets/img/setting-action.svg"
             alt="setting"> <span class="text"> Settings</span>
      </li>
    </ul>
  </div>
</div>
