import { Injectable } from '@angular/core';
import { HttpInternalService } from './http.service';
import { environment } from 'src/environments/environment';
import { NewReport } from 'src/app/modules/bookkeeping/models/supplier/new-report';

@Injectable({
  providedIn: 'root'
})
export class UnreconciledReportService {
  constructor(
    private _httpService: HttpInternalService,
  ) { }

  getUnreconciledReport(organizationId: string) {
      return this._httpService.getRequest<any>(`Reporting/getUnreconciledReport?organizationId=${organizationId}`);
  }

  createNewReport(report: NewReport) {
    return this._httpService.postClearRequest<{ reportId: string, message :string}>('Reporting/createNewReport', report);
  }

  GetUnreconciledReportSummary(organisationID: string, status: string) {
    var reporturl = environment.apiURL + "Reporting/getUnreconciledReportSummary";
    return this._httpService.getRequest<any>(`${reporturl}?organizationId=${organisationID}&status=${status}`);
  }
}
