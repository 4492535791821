import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { QuickBookParam, xeroParams } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpFinalModel, SignUpFullModel } from 'src/app/core/model/signup-model';
import { RegisterService } from '../../../../data/service/register.service'
import { ToasterMessageService } from '../../../../shared/toaster-message/toaster-message.service';
import { ToasterTypes } from '../../../../core/enum/toaster-type.enum';
import { LoginModel } from 'src/app/core/model/login-model';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { finalize, take } from 'rxjs/operators';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { UserService } from 'src/app/data/service/user.service';
import { NotificationsHubService } from 'src/app/core/service/hubs/notifications-hub.service';
import { RefreshLog_Type } from '../../../../data/enums/common-enum';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {
  @Output() public onNextSlide = new EventEmitter();
  @Output() public onPrevSlide = new EventEmitter();
  @Output() public onSetOrganizationIds = new EventEmitter();

  @Input() public organizationIds: Array<string>;

  public isShowComponent = true;
  connectedToXero:boolean=false;
  connectedToQBO:boolean=false;
  isWarningShown: boolean = false;
  SignupFullModel : SignUpFullModel | null;
  signupModel: SignUpFinalModel;
  domainName:string;
  loading: boolean = false;
  loginModel: LoginModel | null;

  private xeroWindow: any;
  private QuickBookWindow: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private registerService: RegisterService,
    private toasterMessageService: ToasterMessageService,
    private router: Router,
    private authService: AuthService,
    private suppliersService: SuppliersService,
    private _userService: UserService,
    private readonly _notificationsHubService: NotificationsHubService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param.organizationId && window.opener) {
        this.isShowComponent = false;
        window.opener.xeroData = param.organizationId;
        window.opener.quickbooksData = param.organizationId;
        window.opener.domainName = param.domain;
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      let OrganisationId = params['OrganisationId'];
      this.organizationIds = OrganisationId.split(' ');
      this.domainName = params['Domain'];
    });

    if (this.domainName === "Xero") {
      this.connectedToXero = true;
    } else if (this.domainName === "QuickBooks") {
      this.connectedToQBO = true;
    }

    this.SignupFullModel = JSON.parse(sessionStorage.getItem("newuser"));
  }

  openXeroWindow() {
    if (this.xeroWindow) {
      this.xeroWindow.close();
    }
    this.xeroWindow = window.location.replace(xeroParams.xeroOauth2Link);
    const interval = setInterval(() => {
      if (this.xeroWindow.closed) {
        clearInterval(interval);
        this.xeroWindow = null;
      }
      var data = this.xeroWindow;
      if ((<any>window).xeroData !== undefined) {
        const orgIds = (<any>window).xeroData.split(' ');
        if (orgIds.length > 0) {
          this.connectedToXero = true;
          this.xeroWindow.close();
          clearInterval(interval);
          this.xeroWindow = null;
          this.organizationIds = orgIds;
          this.onSetOrganizationIds.emit(orgIds);
          this.onNextSlide.emit();
        }
      }
    }, 1000);
  }

  openQuickBookWindow() {
    if (this.QuickBookWindow) {
      this.QuickBookWindow.close();
}                 

    this.QuickBookWindow = window.location.replace(QuickBookParam.QuickBookOauth2Link);

    const interval = setInterval(() => {
      if (this.QuickBookWindow.closed) {
        clearInterval(interval);
        this.QuickBookWindow = null;
      }

      var data = this.QuickBookWindow;
      if ((<any>window).quickbooksData !== undefined) {
        const orgIds = (<any>window).quickbooksData.split(' ');
        const domain = (<any>window).domainName.split('');
        if (orgIds.length > 0) {
          this.connectedToQBO = true;
          this.QuickBookWindow.close();
          clearInterval(interval);
          this.QuickBookWindow = null;
          this.organizationIds = orgIds;
          this.onSetOrganizationIds.emit(orgIds);
          this.onNextSlide.emit();
        }
      }
    }, 1000);
  }

  singUp() {
    this.activatedRoute.queryParams.subscribe(params => {
      let OrganisationId = params['OrganisationId'];
      this.organizationIds = OrganisationId.split(' ');
      this.domainName = params['Domain'];
    });

    this.SignupFullModel = JSON.parse(sessionStorage.getItem("newuser"));

    this.signupModel = {
      domain: this.domainName,
      user: this.SignupFullModel,
      organisationIds : this.organizationIds.toString(),
      invitedUsers:null,
      organisationId:this.organizationIds[0]
    }

    if (this.signupModel != null && this.signupModel != undefined) {
      this.loading = true;
      this.registerService.signUpNew('Account/SignUp', this.signupModel)
        .subscribe((response) => {
          if (response.status === 0) {
            this.loading = false;
            this.toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'New user registered. Check email for confirmation.');
            
            this.loginModel = {
              email: this.SignupFullModel.email.toLowerCase(),
              password: this.SignupFullModel.password,
              accountRemember: true
            }

            this.authService.signIn(this.loginModel)
              .pipe(
                take(1),
                finalize(() => this.loading = false))
              .subscribe((response) => {
                if (response.status != 0) {
                  this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
                }
                else {
                  this.setUser(response);
                  this._notificationsHubService.start();
                  
                }
              }, (error) => {
                this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', error.message);
              });
          }
          else {
            this.loading = false;
            this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
          }
        }, (error) => {
          this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', 'Error inviting users');
        });
    }
  }

  setUser(response) {
    sessionStorage.UserName = response.data.user.name;
    sessionStorage.Id = response.data.user.id;
    sessionStorage.UserId = response.data.user.userId;
    localStorage.setItem('UserName', response.data.user.name);
    localStorage.setItem('UserId', response.data.user.userId);
    localStorage.setItem('Id', response.data.user.id);
    localStorage.setItem('roleId', response.data.user.role);
    localStorage.setItem('SelectedOrganization', response.data.user.organizationId);
    localStorage.setItem('CreatedAt', response.data.user.created_date);
    this._userService.upUserDetails = JSON.stringify(response.data.user);
    this._userService.setUserRoleData(response.data.user.role);

    if(response.data.user.organizationId) {
      sessionStorage.organizationId = response.data.user.organizationId;
      sessionStorage.selectedOrganization = response.data.user.organizationId;
      this.AddandUpdatesupplierXeroData(response.data.user.organizationId,response.data.user.email) 
    }
  }

  AddandUpdatesupplierXeroData(orgId, email) {
    this.suppliersService.AddandUpdatesupplierXeroDataInitial(orgId, email, RefreshLog_Type.Signup)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('/signup/wizzard');
      });
  }
}
