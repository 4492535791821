import { Component, OnInit, Input, Output, EventEmitter,OnDestroy, Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { dateToString } from 'src/app/core/util/date-converter';
import { TrackingCategory } from '../models/tracking-categories.ts/trackingCategory';
import { PaymentAccount } from '../models/account/payment-account';
import { SupplierShort } from '../models/supplier/supplier-short';
import { TrackingCategoryOption } from '../models/tracking-categories.ts/tracking-category-option';
import { Tag } from 'src/app/data/model/tag';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { take, timeout } from 'rxjs/operators';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { BookkeepingFilter } from '../models/filter/bookkeeping-filter';
import { Location } from '@angular/common';
import { FilterService } from 'src/app/data/service/filter.service';
import { ReportFilter } from 'src/app/data/model/reportFilter';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/data/service/user.service';
import * as $ from "jquery";
import { FormsModule } from '@angular/forms';
import { InvoiceStatus } from '../enums/invoice-status';
import { Unreconciledreportperiod } from 'src/app/core/enum/Unreconciledreport-period.enum';
import { ReportPeriod } from 'src/app/core/enum/report-period.enum';
import moment from 'moment';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

enum FilterMenuSection {
    Main,
    Date,
    Category,
    Supplier,
    Tag,
    TrackingCategory1,
    TrackingCategory2
}


@Component({
    selector: 'app-filter-sidebar',
    templateUrl: './filter-sidebar-component.html',
    styleUrls: ['./filter-sidebar-component.scss']
  })

  @Injectable({
    providedIn: 'root'
  })

export class FilterSidebarComponent implements OnInit, OnDestroy {
    @Input() isSidebarVisible: boolean;
    @Input() tags: Tag[] = [];
    @Input() trackingCategories: TrackingCategory[] = [];
    @Input() selectedTrackingCategories: TrackingCategory[] = [];
    @Input() selectedSuppliers: SupplierShort[] = [];
    @Input() selectedTags: Tag[] = [];
    @Input() accounts: PaymentAccount[] = [];
    @Input() filter = new BookkeepingFilter();
    @Input() filterId: string = '';
    @Input() isEditMode: boolean = false;
    @Input() invoiceStatus: InvoiceStatus | null;
    @Input() isUnreconciledReport: boolean = false;
    @Output() updateInvoices: EventEmitter<string> = new EventEmitter<string>();
    @Output() closeFilter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() newItemEvent = new EventEmitter<ReportFilter>();
    @Output() closeEvent = new EventEmitter();
    @Output() deleteEvent = new EventEmitter();

    importData:any={success:[],error:[],type:'success',active:false}
    suppliers: SupplierShort[] = [];
    subscriptions: Subscription[] = [];
    filterName: string = "";
    isEditable: boolean = false;
    showDateFilter: boolean = false;
    filterDateRange: DateRange<Date> = null;
    filterDateRangeOption: string = null;
    dateRange; string = "";

    filterUrlPattern: string = '';
    private status = InvoiceStatus;

    dateFormat: string = null;
    currentFilterMenuSection: FilterMenuSection = FilterMenuSection.Main;
    filterMenuSections = FilterMenuSection;

    filterCategories: PaymentAccount[] = [];
    filterSuppliers: SupplierShort[] = [];
    filterTags: Tag[] = [];
    filterTrackingOptions1: TrackingCategoryOption[] = [];
    selectedTrackingOptions1: TrackingCategoryOption[] = [];
    filterTrackingOptions2: TrackingCategoryOption[] = [];

    tagSearchTerm: string = null;
    searchedSuppliers: SupplierShort[];
    searchedAccounts: PaymentAccount[];
    searchedTags: Tag[];
    searchedTrackingOprions1: TrackingCategoryOption[];
    searchedTrackingOprions2: TrackingCategoryOption[];
    dropdownSettings = {};
    supplierDropdownSettings = {};
    tagsDropdownSettings = {};
    trackingDropdownSettings = {};
    dropdownBankSettings = {};
    userDetails:any;
    searchCriterias: string []= [];
    isDirty:boolean = false;
    isPinned: boolean = false;
    filterSeqName: string = "";
    isTagsAssigned: boolean = false;
    isTrackingAssigned: boolean = false;
    isSpinnerShown: boolean = false;
    SelctedReportName:string ="";

    filterBank : PaymentAccount[] = [];
    DateRangeSelcted: string = "";
    unreconciledreportperiod = Unreconciledreportperiod;
    reportPeriod = ReportPeriod;
    bankAccountList: PaymentAccount[] = [];
    filterType:string='';
    isXeroAccount: boolean = false;
    showDateRange:string ="";
    Source="Spreadsheet";
    constructor(
      private readonly _location: Location,
      private readonly _suppliersService: SuppliersService,
      private readonly _organizationService: OrganizationService,
      private readonly _toasterMessageService: ToasterMessageService,
      private readonly _filterService: FilterService,
      private _userService: UserService,
    )
    {
      this.dateFormat = sessionStorage.getItem('orgDateFormat');
      this.filterType='';
    }

    GetBankAcoountDetails() {
      this._organizationService.GetBankAccountDetails("Organisation/GetBankAccountDetails", sessionStorage.organizationId).subscribe((response: any) => {
        if (response.status == 0) {
          this.bankAccountList = response.data;
          console.log(response);
          //if (this.bankAccountList?.length > 0) {
          //   this.selectedBankAccount = this.bankAccountList[0].accountID;
          //   this.MyBankAccount = this.bankAccountList[0];
          //   if (this.bankAccountList[0]["bankName"] === "NAB") {
          //     this.isNABBank = true;
          //   }
          // }
          this.filterBank?.forEach(element => {
            element.checked = true;
          });
        }
      }, err => {
        this.bankAccountList = [];
       // this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.message);
      });
    }

    ngOnInit(): void {
      this._organizationService.organizationList.subscribe(data => {
        if (data) {
          const getOrganization = data.find(x => x.organisationID.toLowerCase() == sessionStorage.organizationId.toLowerCase());
          if (getOrganization) {
            this.isXeroAccount=getOrganization.accountingIntegration;
          }

        }
      });

      this.filterType='';
      this.GetBankAcoountDetails();

      this.SelctedReportName="Import Transactions";

      this.getLatestFilterNumber();
      this.getSuppliers();
      this.subscriptions.push(this._userService.loggedUserDetails
        .subscribe(data => {
          if (data) {
            this.userDetails = data;
          } else {
            this.userDetails = [];
          }
        }));

      // this.isEditable = true;
      this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
      };
      this.supplierDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      this.tagsDropdownSettings = {
        singleSelection: false,
        idField: 'tagId',
        textField: 'tagName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      this.trackingDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      this.dropdownBankSettings = {
        singleSelection: false,
        idField: 'accountID',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };
    }
    ngOnDestroy() {

    }
    saveContactForm(fieldName: string) {

      // if (this.isEditable) {
      //   this.submit(fieldName);
      // }
    }
    startDate = new Date;
    lastDayOfMonth = new Date;
    keyInEnum(): Array<string> {
      var keys;
      if (this.SelctedReportName == 'Unreconciled Items') {

        this.DateRangeSelcted = "This Month";

        keys = Object.values(this.unreconciledreportperiod);
      }
      else {
        keys = Object.values(this.reportPeriod);
      }
      this.startDate = new Date;
      this.lastDayOfMonth = new Date;
      if (this.DateRangeSelcted == "This Month") {
        this.startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
        this.lastDayOfMonth = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
        this.showDateRange=moment(this.startDate).format("MM/DD/YYYY") +" to "+moment(this.lastDayOfMonth).format("MM/DD/YYYY");
      }
      else if (this.DateRangeSelcted == "Last Month") {

        this.startDate = new Date(moment((this.currentdateitem)).format("MM/DD/YYYY"));
        this.lastDayOfMonth = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        this.showDateRange=moment(this.startDate).format("MM/DD/YYYY") +" to "+moment(this.lastDayOfMonth).format("MM/DD/YYYY");

      }
      else if (this.DateRangeSelcted == "This Quarter") {
        var quarter = Math.floor((this.date.getMonth() + 3) / 3)
        var quarterstart = moment(this.date.getFullYear(), "YYYY").quarter(quarter).startOf('quarter');
        var quarterend = moment(this.date.getFullYear(), "YYYY").quarter(quarter).endOf('quarter');
        this.startDate = quarterstart.toDate();
        this.lastDayOfMonth = quarterend.toDate();
        this.showDateRange=moment(this.startDate).format("MM/DD/YYYY") +" to "+moment(this.lastDayOfMonth).format("MM/DD/YYYY");

      }
      else if (this.DateRangeSelcted == "Last Quarter") {
        var quarter = Math.floor((this.date.getMonth() / 3));
        var quarterstart = moment(this.date.getFullYear(), "YYYY").quarter(quarter).startOf('quarter');
        var quarterend = moment(this.date.getFullYear(), "YYYY").quarter(quarter).endOf('quarter');
        this.startDate = quarterstart.toDate();
        this.lastDayOfMonth = quarterend.toDate();
        this.showDateRange=moment(this.startDate).format("MM/DD/YYYY") +" to "+moment(this.lastDayOfMonth).format("MM/DD/YYYY");
      }
      else if (this.DateRangeSelcted == "This Financial Year") {
        this.startDate = new Date("07/01/" + (this.dateitem.getFullYear() - 1));
        this.lastDayOfMonth = new Date("06/30/" + this.date.getFullYear());
        this.showDateRange=moment(this.startDate).format("MM/DD/YYYY") +" to "+moment(this.lastDayOfMonth).format("MM/DD/YYYY");
      }
      else if (this.DateRangeSelcted == "Last Financial Year") {
        this.startDate = new Date("07/01/" + (this.dateitem.getFullYear() - 2));
        this.lastDayOfMonth = new Date("06/30/" + (this.dateitem.getFullYear() - 1));
        this.showDateRange=moment(this.startDate).format("MM/DD/YYYY") +" to "+moment(this.lastDayOfMonth).format("MM/DD/YYYY");
      }

      return keys.slice().filter(e => e !== 'Custom Range');
    }



    getLatestFilterNumber() {
      this._filterService.getLatestFilterName("Filter/GetLatestFilterNumber", 1, this._organizationService.selectedOrganization)
        .pipe(take(1))
        .subscribe(
          (name) => {
            this.filterName = name.data;
            this.filterSeqName = name.data;
          });
    }

    getSuppliers() {
        this._suppliersService.getSuppliers(this._organizationService.selectedOrganization)
          .pipe(take(1))
          .subscribe(
            (data) => {
              this.suppliers = data.sort((a, b) => a.name.localeCompare(b.name)) || [];

              this.searchedSuppliers = this.suppliers;
              this.searchedTags = this.tags;
              this.searchedAccounts = this.accounts;
              if (this.selectedTrackingCategories && this.selectedTrackingCategories.length > 0) {
                this.searchedTrackingOprions1 = this.selectedTrackingCategories[0].options;
                if (this.selectedTrackingCategories.length > 1) {
                  this.searchedTrackingOprions2 = this.selectedTrackingCategories[1].options;
                }
                if(this.selectedTrackingCategories && this.selectedTrackingCategories.length > 0){
                  this.searchedTrackingOprions1.splice(0, 0, {id: '0', name: 'Unassigned', trackingCategoryId: '', group: 0});
                }
              }

              this.showTagsFromAddressBar();
            },
            (e: Error) => {
              this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
            }
          );
      }

    private showTagsFromAddressBar() {
        this.filterSuppliers?.forEach(element => {
          element.checked = true;
        });

        this.filterCategories?.forEach(element => {
          element.checked = true;
        });

        this.filterTags?.forEach(element => {
          element.checked = true;
        });

        this.filterTrackingOptions1?.forEach(element => {
          element.checked = true;
        });

        this.filterTrackingOptions2?.forEach(element => {
          element.checked = true;
        });
    }

    CloseTheFilterSidebar(filter: ReportFilter) {
      this.newItemEvent.emit(filter);
      this.showDateFilter = false;
      this.isEditable = true;
      this.selectedSuppliers = [];
      this.selectedTags=[];
      this.filterCategories = [];
      this.filterDateRange = null;
      this.filterSuppliers = [];
      this.filterTags = [];
      this.dateRange = null;
      this.filterName = this.filterSeqName;

      this.filterBank = [];
      this.isEditMode = false;
      this.isDirty = false;
      this.isPinned = false;
    }

    CloseFilterSidebar() {
      this.closeEvent.emit();
      this.showDateFilter = false;
      this.isEditable = true;
      this.selectedSuppliers = [];
      this.selectedTags=[];
      this.filterCategories = [];
      this.filterDateRange = null;
      this.filterSuppliers = [];
      this.filterTags = [];
      this.dateRange = null;
      this.filterName = this.filterSeqName;

      this.filterBank = [];
      this.isEditMode = false;
      this.isDirty = false;
      this.isPinned = false;
  }

    toogleDateFilter(){
        this.showDateFilter = !this.showDateFilter;
    }

    setDateRange(event) {
      if(event.target != undefined && event.target.value == "") {
        this.filterDateRange = null;
        this.dateRange = "";
        this.filterDateRangeOption = null;
        this.updateFilter('invoiceDateRange', null);
        this.showDateFilter = false;
      }
      else {

        this.filterDateRange = event.selectedDateRange;
        this.filterDateRangeOption = event.selectedOption;
        this.dateRange = dateToString(this.filterDateRange.start) + " - " +  dateToString(this.filterDateRange.end);
        this.toogleDateFilter();
        this.updateFilter('invoiceDateRange', [this.filterDateRange.start, this.filterDateRange.end]);
      }
      this.isDirty = true;
    }

    clearSelectedDateRange() {
      this.filterDateRange = null;
      this.dateRange = "";
      this.filterDateRangeOption = null;
      this.updateFilter('invoiceDateRange', null);
      this.showDateFilter = false;
    }

    onItemSelect(item: any) {
        // this.filterCategories.push(item);
        this.updateFilter('categories', this.filterCategories.map(c => c.name.replace('&', '%26')));
        this.isDirty=true;
    }

    onSelectAll(items: any) {
        this.filterCategories = items;
        this.updateFilter('categories', this.filterCategories.map(c => c.name.replace('&', '%26')));
        this.isDirty=true;
    }

    onDeleteGroup(item: any) {
        this.filterCategories = Object.values(this.filterCategories).filter(g => g.id != item.typeId);
        this.updateFilter('categories', this.filterCategories.map(c => c.name.replace('&', '%26')));
        this.isDirty=true;
    }

    onDeleteAllGroup(item: any) {
      this.filterCategories = [];
      this.updateFilter('categories', this.filterCategories.map(s => s.id.replace('&', '%26')));
      this.isDirty = true;
    }

    onSupplierItemSelect(item: any) {
      // this.selectedSuppliers.push(item);
      this.filterSuppliers.push(item);
      this.updateFilter('suppliers', this.filterSuppliers.map(s => s.name.replace('&', '%26')));
      this.isDirty=true;
    }

    onSupplierSelectAll(items: any) {
        this.filterSuppliers = items;
        this.selectedSuppliers = items;
        this.updateFilter('suppliers', this.filterSuppliers.map(s => s.name.replace('&', '%26')));
        this.isDirty=true;
    }

    onSupplierDeleteGroup(item: any) {
        this.filterSuppliers = Object.values(this.filterSuppliers).filter(g => g.name != item.name);
        this.updateFilter('suppliers', this.filterSuppliers.map(s => s.name.replace('&', '%26')));
        this.selectedSuppliers = this.filterSuppliers;
        this.isDirty=true;
    }

    onSupplierDeleteAllGroup(item: any) {
      this.filterSuppliers = [];
      this.selectedSuppliers = [];
      this.updateFilter('suppliers', this.filterSuppliers.map(s => s.name.replace('&', '%26')));
      this.isDirty = true;
    }

    onTagItemSelect(item: any) {
      // this.selectedTags.push(item);
      // this.filterTags.push(item);
      if(item.tagName == "Unassigned") {
        let data = this.searchedTags;
        let selectedItem = data.filter(item1 => item1.tagId == item.tagId);
        let selectedItemGroup = selectedItem[0]['group'];
        this.searchedTags = data.map(s => {
          if(s.group == selectedItemGroup){
            s.isDisabled = false;
          } else {
            s.isDisabled = true;
          }
          return s;
        });
        this.isTagsAssigned = true;
        this.selectedTags = Object.values(this.selectedTags).filter(g => g.tagName == "Unassigned");
        this.updateFilter('tags', "Unassigned tags");
      }
      else {
        this.isTagsAssigned = false;
        this.updateFilter('tags', this.selectedTags.map(t => t.tagName));
      }
      this.isDirty=true;

    }

    onTagSelectAll(items: Tag[]) {
      this.tags = this.tags.map(s => {
        if(s.group === 0){
          s.isDisabled = true;
        } else {
          s.isDisabled = false;
        }
        return s;
      });
      this.selectedTags = Object.values(items).filter(g => g.tagName != "Unassigned");
      this.updateFilter('tags', this.selectedTags.map(t => t.tagName));
      this.isDirty=true;
    }

    onTagDeleteGroup(item: any) {
      if(this.searchedTags && this.searchedTags.length > 0) {
        this.searchedTags = this.searchedTags.map(s => {
          s.isDisabled = false;
          return s;
        });
      }
      this.isTagsAssigned = false;
      this.selectedTags = Object.values(this.filterTags).filter(g => g.tagId != item.tagId);
      this.updateFilter('tags', this.selectedTags.map(t => t.tagName));
      this.isDirty=true;
    }

    onTagDeleteAllGroup(item: any) {
      if(this.tags && this.tags.length > 0) {
        this.tags = this.tags.map(s => {
          s.isDisabled = false;
          return s;
        });
      }
      this.selectedTags = [];
      this.updateFilter('tags', this.selectedTags.map(s => s.tagName.replace('&', '%26')));
      this.isDirty = true;
    }

    onTracking1ItemSelect(item: any) {
      if(item.name == "Unassigned") {
        let data = this.searchedTrackingOprions1;
        let selectedItem = data.filter(item1 => item1.id == item.id);
        let selectedItemGroup = selectedItem[0]['group'];
        this.searchedTrackingOprions1 = data.map(s => {
          if(s.group == selectedItemGroup){
            s.isDisabled = false;
          } else {
            s.isDisabled = true;
          }
          return s;
        });
        this.isTrackingAssigned = true;
        this.filterTrackingOptions1 = Object.values(this.filterTrackingOptions1).filter(g => g.name == "Unassigned");
        this.updateFilter('trackingOptions1', "Unassigned tracking");
      }
      else {
        this.isTrackingAssigned = false;
        this.updateFilter('trackingOptions1', this.filterTrackingOptions1.map(t => t.name));
      }
      this.isDirty=true;
    }

    onTracking1SelectAll(items: TrackingCategoryOption[]) {
      this.searchedTrackingOprions1 = this.searchedTrackingOprions1.map(s => {
        if(s.group === 0){
          s.isDisabled = true;
        } else {
          s.isDisabled = false;
        }
        return s;
      });
      this.filterTrackingOptions1 = Object.values(items).filter(g => g.name != "Unassigned");
      this.updateFilter('trackingOptions1', this.filterTrackingOptions1.map(t => t.name));
      this.isDirty=true;
    }

    onTracking1DeleteGroup(item: any) {
      if(this.searchedTrackingOprions1 && this.searchedTrackingOprions1.length > 0) {
        this.searchedTrackingOprions1 = this.searchedTrackingOprions1.map(s => {
          s.isDisabled = false;
          return s;
        });
      }
      this.filterTrackingOptions1 = Object.values(this.filterTrackingOptions1).filter(g => g.name != item.name);
      this.isTrackingAssigned = false;
      this.updateFilter('trackingOptions1', this.filterTrackingOptions1.map(t => t.name));
      this.isDirty=true;
    }

    onTracking1DeleteAllGroup(item: any) {
      this.searchedTrackingOprions1 = this.searchedTrackingOprions1.map(s => {
        s.isDisabled = false;
        return s;
      });
      this.filterTrackingOptions1 = [];
      this.updateFilter('trackingOptions1', this.filterTrackingOptions1.map(s => s.name.replace('&', '%26')));
      this.isDirty = true;
    }

    public date = new Date();
    public dateitem = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    public currentdateitem = this.dateitem.setMonth(this.dateitem.getMonth() - 1);
    public start: Date = new Date(moment((this.currentdateitem)).format("MM/DD/YYYY"));
    public end: Date = new Date(moment((this.date)).format("MM/DD/YYYY"));

    reportPeriodChange($event) {
      // if ($event.target.value === this.reportPeriod.CustomRange.valueOf()) {
      //   // this.showCustomFilter = true;
      //   this.DateRangeSelcted = $event.target.value;
      // }
      // else {
      //   // this.showCustomFilter = false;

        this.DateRangeSelcted = $event.target.value;
      //}
    }

    onBankItemSelect(item: any) {
      this.updateFilterBank('accounts', this.filterBank.map(c => c.accountID.replace('&', '%26')));
      this.isDirty=true;
    }

    onBankSelectAll(items: any) {
      this.filterBank = items;
      this.updateFilterBank('accounts', this.filterBank.map(c => c.accountID.replace('&', '%26')));
      this.isDirty=true;
    }
    onBankDeleteItem(item: any) {
      this.filterBank = Object.values(this.filterBank).filter(g => g.accountID != item.accountID);
      this.updateFilterBank('accounts', this.filterBank.map(c => c.accountID.replace('&', '%26')));
      this.isDirty=true;
    }

    updateFilterBank(field: string, value: any) {
      this.filter[field] = value;
    }
imprtSuccessGrp:any=[];
imprtErrorGrp:any=[];
  redirectToreport(){
    let bankPart = this.filter.accounts?.length > 0 ? this.filter.accounts?.map(s => `${s},`).join('').slice(0, -1) : '';

    let str :string = JSON.stringify({ 'account':bankPart.substring(0,bankPart.length),'DateRangeSelcted':this.DateRangeSelcted});

    this.isSpinnerShown = true;
    //this.filterUrlPattern = `?${bankPart}&DateRangeSelcted=${this.DateRangeSelcted}`;
    if (this.filter.accounts?.length > 0
      || (this.filter.invoiceDateRange && this.filter.invoiceDateRange[0]))
    {
        //
        var startDate = moment((this.startDate)).format("MM/DD/YYYY");
        var lastDayOfMonth = moment((this.lastDayOfMonth)).format("MM/DD/YYYY");

        var reportDataModel = {
          organizationId: sessionStorage.organizationId,
          userid:  Number(sessionStorage.UserId),
          id: sessionStorage.Id,
          startDate: startDate === "Invalid date"  ? "1900-01-01" : startDate,
          endDate: lastDayOfMonth === "Invalid date" ? "2048-01-01" : lastDayOfMonth,
          isSavedReport: false,
          isoutstandingReport: false,
          isSavedReportData: false,
          Source:'XeroDataCheck',// "Report",
          reportId: undefined,
          Account: bankPart.substring(0,bankPart.length)
        }



        this._organizationService.GetUnreconciledReportList(reportDataModel).subscribe(data => {

          this.isSpinnerShown = false;

            this.importData={success:[],error:[],type:'success',active:true};
            this.Source="Report";
            data.data.forEach((val)=>{
              //val.trannsactionDate=val.transactionDate;
              if(val.importStatus=="success")
              {
                val.isImport=true;
                this.importData.success.push(val);


              }
              else{
                this.importData.error.push(val);
              }
            })

            this.imprtSuccessGrp= this.groupRecord(this.importData.success);
            this.imprtSuccessGrp.forEach(element => {
              element.ischeck=true;
             });
            this.imprtErrorGrp= this.groupRecord(this.importData.error);
        });


    } else {
      this.closeFilter.emit(false);
    }
  }
  setCheckUnCheck(array,isbool)
  {
    array.forEach(item => {
      item.isImport=isbool;
    })

  }
  groupRecord(list) {
    let grpLst=[];
    list.forEach((item) => {

      if (grpLst.length > 0) {
        let isExists = false;
        grpLst.forEach(element => {
          if (element.key == item.accountName) {
            element.value.push(item);
            isExists = true;
          }

        });
        if (!isExists) {
          grpLst.push({ key: item.accountName, value: [item], ischeck: false });
        }
      }
      else {
        grpLst.push({ key: item.accountName, value: [item], ischeck: false });
      }

      // this.transGrpLst.forEach(element => {
      //   for (const group of  (element.value)) {
      //     group.sort((a, b) => a.transactionDate - b.transactionDate);
      //   }
      // });
    });
    return grpLst;

  }
    updateFilter(field: string, value: any) {
      this.filter[field] = value;

      let supplierPart = this.filter.suppliers?.length > 0 ? this.filter.suppliers?.map(s => `suppliers=${s}&`).join('').slice(0, -1) : 'suppliers=';
      let categoryPart = this.filter.categories?.length > 0 ? this.filter.categories?.map(c => `categories=${c}&`).join('').slice(0, -1) : 'categories=';
      let tagPart = "";
      if(this.isTagsAssigned) {
        tagPart = "tags=Unassigned tags";
      }
      else{
        tagPart = this.filter.tags?.length > 0 ? this.filter.tags?.map(t => `tags=${t}&`).join('').slice(0, -1) : 'tags=';
      }
      let trackingOptions1Part = "";
      if(this.isTrackingAssigned) {
        trackingOptions1Part = "track1=Unassigned tracking";
      }
      else{
        trackingOptions1Part = this.filter.trackingOptions1?.length > 0 ? this.filter.trackingOptions1?.map(o => `track1=${o}&`).join('').slice(0, -1) : 'track1=';
      }

      let dateStartPart = this.filter.invoiceDateRange && this.isValidDate(this.filter.invoiceDateRange[0]) && this.filter.invoiceDateRange?.slice(0, 1)[0] ? `dateStart=${dateToString(this.filter.invoiceDateRange[0]) ?? ''}` : 'dateStart=';
      let dateEndPart = this.filter.invoiceDateRange && this.isValidDate(this.filter.invoiceDateRange[0]) && this.filter.invoiceDateRange?.slice(1, 2)[0] ? `dateEnd=${dateToString(this.filter.invoiceDateRange[1]) ?? ''}` : 'dateEnd=';
      let dateOption = this.filterDateRangeOption ? `dateOption=${this.filterDateRangeOption}` : 'dateOption=';
      let statusPart = `status=${this.invoiceStatus == this.status.inProcessing ? 'false' : 'true'}`;

      let trackingOptions2Part = this.filter.trackingOptions2?.length > 0 ? this.filter.trackingOptions2?.map(o => `track2=${o}&`).join('').slice(0, -1) : 'track2=';

      this.filterUrlPattern = `?${supplierPart}&${dateOption}&${dateStartPart}&${dateEndPart}&${categoryPart}&${tagPart}&${trackingOptions1Part}&${trackingOptions2Part}&${statusPart}`;
      this._location.replaceState(`/inbox${this.filterUrlPattern}`);
      if (this.filter.categories?.length > 0
        || this.filter.suppliers?.length > 0
        || this.filter.tags?.length > 0
        || (this.filter.invoiceDateRange && this.filter.invoiceDateRange[0])
        || this.filter.trackingOptions1?.length > 0
        || this.filter.trackingOptions2?.length > 0
      ) {
        this.filterInvoices();
      } else {
        this.closeFilter.emit(false);
      }
  }

  isValidDate(dateObject) {
    return new Date(dateObject).toString() !== 'Invalid Date';
}

    filterInvoices() {
      this.updateInvoices.emit(this.filterUrlPattern);
    }


    SaveFilter() {
      let filter = {
        id: this.filterId,
        name: this.filterName,
        type: 0,
        isPinned: this.isPinned,
        searchCriteria: this.filterUrlPattern,
        createdBy:this.userDetails.id,
        createdDate: new Date(),
        filterPageType: 1,
        organizationId: this._organizationService.selectedOrganization
      } as ReportFilter;

      this._filterService.SaveFilter("", filter)
      .pipe(take(1))
      .subscribe(
        (createdFilter: string) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, "Success","Filter Saved Successfully.");
          filter.id = createdFilter;
          this.CloseTheFilterSidebar(filter);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
    }

    GetFilterData(){
      this.isSpinnerShown = true;
      this._filterService.GetFilter('Filter/GetReportFilterById',$("#hdFilterId").val())
      .pipe(take(1))
      .subscribe(
        (reportFilter) => {
          this.filterName = reportFilter.name;
          this.filterId = reportFilter.id;
          this.filterUrlPattern = reportFilter.searchCriteria;
          this.isPinned = reportFilter.isPinned;
          this.filterSuppliers = [];
          this.selectedSuppliers = [];
          this.filterTags = [];
          this.filterCategories = [];
          this.selectedTrackingOptions1 = [];
          this.filterTrackingOptions1 = [];

          if(this.filterUrlPattern !="") {
            this.searchCriterias = this.filterUrlPattern.replace("?", "").split("&");

            for(var i=0; i < this.searchCriterias.length;i++) {
              if(this.searchCriterias[i].startsWith("suppliers") && this.searchCriterias[i] != "suppliers="){
                this.filterSuppliers.push(this.suppliers.find(x => x.name.toLowerCase() === this.searchCriterias[i].toLowerCase().replace("suppliers=","").replace('%26', '&')));
                this.selectedSuppliers = this.filterSuppliers;
                this.updateFilter('suppliers', this.filterSuppliers.map(s => s.name.replace('&', '%26')));
              }
              if(this.searchCriterias[i].startsWith("tags") && this.searchCriterias[i] != "tags="){
                if(this.searchCriterias[i] == "tags=Unassigned tags") {
                  this.isTagsAssigned = true;
                  this.filterTags.push(this.tags.find(x => x.tagName.toLowerCase() === "unassigned"));
                  this.selectedTags = this.filterTags;
                } else {
                  this.filterTags.push(this.tags.find(x => x.tagName.toLowerCase() === this.searchCriterias[i].toLowerCase().replace("tags=","").replace('%26', '&')));
                  this.selectedTags = this.filterTags;
                }
                this.updateFilter('tags', this.filterTags.map(s => s.tagName.replace('&', '%26')));
              }
              if(this.searchCriterias[i].startsWith("categories") && this.searchCriterias[i] != "categories="){
                this.filterCategories.push(this.accounts.find(x => x.name.toLowerCase() === this.searchCriterias[i].toLowerCase().replace("categories=","").replace('%26', '&')));
                this.updateFilter('categories', this.filterCategories.map(s => s.name.replace('&', '%26')));
              }
              if(this.searchCriterias[i].startsWith("dateStart") && this.searchCriterias[i] != "dateStart"){
                this.dateRange =  this.searchCriterias[i].replace("dateStart=",'')+ " - ";
              }
              if(this.searchCriterias[i].startsWith("dateEnd") && this.searchCriterias[i] != "dateEnd"){
                this.dateRange = this.dateRange + this.searchCriterias[i].replace("dateEnd=",'');
                var dateArr = this.dateRange.split(' - ');
                this.filter.invoiceDateRange= [new Date(dateArr[0]) ,new Date(dateArr[1])];
                this.filterDateRange = new DateRange<Date>(this.filter.invoiceDateRange[0], this.filter.invoiceDateRange[1]);
              }
              if (this.searchCriterias[i].startsWith("dateOption=") && this.searchCriterias[i] != "dateOption=") {
                this.filterDateRangeOption = this.searchCriterias[i].replace("dateOption=", "");
              }
              if(this.searchCriterias[i].startsWith("track1") && this.searchCriterias[i] != "track1="){
                if(this.searchCriterias[i] == "track1=Unassigned tracking") {
                  this.isTrackingAssigned = true;
                  this.selectedTrackingOptions1.push(this.searchedTrackingOprions1.find(x => x.name.toLowerCase() === "unassigned"));
                  this.filterTrackingOptions1 = this.selectedTrackingOptions1;
                } else {
                  this.selectedTrackingOptions1.push(this.searchedTrackingOprions1.find(x => x.name.toLowerCase() === this.searchCriterias[i].toLowerCase().replace("track1=","").replace('%26', '&')));
                  this.filterTrackingOptions1 = this.selectedTrackingOptions1;
                }
              }
            }
          }
          this.isSpinnerShown = false;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          this.isSpinnerShown = false;
        }
      );
    }
    updateDirty(){
      this.isDirty = true;
    }

    pinnedFilter(){
      this.isPinned = !this.isPinned;

      let filter = {
        id: this.filterId,
        name: this.filterName,
        type: 0,
        isPinned: this.isPinned,
        searchCriteria: this.filterUrlPattern,
        createdBy:this.userDetails.id,
        createdDate: new Date(),
        filterPageType: 3,
        organizationId: this._organizationService.selectedOrganization
      } as ReportFilter;

      this._filterService.SaveFilter("", filter)
      .pipe(take(1))
      .subscribe(
        (createdFilter: string) => {
        },
        (e: Error) => {
        }
      );
    }

    deleteFilter(){
      this._filterService.DeleteFilter('Filter/DeleteFilter',$("#hdFilterId").val())
      .pipe(take(1))
      .subscribe(
        () => {
          this.deleteEvent.emit();
          this.CloseFilterSidebar();
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Filter deleted!');
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
    }

    onCheckChange(event) {
      if(event.target.checked) {
        this.updateFilter('tags', "Unassigned tags");
      }
      else {
        this.updateFilter('tags', "");
      }
      this.isDirty=true;
    }

    onTrackCheckChange(event) {
      if(event.target.checked) {
        this.updateFilter('trackingOptions1', "Unassigned tracking");
      }
      else {
        this.updateFilter('trackingOptions1', "");
      }
      this.isDirty=true;
    }




  fileObj:any;
  isFileProcessing:boolean=false;

  added(event: Event) {
   // this.loading = true;
    const [uploadedFile] = Array.from((event.target as HTMLInputElement).files as FileList);

    this.fileObj={name:uploadedFile.name,file:uploadedFile};
    // this.relatedName=fileObj.file.name;
    // this.fileObj=fileObj;
    // this.relatedID =null;
    //this.sendFile();

  }


  dropped(uploadedFiles: NgxFileDropEntry[]) {

    const fileEntry = uploadedFiles[0].fileEntry.isFile ? uploadedFiles[0].fileEntry as FileSystemFileEntry : null;
    //let  fileObj:any;//={name:fileEntry.name,file:fileEntry};
    fileEntry?.file((files: File) => {
      this.fileObj={name:files.name,file:files};
      console.log(this.fileObj)
      // this.fileObj=fileObj;
      // this.relatedName=fileObj.file.name;
      // this.relatedID =null;
       //this.sendFile();
    });
  }

  async sendFile(){
    this.isFileProcessing=true;
    // setTimeout(() => {
    //   this.isFileProcessing=false;
    // }, 5000);
    this.isSpinnerShown = true;
    console.log(this._organizationService.selectedOrganization,"Selected Organization Id",this._organizationService.selectedOrganizationId)
    let data:any =await this._filterService.uploadExcel({ organisationId: this._organizationService.selectedOrganization, invoice: this.fileObj.file, recordId: "", isBankTransMode:true });
    this.isFileProcessing=false;
    if(data.status==1)
    {
       this.importData={success:[],error:[],type:'success',active:true};
       this.isSpinnerShown = false;
       this.Source="Spreadsheet";
       data.data.forEach((val)=>{
        val.transactionDate=val.trannsactionDate;
        if(val.importStatus=="success")
        {
          val.isImport=true;
          this.importData.success.push(val);
        }
        else{
          this.importData.error.push(val);
        }
       })

       this.imprtSuccessGrp= this.groupRecord(this.importData.success);
       this.imprtSuccessGrp.forEach(element => {
        element.ischeck=true;
       });
       this.imprtErrorGrp= this.groupRecord(this.importData.error);
      // this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Data Upload Successfully...');
      // this.CloseFilterSidebar();

      // this.updateInvoices.emit("upload");
    }
    else{
      this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', 'Data Upload Fail...');
    }



    // this._recognizedInvoiceService.invoiceState$.pipe(take(1))
    //     .subscribe(
    //       (invoice: any) => {
    //         this.relatedID =invoice.id;
    //         this.relatedName=this.fileObj.file.name;
    //         this.uploadedFile.emit({name:this.fileObj.file.name,id:invoice.id});
    //         this.isFileProcessing=false;
    //       },
    //       (e: Error) => {

    //         this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
    //         this.isFileProcessing=false;
    //       }
    //     );
  }

  async uploadImportExcel(){
    this.isFileProcessing=true;
    this.isSpinnerShown = true;
    let uploadData= this.importData.success.filter((obj) => obj.isImport);

    let data:any =await this._filterService.uploadImportExcel({ organisationId: this._organizationService.selectedOrganization, userid:Number(sessionStorage.UserId), data: JSON.stringify(uploadData), Source:this.Source,recordId: "", isBankTransMode:true });
    this.isFileProcessing=false;
    if(data.status==1)
    {
      this.isSpinnerShown = false;
      this.importData={success:[],error:[],type:'success',active:false};
      this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Data Upload Successfully...');

      this.CloseFilterSidebar();


      if(this.Source=="Report"){
        let bankPart = this.filter.accounts?.length > 0 ? this.filter.accounts?.map(s => `${s},`).join('').slice(0, -1) : '';
        let str :string = JSON.stringify({ 'account':bankPart.substring(0,bankPart.length),'DateRangeSelcted':this.DateRangeSelcted});
        this.updateInvoices.emit(str);
      }
      else {
        this.updateInvoices.emit("upload");
      }
    }
    else{
      this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', 'Data Upload Fail...');
    }

  }

}
