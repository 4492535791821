import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { InvoiceActivity } from '../../models/activity/activity';
import { UserService } from 'src/app/data/service/user.service';
import { NotesComponent } from '../notes/notes.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { take } from 'rxjs/operators';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';
import {
  InvoiceShort,
  InvoiceUnReconcileShort,
} from '../../models/invoice/invoice-short';
import { UnreconciledReport } from '../../models/invoice/unreconciled-report';
import { CreatedInvoiceWithLines } from '../../models/invoice/created-invoice-with-lines';
import { CreditNoteDTO } from '../../models/credit-note/credit-note';
import { BankTransactionDTO } from '../../models/expense/bank-transactions';
import { InvoiceLine } from '../../models/invoice/invoice-line';
import { Subscription } from 'rxjs';
import { AccountingIntegration } from 'src/app/data/enums/accounting-integration';
import { Invoice } from '../../models/invoice/invoice';
import { PaymentAccount } from '../../models/account/payment-account';
import { Pannels } from '../../enums/sidebar-pannels';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent extends BaseComponent implements OnInit {
  @Output() moveBack = new EventEmitter<void>();
  @Output() deleteTrans = new EventEmitter<string>();
  @Output() assignTransa = new EventEmitter<object>();
  @Output() transNotes = new EventEmitter<object>();
  @Output() refreshDataOnMatch = new EventEmitter<object>();
  @Input() supplierName: string = '';
  @Input() invoiceId: string = null;
  @Input() supplierId: string = null;
  @Input() isInvoiceMode: boolean = true;
  @Input() invoiceColorNumber: number = 1;
  @Input() isBankTransMode: boolean = false;
  @Input() bankTransArr: any = [];
  currentPannel: Pannels = Pannels.DetailsTab;
  noteType: string = '';
  recordId: string = '';
  showToolTipDelay: number = 400;
  activities = [] as InvoiceActivity[];
  isSpinnerShown: boolean = false;
  isActivityExpanded: boolean = true;
  isNoteExpanded: boolean = true;
  organizationId: string = null;
  usersList: any[] = [];
  unreconciledData: InvoiceUnReconcileShort[] = [];
  reconcileData: InvoiceUnReconcileShort;
  isMatchSelected: boolean = false;
  isFileUploadProcessing: boolean = false;
  IsQuickBook: boolean = false;
  IsNoAccounting: boolean = false;
  curIdx: number = 0;
  shownCategoriesInvoiceId: number = 1;
  subscriptions: Subscription[] = [];
  panels = Pannels;
  invoice: Invoice = new Invoice(
    this._organizationService.selectedOrganization
  );
  creditNote: CreditNoteDTO = new CreditNoteDTO(
    this._organizationService.selectedOrganization
  );
  bankTransation: BankTransactionDTO = new BankTransactionDTO(
    this._organizationService.selectedOrganization
  );
  // row ={
  //   rowCount:1,supplierName:'Demo Company (AU)',hexColorClass:"sup_img_box_1",shortCode:"JD",isDemo:false,contactStatus:true,invoiceNumber:'123456',accountName:"Donations",total:123,amountDue:234,date:new Date('2024-03-12'),submissionMethod:3
  // }
  invoiceLines: any = [];
  lineItemList = [];
  isStatusChg: boolean = false;
  noteMentions: any[] = [];
  bankAccountList: PaymentAccount[] = [];
  type: string = '';
  @ViewChild('noteinput') noteinput: ElementRef;
  @ViewChild('commentModel') commentModel: ModalDirective;
  constructor(
    private readonly _invoiceService: InvoiceService,
    private readonly _toasterMessageService: ToasterMessageService,
    private readonly _organizationService: OrganizationService,
    private readonly _supplierService: SuppliersService,
    private _userService: UserService,
    private confirmationDialogService: ConfirmationDialogService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private router: Router
  ) {
    super();
  }
  @ViewChild(NotesComponent) notesComponent: NotesComponent;
  @HostListener('click', ['$event'])
  onClick(event): void {
    this.notesComponent?.hideEmoji(event);
  }
  ngOnInit(): void {
    this.setIsQuickBookOrNot();
    this.GetBankAcoountDetails();
    if (this.isInvoiceMode) {
      this.isSpinnerShown = true;

      this._invoiceService
        .getInvoiceActivityById(
          this.invoiceId,
          this._organizationService.selectedOrganization
        )
        .pipe(this.untilThis)
        .subscribe(
          (data: InvoiceActivity[]) => {
            this.activities = this.activities.concat(data);
            this.isSpinnerShown = false;

            let actors = [] as string[];
            this.activities?.forEach((element) => {
              if (actors?.length == 0) {
                actors.push(element.actor);
              } else if (!actors.includes(element.actor)) {
                actors.push(element.actor);
              }
              element.visible = false;
            });

            if (actors && actors.length > 0) {
              this.activities.forEach((element) => {
                element.colorNumber = actors.indexOf(element.actor) + 1;
              });
            }
          },
          (e: Error) => {
            this.isSpinnerShown = false;
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    } else {
      this.isSpinnerShown = true;
      this._supplierService
        .getSupplierActivityById(
          this._organizationService.selectedOrganization,
          this.supplierId
        )
        .pipe(this.untilThis)
        .subscribe(
          (data) => {
            this.isSpinnerShown = false;
            this.activities = this.activities.concat(
              data.filter((a) => a.date != null)
            );
          },
          (e: Error) => {
            this.isSpinnerShown = false;
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }
    if (this.isBankTransMode) {
      this.organizationId = this._organizationService.selectedOrganization;
      this.isSpinnerShown = true;

      this.activities = this.bankTransArr;

      this.invoiceId = this.activities[this.curIdx]['unreconciledReportId'];

      this.getRelatedData();

      // .pipe(this.untilThis)
      // .subscribe(
      //   (data: InvoiceActivity[]) => {

      // let actors = [] as string[];
      // this.activities?.forEach(element => {
      //   if (actors?.length == 0) {
      //     actors.push(element.actor);
      //   } else if(!actors.includes(element.actor)) {
      //     actors.push(element.actor);
      //   }
      //   element.visible = false;
      // });

      // if (actors && actors.length > 0) {
      //   this.activities.forEach(element => {
      //     element.colorNumber = actors.indexOf(element.actor) + 1;
      //   });
      // }
      // },
      // (e: Error) => {
      //   this.isSpinnerShown = false;
      //   this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
      // });
    }

    this.setNoteType();
  }
  setIsQuickBookOrNot() {
    this.subscriptions.push(
      this._organizationService.organizationList.subscribe((data) => {
        const org = data?.find(
          (o) =>
            o.organisationID === this._organizationService.selectedOrganization
        );
        this.IsQuickBook =
          org?.accountingIntegration === AccountingIntegration.QBO
            ? true
            : false;
        this.IsNoAccounting =
          org?.accountingIntegration === AccountingIntegration.None
            ? true
            : false;
      })
    );
  }
  onScroll(event: any) {
    if (document.querySelector('#activitydiv').scrollTop < 70) {
      // document.getElementsByClassName("transIcon")[0].style.top=20;
      const highlights = document.getElementsByClassName('transIcon');
      for (let i = 0; i < highlights.length; i++) {
        const element = highlights[i] as HTMLElement;
        element.style.top =
          16 - document.querySelector('#activitydiv').scrollTop + 'px';
      }
    }
  }

  getRelatedData() {
    if (this.activities[this.curIdx]['relatedID'] != '') {
      // this.relatedName=fileObj.file.name;
      this.relatedID = this.activities[this.curIdx]['relatedID'];
      this.relatedName = this.activities[this.curIdx]['relatedName'];

      this._invoiceService
        .getRelatedData(
          this._organizationService.selectedOrganization,
          this.activities[this.curIdx]['relatedID']
        )
        .subscribe((data: any) => {
          this.isSpinnerShown = false;
          this.invoiceLines = data;
          this.isFileUploadProcessing = false;
          this.prepareProcessedInvoices(this.invoiceLines);
          if (data.length > 0) {
            // this.activities[this.curIdx]['status'] =
            //   this.invoiceLines[0].amountDue +
            //     this.activities[this.curIdx]['amount'] ==
            //   0
            //     ? 'Actioned'
            //     : this.activities[this.curIdx]['status'];
            this.activities[this.curIdx]['ischeck'] =
              this.invoiceLines[0].amountDue +
                this.activities[this.curIdx]['amount'] ==
              0
                ? false
                : true;
          }
          if (this.isBankTransMode) {
            this._invoiceService
              .getMatchData(
                this._organizationService.selectedOrganization,
                this.activities[this.curIdx]['unreconciledReportId']
              )
              .subscribe((data: any) => {
                this.isSpinnerShown = false;
                this.unreconciledData = data;
                this.prepareProcessedInvoices(this.unreconciledData);
                console.log(this.unreconciledData);
              });
          }
        });
    } else {
      this.isSpinnerShown = false;
    }
  }

  private prepareProcessedInvoices(array: any) {
    let count = 1;
    array.forEach((element) => {
      let stringToSplit = element.supplierName;

      if (stringToSplit) {
        let x = stringToSplit.split(' ');
        if (x.length == 1) {
          element.shortCode = x[0].charAt(0);
        }
        if (x.length == 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0);
        }
        if (x.length > 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0) + x[2].charAt(0);
        }
        if (x.length == 0) {
          element.shortCode = 'NA';
        }
      }
      element.visible = true;
      element.selected = false;
      element.rowCount = count;
      count = count + 1;
      if (count == 7) {
        count = 1;
      }
    });
  }

  changeTransaction(type: string) {
    if (type == 'prev' && this.curIdx == 0) {
      return;
    } else if (type == 'next' && this.curIdx == this.activities.length - 1) {
      return;
    }

    this.isNoteExpanded = false;
    this.recordId = '';
    this.isSpinnerShown = true;
    setTimeout(() => {
      if (type == 'prev') {
        this.curIdx = this.curIdx == 0 ? 0 : this.curIdx - 1;
        this.recordId = this.activities[this.curIdx]['unreconciledReportId'];
      } else if (type == 'next') {
        this.curIdx =
          this.curIdx == this.activities.length - 1
            ? this.curIdx
            : this.curIdx + 1;
        this.recordId = this.activities[this.curIdx]['unreconciledReportId'];
      }
      this.isNoteExpanded = true;
      this.isSpinnerShown = false;
      this.getRelatedData();
    });
  }

  setNoteType() {
    this.recordId = this.invoiceId;
    this.noteType = 'Invoice';
    if (this.router.url.toLowerCase().indexOf('suppliers') > 0) {
      this.noteType = 'Contact';
      this.recordId = this.supplierId;
    }
    if (this.isBankTransMode) {
      this.noteType = 'unmatched';
      //this.recordId='15e3f792-64ba-4b10-96a7-288651214740';
    }
  }
  moveBackClick() {
    this.moveBack.emit();
  }

  datePipe: DatePipe = new DatePipe(this.getNavigatorLanguage());
  getFormattedDate(date: Date) {
    var createdDate = this.datePipe.transform(
      date + 'Z',
      'dd/MM/yy',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      this.getNavigatorLanguage()
    );
    var todaydate = new Date();
    var currentDate = this.datePipe.transform(todaydate, 'dd/MM/yy');
    if (createdDate == currentDate) {
      return (
        'Today at ' +
        this.datePipe.transform(
          date + 'Z',
          'h:mm a',
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          this.getNavigatorLanguage()
        )
      );
    } else {
      return (
        createdDate +
        ' at ' +
        this.datePipe.transform(
          date + 'Z',
          'h:mm a',
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          this.getNavigatorLanguage()
        )
      );
    }
  }

  getNavigatorLanguage() {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return 'en-US';
    }
  }

  removeTrans() {
    this.confirmationDialogService
      .confirm(
        'Confirm',
        'Do you want to delete this transaction?',
        'Yes',
        'No',
        ''
      )
      .then((confirm) => {
        if (confirm) {
          let data = { Action: 'removetrans' };
          this.saveTransactionStatus(data);
        } else {
          // this.DeleteSavedReport();
        }
      })
      .catch(() =>
        console.log(
          'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
        )
      );

    // if(confirm("Are you sure to delete tranaction?")){
    //   let data ={Action:'removetrans'}

    //   this.saveTransactionStatus(data);

    // }
  }
  saveTransactionStatus(data) {
    (data.AssignedBy = sessionStorage.Id),
      (data.UnreconciledReportId =
        this.activities[this.curIdx]['unreconciledReportId']),
      this._organizationService
        .saveTransactionStatus(data)
        .subscribe((result: any) => {
          if (result.status == 1) {
            if (data.Action == 'removetrans') {
              this.deleteTrans.emit(
                this.activities[this.curIdx]['unreconciledReportId']
              );
            }
            this.activities.splice(this.curIdx);
            this.curIdx =
              this.activities.length > this.curIdx || this.curIdx == 0
                ? this.curIdx
                : this.curIdx - 1;
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Success,
              'Success',
              result.Message
            );
          } else {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              result.Message
            );
          }
        });
  }
  assignTrans(obj: any) {
    this.assignTransa.emit(obj);
  }
  transNote(obj: any) {
    this.transNotes.emit(obj);
  }

  fileObj: any;
  uploadFile(file: any) {
    this.fileObj = file;
    this.activities[this.curIdx]['relatedID'] = file.id;
    this.activities[this.curIdx]['relatedName'] = file.name;
  }

  added(event: Event) {
    // this.loading = true;
    const [uploadedFile] = Array.from(
      (event.target as HTMLInputElement).files as FileList
    );

    let fileObj = { name: uploadedFile.name, file: uploadedFile };
    this.relatedName = fileObj.file.name;
    this.fileObj = fileObj;
    this.relatedID = null;
    this.isFileUploadProcessing = true;
    this.sendFile();
  }
  relatedID: string = null;
  relatedName: string = 'Test';
  uploadedFile: any;
  isFileProcessing: boolean = false;
  dropped(uploadedFiles: NgxFileDropEntry[]) {
    const fileEntry = uploadedFiles[0].fileEntry.isFile
      ? (uploadedFiles[0].fileEntry as FileSystemFileEntry)
      : null;
    let fileObj: any;
    fileEntry?.file((files: File) => {
      fileObj = { name: files.name, file: files };
      this.fileObj = fileObj;
      this.relatedName = fileObj.file.name;
      this.isFileUploadProcessing = true;
      this.relatedID = null;
      this.sendFile();
    });
  }

  async sendFile() {
    this.isFileProcessing = true;
    this.isFileUploadProcessing = true;
    // setTimeout(() => {
    //   this.isFileProcessing=false;
    // }, 5000);
    await this._invoiceService.uploadInvoice({
      organisationId: this._organizationService.selectedOrganization,
      invoice: this.fileObj.file,
      recordId: this.recordId,
      isBankTransMode: this.isBankTransMode,
    });

    this._recognizedInvoiceService.invoiceState$.pipe(take(1)).subscribe(
      (invoice: any) => {
        this.relatedID = invoice.id;
        this.relatedName = this.fileObj.file.name;
        this.uploadFile({ name: this.fileObj.file.name, id: invoice.id });
        this.isFileUploadProcessing = false;
        var model = {
          unreconciledReportIds: this.invoiceId,
          invoiceId: this.relatedID,
          organizationId: this._organizationService.selectedOrganization,
        };
        this._invoiceService
          .updateSuggestedMatches(model)
          .pipe(take(1))
          .subscribe(() => {
            this.getRelatedData();
            this.refreshDataOnMatch.emit();
          });
      },
      (e: Error) => {
        this._toasterMessageService.displayToasterState(
          ToasterTypes.Error,
          'Error',
          e.message
        );
        // this.isFileProcessing=false;
      }
    );
  }
  changeSelectedMatch(event, id) {
    if (!event) {
      this.reconcileData = new InvoiceUnReconcileShort();
      this.unreconciledData.forEach((num, index) => {
        this.unreconciledData[index].visible = false;
        this.unreconciledData[index].selected = false;
      });
      this.unreconciledData.find((x) => x.id == id).visible = true;
      this.unreconciledData.find((x) => x.id == id).selected = !event;
      if (this.unreconciledData.findIndex((x) => x.id == id) > -1) {
        this.isMatchSelected = true;
        this.reconcileData = this.unreconciledData.find((x) => x.id == id);
      }
    } else {
      this.reconcileData = new InvoiceUnReconcileShort();
      this.unreconciledData.forEach((num, index) => {
        this.unreconciledData[index].visible = true;
        this.unreconciledData[index].selected = false;
      });
      this.isMatchSelected = false;
    }
  }

  matchTransaction() {
    var model = {
      unreconciledReportIds: this.reconcileData.unreconciledReportId,
      invoiceId: this.reconcileData.id,
      organizationId: this._organizationService.selectedOrganization,
    };
    this._invoiceService
      .updateSuggestedMatches(model)
      .pipe(take(1))
      .subscribe(
        () => {
          this.isMatchSelected = false;
          
          debugger;
          if (!this.reconcileData.isProcessed && this.reconcileData.required_Fields) {
            this._getInvoice(this.reconcileData.id);
          }
          else {
            this.getRelatedDataMatch();
          }
          this.refreshDataOnMatch.emit();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(
            ToasterTypes.ErrorInbox,
            '',
            `Error!`
          );
        }
      );
  }

  private _getInvoice(id) {
    if (id) {
      this._invoiceService
        .getInvoiceById(id)
        .pipe(take(1))
        .subscribe(
          (invoice: Invoice) => {
            debugger;
            this.invoice = invoice;
            this.handleInvoiceType();
            this.publishInvoice();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }
  }

  private handleInvoiceType() {
    if (this.invoice.type !== null) {
      let invoiceType = this.invoice.type.toString();
      if (invoiceType == 'Credit Note' || invoiceType == 'ACCPAYCREDIT') {
        this.type = 'ACCPAYCREDIT';
      } else if (invoiceType == 'Supplier Invoice' || invoiceType == 'ACCPAY') {
        this.type = 'ACCPAY';
      } else if (invoiceType == 'Expense Claim' || invoiceType == 'EXPCLAIMS') {
        this.type = 'EXPCLAIMS';
      } else if (invoiceType == 'Receipt' || invoiceType == 'EXP') {
        this.type = 'EXP';
      }
    } else {
      this.type = 'ACCPAY';
    }
  }

  publishInvoice() {
    if (this.type == 'ACCPAYCREDIT') {
      this._invoiceService
        .publishCreditNote(this.getCreditNoteObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {}
        );
    } else if (this.type == 'EXP') {
      this.publishReceipt();
    } else {
      this._invoiceService
        .publishInvoice(this.getInvoiceObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }
  }

  getRelatedDataMatch() {
    this._invoiceService
            .getRelatedData(
              this._organizationService.selectedOrganization,
              this.reconcileData.id.toString()
            )
            .subscribe((data: any) => {
              this.isSpinnerShown = false;
              this.invoiceLines = data;
              console.log(this.invoiceLines);
              this.prepareProcessedInvoices(this.invoiceLines);
              if (data.length > 0) {
                // this.activities[this.curIdx]['status'] =
                //   this.invoiceLines[0].amountDue +
                //     this.activities[this.curIdx]['amount'] ==
                //   0
                //     ? 'Actioned'
                //     : this.activities[this.curIdx]['status'];
                this.activities[this.curIdx]['ischeck'] =
                  this.invoiceLines[0].amountDue +
                    this.activities[this.curIdx]['amount'] ==
                  0
                    ? false
                    : true;
                this.activities[this.curIdx]['relatedID'] =
                  this.reconcileData.id;
              }
            });
  }

  publishReceipt() {
    if (this.IsQuickBook) {
      this._invoiceService
        .publishReceiptQBO(this.getReceiptObjectQBO())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    } else {
      this._invoiceService
        .publishReceipt(this.getReceiptObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }
  }

  getInvoiceObject(isSaving: boolean = false): CreatedInvoiceWithLines {
    let invoice = new CreatedInvoiceWithLines();
    invoice.amountDue = this.invoice.amountDue ?? 0;
    invoice.currency = this.invoice.currency;
    invoice.date = this.invoice.date;
    invoice.dueDate = isSaving ? this.invoice.dueDate : !this.invoice.dueDate ? this.invoice.date : this.invoice.dueDate;
    invoice.supplierName = this.invoice.supplierName;
    invoice.supplierId = this.invoice.supplierId;
    (invoice.id = this.invoice.id.toString() ?? ''),
      (invoice.invoiceNumber = this.invoice.invoiceNumber);
    invoice.organisationId =
      this.invoice.organisationId ??
      this._organizationService.selectedOrganization;
    invoice.paymentStatus = this.invoice.paymentStatus;
    invoice.pdfUrl = this.invoice.pdfUrl;
    invoice.subTotal = this.invoice.subTotal ?? 0;
    invoice.totalTax = this.invoice.totalTax ?? 0;
    invoice.type = this.invoice.type;
    invoice.invoiceLines = this.invoice.invoiceLines;
    invoice.InvoiceOrCreditNote = this.invoice.type;
    invoice.paidBy = this.invoice.paidBy;
    invoice.expenseReportId = this.invoice.expenseReportId;
    invoice.expenseReportName = this.invoice.expenseReportName;
    invoice.paymentAccountNumber = this.invoice.paymentAccountNumber;
    invoice.paymentDate = this.invoice.paymentDate;
    invoice.unreconciledReportIds = this.invoiceId;
    if (invoice.invoiceLines?.length > 0) {
      for (let line of invoice.invoiceLines) {
        line.organisationId =
          this.invoice.organisationId ??
          this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }

    return invoice;
  }

  getCreditNoteObject(isSaving: boolean = false): CreditNoteDTO {
    let creditNote = new CreditNoteDTO(
      this._organizationService.selectedOrganization
    );
    Object.keys(creditNote).reduce(
      (a, k) => ((a[k] = this.creditNote[k]), a),
      creditNote
    );
    creditNote.contact = {
      contactID: this.invoice.supplierId,
      name: this.invoice.supplierName,
      status: 'ACTIVE',
    };
    creditNote.invoiceID = this.invoice.id;
    creditNote.date = this.invoice.date;
    creditNote.subTotal = this.invoice.subTotal;
    creditNote.totalTax = this.invoice.totalTax;
    creditNote.total = this.invoice.amountDue;
    creditNote.lineItems = this.lineItemList;
    creditNote.PdfUrl = this.invoice.pdfUrl;
    creditNote.creditNoteID = this.invoice.id.toString();
    creditNote.InvoiceOrCreditNote = 'Credit Note';
    creditNote.currency = this.invoice.currency;
    creditNote.currencyCode = this.invoice.currency;
    creditNote.invoiceNumber = this.invoice.invoiceNumber;
    creditNote.unreconciledReportIds = this.invoiceId;
    return creditNote;
  }

  getReceiptObject(isSaving: boolean = false): BankTransactionDTO {
    let bankTransaction = new BankTransactionDTO(
      this._organizationService.selectedOrganization
    );
    Object.keys(bankTransaction).reduce(
      (a, k) => ((a[k] = this.bankTransation[k]), a),
      bankTransaction
    );

    let currentPaymentFrom: any = this.bankAccountList.find(
      (x) => x.accountID == this.invoice.paymentAccountNumber
    );
    bankTransaction.bankAccount = {
      accountID: this.invoice?.accountID,
      name: this.invoice?.accountName,
      type:
        currentPaymentFrom?.type == 'CREDITCARD'
          ? 'BANK'
          : currentPaymentFrom?.type,
      currencyCode: this.invoice.currency,
      bankAccountNumber: currentPaymentFrom?.bankAccountNumber,
    };
    bankTransaction.contact = {
      contactID: this.invoice.supplierId,
      name: this.invoice.supplierName,
      status: 'ACTIVE',
    };
    bankTransaction.date = this.invoice.date;
    bankTransaction.subTotal = this.invoice.subTotal;
    bankTransaction.totalTax = this.invoice.totalTax;
    bankTransaction.total = this.invoice.amountDue;
    bankTransaction.PdfUrl = this.invoice.pdfUrl;
    bankTransaction.invoiceId = this.invoice.id.toString();
    bankTransaction.InvoiceOrCreditNote = 'Receipt';
    bankTransaction.type = DocumentType['Receipt'];
    bankTransaction.currency = this.invoice.currency;
    bankTransaction.currencyCode = this.invoice.currency;
    bankTransaction.invoiceNumber = this.invoice.invoiceNumber;
    bankTransaction.paymentAccountNumber = this.invoice.paymentAccountNumber;
    bankTransaction.paymentStatus = this.invoice.paymentStatus;
    bankTransaction.lineItems = this.invoice.invoiceLines;
    bankTransaction.unreconciledReportIds = this.invoiceId;
    if (bankTransaction.lineItems?.length > 0) {
      for (let line of bankTransaction.lineItems) {
        line.accountName = '';
        line.organisationId =
          this.invoice.organisationId ??
          this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }
    return bankTransaction;
  }

  getReceiptObjectQBO(isSaving: boolean = false): CreatedInvoiceWithLines {
    let invoice = new CreatedInvoiceWithLines();
    invoice.amountDue = this.invoice.amountDue ?? 0;
    invoice.currency = this.invoice.currency;
    invoice.date = this.invoice.date;
    invoice.dueDate = isSaving ? this.invoice.dueDate : !this.invoice.dueDate ? this.invoice.date : this.invoice.dueDate;
    invoice.supplierName = this.invoice.supplierName;
    invoice.supplierId = this.invoice.supplierId;
    (invoice.id = this.invoice.id.toString() ?? ''),
      (invoice.invoiceNumber = this.invoice.invoiceNumber);
    invoice.organisationId =
      this.invoice.organisationId ??
      this._organizationService.selectedOrganization;
    invoice.paymentStatus = this.invoice.paymentStatus;
    invoice.pdfUrl = this.invoice.pdfUrl;
    invoice.subTotal = this.invoice.subTotal ?? 0;
    invoice.totalTax = this.invoice.totalTax ?? 0;
    invoice.type = this.invoice.type;
    invoice.invoiceLines = this.invoice.invoiceLines;
    invoice.InvoiceOrCreditNote = this.invoice.type;
    invoice.paidBy = this.invoice.paidBy;
    invoice.expenseReportId = this.invoice.expenseReportId;
    invoice.expenseReportName = this.invoice.expenseReportName;
    invoice.paymentAccountNumber = this.invoice.paymentAccountNumber;
    invoice.paymentDate = this.invoice.paymentDate;
    invoice.unreconciledReportIds = this.invoiceId;
    if (invoice.invoiceLines?.length > 0) {
      for (let line of invoice.invoiceLines) {
        line.organisationId =
          this.invoice.organisationId ??
          this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }

    return invoice;
  }

  GetBankAcoountDetails() {
    this._organizationService
      .GetBankAccountDetails(
        'Organisation/GetBankAccountDetails',
        this._organizationService.selectedOrganization
      )
      .subscribe(
        (response: any) => {
          if (response.status == 0) {
            this.bankAccountList = response.data;
            if (this.bankAccountList?.length > 0) {
              this.bankAccountList.forEach((payAcc) => {
                payAcc.accountID = payAcc.accountID.toLowerCase();
              });
            }
          }
        },
        (err) => {
          this.bankAccountList = [];
          this._toasterMessageService.displayToasterState(
            ToasterTypes.Error,
            'Error',
            err.message
          );
        }
      );
  }

  tabClick(tab: Pannels, isDefaultShow: boolean) {
    switch (tab) {
      case Pannels.DetailsTab:
        this.currentPannel = Pannels.DetailsTab;
        break;
      case Pannels.ActivityTab:
        this.showActivityPanel(isDefaultShow);
        break;
      default:
        this.currentPannel = Pannels.DetailsTab;
        break;
    }
  }

  showActivityPanel(isDefaultShow: boolean) {
    if (!isDefaultShow) {
      if (this.currentPannel == Pannels.ActivityTab) {
        this.currentPannel = Pannels.DetailsTab;
      } else {
        this.currentPannel = Pannels.ActivityTab;
      }
    }
  }
}
