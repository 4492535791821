<div class="key-value-container">
  <div class="content">
    <h2 class="title gray" *ngIf="!invoicePairSet.length">There is no standard invoce value set</h2>
    <h2 class="title" *ngIf="invoicePairSet.length">Standard invoce value set</h2>
    <table class="table table-hover" *ngIf="invoicePairSet.length">
      <tbody>
          <tr *ngFor="let pair of invoicePairSet">
              <td class="w-25">{{pair.key}}</td>
              <td>{{pair.value ? pair.value : 'null'}}</td>
          </tr>
      </tbody>
  </table>
  <h2 class="title gray" *ngIf="!pairSet.length">There is no general value set</h2>
  <h2 class="title" *ngIf="pairSet.length">General value set</h2>
  <table class="table table-hover" *ngIf="pairSet.length">
    <tbody>
        <tr *ngFor="let pair of pairSet">
            <td>{{pair.key}}</td>
            <td>{{pair.value ? pair.value : 'null'}}</td>
        </tr>
    </tbody>
</table>
  </div>
</div>
