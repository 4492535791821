import { AbstractControl, ValidationErrors } from "@angular/forms"

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  let message: string = '<ul class="square1">';
  let isValid:boolean= true;
  if (!value) {
    return null;
  }
    
  let upperCaseCharacters = /[A-Z]+/g
  if (upperCaseCharacters.test(value) === false) {
    isValid = false;
    message += `<li class="red">Password must have atleast one Uppercase letter (A-Z)`;
  }

  let lowerCaseCharacters = /[a-z]+/g
  if (lowerCaseCharacters.test(value) === false) {
    isValid = false;
    message += `<li class="red">Password must have atleast one Lowercase letter (a-z)`;
  }


  let numberCharacters = /[0-9]+/g
  if (numberCharacters.test(value) === false) {
    isValid = false;
    message += `<li class="red">Password must have atleast one digit (0-9)`;
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (specialCharacters.test(value) === false) {
    isValid = false;
    message += `<li class="red">Password must have atleast one special character (~!@#$%&*_)`;
  }
  if (value.length < 9 || value.length > 125) {
    isValid = false;
    message += `<li class="red">Password must be at least 8 character length`;
  }
  if (isValid == true) {
    return null;
  }
  else {
    return { passwordStrength: message };
  }
}
