import { Component,Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from "jquery";
@Component({
  selector: 'app-report-comments',
  templateUrl: './report-comments.component.html',
  styleUrls: ['./report-comments.component.scss']
})
export class ReportCommentsComponent implements OnInit {
  Comments: any;
  IsShowSaveButton : boolean;
  
  public showclosebutton: boolean = false;
  public showsavebutton: boolean = false;
  public IsShowcommentsButton: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<ReportCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){   
      this.Comments = data.Comments;
       this.IsShowSaveButton = data.IsShowSaveButton;
  }
  
  ngOnInit(): void {

 if(this.Comments == null || this.Comments == "")
 {
   this.Comments = "";
  
 }

 if(this.IsShowSaveButton == true)
 {
  this.showsavebutton = true;
  this.showclosebutton = false;
  $("#CommentDetails").show();
  $("#CommentDetails").val(this.Comments);
 }
 else
 {
   this.showclosebutton = true;
   this.showsavebutton = false;
  this.IsShowcommentsButton = true;
  $("#showCommentDetails").show();
  $("#showCommentDetails").text(this.Comments);
 }
  
 
  }
  Selectyes() {
    sessionStorage.SelectedValue = "Yes";
    sessionStorage.CommentDetails = $("#CommentDetails").val();
    this.dialogRef.close();
  }
  Selectno() {
    sessionStorage.SelectedValue = "No";
    this.dialogRef.close();
  }
}
