import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { Tag } from 'src/app/data/model/tag';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ReportService } from 'src/app/data/service/report.service';
import { TagsService } from 'src/app/data/service/tags.service';
import { ConfirmationDialogService } from 'src/app/shared/confirmation-dialog/confirmation-dialog.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { Invoice } from '../../models/invoice/invoice';
import { RelatedData, RelatedDataRequest, ApprovalUser } from '../../models/related/related';
import { ContactInvoiceShortData } from '../../models/related/related-contact';
import { TagToInvoices } from '../../models/tag/tag-to-invoice';
import { RelatedDataService } from '../../services/related-data.service';

@Component({
  selector: 'app-related',
  templateUrl: './related.component.html',
  styleUrls: ['./related.component.scss']
})
export class RelatedComponent extends BaseComponent implements OnInit {
  @Input() invoice: Invoice = null;
  @Input() supplierId: string = null;
  @Input() billsToPayMode: boolean = false;
  @Input() invoiceColorNumber: number = 1;
  @Input() hexColorClass: string = "";
  @Output() showContactForm = new EventEmitter<boolean>(); 
  @Output() clickInvoiceEvent = new EventEmitter<string>(); 

  isCompanyExpanded: boolean = true;
  isApprovalsExpanded: boolean = true;
  isAccountingExpanded: boolean = true;
  isApprovedPanelExpanded: boolean = false;
  isMetadataExpanded: boolean = false;
  isTagsExpanded: boolean = false;

  relatedData = {} as RelatedData;
  link: string = null;
  showToolTipDelay: number = 400;
  isSpinnerShown: boolean = false;
  tagList = [] as Tag[];
  filteredTagList = [] as Tag[];
  tagSearchTerm: string = null;
  organizationId: string = null;
  isAddTagMenuItemShown: boolean = false;
  isBillsExpanded: boolean = true;
  outstandingInvoices = [] as ContactInvoiceShortData[];
  paidInvoices = [] as ContactInvoiceShortData[];
  outstandingTotal: number = null;
  isPaidInvoiceShown: boolean = false;

  constructor(
    private readonly _relatedDataService: RelatedDataService,
    private readonly _organizationService: OrganizationService,
    private readonly _toasterMessageService: ToasterMessageService,
    private readonly _reportService: ReportService,
    private readonly _tagsService: TagsService,
    private readonly _router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.isSpinnerShown = true;
    this.organizationId = this._organizationService.selectedOrganization;

    const requestParam = {
      invoiceId: this.invoice?.id.toString(),
      invoiceReferenceId: this.invoice?.referenceId,
      organizationId: this._organizationService.selectedOrganization,
      supplierId: this.supplierId,
      userId: +localStorage.getItem('UserId')
    } as RelatedDataRequest;

    this._relatedDataService.getRelatedData(requestParam)
      .pipe(this.untilThis)
      .subscribe(
        (data: RelatedData) => {
          this.relatedData = data;
          this.isSpinnerShown = false;
          this.isTagsExpanded = this.relatedData.invoice?.tags?.length > 0;

          if (this.relatedData?.invoices?.length > 0) {
            if(this.relatedData?.organization?.isQBO){
              this.outstandingInvoices = this.relatedData?.invoices?.filter(i => i.status == 'Awaiting Payment');
            }
            else{
              this.outstandingInvoices = this.relatedData?.invoices?.filter(i => i.status == 'AUTHORISED');
            }
            this.paidInvoices = this.relatedData?.invoices?.filter(i => i.status == 'PAID');
            this.outstandingTotal = this.outstandingInvoices?.reduce((a, b) => a + b.amountDue, 0);
          }

          this.prepareAccIntLink();
        },
        (e: Error) => {
          this.isSpinnerShown = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )

      this.getOrganizationTags();
  }

  showInvoice(invoice: ContactInvoiceShortData) {
    if (this.billsToPayMode) {
      this.clickInvoiceEvent.emit(invoice.invoiceId);
    } else {
      this._router.navigate([`/payable/outstanding-amounts/${invoice.invoiceId}`], { replaceUrl: true });
    }
  }

  createNewTag() {
    this.isAddTagMenuItemShown = false;

    let tag = { 
      backColor: null, 
      textColor: null,
      tagName: this.tagSearchTerm
    } as Tag;

    tag.organizationId = this.organizationId;

    this._tagsService.createTag(tag)
      .pipe(take(1))
      .subscribe(
        (createdTag: Tag) => {
          tag = { ...createdTag };
          this.tagList = [...this.tagList, tag];
          this.filteredTagList = [...this.tagList];
          this.addTagToInvoices(tag);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  addTagToInvoices(tag: Tag) {
    if (this.relatedData.invoice.tags?.some(t => t.tagId == tag.tagId)) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'The invoice has this tag!')
      return;
    }

    let tagToInvoices = {
      invoiceIds: [this.relatedData.invoice.invoiceId],
      tagId: tag.tagId,
      billsToPayMode: this.billsToPayMode
    } as TagToInvoices;

    this._tagsService.addTagToInvoices(tagToInvoices)
      .pipe(take(1))
      .subscribe(
        () => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Tag added to selected invoices!');
          
          if (this.relatedData.invoice.tags?.length > 0) {
            this.relatedData.invoice.tags = [...this.relatedData.invoice.tags, tag];
          } else {
            this.relatedData.invoice.tags = [tag];
          }

          this.isTagsExpanded = true;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  deleteTagFromInvoice(tag: Tag) {
    this._tagsService.deleteTagFromInvoices(tag.tagId, this.invoice?.id.toString(), this.billsToPayMode)
      .pipe(this.untilThis)
      .subscribe(
        () => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', `Tag '${tag.tagName}' removed from the invoice!`);
          this.relatedData.invoice.tags = this.relatedData.invoice.tags.filter(t => t.tagId != tag.tagId);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  filterTags(event) {
    this.tagSearchTerm = event;
    this.filteredTagList = this.tagList.filter(t => t.tagName.toLowerCase().includes(this.tagSearchTerm?.toLowerCase()));
    let exactlyMatchTags = this.filteredTagList.filter(t => t.tagName.toLowerCase() == this.tagSearchTerm?.toLowerCase());

    if (exactlyMatchTags?.length == 0) {
      this.isAddTagMenuItemShown = true;
    } else {
      this.isAddTagMenuItemShown = false;
    }
  }

  clearTagSearchValue() {
    setTimeout(() => {
      this.tagSearchTerm = null;
      this.filteredTagList = this.tagList;
    }, 250);
  }

  showContactPanel() {
    this.showContactForm.emit(true);
  }

  approveInvoice() {
    let invoiceid;
    if (this.relatedData?.invoice?.accIntInvoiceId != null) {
      invoiceid = this.relatedData?.invoice?.accIntInvoiceId;
    } else {
      invoiceid = this.relatedData?.invoice?.invoiceId;
    }
    const reportDataModel =
    {
      ApproveId: "",
      OrganizationId: this._organizationService.selectedOrganization,
      InvoiceId: invoiceid,
      ApproveBy: localStorage.getItem('UpUserId'),
      ApprovedByName: localStorage.getItem('UserName'),
      ApprovalDate: new Date()
    }

    this._organizationService.InsertApprovals(reportDataModel) //checked
      .pipe(this.untilThis)
      .subscribe(data => {
        if (data.status == 1) {
          this.relatedData.approvals.approved = true;
          this.relatedData.approvals.approvedAt = reportDataModel.ApprovalDate;
          this.relatedData.approvals.approvedBy = { name: reportDataModel.ApprovedByName } as ApprovalUser;
          this.isApprovalsExpanded = true;
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, '', data.message);
        }
      }, 
      (e: Error) => {
        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
      });
  }

  sendEmailToUser(user: ApprovalUser) {
    let invoiceid;
    if (this.relatedData?.invoice?.accIntInvoiceId != null) {
      invoiceid = this.relatedData?.invoice?.accIntInvoiceId;
    } else {
      invoiceid = this.relatedData?.invoice?.invoiceId;
    }
    var emailRequestModal = {
      UserName: user.name,
      EmailId: user.email,
      ReportId: Guid.EMPTY,
      TaskId: Guid.EMPTY,
      Comment: 'Could you, please, approve an invoce?',
      AssignedToUserID: user.userGuidId,
      AssignedByuserID: sessionStorage.Id,
      OrganisationID: this._organizationService.selectedOrganization,
      
      InvoiceID: invoiceid,
      ContactID: this.supplierId,
      Supplier: this.relatedData?.company?.name,
      Reference: this.relatedData?.invoice?.referenceId,
      InvoiceDate: this.invoice?.date,
      InvoiceDueDate: this.invoice?.dueDate,
      Total: this.invoice?.total,
      InvoiceNumber: this.invoice?.invoiceNumber,
      ReportName: this.invoice?.supplierName,
      ReportUrl: `dashboard?organizationId=${this._organizationService.selectedOrganization}&invoiceId=${invoiceid}`
    };

    this._reportService.sendEmailToUser(emailRequestModal)
      .subscribe(data => {
        if (data.status == 0) {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', data.message);
        }
        else {
          this.relatedData.approvals.approved = false;
          this.relatedData.approvals.sentForApproval = true;
          this.relatedData.approvals.approvedBy = user;
          this.relatedData.approvals.approvedAt = new Date();

          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', data.message);
        }
      });
  }

  menuClosed() {
    setTimeout(() => {
      this.isAddTagMenuItemShown = false;
    }, 100); 
  }

  private prepareAccIntLink() {
    if (this.relatedData?.invoice?.accIntInvoiceId && this.relatedData?.organization?.isQBO) {
      this.link = `https://app.qbo.intuit.com/app/bill?txnId=${this.relatedData.invoice.referenceId}`;
    } else if (this.relatedData?.invoice?.accIntInvoiceId) {
      if (this.relatedData?.invoice?.type == 'ACCPAY') {
        this.link = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${this.relatedData.organization.shortCode}&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=${this.relatedData.invoice.accIntInvoiceId}`;
      } else {
        this.link = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${this.relatedData.organization.shortCode}&redirecturl=/AccountsPayable/ViewCreditNote.aspx?creditNoteID=${this.relatedData.invoice.accIntInvoiceId}`;
      }
    }
  }

  private getOrganizationTags() {
    this._tagsService.getTagsByOrganizationId(this.organizationId)
      .pipe(take(1))
      .subscribe(
        (tags: Tag[]) => {
          this.tagList = tags;
          this.filteredTagList = this.tagList;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }
}
