<div class="related-container">
  <div class="custom-mat-spinner" *ngIf="isSpinnerShown"> 
    <mat-spinner></mat-spinner>
    <div class="text">Data loading...</div>
  </div>

  <div *ngIf="!isSpinnerShown">

    <!-- Bills -->
    <section class="bills">
      <div class="block-title" style="margin-bottom: 15px">
        <span (click)="isBillsExpanded = !isBillsExpanded">Bills</span>
        <span  class="shevron" (click)="isBillsExpanded = !isBillsExpanded">
          <img [ngClass]="isBillsExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
      </div>
      <div class="block-content" *ngIf="isBillsExpanded"  [ngClass]="isBillsExpanded ? 'expaned' : ''">
        <div class="subgroup-title">Outstanding</div>
        <div class="invoices">
          <div class="placeholder" *ngIf="outstandingInvoices?.length == 0">No bills to display</div>
          <div class="invoice" *ngFor="let invoice of outstandingInvoices" (click)="showInvoice(invoice)">
            <div class="name">
              <div class="reference"> Inv {{invoice.invoiceNumber}}</div>
              <div class="status">{{invoice.status}}</div>
            </div>
            <div class="value" *ngIf="invoice.amountDue >= 0">{{invoice.amountDue | number: '1.2-2'}}</div>
            <div class="value" *ngIf="invoice.amountDue < 0">({{-1 * invoice.amountDue  | number: '1.2-2'}})</div>
          </div>
        </div>

        <div class="total-row" *ngIf="outstandingInvoices?.length != 0">
          <div></div>
          <div class="total" *ngIf="outstandingTotal >= 0">{{outstandingTotal | number: '1.2-2'}}</div>
          <div class="total" *ngIf="outstandingTotal < 0">({{-1 * outstandingTotal | number: '1.2-2'}})</div>
        </div>

        <div class="subgroup-title" style="margin-top: 20px" *ngIf="paidInvoices?.length > 0 && isPaidInvoiceShown">Paid</div>
        <div class="invoices" *ngIf="paidInvoices?.length > 0 && isPaidInvoiceShown">
          <div class="invoice" *ngFor="let invoice of paidInvoices" (click)="showInvoice(invoice)">
            <div class="name">
              <div class="reference"> Inv {{invoice.invoiceNumber}}</div>
              <div class="status">{{invoice.status}}</div>
            </div>
            <div class="value" *ngIf="invoice.amountDue >= 0">{{invoice.amountDue | number: '1.2-2'}}</div>
            <div class="value" *ngIf="invoice.amountDue < 0">({{-1 * invoice.amountDue  | number: '1.2-2'}})</div>
          </div>
        </div>

        <div class="show-more" *ngIf="paidInvoices?.length > 0" (click)=" isPaidInvoiceShown = !isPaidInvoiceShown">
          <span *ngIf="!isPaidInvoiceShown">Show Paid</span>
          <span *ngIf="isPaidInvoiceShown">Hide Paid</span>
        </div>
      </div>
    </section>

    <!-- Automations -->
    <section class="automations">
      <div class="block-title">
        <span (click)="isAutomationsExpanded = !isAutomationsExpanded">Automations</span>
        <span  class="shevron" (click)="isAutomationsExpanded = !isAutomationsExpanded">
          <img [ngClass]="isAutomationsExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
      </div>
      <div class="block-content" *ngIf="isAutomationsExpanded" [ngClass]="isAutomationsExpanded ? 'expaned' : ''">
        <div class="setting-line">
          <div class="caption">Auto-publish</div>
          <div class="switcher form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="isAutoPublishOn" (change)="changeAutoPublishSetting($event)">
          </div>
        </div>
        <div class="setting-line">
          <div class="caption">Auto-approve</div>
          <div class="switcher form-check form-switch" style="width: fit-content; height: fit-content;" [matTooltip]="autoApprover ? '' : 'Select an approver first'"
            [matTooltipShowDelay]="showToolTipDelay">
            <input class="form-check-input" type="checkbox" role="switch" [disabled]="!autoApprover" [ngModel]="isAutoApprovalOn" 
              (change)="changeAutoApprovalSetting($event)">
          </div>
        </div>
        <div class="users" *ngIf="relatedData?.organization?.users?.length > 0" >
          <div class="title" *ngIf="autoApprover">
            <span>Approver:</span>
            <span class="user">{{autoApprover?.name}} - {{autoApprover?.email}}</span>
          </div>
          <div class="title">Select a user for approval</div>
          <ul>
            <li class="user activity" *ngFor="let user of relatedData?.organization?.users" (click)="selectUserForAutoApproval(user)">
              {{user.name}} - {{user.email}}
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- Accounting Integration -->
    <section class="accounting">
      <div class="block-title">
        <span  (click)="isAccountingExpanded = !isAccountingExpanded">
          <span *ngIf="!relatedData?.organization?.isQBO">Xero</span>
          <span *ngIf="relatedData?.organization?.isQBO">QuickBooks</span>
        </span>
        <span class="shevron" (click)="isAccountingExpanded = !isAccountingExpanded">
          <img [ngClass]="isAccountingExpanded ? 'img-up' : ''" src="../../../../assets/img/invoiceForm/shevron.svg" alt="">
        </span>
      </div>
      <div class="block-content" *ngIf="isAccountingExpanded" [ngClass]="isAccountingExpanded ? 'expaned' : ''">
        <div class="acc-type">
          <div class="logo-container">
            <div class="accounting-logo">
              <img *ngIf="!relatedData?.organization?.isQBO" class="act-logo" src="../../../../assets/img/company/XeroLogo.svg" alt=""/>
              <img *ngIf="relatedData?.organization?.isQBO" class="act-logo" src="../../../../assets/img/company/QBOLogo.png" alt=""/>
            </div>
          </div>
          <div class="desc">
            <div class="title">{{supplier?.name}}</div>
            <div class="subtitle" *ngIf="relatedData?.organization?.firstName || relatedData?.organization?.lastName || relatedData?.organization?.email">
              <span>{{relatedData?.organization?.firstName}}&nbsp;{{relatedData?.organization?.lastName}}</span>
              <span *ngIf="relatedData?.organization?.email">&nbsp;-&nbsp;{{relatedData?.organization?.email}}</span>
            </div>
          </div>
          <a class="img-goto" href="{{link}}" target="_blank" [matTooltip]="relatedData?.organization?.isQBO ? 'Go to QuickBooks' : 'Go to Xero'"
            [matTooltipShowDelay]="showToolTipDelay">
            <img src="../../../../assets/img/invoiceForm/goto.svg" alt=""/>
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
