import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/service/auth/auth.service';
import { OrganizationService } from '../../data/service/organization.service';
import { UserService } from '../../data/service/user.service';
import { ToasterMessageService } from '../../shared/toaster-message/toaster-message.service';
import { CurrentUserModel } from '../../data/model/register-model';
import { ToasterTypes } from '../../core/enum/toaster-type.enum';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { SharedService } from 'src/app/core/service/shared.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ScreenBrakepoints } from 'src/app/data/model/screen-brakepoints';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ContentLayoutComponent implements OnInit, OnDestroy {

  userDetails: any;
  subscriptions: Subscription[] = [];
  currentUserModel: CurrentUserModel;

  public IsQuickBookResponse: number;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCustomJs();
  }

  constructor(
    private authService: AuthService,
    private organizationService: OrganizationService,
    private userService: UserService,
    public spinnerService: SpinnerService,
    private toasterMessageService: ToasterMessageService,
    public readonly sharedService: SharedService,
    private readonly _breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.setCustomJs();
    this.userService.loggedUserDetails.next(JSON.parse(this.userService.upUserDetails));
    this.subscriptions.push(this.userService.loggedUserDetails.subscribe(data => {
      this.userDetails = data || [];
    }));
    this.getAllOrganizationAndUserDetails();

    this._breakpointObserver.observe([
      "(max-width: 768px) and (min-width: 429px)",
      "(max-width: 429px)",
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        if (result.breakpoints['(max-width: 429px)']) {
          this.sharedService.setScreenBrakepoint(ScreenBrakepoints.MaxWidth428px);
        } else {
          this.sharedService.setScreenBrakepoint(ScreenBrakepoints.MaxWidth768px);
        }
      } else {
        this.sharedService.setScreenBrakepoint(ScreenBrakepoints.MinWidth769px);
      }
    });
  }

  setCustomJs() {
    (document.getElementById('custom-js') as HTMLScriptElement).src = 'assets/js/custom.js';
  }
  
  //Get companyInfo for quickbook
  GetAllCompanyInfoAndUserDetails() {

    //Get all companyInfo List from CompanyInfo_QuickBook table
    this.organizationService.getOrganization('QuickBooks/CompanyInfoGet').pipe().subscribe((response: any) => {

      if (response.status == 0) {
        this.organizationService.organizationList.next(response.data);
      } else {

        this.organizationService.organizationList.next(null);
        this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
        this.authService.signOut();

      }
    }, err => {

      this.organizationService.organizationList.next(null);
      this.userService.loggedUserDetails.next(null);
      this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.statusText);
      this.authService.signOut();

    });
  }

  getAllOrganizationAndUserDetails() {

    this.organizationService.getOrganization('Organisation/OrganisationGet').pipe().subscribe((response: any) => {
      if (response.status === 1) {
        this.organizationService.organizationList.next(response.data);
      } else {
        this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', response.message);
        this.authService.signOut();
      }
    }, err => {
      this.organizationService.organizationList.next(null);
      this.userService.loggedUserDetails.next(null);
      this.toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.statusText);
      this.authService.signOut();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}