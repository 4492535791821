import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { KeyValuePair } from '../../models/invoice/key-value-pairs';

@Component({
  selector: 'app-key-value-pairs',
  templateUrl: './key-value-pairs.component.html',
  styleUrls: ['./key-value-pairs.component.scss']
})
export class KeyValuePairsComponent implements OnInit {
  @Input() invoiceId: string = '';

  invoicePairSet: KeyValuePair[] = [];
  pairSet: KeyValuePair[] = [];  

  jsonPairs: any;
  showToolTipDelay: number = 400;

  constructor() {}

  ngOnInit() {
    if (this.invoiceId)
      this.getPairs(this.invoiceId);
  }

  private getPairs(invoiceId: string) {
    const storage = localStorage.getItem('StoredPairs');

    if (storage) {
      const storedPairs = [...JSON.parse(storage)] as KeyValuePair[];
      const existedPair = storedPairs.find(p => p.key === invoiceId);

      const tempArray = existedPair?.value.split('!@#$%^&*()_-=+');
      
      if (tempArray) {
        const var1 = JSON.parse(tempArray[0]);
        Object.keys(var1).forEach((a, i) => this.invoicePairSet.push({
          key: a,
          value: Object.values(var1)[i]
        } as KeyValuePair));
  
        const var2 = JSON.parse(tempArray[1]);
        Object.keys(var2).forEach((a, i) => this.pairSet.push({
          key: a,
          value: Object.values(var2)[i]
        } as KeyValuePair));
      }  
    } else {
      this.jsonPairs = 'Unfortunately, something went wrong...';
    }
  }
}