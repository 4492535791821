import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpInternalService {
    public baseUrl: string = environment.apiURL;
    public headers = new HttpHeaders().set('content-type', 'application/json');

    constructor(private _http: HttpClient) { }

    public getRequest<T>(url: string, httpParams?: any, responseType?: any, headers?: any): Observable<T> {
        return this._http.get<T>(
            this.buildUrl(url),
            { headers: !headers ? this.getHeaders() : headers, params: httpParams, responseType: !responseType ? 'json' : responseType },
        );
    }

    public postClearRequest<T>(url: string, payload: object): Observable<T> {
        return this._http.post<T>(this.buildUrl(url), payload);
    }

    public postRequest<T>(url: string, payload: any, headers?: any, responseType?: any): Observable<T> {
        return this._http.post<T>(
            this.buildUrl(url),
            payload,
            { headers: !headers ? this.getHeaders() : headers, responseType: !responseType ? 'json' : responseType }
        );
    }

    async postPromiseRequest(url: string, payload: object): Promise<any> {
      return await this._http.post(this.buildUrl(url), payload).toPromise() as any;
    }


    public putRequest<T>(url: string, payload: object, headers?: any): Observable<T> {
        return this._http.put<T>(this.buildUrl(url), payload, { headers: !headers ? this.getHeaders() : headers });
    }

    public deleteRequest<T>(url: string, httpParams?: any, headers?: any): Observable<T> {
        return this._http.delete<T>(
            this.buildUrl(url),
            { headers: !headers ? this.getHeaders() : headers, params: httpParams });
    }

    public buildUrl(url: string): string {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        }
        return this.baseUrl + url;
    }

    public getHeaders(): HttpHeaders {
        return this.headers;
    }

    public deleteHeader(key: string) {
        this.headers = this.headers.delete(key);
    }

    public prepareData = (payload: object): string => JSON.stringify(payload);
}
