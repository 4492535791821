<div class="step-side-form-container">
  <div class="signup-form-container">
    <form class="signup-form" role="form" name="SignUpForm">
      <div class="step-header">Let's Verify Your Email</div>
      <div class="step-header-second">Follow the link we've sent to {{email}} to continue with your account creation</div>
      <hr style="background-color: #000; height: 2px;opacity: 1;"/>
      <div class="step-header-second" style="margin-top: 20px;margin-bottom: 5px;">Didn't receive an email?</div>
      <div class="step-header-second" style="font-weight: 600;cursor: pointer;" (click)="resendMail()">Resend Link</div>
    </form>
  </div>
</div>
<div class="step_side_image_container">
  <div class="signup_image_container">
    <img class="step-one-img" src="../../../../../assets/img/signup/step-two.svg">
  </div>
</div>
