import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { InvoiceShort } from '../../models/invoice/invoice-short';
import { InvoiceStatus } from '../../enums/invoice-status';
import { Router } from '@angular/router';
import { inProcessingRecord } from '../../data/in-processing-record';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { Invoice } from '../../models/invoice/invoice';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { take } from 'rxjs/operators';
import { InboxInvoicesStorageService } from '../../services/inbox-invoices-storage.service';
import { SortTableParameters } from '../../models/filter/sort-table-parameters';
import { Tag } from 'src/app/data/model/tag';
import { DeletedTagFromInvoice } from '../../models/tag/deleted-tag';
import { Guid } from 'guid-typescript';
import { TrackingCategory } from '../../models/tracking-categories.ts/trackingCategory';
import { ScreenBrakepoints } from 'src/app/data/model/screen-brakepoints';
import * as $ from 'jquery';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent extends BaseComponent implements OnInit {
  @Input() invoices: InvoiceShort[] = [];
  @Input() status: InvoiceStatus;
  @Input() emptyMessage: string = "No data to display";
  @Input() maxCount: number = 0;
  @Input() pageSize: number = 25;
  @Input() page: number = 1;
  @Input() screenBrakepoint: ScreenBrakepoints = ScreenBrakepoints.MinWidth769px;
  
  @Input() selectedTrackingCategories: TrackingCategory[] = [];

  @Output() pageSelect = new EventEmitter<number>();
  @Output() sortTableEvent = new EventEmitter<SortTableParameters>();
  @Output() deleteTagEvent = new EventEmitter<DeletedTagFromInvoice>();
  @Output() rowCheckboxClickEvent = new EventEmitter<void>();

  @ViewChild('BookkeepingTable') table: any;

  columnMode = ColumnMode;
  statuses = InvoiceStatus;
  inProcessingRecord = inProcessingRecord;
  count = 0;
  showSidebar: boolean = false;
  isHorizontalDirection: boolean = false;
  loading: boolean = false;
  isPdf: boolean = false;
  tempFile: string;
  invoiceId: any;
  isHeaderCheckboxSelected: boolean = false;
  shownCategoriesInvoiceId: Guid = null;
  shownTrackingOptions1InvoiceId: Guid = null;
  shownTrackingOptions2InvoiceId: Guid = null;

  trackingCategories: TrackingCategory[] = [];
  selectedTrackingCategoriesIds: string[] = [];
  screenBrakepoints = ScreenBrakepoints;

  constructor(
    private _router: Router,
    private _toasterMessageService: ToasterMessageService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private _invoiceService: InvoiceService,
    private _inboxInvoicesStorage: InboxInvoicesStorageService,
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.invoices);
    if (this.status === InvoiceStatus.inProcessing) {
      this.subscribeToAddingInvoice();
      this.subscribeToInvoiceUpdates();
    }
  }

  showMoreCategories(invoice: InvoiceShort) {
    if (this.shownCategoriesInvoiceId == invoice.id) {
      this.shownCategoriesInvoiceId = null;
    } else {
      this.shownCategoriesInvoiceId = invoice.id;
    }
  }

  showMoreTrackingOptions(kind: string, invoice: InvoiceShort) {
    if (kind == '1') {
      if (this.shownTrackingOptions1InvoiceId == invoice.id) {
        this.shownTrackingOptions1InvoiceId = null;
      } else {
        this.shownTrackingOptions1InvoiceId = invoice.id;
      }
    } else {
      if (this.shownTrackingOptions2InvoiceId == invoice.id) {
        this.shownTrackingOptions2InvoiceId = null;
      } else {
        this.shownTrackingOptions2InvoiceId = invoice.id;
      }
    }
  }
 
  deleteTag(tag: Tag, invoice: InvoiceShort) {
    let tagToInvoices = {
      tagId: tag.tagId,
      tagName: tag.tagName,
      invoiceId: invoice.id.toString(),
      invoiceProcessedStatus: this.status == InvoiceStatus.inProcessing ? false : true
    } as DeletedTagFromInvoice;

    this.deleteTagEvent.emit(tagToInvoices);
  }

  subscribeToInvoiceUpdates() {
    this._recognizedInvoiceService.invoiceState$
      .pipe(this.untilThis)
      .subscribe(
        (invoice: Invoice) => {
          if (invoice?.referenceId && invoice.referenceId != Guid.EMPTY) {
              const index = this.invoices.lastIndexOf(inProcessingRecord);
              if (index !== -1) {
                this.invoices = this.invoices.filter(i => i != this.invoices[index]);
                this.invoices = [...this.invoices];
              }
          }
          this._addNewInvoice(invoice);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  subscribeToAddingInvoice() {
    this._recognizedInvoiceService.inProcessingRecordState$
      .pipe(this.untilThis)
      .subscribe(
        (invoice: InvoiceShort) => {
          this.invoices = [invoice, ...this.invoices];
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  onSort(event) {
    let sortData = {
      value: event.newValue,
      columnName: event.column.name,
      property: event.column.prop
    } as SortTableParameters;

    this.sortTableEvent.emit(sortData);
  }

  onActivate(event: any) {
    if (event.type !== 'click')
      return;
    else {
      if (event.column.name == 'Checker') {
        return;
      }

      if (event.column.name === "") {
        this.invoiceId = event.row.id
        this._getInvoice();
        return;
      }
    }

    if (event.row.invoiceNumber === inProcessingRecord.invoiceNumber) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'This invoice is being processed!');
      return;
    }

    this._inboxInvoicesStorage.fillInvoicesArray(this.invoices, event.row.id);

    this.status === this.statuses.processed ? this._router.navigate([`/inbox/viewInvoice/${event.row.id}`])
      : this._router.navigate([`/inbox/editInvoice/${event.row.id}`]);
  }

  onHeaderCheckboxChange() {
    this.invoices.forEach(invoice => {
      invoice.checked = this.isHeaderCheckboxSelected;
    });
    if(this.isHeaderCheckboxSelected)
      $(".detailpanel").css('height', "calc(100vh - 125px)");
    else 
      $(".detailpanel").css('height', "calc(100vh)");
    this.rowCheckboxClickEvent.emit();
  }

  onCheckboxChange(event: any, row: InvoiceShort) {
    row.checked = event.target.checked;
    let checkedInvoices = this.invoices.filter(i => i.checked == true);

    if (checkedInvoices?.length == 0) {
      this.isHeaderCheckboxSelected = false;
    }
    if(checkedInvoices?.length > 0)
      $(".detailpanel").css('height', "calc(100vh - 125px)");
    else 
      $(".detailpanel").css('height', "calc(100vh)");
    this.rowCheckboxClickEvent.emit();
  }

  private _addNewInvoice(invoice: Invoice) {
    const index = this.invoices.lastIndexOf(inProcessingRecord);

    if (index !== -1) {
      this.invoices[index] = invoice as unknown as InvoiceShort;
      this.invoices[index].shortCode = this.setShortCodeToUpdatedInvoice(this.invoices[index].supplierName);
      this.invoices[index].rowCount = 6;
      this.invoices = [...this.invoices];
    }
  }

  toggleFilter() {
    this.showSidebar = !this.showSidebar;
  }

  private setShortCodeToUpdatedInvoice(supplierName: string) {
    let result = '';
    if (supplierName && supplierName != '') {
      for (let w of supplierName.split(' ')) {
        result += w[0];
      }
      return result.slice(0, 3).toUpperCase();;
    }
    return '';
  }

  private _getInvoice() {
    const id = this.invoiceId;
    if (id) {
      this.loading = true;
      this._invoiceService.getInvoiceById(id)
        .pipe(take(1))
        .subscribe(
          (invoice: Invoice) => {
            this._recognizedInvoiceService.sendExistingInvoice(invoice);
            this.loading = false;
          },
          (e: Error) => {
            this.loading = false;
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          }
        )
    }
  }

  clickOn(event: any) {
    this.pageSelect.emit(event.page as number);
  }
}
