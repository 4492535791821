import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[phoneMask]'
})
export class PhoneMasksDirective {

    @HostListener('input', ['$event']) onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        let trimmed = input.value.replace(/\s+/g, '');

        let numbers = [];
        numbers.push(trimmed.substr(0, 3));
        if (trimmed.substr(3, 3) !== "")
            numbers.push(trimmed.substr(3, 3));
        if (trimmed.substr(6, 4) != "")
            numbers.push(trimmed.substr(6, 4));
        input.value = numbers.join(' ');
    }
}