<div class="payment-container">
  <div class="custom-mat-spinner sidebar-spinner" *ngIf="isSpinnerShown" [ngClass]="{'inbox-sidebar-spinner': !billsToPayMode && isSpinnerShown , 'billspay-sidebar': billsToPayMode && isSpinnerShown}">
    <mat-spinner></mat-spinner>
    <div class="text">Data loading...</div>
  </div>
  <section class="payment-header">
    <div class="info">
      <div class="title">
        <div class="payment-number">{{selectedTask?.name}}</div>

      </div><div class="close-icon" matTooltip="Close Tasks Side Bar" [matTooltipShowDelay]="showToolTipDelay"
                 (click)="closeFormOnBillsToPay()">
        <img src="../../../../../assets/img/close-icon.svg" alt="">
      </div>
    </div>
    <div class="tab-header">
      <div class="tab" [ngClass]="currentPannel == panels.DetailsTab ? 'active' : ''" (click)="tabClick(panels.DetailsTab)">
        <div class="caption">Details</div>
        <div class="underline"></div>
      </div>
      <!--<div class="tab" [ngClass]="currentPannel == panels.ActivityTab ? 'active' : ''" (click)="tabClick(panels.ActivityTab)">
        <div class="caption">Activity</div>
        <div class="underline"></div>
      </div>
      <div class="tab" [ngClass]="currentPannel == panels.RelatedTab ? 'active' : ''" (click)="tabClick(panels.RelatedTab)">
        <div class="caption">Related</div>
        <div class="underline"></div>
      </div>-->
    </div>
  </section>
  <!-- Details tab -->
  <section class="payment-tab" *ngIf="currentPannel == panels.DetailsTab">
    <section class="summary">
      <div class="block">
        <div class="caption">Task</div>
        <div class="values">
          <input type="text" [value]="selectedTask.name" />
        </div>
      </div>
      <div class="block" *ngIf="selectedTask.taskUrl">
        <div class="caption">Related</div>
        <div class="values">
          <a href="/{{selectedTask.taskUrl}}" target="_blank" style="cursor:pointer; color:dodgerblue">{{selectedTask.urlName}}</a>
        </div>
      </div>
      <div class="block">
        <div class="caption">Date</div>
        <div class="values">
          <input type="text" [value]="getFormatedDate(selectedTask?.date)" />
        </div>
      </div>
      <div class="block">
        <div class="caption">Due Date</div>
        <div class="values">
          <input type="text" [value]="getFormatedDate(selectedTask?.dueDate)" />
        </div>
      </div>
      <div class="block">
        <div class="caption">Assigned By</div>
        <div class="values">
          <input type="text" [value]="selectedTask.assignedByUserNameFull" />
        </div>
      </div>
    </section>
  </section>
  <!-- Activity Panel -->
  <app-activity *ngIf="currentPannel == panels.ActivityTab"
                (moveBack)="showActivityPanel()"
                [invoiceColorNumber]="invoiceColorNumber"
                [invoiceId]="invoice.id == '00000000-0000-0000-0000-000000000000' ? invoice.referenceId : invoice.id"
                [supplierName]="currentSupplier.name ? currentSupplier.name : invoice.supplierName">
  </app-activity>
  <!-- Related -->
  <app-related *ngIf="currentPannel == panels.RelatedTab"
               [invoice]="invoice"
               [supplierId]="invoice?.supplierId"
               (showContactForm)="toggleCreateSupplierForm($event)"
               (clickInvoiceEvent)="closeContactForm($event)"
               [invoiceColorNumber]="invoiceColorNumber"
               [hexColorClass]="hexColorClass"
               [billsToPayMode]="billsToPayMode">
  </app-related>
</div>
<ng-container *ngIf="!selectedTask.complete">
  <!-- Buttons -->
  <div id="floating-div" class="floating-button-div" *ngIf="( approvalMode) && (currentPannel == panels.DetailsTab) ">
    <div class="buttons">
      <button *ngIf="approvalMode && isApproveButtonShown" class="btn-publish approval-button" [disabled]="isApproving" style="position:relative"
              matTooltip="Approve the invoice" [matTooltipShowDelay]="showToolTipDelay" (click)="approveCurrentInvoice()">
        <span *ngIf="!isApproving" class="btn-text">Approve</span>
        <span *ngIf="isApproving" class="btn-text">Approving</span>
        <span class="spinner-wrapper" *ngIf="isApproving"><mat-spinner [diameter]="18"></mat-spinner></span>
      </button>
    </div>
  </div>
</ng-container>
