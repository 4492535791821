<div [ngClass]="!isHorizontalDirection ? 'file-placeholder' : 'file-placeholder horizontal'" style="width: 100%;">
    <div class="line">
        <div class="text-placeholder high long"></div>
        <div class="text-placeholder high short"></div>
    </div>
    <div class="line">
        <div class="text-placeholder short"></div>
    </div>
    <div class="line" *ngFor="let _ of [].constructor(2)">
        <div class="text-placeholder shortest"></div>
    </div>
    <div class="divider"></div>
    <div class="line" *ngFor="let _ of [].constructor(4)">
        <div class="text-placeholder shortest"></div>
        <div class="text-placeholder short"></div>
        <div class="text-placeholder small mobile-hidden"></div>
        <div class="text-placeholder small mobile-hidden"></div>
    </div>
    <div class="divider mobile-hidden"></div>
    <div class="line mobile-hidden" *ngFor="let _ of [].constructor(2)">
        <div class="text-placeholder short"></div>
    </div>
    <div class="end-placeholder mobile-hidden"></div>
</div>