<div class="data_in">
  <div class="inbox-container">
    <ng-container *ngIf="!isMobileDevice">
      <div class="preview-invoice">
        <app-upload-document [isHorizontalDirection]=isHorizontalDirection></app-upload-document>
      </div>
      <div class="form-invoice">
        <div>
          <app-upload-invoice-form [isEditable]="isEditMode" [billsToPayMode]="false"
                                   [hexColorClass]="hexColorClass"
                                   [currentPannel]="currentPannel"> </app-upload-invoice-form>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isMobileDevice">
      <div ngClass="cd_sidebar_main sidenav sidenav-show outside_amo_sidebar">
        <div class="modal_wrapper_back"   ></div>
        <div class="sidenav_in cd_sidebar">
          <div class="form-group">
            <app-upload-invoice-form [isEditable]="isEditMode" [billsToPayMode]="false" [isInboxMobileMode]="true"
                                     [hexColorClass]="hexColorClass"  (closeFormOnBillsToPayEvent)="toggleInvoiceSideBar()"
                                     [currentPannel]="currentPannel"> </app-upload-invoice-form>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
