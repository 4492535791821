import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { NotificationsHubService } from 'src/app/core/service/hubs/notifications-hub.service';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { UserService } from 'src/app/data/service/user.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { xeroSignUpConnection } from 'src/environments/environment';
import { RefreshLog_Type } from '../../../../data/enums/common-enum';

@Component({
  selector: 'app-xero-sign-up',
  templateUrl: './xero-sign-up.component.html',
  styleUrls: ['./xero-sign-up.component.scss']
})
export class XeroSignUpComponent implements OnInit, OnDestroy {
  xeroSignUpData: any = null;
  reveivedData: any = null;
  isSpinnerShown: boolean = true;
  redirectionText: string = 'Redirecting to Xero...';
  redirectionSubText: string = null;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly _authService: AuthService,
    private readonly _toasterMessageService: ToasterMessageService,
    private readonly _userService: UserService,
    private readonly suppliersService: SuppliersService,
    private readonly router: Router,
    private readonly _notificationsHubService: NotificationsHubService
  ) { }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(params => {
        let data = params['data'];
        let error = params['error'];

        if (data && data != '') {
          this.redirectionText = 'Signing Up to Synced...';
          this.reveivedData = JSON.parse(data);
          this.xeroSignUpData = this.reveivedData?.Data;

          this._authService.accessToken = this.xeroSignUpData?.access_token;
          this._authService.accessUpUserId = this.xeroSignUpData?.user?.Id;

          sessionStorage.UserName = this.xeroSignUpData?.user?.Name;
          sessionStorage.Id = this.xeroSignUpData?.user?.Id;
          sessionStorage.UserId = this.xeroSignUpData?.user?.UserId;
          localStorage.setItem('UserName', this.xeroSignUpData?.user?.Name);
          localStorage.setItem('UserId', this.xeroSignUpData?.user?.UserId);
          localStorage.setItem('Id', this.xeroSignUpData?.user?.Id);
          localStorage.setItem('SelectedOrganization', this.xeroSignUpData?.user?.OrganizationId);
          localStorage.setItem('IsLogedin', 'true');
          localStorage.setItem('IsXeroDirect', 'true');
          const user = {
            email: this.xeroSignUpData?.user?.Email,
            id: this.xeroSignUpData?.user?.Id,
            name: this.xeroSignUpData?.user?.Name,
            firstname: this.xeroSignUpData?.user?.FirstName,
            lastname: this.xeroSignUpData?.user?.LastName,
            organizationId: this.xeroSignUpData?.user?.OrganizationId,
            role: this.xeroSignUpData?.user?.Role,
            userId: this.xeroSignUpData?.user?.UserId
          } as any;

          this._userService.upUserDetails = JSON.stringify(user);
          this._userService.setUserRoleData(user.role);
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Congrats!', 'You were just signed up in Synced');

          if(this.xeroSignUpData?.user?.OrganizationId) {
            sessionStorage.organizationId = this.xeroSignUpData?.user?.OrganizationId;
            sessionStorage.selectedOrganization = this.xeroSignUpData?.user?.OrganizationId;
            this.AddandUpdatesupplierXeroData(this.xeroSignUpData?.user?.OrganizationId, this.xeroSignUpData?.user?.Email) 
            this._notificationsHubService.start();
          }
          else{
            this._notificationsHubService.start();
            this.router.navigateByUrl('/signup/wizzard');
          }
        } else if (error && error != '') {
          this.isSpinnerShown = false;
          this.redirectionText = error;
          this.redirectionSubText = 'Please try again later';

          setTimeout(() => {
            this.redirectionText = null;
            this.redirectionSubText = 'Redirecting...';
            this.isSpinnerShown = true;
          }, 4000);

          setTimeout(() => {
            this.router.navigateByUrl('/signup/step-one');
          }, 5000);
        } else {
          window.location.replace(xeroSignUpConnection.xeroOAuth2SignUpLink);
        }
      });
  }

  private AddandUpdatesupplierXeroData(orgId, email) {
    this.suppliersService.AddandUpdatesupplierXeroData(orgId,email,RefreshLog_Type.Signup)
      .pipe(take(1))
      .subscribe(data => {
        this.router.navigateByUrl('/signup/wizzard');
      });
  }
}
