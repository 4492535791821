import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { InlineSVGModule } from 'ng-inline-svg';
import { CommonSharedModule } from '../app/shared/shared.module';
import { CoreModule } from '../app/core/core.module';
import { DataModule } from '../app/data/data.module';
import { AppRoutingModule } from './app.routing'
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { RegistrationLayoutComponent } from './layout/registration-layout/registration-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { SidebarMenuComponent } from './layout/sidebar-menu/sidebar-menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ReportCommentsComponent } from '../app/modules/report/reports/report-comments/report-comments/report-comments.component';
import { EmailModalComponent } from './shared/email-modal/email-modal.component';
import { BookkeepingModule } from './modules/bookkeeping/bookkeeping.module';
import { DatePipe } from '@angular/common';
import { SignUpModule } from './modules/signup/signup.module';
import { SignupLayoutComponent } from './layout/signup-layout/signup-layout.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; 
// declare var require: any;

// export function ABA() {
//   const hc = require('aba-generator');
//   const dd = require('/node_modules/printf/lib/printf.js');
//   dd(hc);

//   return hc;
// }

//import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

//import { ReportCommentsComponent } from './report/content-layout/content-layout.component'
@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    RegistrationLayoutComponent,
    ContentLayoutComponent,
    SidebarMenuComponent,
    HeaderComponent,
    ReportCommentsComponent,
    EmailModalComponent,
    SignupLayoutComponent, 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    InlineSVGModule.forRoot(),    
    NgbModule,  
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,

    // core & shared
    CommonSharedModule,
    CoreModule,
    DataModule,

    // app
    AppRoutingModule,
    BookkeepingModule,
    SignUpModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  
  providers: [
    DatePipe,
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
