import { Injectable } from '@angular/core';
import { BatchPaymentRead } from 'src/app/modules/payable/models/batch-payment-read';
import { BatchPaymentActivity } from 'src/app/modules/payable/models/payment-activity';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private _httpService: HttpInternalService) { }

  getReports(url: string, organizationId: string) {
    return this._httpService.getRequest(`${url + "?OrganizationId=" + organizationId}`);
  }

  sendEmailToUser(emailRequestModal) {
    return this._httpService.postRequest<any>('Reporting/SendEmailToUser', JSON.stringify(emailRequestModal));
  }

  DeleteSavedReportData(reportId, reportName) {
    return this._httpService.getRequest<any>(`${'Reporting/DeleteSavedReportData' + "?reportId=" + reportId + "&reportName=" + reportName}`);
  }

  GetApprovalAssignDetail(organisationId,InvoiceId) {
    return this._httpService.getRequest<any>('Reporting/GetApprovalAssignDetail' + "?organisationId=" + organisationId + "&InvoiceId=" + InvoiceId);
  }

  getBatchPaymentById(organisationId: string, paymentId: string) {
    return this._httpService.getRequest<BatchPaymentRead>('Reporting/GetBatchPaymentById' + "?organisationId=" + organisationId + "&paymentId=" + paymentId);
  }

  getBatchPaymentActivity(organisationId: string, paymentId: string) {
    return this._httpService.getRequest<BatchPaymentActivity[]>('Reporting/GetBatchPaymentActivity' + "?organisationId=" + organisationId + "&paymentId=" + paymentId);
  }
}
