<div class="step-side-form-container">
    <div class="signup-form-container">
        <form class="signup-form" role="form" name="loginForm">
          <div class="step-header">Integrations</div>
          <div class="step-header-second">Sync bills, contacts & more with your accounts</div>
          <div *ngIf="connectedToXero === false && connectedToQBO === false">
            <div class="form-group">
              <a (click)="openXeroWindow()" class="signup-connect-link" [ngClass]="{'connected':connectedToXero === true,'disabled':connectedToQBO === true}"> 
                  <img src="../../../../assets/img/connect-xero-blue.svg" alt="">
              </a>
            </div>
            <div class="form-group">
                  <a (click)="openQuickBookWindow()" class="signup-connect-link ml10" [ngClass]="{'connected':connectedToQBO === true,'disabled':connectedToXero === true}">
                      <img src="../../../../assets/img/connect-qbo-img.svg" class="qboImgHover" alt="">
                  </a>
            </div>
          </div>
          <div *ngIf="connectedToXero === true || connectedToQBO === true">
            <div class="form-group">
              <a (click)="openXeroWindow()" class="signup-connect-link" [ngClass]="{'connected':connectedToXero === true,'disabled':connectedToQBO === true}"> 
                  <img src="../../../../assets/img/connect-img.svg" alt="">
                  <span>{{connectedToXero === true ? "Connected to Xero" : "Connect to Xero" }}</span>
              </a>
            </div>
            <div class="form-group">
                  <a (click)="openQuickBookWindow()" class="signup-connect-link ml10" [ngClass]="{'connected':connectedToQBO === true,'disabled':connectedToXero === true}">
                      <img src="../../../../assets/img/QuickBookConnectLogo.png" alt="">
                      <span>{{connectedToQBO === true ? "Connected to QuickBooks Online" : "Connect to QuickBooks Online"}}</span>
                  </a>
            </div>
          </div>
          <div class="form-group">
            <button type="button" (click)="singUp()" class="signup__btn-next" [class.spinner]="loading" [ngClass]="{'disabled': loading === true}">SIGN UP</button>
          </div>
        </form>
    </div>
  </div>
  <div class="step_side_image_container">
    <div class="signup_image_container">
      <img class="step-one-img" src="../../../../../assets/img/signup/step-three.svg">
    </div>
  </div>
