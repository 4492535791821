import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ToasterTypes } from '../../enum/toaster-type.enum';
import { HubFactoryService } from './hub-factory.service';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseHubService {
  protected unsubscribe$ = new Subject<void>();
  protected hubConnection: HubConnection;

  protected constructor(
    protected readonly _hubFactory: HubFactoryService,
    protected readonly _toasterMessageService: ToasterMessageService,
  ) { }

  protected abstract get hubUrl(): string;

  start() {
    this._hubFactory.createHub(this.hubUrl)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (connection) => {
        if (!connection) {
          return;
        }

        this.hubConnection = connection;

        if (this.hubConnection?.state == 'Disconnected') {
          await this.hubConnection
            .start()
            .catch((error) => {
              this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error occurred!', error);
            });

          await this.onStart();
        }
      });
  }

  protected abstract onStart(): void | Promise<void>;
  protected abstract stop();
}
