
export class NewReport{
  ReportName: string;
  CreatedBy:string;
  organizationId: string;

  constructor(
    organizarionId: string,
    ReportName: string = '',
    CreateBy:string=''
    ) {
    this.organizationId = organizarionId;
    this.ReportName = ReportName;
    this.CreatedBy=CreateBy;

  }
}
