import { BankAccountType } from "../../../../data/enums/common-enum";
import { CurrencyCode } from "../../enums/currency-code";

export class BankAccount {
  accountNumber: string;
  branchDetails: string;
  currency: CurrencyCode;
  isPrimary: boolean;
  contactId: string;
  organisationId: string;
  accountName: string;
  id: string;
  BSBNumber: string;
  routingNumber: string;
  accountNumberUS: string;
  type: number;

  constructor(organizationId: string,
    contactId: string = '',
    accountNumber: string = null,
    branchDetails: string = null,
    currency: CurrencyCode = CurrencyCode.AUD) {
    this.organisationId = organizationId;
    this.currency = currency;
    this.accountNumber = accountNumber;
    this.branchDetails = branchDetails;
    this.contactId = contactId;
    this.accountName = "";
    this.type = BankAccountType.User;
  }
}
