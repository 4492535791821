import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BillsToPayInvoiceDetailsRequest } from '../models/invoice/bills-to-pay-invoice-details-request';
import { Invoice } from '../models/invoice/invoice';
import { InvoiceShort } from '../models/invoice/invoice-short';

@Injectable({
  providedIn: 'root'
})
export class RecognizedInvoiceService {
  private _invoiceSubject = new Subject<Invoice>();
  private _existingInvoiceSubject = new Subject<Invoice>();
  private _inProcessingRecord = new Subject<InvoiceShort>();
  private _invoicesSubject = new Subject<any>();
  private _invoicesModelSubject = new Subject<any>();
  private _billsToPayInvoiceDetailsRequest$ = new Subject<BillsToPayInvoiceDetailsRequest>();

  invoicesState$ = this._invoicesSubject.asObservable();
  invoiceState$ = this._invoiceSubject.asObservable();
  inProcessingRecordState$ = this._inProcessingRecord.asObservable();
  existingInvoiceState$ = this._existingInvoiceSubject.asObservable();

  invoicesModelState$ = this._invoicesModelSubject.asObservable();
  billsToPayInvoiceDetailsRequest$ = this._billsToPayInvoiceDetailsRequest$.asObservable();

  send(invoice: Invoice) {
    this._invoiceSubject.next(invoice);
  }

  sendExistingInvoice(invoice: Invoice) {
    this._existingInvoiceSubject.next(invoice);
  }

  addInProcessingRecord(invoice: InvoiceShort) {
    this._inProcessingRecord.next(invoice);
  }

  back(data: any) {
    this._invoicesModelSubject.next(data);
  }

  sendBillsToPayInvoiceDetailsRequest(request: BillsToPayInvoiceDetailsRequest) {
    this._billsToPayInvoiceDetailsRequest$.next(request);
  }
}
