import { Component, Input } from '@angular/core';
import { PaymentAccount } from '../../models/account/payment-account';
import { InvoiceLine } from '../../models/invoice/invoice-line';
import { TaxRate } from '../../models/taxRate';
import { TrackingCategory } from '../../models/tracking-categories.ts/trackingCategory';

@Component({
  selector: 'app-totals-line-new',
  templateUrl: './totals-line-new.component.html',
  styleUrls: ['./totals-line-new.component.scss']
})
export class TotalsLineNewComponent {
  @Input() index: number = 0;
  @Input() invoiceLine: InvoiceLine;
  @Input() accounts: PaymentAccount[] = [];
  @Input() taxRates: TaxRate[] = [];
  @Input() trackingCategories: TrackingCategory[] = [];

  getAccountName() {
    return this.accounts.find(a => a.id === this.invoiceLine.accountId?.toLowerCase())?.name;
  }

  getTaxRateName() {
    return this.taxRates.find(tr => tr.id === this.invoiceLine.taxId)?.name;
  }

  getTrackingCategory(id: string) {
    return this.trackingCategories.find(c => c.options.some(o => o.id === id))?.options.find(o => o.id === id)?.name;
  }
}
