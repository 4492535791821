<div class="step-side-form-container">
  <div class="signup-form-container">
      <form class="signup-form" role="form" name="SignUpForm" [formGroup]="SignUpForm"  (ngSubmit)="nextStep()">
        <div class="step-header">Your Details</div>
        <div class="step-header-second">Tell us a bit about you & your business!</div>
        <div class="form-group">
          <label for="firstname" class="control-lable">First Name<span class="required">*</span></label>
          <input class="form-control" name="firstname" formControlName="firstname" autocomplete="off">
          <span [ngClass]="{'isvalid' : !signUpFormControl.firstname?.errors?.required }" ></span>
          <span class="text-danger"
            *ngIf="signUpFormControl.firstname?.errors?.required && isWarningShown && (signUpFormControl.firstname.dirty || signUpFormControl.firstname.touched)">

          </span>
        </div>
        <div class="form-group">
          <label for="lastname" class="control-lable">Last Name<span class="required">*</span></label>
          <input class="form-control" name="lastname" formControlName="lastname" autocomplete="off">
          <span [ngClass]="{'isvalid' : !signUpFormControl.lastname?.errors?.required }" ></span>
          <span class="text-danger"
            *ngIf="signUpFormControl.lastname?.errors?.required && isWarningShown && (signUpFormControl.lastname.dirty || signUpFormControl.lastname.touched)">
            

          </span>
        </div>
          <div class="form-group">
            <label for="companyname" class="control-lable">Company Name<span class="required">*</span></label>
            <input class="form-control" name="companyname" formControlName="companyname" autocomplete="off">
            <span [ngClass]="{'isvalid' : !signUpFormControl.companyname?.errors?.required }" ></span>
            <span class="text-danger"
              *ngIf="signUpFormControl.companyname?.errors?.required && isWarningShown && (signUpFormControl.companyname.dirty || signUpFormControl.companyname.touched)">

            </span>
          </div>
          <div class="form-group">
            <button type="submit" class="signup__btn-next" [class.spinner]="loading" [ngClass]="{'disabled': SignUpForm.invalid === true || loading}">NEXT</button>
          </div>
      </form>

  </div>
</div>
<div class="step_side_image_container">
  <div class="signup_image_container">
    <img class="step-one-img" src="../../../../../assets/img/signup/step-two.svg">
  </div>
</div>
