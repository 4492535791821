import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { Pannels } from '../enums/sidebar-pannels';
import { Invoice } from '../models/invoice/invoice';
import { RecognizedInvoiceService } from '../services/recognized-invoice.service';
import { SharedService } from '../services/shared-service';
@Component({
  selector: 'app-add-new-invoice-page',
  templateUrl: './add-new-invoice-page.component.html',
  styleUrls: ['./add-new-invoice-page.component.scss']
})
export class AddNewInvoicePageComponent implements OnInit {
  isEditMode: boolean = true;
  isActionBarShown: boolean = true;
  isHorizontalDirection: boolean = false;
  loading: boolean = false;
  hexColorClass: string = "";
  @Input() currentPannel: Pannels;
  isMobileDevice = false;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _invoiceService: InvoiceService,
    private _toasterMessageService: ToasterMessageService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.checkIfMobileDevice();
    this._checkEditMode();
    this._getInvoice();
    this.openSideBarForNote();
  }

  checkIfMobileDevice() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      this.isMobileDevice = true;
      console.log("mobile device");
    } else {
      this.isMobileDevice = false;
    }
  }
  openSideBarForNote() {
    let recordId = this._route.snapshot.queryParamMap?.get('recordId');

    if (recordId) {
      this.currentPannel = Pannels.ActivityTab;
    }
  }
  // subsribeToSupplierEvents (){
  //   this.sharedService.isVisibleSource.subscribe((isVisible) => {
  //     this.isActionBarShown = isVisible;
  //   });
  // }

  updateColumnDirection() {
    this.isHorizontalDirection = !this.isHorizontalDirection;
    this.toggleActionBar();
  }

  toggleActionBar() {
    this.isActionBarShown = !this.isActionBarShown;
  }
  toggleInvoiceSideBar() {
    this._router.navigate(['/inbox'], { replaceUrl: true })
  }

  private _checkEditMode() {
    if (this._router.url.includes('viewInvoice'))
      this.isEditMode = false;
  }

  private _getInvoice() {
    const id = this._route.snapshot.params?.id;

    if (id) {
      this.loading = true;

      this._invoiceService.getInvoiceById(id)
        .pipe(take(1))
        .subscribe(
          (invoice: Invoice) => {
            this._recognizedInvoiceService.sendExistingInvoice(invoice);
            this.loading = false;
            this.hexColorClass = invoice.hexColorClass;
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          }
        )
    }
  }

}
