import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule  } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {
  @Input() selectedDateRange: DateRange<Date> = null;
  @Input() selectedDateRangeOption: string;
  @Input() pickerID: string;
  @Input() placeholder: string;
  @Output() getDateRange = new EventEmitter<{selectedDateRange: DateRange<Date>, selectedOption: string }>(); 
  @Output() closeDatePicker = new EventEmitter<void>(); 
  @ViewChild('buttondate') matdatePicker: ElementRef;
  dateRangeStart: Date = null;
  dateRangeEnd: Date = null;
  dateStart: string = null;
  dateEnd: string = null;
  broker: boolean = false;
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  }); 

  constructor() { }

  ngOnInit() {
    if(this.selectedDateRange != null) {
      this.range.setValue(this.selectedDateRange);
    }
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if(dateRangeStart == null && dateRangeEnd == null){
      this.dateRangeStart = null;
      this.dateRangeEnd = null;
      this.clearDate();
    }
    else { //(this.dateRangeStart == null && this.dateRangeEnd == null) {
      this.dateRangeStart = new Date(dateRangeStart.value);
      this.dateRangeEnd = new Date(dateRangeEnd.value);
    }

    if (this.dateRangeStart <= this.dateRangeEnd) {
      this.selectedDateRange = new DateRange(this.dateRangeStart, this.dateRangeEnd);
    }
    
    this.setDateRange();
  }

  cancelDateRange() {
    this.closeDatePicker.emit();
  }

  clearDate(){
    this.selectedDateRange = new DateRange(null, null);
    this.range.reset();
    this.setDateRange();  
  }

  setDateRange() {
    this.getDateRange.emit({selectedDateRange: this.selectedDateRange, selectedOption: this.selectedDateRangeOption});
  }

  onSelectType(event){
    if(event == 0) {
      this.dateRangeStart = this.dateRangeEnd = new Date();
      this.selectedDateRange = new DateRange(this.dateRangeStart, this.dateRangeEnd);
    }
    else if(event == 1) {
      var curr = new Date;
      var first = curr.getDate() - curr.getDay();
      var last = first + 6;

      this.dateRangeStart = new Date(curr.setDate(first));
      this.dateRangeEnd = new Date(curr.setDate(last));
      this.selectedDateRange = new DateRange(this.dateRangeStart, this.dateRangeEnd);
    }
    else if(event == 2) {
      var date = new Date();
      this.dateRangeStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateRangeEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.selectedDateRange = new DateRange(this.dateRangeStart, this.dateRangeEnd);
    }
    else if(event == 3) {
      var date = new Date();
      this.dateRangeEnd = new Date();
      this.selectedDateRange = new DateRange(null, this.dateRangeEnd);
    }
    else {
      this.selectedDateRange = new DateRange(null, null);
    }
    this.setDateRange();
  }
}
