import { Injectable } from '@angular/core';
import { HttpInternalService } from 'src/app/data/service/http.service';
import { RelatedData, RelatedDataRequest } from '../models/related/related';
import { ContactRelatedData, ContactRelatedDataRequest } from '../models/related/related-contact';
import { SupplierAutoApprover } from '../models/related/contact-auto-approver';
import { SupplierAutoPublish } from '../models/related/contact-auto-publish';

@Injectable({
  providedIn: 'root'
})
export class RelatedDataService {

  constructor(
    private readonly _httpService: HttpInternalService
  ) {}

  getRelatedData(relatedDataRequest: RelatedDataRequest) {
    return this._httpService.postRequest<RelatedData>('RelatedData/GetRelatedData', relatedDataRequest);
  }

  getContactRelatedData(dataRequest: ContactRelatedDataRequest) {
    return this._httpService.postRequest<ContactRelatedData>('RelatedData/GetContactRelatedData', dataRequest);
  }

  setSupplierAutoApprover(approver: SupplierAutoApprover) {
    return this._httpService.postRequest<void>('RelatedData/setSupplierAutoApprover', approver);
  }

  setSupplierAutoPublish(autoPublish: SupplierAutoPublish) {
    return this._httpService.postRequest<void>('RelatedData/setSupplierAutoPublish', autoPublish);
  }
}
