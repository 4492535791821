<div class="add-lines-container">
  <div class="controls">
   <div>&nbsp;</div>
   <div>&nbsp;</div>
    <div class="close-icon" matTooltip="Close" [matTooltipShowDelay]="showToolTipDelay"
         (click)="moveBackClick()">
      <img src="../../../../../assets/img/close-icon.svg" alt="">
    </div>
    <!--<span class="icon-left" matTooltip="Move back" [matTooltipShowDelay]="showToolTipDelay" (click)="moveBackClick()"></span>-->
  </div>
  <div class="content"  [ngStyle]="calculateDivHeight()">
    <!-- Totals -->
    <div class="field-label">Totals</div>

    <!-- Content -->
    <form class="main-content">
      <div class="header-section">
        <div class="caption account">
          <span>Account</span>
        </div>
        <div class="caption amount">
          <span>Amount</span>
        </div>
      </div>
      <app-add-lines-one-line *ngFor="let line of invoiceLines; let i = index"
                              [index]="i"
                              [accounts]=accounts
                              [invoiceLine]="line"
                              [taxRates]="taxRates"
                              [selectedTaxRateId]="line.taxId"
                              [trackingCategories]="trackingCategories"
                              (updateLine)="updateTable($event)"
                              (deleteLine)="deleteInvoiceLine($event)"
                              [isAddedLine]="isJustAddedLine && i == invoiceLines.length - 1">
      </app-add-lines-one-line>

      <!-- Add Line Item -->
      <div class="add-line">
        <span (click)="addInvoiceLine()">+ Add Line Item</span>
      </div>

      <!-- Total -->
      <div class="total-summary">
        <div></div>
        <div class="caption top-padding">Tax</div>
        <div class="value top-line top-padding">{{invoiceTotals.totalTax |  number : '1.0-2'}}</div>
        <div></div>
        <div class="caption">Total</div>
        <div class="value">{{invoiceTotals.amountDue |  number : '1.0-2'}}</div>
      </div>
    </form>
  </div>
  <div id="floating-div" class="floating-button-div">
    <div class="buttons">
      <div>&nbsp;</div>
      <button class="btn-save" (click)="submit()" matTooltip="Complete splitting and move back"
              [matTooltipShowDelay]="showToolTipDelay">
        Save
      </button>
    </div>
  </div>
  </div>
