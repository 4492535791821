import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ToasterTypes } from '../enum/toaster-type.enum';

import { AuthService } from '../service/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private authService: AuthService,
        private router: Router,
        private readonly _toasterMessageService: ToasterMessageService
    ) { }

    private _check(redirectURL): Observable<boolean> {
  
        return this.authService.check()
            .pipe(
                switchMap((authenticated) => {
                    if (!authenticated) {
                        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Authentication Error', 'Please login');
                        this.router.navigate(['auth/login'], { queryParams: { redirectURL } });

                        return of(false);
                    }

                    return of(true);
                })
            );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
        let redirectUrl = state.url;

        if (redirectUrl === '/login') {
            redirectUrl = '/';
        }

        return this._check(redirectUrl);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
        let redirectUrl = state.url;

        if (redirectUrl === '/login') {
            redirectUrl = '/';
        }

        return this._check(redirectUrl);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  
        return this._check('/');
    }
}
