import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { take } from 'rxjs/operators';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { objectToFormData } from 'src/app/core/util/FormData-converter';
import { InvoiceActivity } from 'src/app/modules/bookkeeping/models/activity/activity';
import { CreditNoteDTO } from 'src/app/modules/bookkeeping/models/credit-note/credit-note';
import { ArchiveInvoice, ArchiveRequest } from 'src/app/modules/bookkeeping/models/invoice/archive-request';
import { BillsToPayInvoiceDetailsRequest } from 'src/app/modules/bookkeeping/models/invoice/bills-to-pay-invoice-details-request';
import { CreatedInvoice } from 'src/app/modules/bookkeeping/models/invoice/created-invoice';
import { CreatedInvoiceWithLines } from 'src/app/modules/bookkeeping/models/invoice/created-invoice-with-lines';
import { InboxInvoices } from 'src/app/modules/bookkeeping/models/invoice/inbox-invoices';
import { Invoice } from 'src/app/modules/bookkeeping/models/invoice/invoice';
import { SyncedExpClaims } from 'src/app/modules/bookkeeping/models/invoice/invoice-exp-claims';
import { InvoiceShort } from 'src/app/modules/bookkeeping/models/invoice/invoice-short';
import { KeyValuePair } from 'src/app/modules/bookkeeping/models/invoice/key-value-pairs';
import { RecognizedInvoiceService } from 'src/app/modules/bookkeeping/services/recognized-invoice.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { BankTransactionDTO } from '../../modules/bookkeeping/models/expense/bank-transactions';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(
    private _httpService: HttpInternalService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private _toasterMessageService: ToasterMessageService
  ) { }

  uploadInvoice(invoice: CreatedInvoice) {
    const formData = objectToFormData(invoice);

    this._httpService.postClearRequest('Invoices/uploadInvoice' , formData)
      .pipe(take(1))
      .subscribe(
        (invoice: Invoice) => {
          // if(invoice.error != undefined && invoice.error == 'LimitExceed'){
          //   this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
          //     `You’ve exceeded your monthly document limit. Upgrade your subscription. <a  class=\"toastr-link\" href=\"/setting/billing\">Upgrade</a>`);
          // }
          // else{
          if (invoice != null) {
            // this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Document submitted for processing');
              this._recognizedInvoiceService.send(invoice);
              this.writePairsToLocalStorage(invoice);
            }
          // }
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  private writePairsToLocalStorage(invoice: Invoice) {
    const storage = localStorage.getItem('StoredPairs');

    if (storage) {
      let storedPairs = [...JSON.parse(storage)] as KeyValuePair[];
      let existedPair = storedPairs.find(p => p.key === invoice?.id.toString());
      if (!existedPair && invoice.pairs) {
        let pairs = {
          key: invoice.id.toString(),
          value: invoice.pairs
        } as KeyValuePair;
        storedPairs.push(pairs);
      } else if (existedPair) {
        existedPair.key = invoice.id.toString();
        existedPair.value = invoice.pairs;
      }
      localStorage.setItem('StoredPairs', JSON.stringify(storedPairs));
    } else {
      let pairs = [{
        key: invoice?.id?.toString(),
        value: invoice?.pairs
      } as KeyValuePair];
      localStorage.setItem('StoredPairs', JSON.stringify(pairs));
    }
  }

  publishInvoice(invoice: CreatedInvoiceWithLines) {
    return this._httpService.postClearRequest('Invoices/publishInvoice', invoice);
  }

  publishCreditNote(creditNote: CreditNoteDTO) {
    return this._httpService.postClearRequest('Invoices/publishCreditNote', creditNote);
  }

  publishReceipt(bankTransaction: BankTransactionDTO) {
    return this._httpService.postClearRequest('Invoices/PublishReceipt', bankTransaction);
  }

  publishReceiptQBO(bankTransaction: CreatedInvoiceWithLines) {
    return this._httpService.postClearRequest('Invoices/PublishReceiptQBO', bankTransaction);
  }
  updateInvoice(invoice: CreatedInvoiceWithLines){
    return this._httpService.putRequest<boolean>('Invoices/updateInvoice', invoice);
  }

  GetReceiptAttachments(organizationId,invoiceID){
    return this._httpService.getRequest(`Xero/GetReceiptAttachments?organizationId=${organizationId}&invoiceID=${invoiceID}`);
  }

  deleteInvoice(id: string) {
    return this._httpService.deleteRequest(`Invoices/deleteInvoice?id=${id}`);
  }

  getDownloadInvoiceUrl(id: string, organisationId: string, fileName: string = "") {
    return this._httpService.buildUrl(`Invoices/downloadInvoice?id=${id}&organisationId=${organisationId}&fileName=${fileName}`);
  }

  downloadFile(id: string, organisationId: string) {
    return this._httpService.getRequest<Blob>(`Invoices/downloadInvoice?id=${id}&organisationId=${organisationId}`, '', 'blob' );
  }

  downloadZipArchive(archiveRequest: ArchiveRequest) {
    return this._httpService.postRequest<Blob>(`Invoices/DownloadZipArchive`, archiveRequest, null, 'blob');
  }

  archiveInvoice(archivedInvoice: ArchiveInvoice) {
    return this._httpService.postRequest(`Invoices/ArchiveInvoice`, archivedInvoice);
  }

  unarchiveInvoice(archivedInvoice: ArchiveInvoice) {
    return this._httpService.postRequest(`Invoices/UnarchiveInvoice`, archivedInvoice);
  }

  getInvoicesUploadedByUser(id: string, isProcessed: boolean | null = null) {
    return this._httpService.getRequest<InvoiceShort[]>(`Invoices/getInvoicesUploadedByUser?id=${id}&isProcessed=${isProcessed}`);
  }

  getInboxInvoicesUploadedByUser(id: string, page: number, pageSize: number, sortDirection: string, isProcessed: boolean | null = null, searchText :string ='') {
    return this._httpService.getRequest<InboxInvoices>(`Invoices/getInvoicesUploadedByUser?id=${id}&page=${page}&pageSize=${pageSize}&sort=${sortDirection}&isProcessed=${isProcessed}&searchText=${searchText}`);
  }

  getInvoiceById(id: string) {
    return this._httpService.getRequest<Invoice>(`Invoices/getInvoiceById?id=${id}`);
  }

  getBankNameByBSB(bsb: string) {
    return this._httpService.getRequest<{ name: string }>(`Invoices/getBankNameByBSB?bsb=${bsb}`);
  }

  getInvoiceByReferenceId(id: string,orgId:string,referenceID?:string) {
    return this._httpService.getRequest<Invoice>(`Invoices/getInvoiceByReferenceId?id=${id}&organisationId=${orgId}&referenceID=${referenceID}`);
  }

  getInvoiceActivityById(invoiceId: string, organizationId: string) {
    return this._httpService.getRequest<InvoiceActivity[]>(`Invoices/getInvoiceActivityById?invoiceId=${invoiceId}&organizationId=${organizationId}`);
  }

  getInvoiceDetailsForBillsToPay(requestData: BillsToPayInvoiceDetailsRequest) {
    return this._httpService.postRequest<Invoice>('Invoices/GetInvoiceDetailsForBillsToPay', requestData);
  }

  getAllExpClaimsByOrganizationId(organizationId: string, userId: string) {
    return this._httpService.getRequest<any>(`${"Reporting/getExpClaimsByOrganizationId" + "?organisationId=" + organizationId + "&userid=" + userId}`);
  }

  createExpenseReport(syncedExpClaims: SyncedExpClaims) {
    return this._httpService.postClearRequest<{ id: string }>('Reporting/createExpReport', syncedExpClaims);
  }

  createDefaultInvoice(orgnazationId: string) {
    return this._httpService.getRequest('Invoices/GenerateDefaultInvoice?orgnazationId=' + orgnazationId);
  }
  updateSuggestedMatches(model:any) {
    return this._httpService.postRequest('Invoices/UpdateSuggestedMatches', model);
  }

  getRelatedData( organizationId: string,relatedId: string) {
    return this._httpService.getRequest<any>(`Invoices/getRelatedData?organizationId=${organizationId}&relatedId=${relatedId}`);
  }

  getMatchData( organizationId: string,relatedId: string) {
    return this._httpService.getRequest<any>(`Invoices/getMatchData?organizationId=${organizationId}&relatedId=${relatedId}`);
  }
}
