import { Injectable } from '@angular/core';
import { InboxInvoices } from 'src/app/modules/bookkeeping/models/invoice/inbox-invoices';
import { SearchInvoicesResponse, SearchParams, SearchResponse, SearchSuppliersResponse } from '../model/search-result';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(
    private _httpService: HttpInternalService,
  ) { }

  searchInvoices(filterParams: string) {
    return this._httpService.getRequest<InboxInvoices>(`Search/searchInvoices${filterParams}`);
  }

  searchSuppliers(filterParams: string) {
    return this._httpService.getRequest<any>(`Search/searchSuppliers${filterParams}`);
  }

  getMainSearchResult(searchParams: SearchParams) {
    return this._httpService.getRequest<SearchResponse>(`Search/getMainSearchResults?searchTerm=${searchParams.searchTerm}&organizationId=${searchParams.organizationId}&pageSize=${searchParams.pageSize}&page=${searchParams.page}`);
  }

  getMainSearchInvoicesResults(searchParams: SearchParams) {
    return this._httpService.getRequest<SearchInvoicesResponse>(`Search/getMainSearchInvoicesResults?searchTerm=${searchParams.searchTerm}&organizationId=${searchParams.organizationId}&pageSize=${searchParams.pageSize}&page=${searchParams.page}`);
  }

  getMainSearchSuppliersResults(searchParams: SearchParams) {
    return this._httpService.getRequest<SearchSuppliersResponse>(`Search/getMainSearchSuppliersResults?searchTerm=${searchParams.searchTerm}&organizationId=${searchParams.organizationId}&pageSize=${searchParams.pageSize}&page=${searchParams.page}`);
  }
}
