import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HubFactoryService {

  private _hubs = new Map<string, HubConnection>();
  baseUrl: string = environment.apiURL?.replace('api/', '');

  constructor() { }

  createHub(hubName: string) {
    if (this._hubs.has(hubName)) {
      return of(this._hubs.get(hubName));
    }

    let token = localStorage.AccessToken;

    if (token) {
      const hub = this._buildHub(hubName, token);
      this._hubs.set(hubName, hub);
      return of(hub);
    }
  }

  private _buildHub(hubUrl: string, token: string) {
    return new HubConnectionBuilder()
      .withUrl(this._buildUrl(hubUrl), {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => token
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();
  }

  private _buildUrl(hubUrl: string) {
    if (hubUrl.startsWith('http://') || hubUrl.startsWith('https://')) {
      return hubUrl;
    }
    return `${this.baseUrl}${hubUrl}`;
  }
}
