import { Type } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { HttpClient } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { StripePaymentPlanModel } from '../model/stripePayment';
import { HttpInternalService } from './http.service';

@Injectable({
    providedIn: 'root'
  })
  export class StripePaymentService {
    stripePaymentPlanDetails: BehaviorSubject<StripePaymentPlanModel> = new BehaviorSubject<StripePaymentPlanModel>(null);
  
    constructor(private _httpService: HttpInternalService) { }    

    GetPlansFromDatabase(url: string) {
      return this._httpService.getRequest(`${url}`);
    }
    createSubscription(formData: any|null) {
      return this._httpService.postRequest<string>('StripePayment/CreateSubscriptions', JSON.stringify(formData));
    }
    GetAllInvoice(formData: any|null) {
      return this._httpService.postRequest<string>('StripePayment/GetAllInvoice', JSON.stringify(formData));
    }
    CancelSubscription(url) {
      return this._httpService.getRequest(`${url}`);
    }
    GetPaymentMethod(url) {
      return this._httpService.getRequest(`${url}`);
    }
    GetAllCountryList(url) {
      return this._httpService.getRequest(`${url}`);
    }
    SyncStripePlansToDatabase(url) {
      return this._httpService.getRequest(`${url}`);
    }
    updateCustomerPaymentMathod(formData: any|null) {
      return this._httpService.postRequest<string>('StripePayment/UpdateCustomerPaymentMathod', JSON.stringify(formData));
    }
    GetUsageData(formData: any|null) {
      return this._httpService.postRequest<string>('StripePayment/GetUsageData', JSON.stringify(formData));
    }
    SyncSubscriptionsToDatabase(url) {
      return this._httpService.getRequest(`${url}`);
    }
    ValidateCouponCode(couponCode: string) {
      return this._httpService.getRequest<string>('StripePayment/ValidateCouponCode' + "?couponCode=" + couponCode);
    }
  }