import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BankAccount } from '../../modules/bookkeeping/models/account/bank-account'; 
import { CurrentUserModel } from '../model/register-model';

import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  loggedUserDetails: BehaviorSubject<CurrentUserModel> = new BehaviorSubject<CurrentUserModel>(null);

  constructor(private _httpService: HttpInternalService) { }

  private userRole = new BehaviorSubject<number>(0);
  userRole$ = this.userRole.asObservable();

  setUserRoleData(data: number) {    
      this.userRole.next(data);    
  }

  set upUserDetails(data: string) {
    localStorage.setItem('UpUserDetails', data);
  }

  get upUserDetails(): string {
    return localStorage.getItem('UpUserDetails');
  }

  getSelectedUser(url: string, userId: string) {
    return this._httpService.getRequest(`${url + "?UserId=" + userId}`);
  }

  getUserGetById(userId: string) {
    return this._httpService.getRequest<any>(`${"Account/UserGetById" + "?UserId=" + userId}`);
  }

  getUsers(url: string, organizationId: string) {
    return this._httpService.getRequest(`${url + "?Id=" + organizationId}`);
  }

  userActivationOrDeletion(url: string, userId: string, status: boolean, activeDelete: string, organizationId: string) {
    return this._httpService.deleteRequest(`${url + "?Id=" + userId + "&IsActive=" + status + "&ActivationParameter=" + activeDelete + "&organizationId=" + organizationId}`);
  }

  userInsertionOrUpdation(url: string, registerModel) {
    return this._httpService.postRequest(url, JSON.stringify(registerModel));
  }
  getAllUsersByOrganizationId(organizationId: string, userId) {
    return this._httpService.getRequest<any>(`${"Reporting/GetUsersByOrganizationId" + "?organisationId=" + organizationId + "&userId=" + userId}`);
  }
  getUsersByOrganizationId(organizationId: string) {
    return this._httpService.getRequest<any>(`${"Reporting/GetAllUsersByOrganizationId" + "?organisationId=" + organizationId}`);
  }
  getDeletedUsers(url: string, organisationId: string) {
    return this._httpService.getRequest(`${url + "?organisationId=" + organisationId}`);
  }
  ReassignedTaskToUser(registerModel) {
    return this._httpService.postRequest<any>(`${"Reporting/ReassignedTaskToUser"}`, JSON.stringify(registerModel));
  }
  assignOrganizationsToAllUsers(url: string, registerModel) {
    return this._httpService.postRequest(url, JSON.stringify(registerModel));
  }

  updateUserLastLogin(model: any) {
    return this._httpService.postRequest(`${"Account/UpdateUserLastLogin"}`, JSON.stringify(model));
  }

  insertUpdateUserBankDetail(model: BankAccount) {
    return this._httpService.postRequest<any>(`${"Account/InsertUpdateUserBankDetail"}`, JSON.stringify(model));
  }
}
