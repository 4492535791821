import { Component, Input, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ScreenBrakepoints } from 'src/app/data/model/screen-brakepoints';

@Component({
  selector: 'app-table-placeholder',
  templateUrl: './table-placeholder.component.html',
  styleUrls: ['./table-placeholder.component.scss']
})
export class TablePlaceholderComponent {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() screenBrakepoint: ScreenBrakepoints = ScreenBrakepoints.MinWidth769px;

  list = Array.from({ length: 5 }, () => new Object());
  columnMode = ColumnMode;
  screenBrakepoints = ScreenBrakepoints;
}
