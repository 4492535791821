import { Injectable } from '@angular/core';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { RecoverModel } from '../model/recover-model';

import { RegisterService } from '../service/register.service'

@Injectable()
export class UserValidator {
  constructor(private registerService: RegisterService) { }

  uniqueEmailValidator(url): AsyncValidatorFn {
    return (control: AbstractControl): any => {      
      return this.registerService.searchUserByEmail(url, (new RecoverModel(control.value, ''))).pipe(
        map(res => {
          if ((<any>res).status === 1) {
            return { 'emailIsBusy': true };
          }
        })
      );
    };
  }

}

export const BSBPattern = /\d{3}-\d{3}/i;
export const AccountNumberPattern = /^[0-9]*$/i;
export const RoutingPattern = /^[0-9]*$/gi;
