import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { SidebarType } from '../../enums/sidebar-type';
import { UpdatedSettings } from '../../models/settings/updated-settings';
import { TaxRate } from '../../models/taxRate';
import { TrackingCategory } from '../../models/tracking-categories.ts/trackingCategory';
import { Subscription } from 'rxjs';
import { AccountingIntegration } from 'src/app/data/enums/accounting-integration';
import { TaxBasis } from '../../enums/taxBasis';
import { SelectTaxBasis } from '../../data/common';

@Component({
  selector: 'app-settings-sidebar',
  templateUrl: './settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.scss']
})
export class SettingsSidebarComponent extends BaseComponent implements OnInit {
  readonly maxSelectedTrackingCategories: number = 2;

  @Output() isClosed = new EventEmitter<SidebarType>();

  loading: boolean = false;
  taxRatesLoading: boolean = false;
  trackingCategoriesLoading: boolean = false;
  selectedReportName: string = "Inbox Settings";
  organizationEmail: string = "";
  taxRates: TaxRate[] = [];
  taxRatesExclusive: TaxRate[] = [];
  selectedTaxRateId: string = null;
  trackingCategories: TrackingCategory[] = [];
  selectedTrackingCategoriesIds: string[] = [];
  //qboTaxDefault = {id:"0",name:"Ignore Tax",rate:0};
  organisationName = "";
  subscriptions: Subscription[] = [];
  IsQuickBook: boolean = false;
  showToolTipDelay: number = 400;
  selectedTaxBasis: number;
  selectedTaxExclusiveRateId: string = null;
  taxBasisItem = SelectTaxBasis  
    //[{ id: 1, name: "Ignore Tax" },
    //{ id: 2, name: "Default Rate" },
    //{ id: 3, name: "Extracted Amount" }
    //]
  constructor(
    private _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
    private _clipboardService: ClipboardService,
  ) {
    super();
  }

  ngOnInit() {
    this.setIsQuickBookOrNot();
    this.getTaxRates();
    this.getSelectedTaxRate();
    this.getTrackingCategories();
    this.getSelectedTrackingCategories();
    this.getOrganizationEmail();
    this.organisationName = this._organizationService.selectedOrganizationName;
  }

  setIsQuickBookOrNot() {
    this.subscriptions.push(
      this._organizationService.organizationList
        .subscribe(data => {
          const org = data?.find(o => o.organisationID === this._organizationService.selectedOrganization);
          this.IsQuickBook = org?.accountingIntegration === AccountingIntegration.QBO ? true : false;
        }));
  }

  getSelectedTaxRate() {
    this.taxRatesLoading = true;
    this._organizationService.getOrganizationTaxRateId(this._organizationService.selectedOrganization)
      .pipe(this.untilThis,
        finalize(() => this.taxRatesLoading = false))
      .subscribe(
        (response) => {
          this.selectedTaxBasis = response.taxBasis
          this.selectedTaxRateId = response.taxRateId;
          this.selectedTaxExclusiveRateId = response.taxExclusiveRateId;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  getSelectedTrackingCategories() {
    this.trackingCategoriesLoading = true;
    this._organizationService.getOrganizationTrackingCategories(this._organizationService.selectedOrganization)
      .pipe(this.untilThis,
        finalize(() => this.trackingCategoriesLoading = false))
      .subscribe(
        (categories: string[]) => {
          this.selectedTrackingCategoriesIds = categories;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  getTaxRates() {
    this._organizationService.getTaxRates(this._organizationService.selectedOrganization, true)
      .pipe(this.untilThis)
      .subscribe(
        (taxRates: TaxRate[]) => {
          this.taxRates = taxRates;
          this.taxRatesExclusive = taxRates.filter((x) => x.rate == 0);

        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  getTrackingCategories() {
    this._organizationService.getTrackingCategories(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (trackingCategories: TrackingCategory[]) => {
          this.trackingCategories = trackingCategories;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  getOrganizationEmail() {
    this._organizationService.getOrganizationEmail(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (response) => {
          this.organizationEmail = response.email;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  checkTrackingCategoriesNumber(): boolean {
    if (this.selectedTrackingCategoriesIds.length > this.maxSelectedTrackingCategories) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Use no more than two categories.');
      return false;
    }

    return true;
  }

  save() {
    if (this.selectedTaxBasis == TaxBasis.IgnoreTax) {
      this.selectedTaxRateId = null;
      this.selectedTaxExclusiveRateId = null;
    }
    if (this.selectedTaxBasis == TaxBasis.DefaultRate && (this.selectedTaxRateId == null || this.selectedTaxRateId == '')) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
      return;
    }
    if (this.selectedTaxBasis == TaxBasis.ExtractedAmount && (!this.IsQuickBook && ((this.selectedTaxRateId == null || this.selectedTaxRateId == '') || (this.selectedTaxExclusiveRateId == null || this.selectedTaxExclusiveRateId == '')))) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Select Tax Rate.');
      return;
    }
    else {
      if (this.IsQuickBook && this.selectedTaxBasis == TaxBasis.ExtractedAmount)
        this.selectedTaxExclusiveRateId = "-1";
      else if (this.selectedTaxBasis == TaxBasis.DefaultRate) {
        this.selectedTaxExclusiveRateId = null;
      }
      else {
        this.selectedTaxExclusiveRateId = this.selectedTaxExclusiveRateId ?? null;
      }
    }
    if (!this.checkTrackingCategoriesNumber())
      return;

    this.updateSettings(new UpdatedSettings(
      this._organizationService.selectedOrganization,
      this.selectedTaxBasis,
      this.selectedTaxRateId ?? null,
      this.selectedTrackingCategoriesIds,
      this.selectedTaxExclusiveRateId ?? null));
  }

  updateSettings(updatedSettings: UpdatedSettings) {
    this.loading = true;

    this._organizationService.updateOrganizationSettings(updatedSettings)
      .pipe(this.untilThis,
        finalize(() => this.loading = false))
      .subscribe(
        () => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Settings updated!');
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  cancel() {
    this.isClosed.emit(SidebarType.settings);
  }

  copyEmail() {
    this._clipboardService.copyFromContent(this.organizationEmail);
    this._toasterMessageService.displayToasterState(ToasterTypes.Info, '', 'Email copied to clipboard!');
  }
}
