<div class="contact-container">
  <div class="back-area" *ngIf="!supplierTabMode" (click)="cancel()">
    <div class="shevron">
      <img src="../../../../../assets/img/invoiceForm/shevron-white.svg" alt="">
    </div>
    <div class="text">Back to Invoice</div>
  </div>
  
  <section class="controls-panel">
    <div class="logo-container">
      <!-- <div class="logo" [ngClass]="{'sup_img_box_1': invoiceColorNumber == 1,'sup_img_box_2': invoiceColorNumber == 2,'sup_img_box_3': invoiceColorNumber ==3,'sup_img_box_4': invoiceColorNumber == 4,'sup_img_box_5': invoiceColorNumber == 5,'sup_img_box_6': invoiceColorNumber == 6}">
        <span>{{supplier.name | shortLogo}}</span>
      </div> -->
      <div class="logo {{supplier.hexColorClass}}">
        <span>{{supplier.name | shortLogo}}</span>
      </div>
    </div>
    <div class="title">
      <span>{{supplier.name}}</span>
    </div>
    <div class="control" *ngIf="false">
      <img class="icon-left" matTooltip="Move back" [matTooltipShowDelay]="showToolTipDelay" (click)="cancel()"
        src="../../../../../assets/img/invoiceForm/arrow-left.svg"/>
    </div>
    <div class="close-icon" matTooltip="Hide Side Bar" [matTooltipShowDelay]="showToolTipDelay"
      (click)="closeFormOnSupplier()" *ngIf="supplierTabMode">
        <img src="../../../../../assets/img/close-icon.svg" alt="">
    </div>
  </section>

  <section class="tab-header">
    <div class="_tab" [ngClass]="currentPannel == panels.DetailsTab ? 'active' : ''" (click)="tabClick(panels.DetailsTab)">
      <div class="caption">Details</div>
      <div class="underline"></div>
    </div>
    <div class="_tab" [ngClass]="currentPannel == panels.ActivityTab ? 'active' : ''" (click)="tabClick(panels.ActivityTab)">
      <div class="caption">Activity</div>
      <div class="underline"></div>
    </div>
    <div class="_tab" [ngClass]="currentPannel == panels.RelatedTab ? 'active' : ''" (click)="tabClick(panels.RelatedTab)">
      <div class="caption">Related</div>
      <div class="underline"></div>
    </div>
  </section>


  <section class="content" *ngIf="currentPannel == panels.DetailsTab">
    <form #form="ngForm">
      <!-- Supplier -->
      <div class="form-group">
        <div class="field-label">Name</div>
        <div class="field-input">
          <input [(ngModel)]="supplier.name" name="name" class="frm-input" type="text" [ngClass]="isEditable ? 'control-active' : ''"
                 [placeholder]="isEditable ? 'Input Supplier Name' : 'No Supplier Name'" required (change)="saveContactForm('Name')" [readonly]="!isEditable">
        </div>
      </div>

      <!-- Alias -->
      <div class="form-group">
        <div class="field-label">Alias</div>
        <div class="field-input">
          <ul class="list" *ngIf="!supplier?.aliases || supplier?.aliases?.length == 0">
            <li>No aliases</li>
          </ul>
          <ul class="list" *ngIf="supplier?.aliases?.length > 0">
            <li *ngFor="let alias of supplier.aliases">{{alias.alias}}</li>
          </ul>
        </div>
      </div>

      <!-- Tax Number -->
      <div class="form-group">
        <div class="field-label">Tax Number</div>
        <div class="field-input">
          <input [(ngModel)]="supplier.taxNumber" name="taxNumber" class="frm-input" type="text" [ngClass]="isEditable ? 'control-active' : ''"
                 [placeholder]="isEditable ? 'Input Tax Number' : 'No Tax Number'" (change)="saveContactForm('Tax Number')" [readonly]="!isEditable">
        </div>
      </div>

      <!-- Groups -->
      <div class="form-group margin-bottom">
        <div class="field-label" style="margin-top: 2px;">Groups</div>
        <div class="field-input">
          <ng-multiselect-dropdown [placeholder]="isEditable ? 'Select Contact Group (optional)' : 'No Contact Group'"
                                   [settings]="dropdownSettings"
                                   [data]="suppliersGroup"
                                   [(ngModel)]="selectedItems"
                                   (onSelect)="onItemSelect($event)"
                                   (onSelectAll)="onSelectAll($event)"
                                   (onDeSelect)="onDeleteGroup($event)"
                                   name="suppliersGroup"
                                   class="custom_mat_select"
                                   [ngClass]="isEditable ? 'active' : ''">
          </ng-multiselect-dropdown>

        </div>
      </div>

      <!-- Default Account -->
      <div class="form-group">
        <div class="field-label">Default Account</div>
        <div class="field-input">
          <input [ngModel]="supplier.defaultAccount.name"
                 [typeahead]="accounts"
                 typeaheadOptionField="name"
                 [typeaheadScrollable]=true
                 (typeaheadOnSelect)="onSelectAccount($event)"
                 [typeaheadOptionsInScrollableView]="10"
                 [typeaheadMinLength]="0"
                 [typeaheadOptionsLimit]="accounts.length"
                 class="default_account"
                 [placeholder]="isEditable ? 'Select Account' : 'No Account'"
                 [ngModelOptions]="{standalone: true}"
                 namw="defaultAccount"
                 [disabled]="!isEditable"
                 [ngClass]="isEditable ? 'control-active default_account_active' : ''">
        </div>
      </div>

      <!-- Default Tax Rate -->
      <div class="form-group">
        <div class="field-label">Default Tax Rate</div>
        <div class="field-input">
          <input [ngModel]="supplier.accountsPayableTaxName"
                 [typeahead]="taxRates"
                 typeaheadOptionField="name"
                 [typeaheadScrollable]=true
                 (typeaheadOnSelect)="onSelectTaxRate($event)"
                 [typeaheadOptionsInScrollableView]="10"
                 [typeaheadMinLength]="0"
                 [typeaheadOptionsLimit]="taxRates.length"
                 class="default_account"
                 [placeholder]="isEditable ? 'Select Tax Rate' : 'No Rate'"
                 [ngModelOptions]="{standalone: true}" 
                 [disabled]="!isEditable"
                 [ngClass]="isEditable ? 'control-active default_account_active' : ''">
        </div>
      </div>

      <!-- Default Currency -->
      <div class="form-group">
        <div class="field-label">Default Currency</div>
        <div class="field-input">
          <input [(ngModel)]="supplier.currency"
                 [typeahead]="currencies"
                 [typeaheadScrollable]=true
                 [typeaheadOptionsInScrollableView]="10"
                 [typeaheadOptionsLimit]="currencies?.length"
                 [typeaheadMinLength]="isEditable ? 0 : 1"
                 class="default_account"
                 [placeholder]="isEditable ? 'Select Currency' : 'No Currency'"
                 [ngModelOptions]="{standalone: true}"
                 [disabled]="!isEditable"
                 [ngClass]="isEditable ? 'control-active default_account_active' : ''"
                 (typeaheadOnSelect)="saveContactForm('Currency')">
        </div>
      </div>

      <!-- Contact -->
      <div class="form-group">
        <div class="field-label">Contact</div>
        <div class="field-input">
          <input [(ngModel)]="supplier.firstName" name="firstName" class="frm-input contact" type="text" [ngClass]="isEditable ? 'control-active' : ''"
                 [placeholder]="isEditable ? 'Add First Name' : 'No First Name'" (change)="saveContactForm('First Name')" [readonly]="!isEditable">
          <input [(ngModel)]="supplier.lastName" name="lastName" class="frm-input contact" type="text" [ngClass]="isEditable ? 'control-active' : ''"
                 [placeholder]="isEditable ? 'Add Last Name' : 'No Last Name'" (change)="saveContactForm('Last Name')" [readonly]="!isEditable">
          <input [(ngModel)]="supplier.email" name="email" class="frm-input contact" type="email" [placeholder]="isEditable ? 'Add Email' : 'No Email'" [ngClass]="isEditable ? 'control-active' : ''"
                 email (change)="saveContactForm('Email')" [readonly]="!isEditable">
        </div>
      </div>

      <!-- Payment -->
      <section class="payment">
        <div class="top-line"></div>
        <div class="payment-title">Payment Default</div>
        <div class="form-group">
          <div class="field-label">Method</div>
          <div class="field-input">
            <select class="form-control form-select" name="paymentDetails" [disabled]="!isEditable">
              <option *ngFor="let type of paymentMethods" [ngValue]="type.key">
                {{type.text}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group" *ngIf="countryCode != 'US'">
          <div class="field-label">BSB</div>
          <div class="field-input">
            <span class="name">{{bankName}}</span>
            <input [ngModel]="bankAccount.branchDetails" name="BSB" #BSB="ngModel" class="form-control form-input" type="text"
                   pattern="\d{3}-\d{3}" [placeholder]="isEditable ? 'Enter BSB' : 'No BSB'" autocomplete="off" (ngModelChange)="updateBSB($event)"
                   (change)="saveBSBAccountPair()" [readonly]="!isEditable" [ngClass]="isEditable ? 'control-active' : ''">
            <div *ngIf="BSB.invalid && (BSB.dirty || BSB.touched)" class="error-message">
              <div *ngIf="BSB.errors?.pattern">
                BSB must be 6 digits long and have the following format: XXX-XXX.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="countryCode != 'US'">
          <div class="field-label">Account No.</div>
          <div class="field-input account-number">
            <input [(ngModel)]="bankAccount.accountNumber" name="accountNumber" class="form-control form-input" type="text"
                   pattern="\d+" #accountNumber="ngModel" [placeholder]="isEditable ? 'Enter Account #' : 'No Account #'" autocomplete="off"
                   (change)="saveBSBAccountPair()" [readonly]="!isEditable" [ngClass]="isEditable ? 'control-active' : ''">
            <div *ngIf="accountNumber.invalid && (accountNumber.dirty || accountNumber.touched)" class="error-message">
              <div *ngIf="accountNumber.errors?.pattern">
                Account number must contain only numbers.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="countryCode == 'US'">
          <div class="field-label">Routing No.</div>
          <div class="field-input">
            <input [ngModel]="bankAccount.routingNumber" name="Routing" #Routing="ngModel" class="form-control form-input" type="text"
                   pattern="\d{9}" [placeholder]="isEditable ? 'Enter Routing Number' : 'No Routing Number'" autocomplete="off" (ngModelChange)="updateRouting($event)"
                   (change)="saveRoutingAccountPair()" (keypress)="numberOnly($event)" maxlength="9" [readonly]="!isEditable" [ngClass]="isEditable ? 'control-active' : ''">
            <div *ngIf="Routing.invalid && (Routing.dirty || Routing.touched)" class="error-message">
              <div *ngIf="Routing.errors?.pattern">
                Routing must be 9 digits long and have the following format: XXXXXXXXX.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="countryCode == 'US'">
          <div class="field-label">Account No.</div>
          <div class="field-input account-number">
            <input [(ngModel)]="bankAccount.accountNumberUS" name="accountNumberUS" class="form-control form-input" type="text"
                   pattern="\d+" #accountNumberUS="ngModel" [placeholder]="isEditable ? 'Enter Account #' : 'No Account #'" autocomplete="off"
                   (change)="saveRoutingAccountPair()" [readonly]="!isEditable" [ngClass]="isEditable ? 'control-active' : ''">
            <div *ngIf="accountNumberUS.invalid && (accountNumberUS.dirty || accountNumberUS.touched)" class="error-message">
              <div *ngIf="accountNumberUS.errors?.pattern">
                Account number must contain only numbers.
              </div>
            </div>
          </div>
        </div>
      </section>
      <button class="btn-save" id="saveContactFormButton" (click)="submit('BSB and Account Number')"></button>
    </form>
  </section>
  
  <!-- Activity Panel -->
  <app-activity *ngIf="currentPannel == panels.ActivityTab" (moveBack)="showActivityPanel()" [isInvoiceMode]="false" [supplierId]="supplier.supplierId"></app-activity>

  <!-- Related Panel -->
  <app-related-contact 
    *ngIf="currentPannel == panels.RelatedTab"
    [billsToPayMode]="billsToPayMode"
    [supplier]="supplier"
    (clickInvoiceEvent)="clickOnRelatedInvoice($event)">
  </app-related-contact>
</div>
