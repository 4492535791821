import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const ZOOM_STEP:number = 0.25;
const DEFAULT_ZOOM:number = 1;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @Input() tempFile: string;
  @Input() showButtons: boolean = true;
  @Output() downloadButtonClick = new EventEmitter<void>();

  page: number = 1;
  totalPages: number;
  public pdfZoom:number = DEFAULT_ZOOM;
  public showToolTipDelay: number = 400;
  public pageScale: number = 100;

  ngOnInit(): void {
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  public zoomIn()
	{
    this.pdfZoom = this.pdfZoom + 0.25;
    this.pageScale = this.pageScale + 25;
	}

  public zoomReset()
	{
    this.pdfZoom = 1;
    this.pageScale = 100;
	}

	public zoomOut()
	{
    if (this.pdfZoom > 1) {
      this.pdfZoom = this.pdfZoom - 0.25;
      this.pageScale = this.pageScale - 25;
   }
	}

  public downloadPdf() {
    this.downloadButtonClick.emit();
  }
}
