import { InvoiceShort } from "../models/invoice/invoice-short";

export const inProcessingRecord: InvoiceShort = {
    id: null,
    date: null,
    amountDue: null,
    supplierName: "Processing",
    invoiceNumber: "Extracting Details ...",
    categories: [],
    shortCode:"",
    rowCount:0,
    contactStatus:"",
    accountName:""
};