<div class="section">
  <div class="section-number">{{index + 1}})</div>
  <div class="section-input">
    <!-- Account -->
    <div class="line">
      <ng-select #select
        placeholder="Select Account"
        class="custom-select"
        [items]="accounts"
        [searchable]="true"
        [clearable]="false"
        bindLabel="name"
        bindValue="id"
        [(ngModel)]="invoiceLine.accountId"
        name="accountName"
        (ngModelChange)="updateAccountCode($event)"
        [ngModelOptions]="{standalone: true}">
      </ng-select>
      <!-- Total -->
      <input [ngModel]="invoiceLine.subTotal | number" #amountDue="ngModel" name="amountDue" class="value custom-input center editable width-limit"
        (focusout)="updateInvoiceLineTotals($event)" placeholder="Total" [ngModelOptions]="{standalone: true}" required
        [ngClass]="amountDue.errors && (amountDue.dirty || amountDue.touched) ? 'error' : ''"
            >
    </div>
    <div class="line">
      <!-- Tax Rates -->
      <ng-select [placeholder]="'Select Tax Rate'" [searchable]="false"
        [clearable]="false" [ngModel]="selectedTaxRateId" 
        (ngModelChange)="updateTaxRate($event)"
        class="custom-select tax-select" [ngModelOptions]="{standalone: true}">
        <ng-option *ngFor="let tax of taxRates" [value]="tax.id">
          <div class="tax-item">
            <span class="tax-value">{{(invoiceLine.amountDue - (100 * invoiceLine.amountDue) / (100 + tax.rate)) | number : '1.0-2'}}</span>
            <span class="tax-name">{{tax.name}}</span>
          </div>
        </ng-option>
      </ng-select>
      <input [ngModel]="invoiceLine.totalTax | number" (ngModelChange)="invoiceLine.totalTax=converter.stringToFloat($event)"
        name="totalTax" class="value tax-value custom-input center width-limit" placeholder="Tax" readonly
        [ngModelOptions]="{standalone: true}">
    </div>
    <!-- Tracking Category #1 -->
    <div class="line" *ngIf="trackingCategories.length > 0 && (isTouched || invoiceLine.trackingOptions[0])">
      <ng-select #selectOption1
                  [placeholder]="'Select '+ trackingCategories[0].name"
                  class="custom-select"
                  [items]="trackingCategories[0]?.options"
                  [searchable]="false"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="selectedTrackingCategories[0]"
                  name="tracking-category1"
                  (ngModelChange)="onSelectCategory($event, 0)"
                  [ngModelOptions]="{standalone: true}"
                  matTooltip="{{trackingCategories[0]?.name}}" [matTooltipShowDelay]="showToolTipDelay">
      </ng-select>
    </div>
    <!-- Tracking Category #2 -->
    <div class="line" *ngIf="trackingCategories.length > 1 && (isTouched || invoiceLine.trackingOptions[1])">
      <ng-select #selectOption2 
                  [placeholder]="'Select '+trackingCategories[1].name"
                  class="custom-select" 
                  [items]="trackingCategories[1]?.options" 
                  [searchable]="false"
                  bindLabel="name" 
                  bindValue="id"
                  [(ngModel)]="selectedTrackingCategories[1]"
                  name="tracking-category2"
                  (ngModelChange)="onSelectCategory($event, 1)"
                  [ngModelOptions]="{standalone: true}"
                  matTooltip="{{trackingCategories[1]?.name}}" [matTooltipShowDelay]="showToolTipDelay">
      </ng-select>
    </div>
    <!-- Details -->
    <div class="line">
      <input [(ngModel)]="invoiceLine.description" name="description" placeholder="Add Details" class="value custom-input editable"
        type="text" (keyup)="updateInvoiceLine()" autocapitalize="sfksljflsdjkf" aria-autocomplete="none"
        [ngModelOptions]="{standalone: true}" *ngIf="invoiceLine.description || isTouched">
    </div>
  </div>
  <div class="close">
    <i class="fas fa-times" (click)="deleteInvoiceLine()" matTooltip="Delete this line" [matTooltipShowDelay]="showToolTipDelay"></i>
  </div>
</div>
