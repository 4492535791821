<div class="wizzard-container">
  <section class="side-panel"></section>
  <section class="main-content">
    <section class="title-section">
      <div class="company-name">
        {{organisationName}}
      </div>
    </section>
    <section class="tab-header-section">
      <div class="tab-name" (click)="selectTab(wizzardTabs.Data)" [ngClass]="{'tab-active' : currentTab == wizzardTabs.Data , 'completed-tab': isShowSyncingTab ? allowedTabs.includes(1) : isConnectionDone ? allowedTabs.includes(1) : false}">
        Data
        <img *ngIf="isShowSyncingTab ? allowedTabs.includes(1) : isConnectionDone ? allowedTabs.includes(1) : false" src="../../../../../assets/img/signup/checkmark.svg" alt="">
      </div>
      <div class="tab-name" (click)="selectTab(wizzardTabs.Inbox)" [ngClass]="{'tab-active' : currentTab == wizzardTabs.Inbox , 'completed-tab': allowedTabs.includes(2)}">
        Inbox
        <img *ngIf="allowedTabs.includes(2)" src="../../../../../assets/img/signup/checkmark.svg" alt="">
      </div>
      <div class="tab-name" (click)="selectTab(wizzardTabs.Bookkeeping)" [ngClass]="{'tab-active' : currentTab == wizzardTabs.Bookkeeping , 'completed-tab': allowedTabs.includes(3)}">
        Bookkeeping
        <img *ngIf="allowedTabs.includes(3)" src="../../../../../assets/img/signup/checkmark.svg" alt="">
      </div>
      <div class="tab-name" (click)="selectTab(wizzardTabs.PaymentSettings)" [ngClass]="{'tab-active' : currentTab == wizzardTabs.PaymentSettings , 'completed-tab': allowedTabs.includes(4)}">Payments</div>
    </section>
    <div class="custom-mat-spinner" *ngIf="isSpinnerShown">
      <mat-spinner></mat-spinner>
      <div class="text">Data loading...</div>
    </div>
    <section class="tab-content-section">
      <section class="column-1">
        <!-- Tab 1 -->
        <div class="content-1" *ngIf="currentTab == wizzardTabs.Data && loadTab">
          <ng-container *ngIf="!isShowSyncingTab && !showNoAccountingSection">
            <form class="signup-form" role="form" name="loginForm">
              <div class="step-header">Sync To Your Accounts</div>
              <div class="step-header-second">Sync bills, contacts & more from your accounts</div>
              <div class="rh_in">
                <div class="integrationBox">
                  <div *ngFor="let org of integrationList">
                    <div class="integrationEachBox" *ngIf="org.type === 'Integration'">
                      <img src='{{org.logoURL}}' alt="image" class="logo-img" />
                      <div style="font-size: 16px; font-weight: 600; width: 100%; text-align: center;">{{org.name}}</div>
                      <div style="font-size: 12px; font-weight: 400; width: 100%; text-align: center;margin-top: 15px;">{{org.shortDesc}}</div>
                      <ng-container *ngIf="connectedToXero === false && connectedToQBO === false">
                        <ng-container class="" *ngIf="org.name == 'Xero'">
                          <a (click)="openXeroWindow()" class="signup-connect-link" [ngClass]="{'connected':connectedToXero === true,'disabled':connectedToQBO === true}">
                            <img src="../../../../assets/img/connect-xero-blue.svg" alt="" class="domain-img">
                          </a>
                        </ng-container>
                        <ng-container class="form-group" *ngIf="org.name == 'QBO'">
                          <a (click)="openQuickBookWindow()" class="signup-connect-link ml10" [ngClass]="{'connected':connectedToQBO === true,'disabled':connectedToXero === true}">
                            <img src="../../../../assets/img/connect-qbo-img.svg" class="qboImgHover domain-img" alt="">
                          </a>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="without-accounting">
                  <a (click)="continueWOAccounting()" style="text-decoration:underline; cursor:pointer">Continue Without Accounting Integration</a>
                </div>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="isShowSyncingTab && !showNoAccountingSection">
            <div class="tab-title">Syncing Your Data</div>
            <div class="tab-subtitle">
              <div>
                We’re syncing data from your Xero account.
              </div>
              <div>
                This usually only takes a few mins!
              </div>
            </div>
            <div class="download-list">
              <div class="list-item">
                <div class="checkmark">
                  <div class="spinner-wrapper" *ngIf="stage == 1"><mat-spinner [diameter]="20"></mat-spinner></div>
                  <img *ngIf="stage != 1 && stages.includes(1)" src="../../../../../assets/img/signup/checkmark.svg" alt="">
                </div>
                <div class="text">Unpaid Bills</div>
              </div>
              <div class="list-item">
                <div class="checkmark">
                  <div class="spinner-wrapper" *ngIf="stage == 2"><mat-spinner [diameter]="20"></mat-spinner></div>
                  <img *ngIf="stage != 2 && stages.includes(2)" src="../../../../../assets/img/signup/checkmark.svg" alt="">
                </div>
                <div class="text">Accounts</div>
              </div>
              <div class="list-item">
                <div class="checkmark">
                  <div class="spinner-wrapper" *ngIf="stage == 3"><mat-spinner [diameter]="20"></mat-spinner></div>
                  <img *ngIf="stage != 3 && stages.includes(3)" src="../../../../../assets/img/signup/checkmark.svg" alt="">
                </div>
                <div class="text">Contacts</div>
              </div>
              <div class="list-item">
                <div class="checkmark">
                  <div class="spinner-wrapper" *ngIf="stage == 4"><mat-spinner [diameter]="20"></mat-spinner></div>
                  <img *ngIf="stage != 4 && stages.includes(4)" src="../../../../../assets/img/signup/checkmark.svg" alt="">
                </div>
                <div class="text">Tax Rates</div>
              </div>
              <div class="list-item" *ngIf="!IsQuickBook">
                <div class="checkmark">
                  <div class="spinner-wrapper" *ngIf="stage == 5"><mat-spinner [diameter]="20"></mat-spinner></div>
                  <img *ngIf="stage != 5 && stages.includes(5)" src="../../../../../assets/img/signup/checkmark.svg" alt="">
                </div>
                <div class="text">Tracking Categories</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="showNoAccountingSection">
            <div class="tab-title">Setting Up Your Account</div>
            <div class="tab-subtitle">
              <div class="step-header-second">
                To make your setting defaults most relevant to you, please select what country your business is incorportated in.

              
              </div>
              <div class="step-header-second">
                 Which country does your business operate in?
              </div>
              <div class="block-title">Country</div>
              <div>
                <ng-select placeholder="Select Country"
                           class="custom"
                           [items]="countryList"
                           [searchable]="true"
                           [clearable]="false"
                           bindLabel="name"
                           bindValue="name"
                           [(ngModel)]="selectedCountry">
                </ng-select>

              </div>
            </div>
          </ng-container>
        </div>
        <!-- Tab 2-->
        <div class="content-2" *ngIf="currentTab == wizzardTabs.Inbox">
          <div class="tab-title">Document Submission</div>
          <div class="tab-subtitle">
            <div>
              Synced makes it easy to process your business expenses
            </div>
          </div>
          <div class="integrationBox">
            <div>
              <div class="EmailBox col-sm-12">
                <div class="col-sm-2">
                  <img src="../../../../../assets/img/signup/email.png" alt="" style="width:50px">
                </div>
                <div class="col-sm-10 font-400">
                  Your Synced Inbox - Simply Forward Your Emails to This Address
                  <br /><br />
                  <span class="font-600" style="cursor:pointer" matTooltip="Click to copy to clipboard" [matTooltipShowDelay]="showToolTipDelay" (click)="copyEmail()">{{orgEmail}}</span>
                  <br /><br />
                  *Pro-tip - save this address to your contacts to make bill forwarding a breeze
                </div>
              </div>
            </div>
          </div>
          <ng-container>
            <div class="rh_in">
              <div class="GmailoutlookBox"> 
                <div *ngFor="let account of syncedGmailAccountList">
                  <ng-container *ngIf="account.isConnected">
                    <div class="GmailoutlookBoxEachBox">
                      <div class="div-account">
                        
                        <img src='{{account.logoUrl}}' alt="image" class="logo-img" /> 
                        <div>
                          {{account.name}}<br />
                          {{account.email}}
                        </div>
                        <div class="connected">
                          <i class="fa fa-circle" style="margin-right: 5px; color:#30d37b"></i>
                          Connected
                        </div>
                        <div style="cursor:pointer">
                          <p class="back1" (click)="showDeleteConfirmation(account.integration,account.id)">
                            <img src="../../../../../assets/img/company/deleteicon.svg" alt="" /> Remove Connection
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="tab-subtitle">
            <div>
              Or add one of your Gmail or Outlook accounts
            </div>
          </div>
          <ng-container>
            <div class="rh_in">
              <div class="integrationBox">
                <div *ngFor="let org of addonList">
                  <div class="AddonEachBox addon-logo-img" *ngIf="org.type === 'Integration'">
                    <img src='{{org.logoURL}}' alt="image" class="logo-img " (click)="connect(org)" />
                    <div style="font-size: 16px; font-weight: 600; width: 100%; text-align: center;">{{org.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Tab 3 -->
        <div class="content-3" *ngIf="currentTab == wizzardTabs.Bookkeeping">
          <div class="tab-title">Bookkeeping Settings</div>
          <div class="tab-subtitle">
            <div>Upon submission, Synced's AI effortlessly extracts information from your expenses. </div>
          </div>
          <div class="block">
            <div class="block-title">Taxes</div>
            <div class="block-text">Do you want to track taxes paid on your expenses?  </div>
            <div class="extract-taxes">
              <ng-select placeholder="Select Tax Basis"
                         class="custom"
                         [items]="taxBasisItem"
                         [searchable]="false"
                         [clearable]="false"
                         bindLabel="name"
                         bindValue="id"
                         (change)="saveTaxAndTrackingCategories()"
                         [(ngModel)]="selectedTaxBasis">
              </ng-select>
              <ng-container *ngIf="selectedTaxBasis == 2">
                <div class="block-text pb-3 pt-3">What is your default tax rate on expenses?</div>
                <ng-select placeholder="Select Tax Rate"
                           class="custom"
                           [items]="taxRates"
                           [searchable]="false"
                           [clearable]="false"
                           bindLabel="name"
                           bindValue="id"
                           (change)="saveTaxAndTrackingCategories()"
                           [loading]="taxRatesLoading"
                           [(ngModel)]="selectedTaxRateId">
                </ng-select>
              </ng-container>
              <ng-container *ngIf="selectedTaxBasis == 3 ">
                <div class="block-text pb-3 pt-3">
                  What is your default tax INCLUSIVE rate?
                </div>
                <ng-select placeholder="Select Tax Rate"
                           class="custom"
                           [items]="taxRates"
                           [searchable]="false"
                           [clearable]="false"
                           bindLabel="name"
                           bindValue="id"
                           (change)="saveTaxAndTrackingCategories()"
                           [loading]="taxRatesLoading"
                           [(ngModel)]="selectedTaxRateId">
                </ng-select>
                <div class="block-text pb-3 pt-3">
                  What is your default tax EXCLUSIVE rate?
                </div>
                <ng-select placeholder="Select Tax Rate"
                           class="custom"
                           [items]="taxRatesExclusive"
                           [searchable]="false"
                           [clearable]="false"
                           bindLabel="name"
                           bindValue="id"
                           (change)="saveTaxAndTrackingCategories()"
                           [loading]="taxRatesLoading"
                           [(ngModel)]="selectedTaxExclusiveRateId">
                </ng-select>
              </ng-container>
            </div>
            <div class="bottom-line" *ngIf="!isNoAccounting"></div>
          </div>
          <div class="block" *ngIf="!IsQuickBook && !isNoAccounting">
            <div class="block-title">Expense Tracking</div>
            <div class="block-text">Do you want to include Xero Tracking Categories when coding your expenses in Synced?</div>
            <div class="categories">
              <ng-select placeholder="None Selected"
                         class="custom"
                         [items]="trackingCategories"
                         [multiple]="true"
                         bindLabel="name"
                         [closeOnSelect]="false"
                         bindValue="id"
                         [(ngModel)]="selectedTrackingCategoriesIds"
                         (add)="saveTaxAndTrackingCategories()"
                         (remove)="saveTaxAndTrackingCategories()"
                         [loading]="dataLoading">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                  <span>{{item.name}}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <!-- Tab 4 -->
        <div class="content-4" *ngIf="currentTab == wizzardTabs.PaymentSettings">
          <div class="tab-title">Payment Settings</div>
          <div class="tab-subtitle">
            <div>Manage & record your payments in Synced and update your accounting software in real-time. What is the default bank account you will pay your suppliers from?</div>
          </div>
          <ng-container>
            <div class="rh_in">
              <div class="PaymentBox">
                <div *ngFor="let bankitem of bankAccounts">
                  <div class="PaymentEachBox">
                    <div class="div-radiobutton">
                      <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group payment-radio"
                                       [(ngModel)]="defaultBankAccount.accountID" (click)="setAccountDetails(bankitem)">
                        <mat-radio-button class="example-radio-button" [value]="bankitem.accountID"></mat-radio-button>
                        <ng-container *ngIf="bankitem.bankAccountType == 'CREDITCARD'">
                          <img src="../../../../../assets/img/signup/creditcard.png" alt="image" class="logo-img" />
                        </ng-container>
                        <ng-container *ngIf="bankitem.bankAccountType != 'CREDITCARD'">
                          <img src="../../../../../assets/img/signup/savingaccount.png" alt="image" class="logo-img" />
                        </ng-container>
                        <div>
                          <div class="accoun-item">
                            {{bankitem.name}}
                            <div class="item" *ngIf="bankitem.bsbNumber">
                              <div class="pro-tip" *ngIf="countryCode=='US'">Routing:</div>
                              <div class="pro-tip" *ngIf="countryCode!='US'">BSB:</div>
                              <div class="pro-tip" *ngIf="bankitem.bsbNumber">{{bankitem.bsbNumber}}</div>
                              <div class="pro-tip gray" *ngIf="!bankitem.bsbNumber && countryCode!='US'">No BSB</div>
                              <div class="pro-tip gray" *ngIf="!bankitem.bsbNumber && countryCode=='US'">No Routing</div>
                            </div>
                            <div class="item" *ngIf="bankitem.bankAccountNumber">
                              <div class="pro-tip">Account No:</div>
                              <div class="pro-tip" *ngIf="bankitem.bankAccountNumber">{{bankitem.bankAccountNumber}}</div>
                              <div class="pro-tip gray" *ngIf="!bankitem.bankAccountNumber ">No Account No</div>
                            </div>
                          </div>
                        </div>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <div *ngIf="isNoAccounting">
                  <div class="PaymentEachBox" style="height:50px;cursor: pointer;" (click)="AddBankAccount()">
                    <div class="div-radiobutton">                         
                        <div>                          
                          <div class="accoun-item" style="font-weight:400;" >
                            <img src="../../../../../assets/img/signup/savingaccount.png" alt="image" class="logo-img" />
                            <span style="padding-left:20px;">
                              <i class="fas fa-plus" style="font-size:12px"></i>
                              Add Bank Account
                            </span>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </ng-container>
        </div>
        <div class="bottom-button">
          <div>&nbsp;</div>
          <div>
            <button class="back-button" (click)="backButtonClick()" *ngIf="currentTab != wizzardTabs.Data || showNoAccountingSection"
                    [disabled]="!isDataDawloaded && !showNoAccountingSection">
              <span class="shevron">
                <img src="../../../../../assets/img/signup/back.png" class="back-img" alt="">
              </span>
              <span class="caption">Back</span>
            </button>
            <button class="next-button" (click)="nextButtonClickWOAccounting()" *ngIf="currentTab == wizzardTabs.Data && isNoAccounting && !isShowSyncingTab"
                    [disabled]="!isDataDawloaded" [ngClass]="isDataDawloaded && isNoAccounting && selectedCountry ? 'hover' : 'faded-button'">
              <span class="caption">Next</span>
              <span class="shevron">
                <img src="../../../../../assets/img/signup/white-right-shevron.svg" alt="" class="arrow-img">
              </span>
            </button>
            <button class="next-button" (click)="nextButtonClick()" *ngIf="currentTab == wizzardTabs.Data && isShowSyncingTab"
                    [disabled]="!isDataDawloaded" [ngClass]="isDataDawloaded && isShowSyncingTab ? 'hover' : 'faded-button'">
              <span class="caption">Next</span>
              <span class="shevron">
                <img src="../../../../../assets/img/signup/white-right-shevron.svg" alt="" class="arrow-img">
              </span>
            </button>
            <button class="next-button" (click)="nextButtonClick()" *ngIf="currentTab == wizzardTabs.Bookkeeping"
                    [disabled]="!selectedTaxBasis"
                    [ngClass]="!selectedTaxBasis  ? 'faded-button' : 'hover'">
              <span class="caption">Next </span>
              <span class="shevron">
                <img src="../../../../../assets/img/signup/white-right-shevron.svg" alt="" class="arrow-img">
              </span>
            </button>
            <button class="next-button" (click)="nextButtonClick()" *ngIf="currentTab == wizzardTabs.Inbox">
              <span class="caption">Next</span>
              <span class="shevron">
                <img src="../../../../../assets/img/signup/white-right-shevron.svg" alt="" class="arrow-img">
              </span>
            </button>
            <button class="complete-button" (click)="nextButtonClick()" *ngIf="currentTab == wizzardTabs.PaymentSettings"
                    [disabled]="bankAccounts?.length > 0 && !defaultBankAccount" [ngClass]="bankAccounts?.length > 0 && !defaultBankAccount ? 'faded-button' : 'hover'">
              <span class="caption">Complete </span>
              <span class="shevron">
                <img src="../../../../../assets/img/signup/white-right-shevron.svg" alt="" class="arrow-img">
              </span>
            </button>
          </div>
        </div>
      </section>
      <section class="column-2" *ngIf="currentTab == wizzardTabs.Inbox">
        <img src="../../../../../assets/img/signup/accounts.png" alt="" style="width:500px">
      </section>
      <section class="column-2" *ngIf="currentTab == wizzardTabs.Bookkeeping">
        <img src="../../../../../assets/img/signup/inbox.png" alt="" style="width:500px">
      </section>
      <section class="column-2" *ngIf="currentTab == wizzardTabs.PaymentSettings">
        <img src="../../../../../assets/img/signup/payment.png" alt="" style="width:500px">
      </section>
      <section class="column-2" *ngIf="currentTab == wizzardTabs.Data && loadTab">
        <img src="../../../../../assets/img/signup//logo.svg" alt="">
      </section>
    </section>
  </section>
</div>
<div class="modal fade data_modal bankaccountmodal" [config]="{'backdrop':'static', 'keyboard': false}" bsModal
    #SupplierManagementModel="bs-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="btn-close close" aria-label="Close" (click)="closeModel()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="dm_head">
            <h3>{{ modelTitle }}</h3>
          </div>
          <div class="dm_content">
            <div [hidden]="!IsAddNewBankAccount" class="add_bank_form">
              <form [formGroup]="SupplierManagementForm" role="form" name="SupplierManagementForm">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" name="name" autocomplete="off" formControlName="name"
                    class="form-control custom__input" onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Enter Account Name'" placeholder="Enter Account Name" />
                  <span class="text-danger"
                    *ngIf="SupplierManagementFormControl.name.errors?.required && (SupplierManagementFormControl.name.dirty || SupplierManagementFormControl.name.touched)">
                    Account Name is required
                  </span>
                </div>
                <div class="form-group">
                  <label for="name">Description</label>
                  <input type="text" name="description" autocomplete="off" formControlName="description"
                    class="form-control custom__input" onfocus="this.placeholder = ''"
                    onblur="this.placeholder = 'Enter Account Description'" placeholder="Enter Account Description" />
                </div>
                <div class="form-group" hidden>
                  <label for="accountnumber">Account Code</label>
                  <input type="text" name="accountnumber" autocomplete="off" formControlName="accountnumber"
                  class="form-control custom__input" placeholder="Enter Account Code" />
                <span class="text-danger"
                  *ngIf="SupplierManagementFormControl.accountnumber.errors?.required && (SupplierManagementFormControl.accountnumber.dirty || SupplierManagementFormControl.accountnumber.touched)">
                  Code is required
                </span>
                </div>
                <div class="form-group report__select">
                  <label for="acctype">Type</label>
                  <select class="select custom__select" name="acctype" formControlName="acctype">
                    <option class="report__select__option" value="CREDITCARD">CREDIT CARD</option>
                    <option class="report__select__option" value="BANK">BANK</option>
                  </select>
                </div>
              </form>
            </div>
            <div class="modal_btns">
              <button class="custom_button green " (click)="onSubmit()" [class.spinner]="loading"
                [disabled]="loading">Save</button>
              <button class="custom_button yellow" (click)="closeModel()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>