import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import moment from 'moment';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { ToasterTypes } from '../../../../core/enum/toaster-type.enum';
import { OrganizationService } from '../../../../data/service/organization.service';
import { ToasterMessageService } from '../../../../shared/toaster-message/toaster-message.service';
import { Pannels } from '../../enums/sidebar-pannels';
import { BillsToPayInvoiceDetailsRequest } from '../../models/invoice/bills-to-pay-invoice-details-request';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';

@Component({
  selector: 'app-related-tasks',
  templateUrl: './related-tasks.component.html',
  styleUrls: ['./related-tasks.component.scss']
})
export class RelatedTasksComponent extends BaseComponent implements OnInit {
  @Input() approvalMode: boolean = false;
  @Output() closeFormOnBillsToPayEvent = new EventEmitter<void>();
  @Output() invoiceApprovedEvent = new EventEmitter<string>();
  isApproving: boolean = false;
  isApproveButtonShown: boolean = false;
  isSpinnerShown: boolean = true;
  selectedTask: any;
  currentPannel = Pannels.DetailsTab;
  dateFormat: string = null;
  panels = Pannels;
  showToolTipDelay: number = 400;
  dateLoading: boolean = false;
  dueDateLoading: boolean = false;
  constructor(private _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private _adapter: DateAdapter<any>) {
    super();
    this.dateFormat = this._organizationService.selectedOrganizationDateFormat;
    if (this.dateFormat != null) {
      this._adapter.setLocale(this.dateFormat);
    }
  }

  ngOnInit(): void {
    this._recognizedInvoiceService.billsToPayInvoiceDetailsRequest$
      .pipe(this.untilThis)
      .subscribe(
        (requestData: BillsToPayInvoiceDetailsRequest) => {
          this.selectedTask = requestData.selectedTask;
          this.isApproveButtonShown = !requestData?.isCompletedTask;
          this.isSpinnerShown = false;
          //this.returnBackToText = requestData.returnBackToText;
        });
  }

  getFormatedDate(formatedate: Date) {
    if (formatedate) {
      if (this.dateFormat == null) {
        return moment(formatedate).format("MM/DD/YYYY");
      }
      let datePipe = new DatePipe(this.dateFormat);
      return datePipe.transform(formatedate, this.dateFormat == 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy');
    }
    else {
      return "";
    }
  }
  closeFormOnBillsToPay() {
    this.closeFormOnBillsToPayEvent.emit();
  }

  tabClick(tab: Pannels ) {
    switch (tab) {
      case Pannels.DetailsTab:
        this.currentPannel = Pannels.DetailsTab
        break;
      case Pannels.ActivityTab:
        this.currentPannel = Pannels.ActivityTab
        //this.getActivityData();
        break;
      case Pannels.RelatedTab:
       // this.showRelatedPanel();
        break;
      default:
        this.currentPannel = Pannels.DetailsTab
        break;
    }
  }

  //showActivityPanel(isDefaultShow: boolean=false) {
  //  this.resetDateLoadingVars();
  //  if (!isDefaultShow) {
  //    if (this.currentPannel == Pannels.ActivityTab) {
  //      this.currentPannel = Pannels.DetailsTab;
  //    } else {
  //      this.currentPannel = Pannels.ActivityTab;
  //    }
  //  }
  //}

  //showRelatedPanel() {
  //  this.resetDateLoadingVars();
  //  if (this.currentPannel == Pannels.RelatedTab) {
  //    this.currentPannel = Pannels.DetailsTab;
  //  } else {
  //    this.currentPannel = Pannels.RelatedTab;
  //  }
  //}

  //private resetDateLoadingVars() {
  //  this.dateLoading = false;
  //  this.dueDateLoading = false;
  //}

  approveCurrentInvoice() {
    this.isApproving = true;

    const reportDataModel =
    {
      ApproveId: "",
      OrganizationId: this._organizationService.selectedOrganization,
      //InvoiceId: this.invoice.referenceId ?? this.invoice.id.toString(),
      ApproveBy: localStorage.getItem('UpUserId'),
      ApprovedByName: localStorage.getItem('UserName'),
      ApprovalDate: new Date()
    }

    this._organizationService.InsertApprovals(reportDataModel)
      .pipe(this.untilThis)
      .subscribe(data => {
        if (data.status == 1) {
          this.isApproving = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'You have approved the bill');
          this.invoiceApprovedEvent.emit(this.selectedTask?.invoiceID);
        }
      },
        (e: Error) => {
          this.isApproving = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        });
  }

   
}
