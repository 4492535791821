<ngx-datatable #table 
              [rows]="list" 
              class="material expandable paddding-x" 
              [columnMode]="columnMode.force"
              [scrollbarV]="false" 
              [scrollbarH]="false" 
              [headerHeight]="50" 
              [footerHeight]="50" 
              rowHeight="auto">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
    </ng-template>
  </ngx-datatable-footer>
  <ngx-datatable-column name="Supplier" [width]="screenBrakepoint == screenBrakepoints.MinWidth769px ? 250 : 200">
    <ng-template let-column="supplierName" ngx-datatable-cell-template>
        <div class="text-placeholder supplier"></div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Date" [width]="80" *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px">
    <ng-template let-column="date" ngx-datatable-cell-template>
        <div class="text-placeholder"></div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Category" [width]="350" *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px"
    headerClass="left-align-column" cellClass="left-align-column">
    <ng-template let-column="status" ngx-datatable-cell-template>
        <div class="text-placeholder"></div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Total" [width]="screenBrakepoint == screenBrakepoints.MinWidth769px ? 150 : 50">
    <ng-template let-column="amountDue" ngx-datatable-cell-template>
        <div class="text-placeholder"></div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Tags" *ngIf="screenBrakepoint == screenBrakepoints.MinWidth769px" [width]="150">
    <ng-template let-column="amountDue" ngx-datatable-cell-template>
        <div class="text-placeholder"></div>
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>