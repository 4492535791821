import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { endsWithAny } from 'src/app/core/util/string-end-checker';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { inProcessingRecord } from '../../data/in-processing-record';
import { SidebarType } from '../../enums/sidebar-type';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-upload-sidebar',
  templateUrl: './upload-sidebar.component.html',
  styleUrls: ['./upload-sidebar.component.scss']
})
export class UploadSidebarComponent extends BaseComponent implements OnInit {
  @Input() recordId: string = '';
  @Input() isBankTransMode: boolean = false;
  @Output() uploadedFile = new EventEmitter<object>();
  @Output() isClosed = new EventEmitter<SidebarType>();

  isLoaded: boolean = false;
  isPdf: boolean = false;
  loading: boolean = false;
  tempFile: string;
  file: File;
  validFileTypes: string[] = ["application/pdf", 'image/png', 'image/jpg', 'image/jpeg'];
  organizationEmail: string = null;
  showToolTipDelay: number = 400;
  isDisabled: boolean = true;


  constructor(
    private _toasterMessageService: ToasterMessageService,
    private _invoiceService: InvoiceService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private _organizationService: OrganizationService
  ) {
    super();
  }

  ngOnInit() {
    this.getOrganizationEmail();
  }

  getOrganizationEmail() {
    this._organizationService.getOrganizationEmail(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (response) => {
          this.organizationEmail = response.email;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  dropped(uploadedFiles: NgxFileDropEntry[]) {
    const fileEntry = uploadedFiles[0].fileEntry.isFile ? uploadedFiles[0].fileEntry as FileSystemFileEntry : null;

    fileEntry?.file((file: File) => {
      this.handleFileInput(file, this.validFileTypes)
    });
  }

  added(event: Event) {
    this.loading = true;
    const [uploadedFile] = Array.from((event.target as HTMLInputElement).files as FileList);
    this.handleFileInput(uploadedFile, this.validFileTypes);
    this.loading = false;
    this.isDisabled = false;
  }

  handleFileInput(file: File, types: string[]) {
    if (endsWithAny(types, file.type)) {
      this.file = file;
      this._setPreview(file);

    }
    else {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Invalid file type!');
    }
  }

  sendFile(file: File) {
    this._invoiceService.uploadInvoice({ organisationId: this._organizationService.selectedOrganization, invoice: file, recordId: this.recordId, isBankTransMode: this.isBankTransMode });
  }

  deleteFile() {
    this.tempFile = '';
    this.file = null;
    this.isLoaded = false;
    this.loading = false;
    this.isDisabled = true;
  }

  save() {
    if (!this.file) {
      return;
    }
    if (this.isBankTransMode) {
      this.uploadedFile.emit({ name: this.file.name, file: this.file });
      //this.uploadedFile.emit({file:this.file});
      this.isClosed.emit(SidebarType.upload);
      this.deleteFile();
    }
    else {

      this.loading = true;
      this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Document submitted for processing');

      this.sendFile(this.file);
      // if (this.isBankTransMode) {
      //   this._recognizedInvoiceService.invoiceState$.pipe(take(1))
      //     .subscribe(
      //       (invoice: any) => {
      //         this.uploadedFile.emit({name:this.file.name,id:invoice.id});
      //         this.isClosed.emit(SidebarType.upload);
      //         this.deleteFile();
      //       },
      //       (e: Error) => {
      //         this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
      //       }
      //     );
      // }
      // else{
      this.isClosed.emit(SidebarType.upload);
      this.deleteFile();
      // }
    }
  }

  cancel() {
    this.isClosed.emit(SidebarType.upload);
  }

  private _setPreview(file: File) {
    const reader = new FileReader();

    reader.onloadend = () => {
      this.tempFile = reader?.result as string;
      this.isPdf = (file.type === "application/pdf") ? true : false;
      this.isLoaded = true;
      this.isDisabled = false;
    }

    reader.readAsDataURL(file);
  }
}
