<div class="custom-mat-spinner" *ngIf="isSpinnerShown">
  <mat-spinner></mat-spinner>
  <div class="text">Data loading...</div>
</div>

<div *ngIf="!isSpinnerShown">
  <div class="filter-container">
    <div *ngIf="isUnreconciledReport">
      <a [routerLink]="" class="closebtn" (click)="CloseFilterSidebar('')">&times;</a>
      <div class="sidenav_in">
        <div class="reportg">
          <div class="heading report__main__heading">{{SelctedReportName}}</div>
          <div class="importData-wrap" [ngClass]="importData.active?'active':''">
            <div style="border-bottom: 1px solid #CCC;">
              <span (click)="importData.type='success'" class="importstatus"
                [ngClass]="importData.type=='success'?'active':''">Success ({{importData.success.length}})</span>
              <span (click)="importData.type='error'" class="importstatus"
                [ngClass]="importData.type=='error'?'active':''">Error ({{importData.error.length}})</span>
            </div>
            <ng-container *ngIf="importData.type=='success'">
              <div style="padding: 20px 0; ">
                <ng-container *ngFor="let item of imprtSuccessGrp">
                  <div class="importData-title"> <input type="checkbox" name="isImport"
                    class="checkbox-round checkboxforreport invoiceCheckbox Iscompleted invoiceCheckbox"
                    [(ngModel)]="item.ischeck" style="width: 16px;height:16px;vertical-align: middle;" (change)="setCheckUnCheck(item.value,item.ischeck)">
                     {{item.key}} ({{item.value.length}})</div>
                  <div *ngFor="let obj of item.value" class="imprtdata">
                    <div style="font-size: 12px;display: inline-block;width: 100%;">
                      <span  class="importdescr" title="{{obj.description}}">
                        <input type="checkbox" name="isImport"
                          class="checkbox-round checkboxforreport invoiceCheckbox Iscompleted invoiceCheckbox"
                          [(ngModel)]="obj.isImport">
                          <div style="display: inline-block;line-height: 20px;">{{obj.description}}</div>
                      </span>
                      <span style="float: right;line-height: 20px;">
                        <span *ngIf="obj.amount > 0"> {{ obj.amount | number:'1.2-2'}}</span>
                        <span *ngIf="obj.amount < 0">({{ obj.amount * -1 | number : '1.2-2'}})</span>
                        <span *ngIf="obj.amount == 0">-</span>
                      </span>
                    </div>

                    <div style=" font-size: 12px; margin-top: 5px; text-align: end;width: 100%; display: inline-block;">{{obj.transactionDate |
                      formatedDate}}</div>
                  </div>
                </ng-container>
                <button class="run report__run__button " *ngIf="importData.success.length > 0"
                  (click)="uploadImportExcel()" style="cursor:pointer;">Import</button>
              </div>
            </ng-container>
            <ng-container *ngIf="importData.type=='error'">
              <div style="padding: 20px 0; font-size: 12px; ">

                <ng-container *ngFor="let item of imprtErrorGrp">
                  <div class="importData-title">{{item.key}} ({{item.value.length}})</div>
                  <div *ngFor="let obj of item.value" class="imprtdata" style="padding: 15px 5px;">
                    <div style="margin-bottom: 5px;">
                      <div style="display: inline-block;line-height: 20px;">{{obj.description}}</div>
                      <div style="float: right;line-height: 20px; ">
                        <span *ngIf="obj.amount > 0"> {{ obj.amount | number:'1.2-2'}}</span>
                        <span *ngIf="obj.amount < 0">({{ obj.amount * -1 | number : '1.2-2'}})</span>
                        <span *ngIf="obj.amount == 0">-</span>
                      </div>
                    </div>
                    <div>
                      <div class="imprstatus">
                        {{obj.importStatus}}
                      </div>
                      <div style=" float: right;margin-top: 5px;">{{obj.transactionDate | formatedDate}}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <ng-container
              *ngIf="importData.type=='success' ? importData.success.length == 0 : importData.error.length == 0 ">
              <tr>
                <td style="font-size: 20px;color:#aaa;text-align: center;" [attr.colspan]="5"> No records to display</td>
              </tr>
            </ng-container>
          </div>
          <div>
            <div class="upload-option" *ngIf="isXeroAccount==0" (click)="filterType='xeroapi'">
              <div style="display: inline-block;">
                <img src="../../../../assets/images/xero.png">
              </div>
              <div class="upload-text">
                <div style="font-size: 20px;">Import From Xero</div>
                <div style="font-size: 12px;">Import From Xero</div>
              </div>
            </div>
            <div class="upload-option" (click)="filterType='excel'">
              <div style="display: inline-block;">
                <img src="../../../../assets/images/excel.png">
              </div>
              <div class="upload-text">
                <div style="font-size: 20px;">Import From Spreadsheet</div>
                <div style="font-size: 12px;">Import transactions from a spreadsheet</div>
              </div>
            </div>
          </div>
          <div class="xeroapi-upload" [ngClass]="filterType=='xeroapi'?'active':''">
            <div class="report_period">
              <h4 class="report__drop__title">Report Period:</h4>
              <!-- <select class="select report__select" [placeholder]="'Select Period'" (change)="reportPeriodChange($event)">
                <option class="report__select__option" *ngFor="let enum of keyInEnum()" value="{{enum}}">{{enum}}</option>
              </select> -->
              <div class="form-group">
                <div class="field-input">
                  <input [(ngModel)]="DateRangeSelcted" [typeahead]="keyInEnum()" [typeaheadScrollable]=true
                    [typeaheadOptionsInScrollableView]="10" [typeaheadOptionsLimit]="keyInEnum()?.length"
                    [typeaheadMinLength]="0" class="select report__select " [placeholder]="'Select Period'"
                    [ngModelOptions]="{standalone: true}">
                  <!-- (typeaheadOnSelect)="saveContactForm('Period')" -->
                </div>
                <span style="position: absolute;margin-top: 10px;color: #9D9D9D;">{{showDateRange}}</span>
              </div>

            </div>
            <div class="custom_range" *ngIf="showCustomFilter">
              <div class="cr_tabs">
                <div class="tab_head">
                  <ul>
                    <li>Custom Date Range
                    </li>
                  </ul>
                </div>
                <div class="tab_data">
                  <div class="tabContents" *ngIf="showFrom">
                    <div>
                      <ejs-daterangepicker [startDate]='start' [endDate]='end' id="datetime"
                        (open)="onOpen($event)"></ejs-daterangepicker>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="form-group mt-4">
              <h4 class="report__drop__title">Bank Account</h4>
              <ng-multiselect-dropdown [placeholder]="'Select Account'" style="cursor:pointer;" [(ngModel)]="filterBank"
                [settings]="dropdownBankSettings" [data]="bankAccountList" (onSelect)="onBankItemSelect($event)"
                (onDeSelect)="onBankDeleteItem($event)" (onSelectAll)="onBankSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>

            <div class="reportg_btn">
              <!-- <a [routerLink]="" (click)="toggleFilter('')" class="cancel report__cancel__button" style="cursor:pointer;">Cancel</a> -->
              <button (click)="redirectToreport()" class="run report__run__button " [class.spinner]="loading"
                [disabled]="loading" style="cursor:pointer;">Import</button>
            </div>
          </div>
          <div class="xeroapi-upload" [ngClass]="filterType=='excel'?'active':''">
            <ngx-file-drop (onFileDrop)="dropped($event)">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <input hidden #filesrc type="file" accept=".xls, .xlsx" (change)="added($event)">

                <div class="report_period" style="color:#000;font-size: 12px;">
                  <span class="labeltext">Step 1</span><span style="line-height: 23px;display: inline-block;">Create a
                    spreadsheet with the following columns:</span>
                  <ul class="labelFeilds">
                    <li>Date</li>
                    <li>Description</li>
                    <li>Spent</li>
                    <li>Received</li>
                    <li>Bank Account</li>
                    <li> <a href="../../../../assets/SampleUnreconciled.xlsx" download="" style="color: #0d6efd;">(or
                        use the spreadsheet template)</a></li>
                  </ul>

                </div>
                <div style="padding: 20px 0;color: #545454;font-size: 12px;">
                  <span class="labeltext">Step 2</span>
                  <div *ngIf="fileObj ==null" class="fileUploadBox">
                    <div class="text">
                      <div>
                        Drag & drop Spreadsheet or
                        <span class="link" (click)="filesrc.click()"
                          style="cursor: pointer;text-decoration: underline;color: #0d6efd;">Browse</span>
                      </div>
                    </div>
                    <input hidden #filesrc type="file" accept=".xls, .xlsx" (change)="added($event)">

                  </div>
                  <div *ngIf="fileObj !=null" style="width: 300px;display: inline-block;margin-left: 0px;">
                    <span class="fileProcess " style="padding-left: 40px;line-height: 36px;"
                      [ngClass]="isFileProcessing?'active load':''">
                      Processing
                    </span>
                    <span *ngIf="!isFileProcessing" style="line-height: 23px;">
                      <b>Attachments</b>
                      <div class="attachment-tag">
                        <div >{{fileObj.name}}</div>
                        <div class="close"  (click)="fileObj=null"><i class="fas fa-times"></i></div>
                      </div>
                    </span>
                  </div>
                </div>
                <div style="color:#545454;font-size: 12px;">
                  <span class="labeltext">Step 3</span><span style="line-height: 23px;display: inline-block;">Click
                    import and review relevant transactions </span>
                </div>
                <div class="reportg_btn">
                  <!-- <a [routerLink]="" (click)="toggleFilter('')" class="cancel report__cancel__button" style="cursor:pointer;">Cancel</a> -->
                  <button class="run report__run__button " (click)="sendFile()" [class.spinner]="loading"
                    [disabled]="loading || fileObj ==null || isFileProcessing" style="cursor:pointer;">Import</button>
                </div>
              </ng-template>
            </ngx-file-drop>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isUnreconciledReport">
      <section class="filter-header">
        <div class="info">
          <div class="title">
            <div class="block">
              <div class="valuesTitle">
                <input type="hidden" id="hdFilterId" [(ngModel)]="filterId" />
                <input type="text" [(ngModel)]="filterName" (ngModelChange)="updateDirty()">
              </div>
            </div>
          </div>
          <div class="dots-menu">
            <a mat-button [matMenuTriggerFor]="menu" class="three-dots" matTooltip="Menu">
              <img src="../../../../../assets/img/invoiceForm/menu.svg" alt="">
            </a>
            <mat-menu #menu="matMenu" class="payments-menu">
              <button mat-menu-item (click)="pinnedFilter()">
                <img src="../../../../../assets/img/push-pin.png" alt="" style="background: transparent;"
                  *ngIf="!isPinned">
                <img src="../../../../../assets/img/push-pin-filled.png" alt="" style="background: transparent;"
                  *ngIf="isPinned">
                <span *ngIf="!isPinned">Pin</span>
                <span *ngIf="isPinned">Unpin</span>
              </button>
              <button mat-menu-item (click)="deleteFilter()" *ngIf="isEditMode">
                <img src="../../../../../assets/img/remove-48.png" alt="" style="width: 20px;">
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
          <div class="close-icon" matTooltip="Close Save Filter Side Bar" (click)="CloseFilterSidebar()">
            <img src="../../../../../assets/img/close-icon.svg" alt="">
          </div>
        </div>
      </section>
      <div>
        <!-- Details tab -->
        <section class="filter-tab">
          <section class="summary">
            <div class="block">
              <div class="caption">Date</div>
              <div class="values">
                <!-- <input type="search" [(ngModel)]="dateRange" (search)="setDateRange($event)"
                  (focus)="toogleDateFilter()" placeholder="Select Date"> -->
                <app-date-time-picker [selectedDateRange]="filterDateRange"
                  [selectedDateRangeOption]="filterDateRangeOption" (getDateRange)="setDateRange($event)"
                  (closeDatePicker)="clearSelectedDateRange()" [placeholder]="'Select Date'">
                </app-date-time-picker>
              </div>
            </div>
            <div class="block">
              <div class="caption">Category</div>
              <div class="values">
                <ng-multiselect-dropdown [placeholder]="'Select Category'" [settings]="dropdownSettings"
                  [data]="searchedAccounts" [(ngModel)]="filterCategories" (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)" (onDeSelect)="onDeleteGroup($event)"
                  (onDeSelectAll)="onDeleteAllGroup($event)" name="category" class="custom_mat_select"
                  [ngClass]="isEditable ? 'active' : ''">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="block">
              <div class="caption">Supplier Name</div>
              <div class="values">
                <ng-multiselect-dropdown [placeholder]="'Select Supplier'" [settings]="supplierDropdownSettings"
                  [data]="suppliers" [(ngModel)]="selectedSuppliers" (onSelect)="onSupplierItemSelect($event)"
                  (onSelectAll)="onSupplierSelectAll($event)" (onDeSelect)="onSupplierDeleteGroup($event)"
                  (onDeSelectAll)="onSupplierDeleteAllGroup($event)" class="custom_mat_select"
                  [ngClass]="isEditable ? 'active' : ''">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="block">
              <div class="caption">Tag</div>
              <div class="values">
                <ng-multiselect-dropdown [placeholder]="'Select Tags'" [settings]="tagsDropdownSettings"
                  [data]="searchedTags" [(ngModel)]="selectedTags" (onSelect)="onTagItemSelect($event)"
                  (onSelectAll)="onTagSelectAll($event)" (onDeSelect)="onTagDeleteGroup($event)"
                  (onDeSelectAll)="onTagDeleteAllGroup($event)" name="tags" class="custom_mat_select"
                  [ngClass]="isEditable ? 'active' : ''">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="block" *ngIf="selectedTrackingCategories && selectedTrackingCategories.length > 0">
              <div class="caption">{{selectedTrackingCategories[0].name}}</div>
              <div class="values">
                <ng-multiselect-dropdown [placeholder]="'Select Tracking Options'" [settings]="trackingDropdownSettings"
                  [data]="searchedTrackingOprions1" [(ngModel)]="filterTrackingOptions1"
                  (onSelect)="onTracking1ItemSelect($event)" (onSelectAll)="onTracking1SelectAll($event)"
                  (onDeSelect)="onTracking1DeleteGroup($event)" (onDeSelectAll)="onTracking1DeleteAllGroup($event)"
                  name="trackingCategories1" class="custom_mat_select" [ngClass]="isEditable ? 'active' : ''">
                </ng-multiselect-dropdown>
              </div>
              <!-- <button *ngIf="selectedTrackingCategories && selectedTrackingCategories.length > 1" mat-menu-item
                (click)="showFilterSection(filterMenuSections.TrackingCategory2); $event.stopPropagation();">
                <span>{{selectedTrackingCategories[1].name}}</span>
                <span class="main-menu-icon"><img src="../../../../../assets/img/inboxPage/shevron-right.svg" alt=""></span>
              </button>
            </section> -->
            </div>
            <!-- <div class="block">
              <div class="caption">
                <input type="checkbox" [(ngModel)]="isTagsAssigned" (change)="onCheckChange($event)" /> Show records without Tags
              </div>
            </div>
            <div class="block" *ngIf="selectedTrackingCategories && selectedTrackingCategories.length > 0">
              <div class="caption">
                <input type="checkbox" [(ngModel)]="isTrackingAssigned" (change)="onTrackCheckChange($event)" /> Show records without {{selectedTrackingCategories[0].name}}
              </div>
            </div> -->
          </section>
          <section>

          </section>
          <section class="controls" *ngIf="(isEditMode && isDirty) || !isEditMode">
            <button class="paid-button" (click)="SaveFilter()" [disabled]="filterName ==''"
              [ngClass]="{'disabled': filterName === ''}">
              <span class="button-text">{{isEditMode ? 'Update Filter' : 'Save Filter'}}</span>
            </button>
          </section>
        </section>
      </div>
    </div>
  </div>
</div>
