import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterService } from './service/register.service';
import { UserService } from './service/user.service';
import { OrganizationService } from './service/organization.service';
import { ReportService } from './service/report.service';

import { UserValidator } from './validator/user-validator'

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RegisterService,
    UserService,
    OrganizationService,
    ReportService,

    UserValidator
  ]
})
export class DataModule { }
