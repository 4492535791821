import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { Invoice } from '../../models/invoice/invoice';
import { OrganizationService } from 'src/app/data/service/organization.service';
@Component({
  selector: 'app-invoice-preivew',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss']
})
export class InvoicePreviewComponent extends BaseComponent implements OnInit {
  @Input() isHorizontalDirection: boolean = false;
  @Input() invoice: Invoice = new Invoice(this._organizationService.selectedOrganization);
  @Output() isClosed = new EventEmitter<boolean>();
  @Output() closeFormEvent = new EventEmitter<string>();

  private invoicePdfUrl: string = '';
  private fileName: string = '';
  isLoaded: boolean = false;
  isPdf: boolean = false;
  tempFile: string;
  showToolTipDelay: number = 400;
  pageScale: number = 100;

  constructor(
    private _toasterMessageService: ToasterMessageService,
    private _invoiceService: InvoiceService,
    private _organizationService: OrganizationService,
    private renderer: Renderer2,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToFileUpdates();
  }

  subscribeToFileUpdates() {
    this.isLoaded = false;
    this.invoicePdfUrl = this.invoice.pdfUrl;
    this.fileName = `${ this.invoice.supplierName} ${ this.invoice.invoiceNumber}`;
    this.downloadInvoice( this.invoice.pdfUrl,this._organizationService.selectedOrganization);
  }

  downloadInvoice(id: string,organisationId:string) {
    this._invoiceService.downloadFile(id,organisationId)
      .pipe(this.untilThis)
      .subscribe(
        (blob: Blob) => {
          this._setPreview(blob);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  private _setPreview(blob: Blob) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.tempFile = reader?.result as string;
      this.isPdf = (blob.type === "application/pdf" || blob.type === "application/octet-stream") ? true : false;
      this.isLoaded = true;
    }

    reader.readAsDataURL(blob);
  }

  downloadPdfInvoice() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this._invoiceService.getDownloadInvoiceUrl(this.invoicePdfUrl, "", this.fileName));
    link.setAttribute('download', this.invoicePdfUrl);
    link.click();
    link.remove();
  }

  public zoomIn()
	{
    this.pageScale = this.pageScale + 25;
	}

  public zoomReset()
	{
    this.pageScale = 100;
	}

	public zoomOut()
	{
    if (this.pageScale > 100) {
      this.pageScale = this.pageScale - 25;
    }
	}

  ClosePreviewSidebar() {
    this.closeFormEvent.emit(this.invoice.id.toString());
  }
}
