import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarType } from '../../enums/sidebar-type';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() isSidebarVisible: boolean;
  @Input() isSettingsSidebar: boolean = false;
  @Input() isUploadSidebar: boolean = false;
  @Input() recordId: string = '';
  @Input() isBankTransMode: boolean = false;

  @Output() isClosed = new EventEmitter<SidebarType>();
   @Output() uploadedFile = new EventEmitter<object>();

  cancel(type: SidebarType) {
    this.isSettingsSidebar = false;
    this.isUploadSidebar = false;
    this.isClosed.emit(type);
  }
  uploadFile(file: any)
  {
    this.uploadedFile.emit(file);
  }
}
