export enum BankAccountType {
  Supplier,
  User,
  Company
}
export enum UserPanels {
  DetailsTab = 1,
  BankingTab = 2
}
export enum UserRole {
  SuperAdmin = 1,
  Admin = 2,
  Submitter = 3,
  Custom = 4
}

export enum OnBoardingStatus {
  Data = 0,
  Inbox = 1,
  Payment = 2,
  Bookkeeping = 3,
  Done = 4
}

export enum RefreshLog_Type {
  Signup,
  Auto,
  Login,
  Manual
}
