<div class="main-wrapper">
  <div class="no-errors" *ngIf="!emailErrorRecords.length && !loading">There is no email processing errors 👍</div>
  <div class="error-row" *ngFor="let record of emailErrorRecords">
    <div class="type-icon">
      <span matTooltip="Email processing error" [matTooltipShowDelay]="showToolTipDelay"
        *ngIf="record.errorRecordType == 0">
        <i class="fa fa-envelope" aria-hidden="true"></i>
      </span>
    </div>
    <div class="data-body">
      <div class="messages">
        <div class="stage">
          <div>{{record.stageOrPlace}} <span *ngIf="record.emailData?.AttachmentName" class="attachment">: {{record.emailData?.AttachmentName}}</span></div>  
        </div>
        <div class="exception-message">{{record.exceptionMessage}}</div>
      </div>
      <div class="date">
        <div class="date-word">Date:&nbsp;</div>
        <div>{{record.date + 'Z' | formatedDate}}</div>
        <span class="date-word">&nbsp;</span>
        <div>{{record.date + 'Z' | date: 'shortTime'}}</div>
      </div>
      <div class="email-details">
        <div class="key margin-bottom">From: <span class="value">{{record.emailData?.FromName}}</span></div>
        <div class="key margin-bottom">Email: <span class="value">{{record.emailData?.FromEmail}}</span></div>
        <div class="key margin-bottom" *ngIf="record.emailData?.Subject">Subject: <span class="value">{{record.emailData?.Subject}}</span></div>
        <div class="key" *ngIf="record.emailData?.PdfUrl">
          Attachment: 
          <span class="value hover" style="cursor: pointer;" 
            (click)="downloadFile(record.emailData.PdfUrl)"
            matTooltip="Download the attachment" [matTooltipShowDelay]="showToolTipDelay">
            Download
          </span>
        </div>
      </div>
    </div>
    <div class="controls">
      <span class="btn-delete" matTooltip="Delete this record" [matTooltipShowDelay]="showToolTipDelay"
        (click)="showDeleteRecordConfirmation(record)">
        <img src="../../../../assets/img/invoiceForm/delete.svg" alt="">
      </span>
    </div>
  </div>
</div>
