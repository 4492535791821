import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CommonSharedModule } from '../shared/shared.module';

import { AuthInterceptor } from './interceptor/auth.interceptor';

import { AuthGuard } from './guard/auth.guard';
import { NoAuthGuard } from './guard/no-auth.guard';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';

import { AuthService } from '../core/service/auth/auth.service';
import { SettingsService } from '../core/service/settings/settings.service';
import { BaseComponent } from './components/base/base.component';

@NgModule({
  imports: [HttpClientModule, CommonSharedModule],
  providers: [
    SettingsService,
    AuthService,

    AuthGuard,
    NoAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  declarations: [
    BaseComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
