import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { RegisterService } from 'src/app/data/service/register.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
@Component({
  selector: 'app-step-verify-email',
  templateUrl: './step-verify-email.component.html',
  styleUrls: ['./step-verify-email.component.scss']
})
export class StepVerifyEmailComponent implements OnInit {
  constructor(
    private registerService: RegisterService,
    private router: Router,
    private _route: ActivatedRoute, 
    private _toasterMessageService: ToasterMessageService
  ) { }
  private subscription: Subscription | undefined;
  IsConfirmed: boolean;
  email: string;

  ngOnInit(): void {
    this.email = this._route.snapshot.params?.id;
    this.startPolling();
  }
  loading: boolean = false;
  nextStep() {
    this.loading = true;
  }

  startPolling() {
    // Poll every 10 seconds (10000 ms)
    this.subscription = this.registerService.pollIsConfirmedFlagValue(30000, "Account/CheckIsEmailConfirmed").subscribe({
      next: (response) => {
        this.IsConfirmed = response;
        if (this.IsConfirmed) {
          this.stopPolling();
        }
      },
      error: (error) => console.error('Error fetching flag:', error)
    });
  }

  stopPolling() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.router.navigate(['/signup/wizzard'], { replaceUrl: true });
    }
  }

  ngOnDestroy(): void {
    this.stopPolling();
  }

  resendMail() {
    this.registerService.resendConfimationMail("Account/ResendConfirmationEmail")
    .subscribe((data: any) => {
      if(data) {
        if(data.status == 0){
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, '', data.message);
        }
        else if(data.status == 1){
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, '', data.message);
        }
      }
    });
  }
}
