<div class="filter-container">
  <div class="show-filter" mat-button [matMenuTriggerFor]="menu">
    <span class="filter-button">
      <span class="icon"><i class="fas fa-plus"></i></span>
      <span>Filter</span>
    </span>
    <span class="spinner-wrapper" style="margin-left: 5px;">
      <mat-spinner [diameter]="18" *ngIf="loading"></mat-spinner>
    </span>
  </div>

  <mat-menu #menu="matMenu" class="filter-menu" (closed)="menuClosed()">
    <section *ngIf="currentFilterMenuSection == filterMenuSections.Main">
      <button mat-menu-item (click)="showFilterSection(filterMenuSections.Date); $event.stopPropagation();">
        <span>Date</span>
        <span class="main-menu-icon"><img src="../../../../../assets/img/inboxPage/shevron-right.svg" alt=""></span>
      </button>

      <button mat-menu-item (click)="showFilterSection(filterMenuSections.Category); $event.stopPropagation();">
        <span>Category</span>
        <span class="main-menu-icon"><img src="../../../../../assets/img/inboxPage/shevron-right.svg" alt=""></span>
      </button>

      <button mat-menu-item (click)="showFilterSection(filterMenuSections.Supplier); $event.stopPropagation();">
        <span>Supplier Name</span>
        <span class="main-menu-icon"><img src="../../../../../assets/img/inboxPage/shevron-right.svg" alt=""></span>
      </button>

      <button mat-menu-item (click)="showFilterSection(filterMenuSections.Tag); $event.stopPropagation();">
        <span>Tag</span>
        <span class="main-menu-icon"><img src="../../../../../assets/img/inboxPage/shevron-right.svg" alt=""></span>
      </button>

      <button *ngIf="selectedTrackingCategories && selectedTrackingCategories.length > 0" mat-menu-item 
        (click)="showFilterSection(filterMenuSections.TrackingCategory1); $event.stopPropagation();">
        <span>{{selectedTrackingCategories[0].name}}</span>
        <span class="main-menu-icon"><img src="../../../../../assets/img/inboxPage/shevron-right.svg" alt=""></span>
      </button>

      <button *ngIf="selectedTrackingCategories && selectedTrackingCategories.length > 1" mat-menu-item 
        (click)="showFilterSection(filterMenuSections.TrackingCategory2); $event.stopPropagation();">
        <span>{{selectedTrackingCategories[1].name}}</span>
        <span class="main-menu-icon"><img src="../../../../../assets/img/inboxPage/shevron-right.svg" alt=""></span>
      </button>
    </section>

    <!-- Date -->
    <section class="calendar-section" *ngIf="currentFilterMenuSection == filterMenuSections.Date">
      <div class="caption">
        <button mat-menu-item (click)="showFilterSection(filterMenuSections.Main); $event.stopPropagation();">
          <span><img src="../../../../../assets/img/inboxPage/shevron-left.svg" alt=""></span>
          <span style="margin-left: 5px; font-weight: 500;">Date</span>
        </button>
      </div>
      <div class="body" (click)="$event.stopPropagation();">
        <app-custom-date-time-picker
          [selectedDateRange]="filterDateRange"
          (closeDatePicker)="menuClosed()"
          (getDateRange)="setDateRange($event)">
        </app-custom-date-time-picker>
      </div>
    </section>

    <!-- Category -->
    <section class="supplier-section" *ngIf="currentFilterMenuSection == filterMenuSections.Category">
      <div class="caption">
        <button mat-menu-item (click)="showFilterSection(filterMenuSections.Main); $event.stopPropagation();">
          <span><img src="../../../../../assets/img/inboxPage/shevron-left.svg" alt=""></span>
          <span style="margin-left: 5px; font-weight: 500; border-bottom: 1px solid #F4F4F4;">Category</span>
        </button>
      </div>
      <div class="body" (click)="$event.stopPropagation();">
        <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
            (ngModelChange)="filterSearchList(filterMenuSections.Category, $event)">
        </div>
        <div class="supplier-list">
          <div class="supplier-item" *ngFor="let item of searchedAccounts">
            <input class="checkbox" [(ngModel)]="item.checked"
              type="checkbox" (click)="clickCategoryCheckBox(item, $event)">
            <div class="supplier-name">{{item.name}}</div>
          </div>
        </div>
      </div>
    </section>

    <!-- Supplier -->
    <section class="supplier-section" *ngIf="currentFilterMenuSection == filterMenuSections.Supplier">
      <div class="caption">
        <button mat-menu-item (click)="showFilterSection(filterMenuSections.Main); $event.stopPropagation();">
          <span><img src="../../../../../assets/img/inboxPage/shevron-left.svg" alt=""></span>
          <span style="margin-left: 5px; font-weight: 500;">Supplier</span>
        </button>
      </div>
      <div class="body" (click)="$event.stopPropagation();">
        <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
            (ngModelChange)="filterSearchList(filterMenuSections.Supplier, $event)">
        </div>
        <div class="supplier-list">
          <div class="supplier-item" *ngFor="let item of searchedSuppliers">
            <input class="checkbox" [(ngModel)]="item.checked"
              type="checkbox" (click)="clickSupplierCheckBox(item, $event)">
            <div class="supplier-name">{{item.name}}</div>
          </div>
        </div>
      </div>
    </section>

    <!-- Tags -->
    <section class="tags-section" *ngIf="currentFilterMenuSection == filterMenuSections.Tag">
      <div class="caption">
        <button mat-menu-item (click)="showFilterSection(filterMenuSections.Main); $event.stopPropagation();">
          <span><img src="../../../../../assets/img/inboxPage/shevron-left.svg" alt=""></span>
          <span style="margin-left: 5px; font-weight: 500;">Tags</span>
        </button>
      </div>
      <div class="body" (click)="$event.stopPropagation();">
        <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
            (ngModelChange)="filterSearchList(filterMenuSections.Tag, $event)">
        </div>
        <div class="tags-list">
          <div class="tag-item">
            <input class="checkbox" [(ngModel)]="unassignedTag.checked"
              type="checkbox" (click)="clickTagCheckBox(unassignedTag, $event)">
            <span class="unassigned-tag">
              <span>{{unassignedTag.tagName}}</span>
            </span>
          </div>
          <div class="tag-item" *ngFor="let tag of searchedTags">
            <input class="checkbox" [(ngModel)]="tag.checked"
              type="checkbox" (click)="clickTagCheckBox(tag, $event)">
            <span class="tag" [ngStyle]="{'color': tag.textColor, 'background': tag.backColor}">
              <span>{{tag.tagName}}</span>
            </span>
          </div>
        </div>
      </div>
    </section>

    <!-- Tracking Category 1 -->
    <section class="supplier-section" *ngIf="currentFilterMenuSection == filterMenuSections.TrackingCategory1">
      <div class="caption">
        <button mat-menu-item (click)="showFilterSection(filterMenuSections.Main); $event.stopPropagation();">
          <span><img src="../../../../../assets/img/inboxPage/shevron-left.svg" alt=""></span>
          <span style="margin-left: 5px; font-weight: 500;">{{selectedTrackingCategories[0].name}}</span>
        </button>
      </div>
      <div class="body" (click)="$event.stopPropagation();">
        <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
            (ngModelChange)="filterSearchList(filterMenuSections.TrackingCategory1, $event)">
        </div>
        <div class="supplier-list">
          <div class="supplier-item" *ngFor="let option of searchedTrackingOprions1">
            <input class="checkbox" [(ngModel)]="option.checked"
              type="checkbox" (click)="clickTrackingCategoryCheckBox(filterMenuSections.TrackingCategory1, option, $event)">
            <div class="supplier-name">{{option.name}}</div>
          </div>
        </div>
      </div>
    </section>

    <!-- Tracking Category 2 -->
    <section class="supplier-section" *ngIf="currentFilterMenuSection == filterMenuSections.TrackingCategory2">
      <div class="caption">
        <button mat-menu-item (click)="showFilterSection(filterMenuSections.Main); $event.stopPropagation();">
          <span><img src="../../../../../assets/img/inboxPage/shevron-left.svg" alt=""></span>
          <span style="margin-left: 5px; font-weight: 500;">{{selectedTrackingCategories[1].name}}</span>
        </button>
      </div>
      <div class="body" (click)="$event.stopPropagation();">
        <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
            (ngModelChange)="filterSearchList(filterMenuSections.TrackingCategory2, $event)">
        </div>
        <div class="supplier-list">
          <div class="supplier-item" *ngFor="let option of searchedTrackingOprions2">
            <input class="checkbox" [(ngModel)]="option.checked"
              type="checkbox" (click)="clickTrackingCategoryCheckBox(filterMenuSections.TrackingCategory2, option, $event)">
            <div class="supplier-name">{{option.name}}</div>
          </div>
        </div>
      </div>
    </section>
  </mat-menu>

  <!-- TAGS -->
  <section class="filter">
    <!-- Date Tag -->
    <div class="filter-tag" *ngIf="filterDateRange?.start && filterDateRange?.end">
      <div class="category">Date</div>
      <div class="vline"></div>
      <div class="value"  mat-button [matMenuTriggerFor]="menuDate">{{filterDateRange.start | formatedDate}} - {{filterDateRange.end | formatedDate}}</div>
      <div class="delete" (click)="deleteFilterTag(filterMenuSections.Date)"><i class="fas fa-close"></i></div>
      <mat-menu #menuDate="matMenu" class="filter-menu" (closed)="menuClosed()">
        <div class="pop-up-body" >
          <app-custom-date-time-picker
            [selectedDateRange]="filterDateRange"
            (closeDatePicker)="menuClosed()"
            (getDateRange)="setDateRange($event)">
          </app-custom-date-time-picker>
        </div>
      </mat-menu>
    </div>

    <!-- Supplier Tag -->
    <div class="filter-tag" *ngIf="filterSuppliers?.length > 0">
      <div class="category">Supplier</div>
      <div class="vline"></div>
      <div class="value" mat-button [matMenuTriggerFor]="menuSupplier" *ngIf="filterSuppliers?.length > 1">Multiple</div>
      <div class="value" mat-button [matMenuTriggerFor]="menuSupplier" *ngIf="filterSuppliers?.length == 1">{{filterSuppliers[0].name}}</div>
      <div class="delete" (click)="deleteFilterTag(filterMenuSections.Supplier)"><i class="fas fa-close"></i></div>
      <mat-menu #menuSupplier="matMenu" class="filter-menu" (closed)="menuClosed()">
        <div class="body" style="padding-top: 5px" (click)="$event.stopPropagation();">
          <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
              (ngModelChange)="filterSearchList(filterMenuSections.Supplier, $event)">
          </div>
          <div class="supplier-list">
            <div class="supplier-item" *ngFor="let item of searchedSuppliers">
                <input class="checkbox" [(ngModel)]="item.checked"
                type="checkbox" (click)="clickSupplierCheckBox(item, $event)">
                <div class="supplier-name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>

    <!-- Category Tag -->
    <div class="filter-tag" *ngIf="filterCategories?.length > 0">
      <div class="category">Category</div>
      <div class="vline"></div>
      <div class="value" mat-button [matMenuTriggerFor]="menuCategory" *ngIf="filterCategories?.length > 1">Multiple</div>
      <div class="value" mat-button [matMenuTriggerFor]="menuCategory" *ngIf="filterCategories?.length == 1">{{filterCategories[0].name}}</div>
      <div class="delete" (click)="deleteFilterTag(filterMenuSections.Category)"><i class="fas fa-close"></i></div>
      <mat-menu #menuCategory="matMenu" class="filter-menu" (closed)="menuClosed()">
        <div class="body" style="padding-top: 5px" (click)="$event.stopPropagation();">
          <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
              (ngModelChange)="filterSearchList(filterMenuSections.Category, $event)">
          </div>
          <div class="supplier-list">
            <div class="supplier-item" *ngFor="let item of searchedAccounts">
              <input class="checkbox" [(ngModel)]="item.checked"
                type="checkbox" (click)="clickCategoryCheckBox(item, $event)">
              <div class="supplier-name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>

    <!-- Tags Tag -->
    <div class="filter-tag" *ngIf="filterTags?.length > 0">
      <div class="category">Tags</div>
      <div class="vline"></div>
      <div class="value" mat-button [matMenuTriggerFor]="menuTags" *ngIf="filterTags?.length > 1">Multiple</div>
      <div class="value" mat-button [matMenuTriggerFor]="menuTags" *ngIf="filterTags?.length == 1">{{filterTags[0].tagName}}</div>
      <div class="delete" (click)="deleteFilterTag(filterMenuSections.Tag)"><i class="fas fa-close"></i></div>
      <mat-menu #menuTags="matMenu" class="filter-menu" (closed)="menuClosed()">
        <div class="body" style="padding-top: 5px" (click)="$event.stopPropagation();">
          <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
              (ngModelChange)="filterSearchList(filterMenuSections.Tag, $event)">
          </div>
          <div class="tags-list">
            <div class="tag-item">
              <input class="checkbox" [(ngModel)]="unassignedTag.checked"
                type="checkbox" (click)="clickTagCheckBox(unassignedTag, $event)">
              <span class="unassigned-tag">
                <span>{{unassignedTag.tagName}}</span>
              </span>
            </div>
            <div class="tag-item" *ngFor="let tag of searchedTags">
              <input class="checkbox" [(ngModel)]="tag.checked"
                type="checkbox" (click)="clickTagCheckBox(tag, $event)">
              <span class="tag" [ngStyle]="{'color': tag.textColor, 'background': tag.backColor}">
                <span>{{tag.tagName}}</span>
              </span>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>

    <!-- TrackingCategories1 Tag -->
    <div class="filter-tag" *ngIf="filterTrackingOptions1?.length > 0">
      <div class="category">{{selectedTrackingCategories[0].name}}</div>
      <div class="vline"></div>
      <div class="value" mat-button [matMenuTriggerFor]="menuTrackingOprions1" *ngIf="filterTrackingOptions1?.length > 1">Multiple</div>
      <div class="value" mat-button [matMenuTriggerFor]="menuTrackingOprions1" *ngIf="filterTrackingOptions1?.length == 1">{{filterTrackingOptions1[0].name}}</div>
      <div class="delete" (click)="deleteFilterTag(filterMenuSections.TrackingCategory1)"><i class="fas fa-close"></i></div>
      <mat-menu #menuTrackingOprions1="matMenu" class="filter-menu" (closed)="menuClosed()">
        <div class="body" style="padding-top: 5px" (click)="$event.stopPropagation();">
          <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
              (ngModelChange)="filterSearchList(filterMenuSections.TrackingCategory1, $event)">
          </div>
          <div class="supplier-list">
            <div class="supplier-item" *ngFor="let item of searchedTrackingOprions1">
              <input class="checkbox" [(ngModel)]="item.checked"
                type="checkbox" (click)="clickTrackingCategoryCheckBox(filterMenuSections.TrackingCategory1, option, $event)">
              <div class="supplier-name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>

    <!-- TrackingCategories2 Tag -->
    <div class="filter-tag" *ngIf="filterTrackingOptions2?.length > 0">
      <div class="category">{{selectedTrackingCategories[1].name}}</div>
      <div class="vline"></div>
      <div class="value" mat-button [matMenuTriggerFor]="menuTrackingOprions2" *ngIf="filterTrackingOptions2?.length > 1">Multiple</div>
      <div class="value" mat-button [matMenuTriggerFor]="menuTrackingOprions2" *ngIf="filterTrackingOptions2?.length == 1">{{filterTrackingOptions2[0].name}}</div>
      <div class="delete" (click)="deleteFilterTag(filterMenuSections.TrackingCategory2)"><i class="fas fa-close"></i></div>
      <mat-menu #menuTrackingOprions2="matMenu" class="filter-menu" (closed)="menuClosed()">
        <div class="body" style="padding-top: 5px" (click)="$event.stopPropagation();">
          <div class="menu-search">
          <input class="search-input" type="text" placeholder="Search" [ngModel]="tagSearchTerm" 
              (ngModelChange)="filterSearchList(filterMenuSections.TrackingCategory2, $event)">
          </div>
          <div class="supplier-list">
            <div class="supplier-item" *ngFor="let item of searchedTrackingOprions2">
              <input class="checkbox" [(ngModel)]="item.checked"
                type="checkbox" (click)="clickTrackingCategoryCheckBox(filterMenuSections.TrackingCategory2, option, $event)">
              <div class="supplier-name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>
  </section>

  <section class="controls">
    <div class="clear-filter" (click)="deleteFilterTag(filterMenuSections.Main)" 
      *ngIf="filterTags?.length > 0 || filterCategories?.length > 0 || filterSuppliers?.length > 0 || (filterDateRange?.start && filterDateRange?.end)
        || filterTrackingOptions1?.length > 0 || filterTrackingOptions2?.length > 0">
      <span class="icon"><i class="fas fa-close"></i></span>
      <span class="clear-text">Clear Filters</span>
    </div>
  </section>
</div>

