<div id="comment_modal" class="commentmodel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md">

        <div class="modal-content" style="padding: 20px 30px 18px 30px;;text-align: center;">
            <div class="modal-body">
                <button type="button" class="btn-close " (click)="Selectno()" aria-label="Close"></button>
                <div class="dm_head">
                    <h3 *ngIf="showsavebutton">Add Comment</h3>
                    <h3 *ngIf="showclosebutton">Your Comment</h3>
                </div>
                <div class="dm_content" style="margin-top:20px">
                    <textarea name="" maxlength="50" id="CommentDetails" style="border: 1px solid rgb(118, 118, 118);" cols="30" rows="4" placeholder="Add comment here" style="display: none;"></textarea>
                    <p id="showCommentDetails" style="display: none;"></p>
                    <!-- <textarea name="" style="display: none;" style="border: 1px solid rgb(118, 118, 118);" id="CommentDetails" cols="30" rows="4" placeholder="Add comment here"></textarea>
                    <p id="showCommentDetails" style="display: none;"></p> -->
                    <div class="modal_btns" style="margin-top: 20px;">
                        <a class="custom_button green" (click)="Selectyes()" style="cursor: pointer;" *ngIf="showsavebutton">Save</a>
                        <a class="custom_button yellow" (click)="Selectno()" style="cursor: pointer;" *ngIf="showsavebutton">Cancel</a>
                        <a class="custom_button yellow" (click)="Selectno()" style="cursor: pointer;" *ngIf="showclosebutton">close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>