<div class="step-side-form-container">
  <div class="signup-form-container">
    <form class="signup-form" [formGroup]="SignUpForm" role="form" name="loginForm" (ngSubmit)="nextStep()">
      <div class="step-header">Create your Synced account</div>
      <div class="step-header-second">This will only take a few mins!</div>

      <div class="log-in-with-xero">
        <button class="xero-button" (click)="signUpWithXero()">
          <img class="logo" src="../../../../../assets/img/company/XeroLogo.svg" alt="" />
          <span>Sign Up With Xero</span>
        </button>

        <div class="lines">
          <div class="line"></div>
          <div class="line-text">Or, sign in with your email</div>
          <div class="line"></div>
        </div>
      </div>

      <div class="">
        <div class="form-group">
          <label for="email" class="control-lable">Your email<span class="required">*</span></label>
          <input class="form-control" name="email" formControlName="email" autocomplete="off"><span [ngClass]="{'isvalid' : !signUpFormControl.email?.errors?.required }"></span>
          <span class="text-muted"
                *ngIf="signUpFormControl.email?.errors?.required && isWarningShown && (signUpFormControl.email.dirty || signUpFormControl.email.touched)">
            Email is required
          </span>
          <span class="text-muted"
                *ngIf="!signUpFormControl.email?.errors?.required && isWarningShown && signUpFormControl.email.errors && (signUpFormControl.email.dirty || signUpFormControl.email.touched)">
            Please enter valid email address
          </span>
          <span class="text-muted"
                *ngIf="signUpFormControl.email?.errors?.emailIsBusy && (signUpFormControl.email.dirty || signUpFormControl.email.touched)">
            This email already exists in the system
          </span>
        </div>
      </div>

      <div class="">
        <div class="form-group">
          <label for="password">Password<span class="required">*</span></label>
          <input class="form-control" name="password" type="password" formControlName="password" autocomplete="off">
          <span class="text-muted"
                *ngIf="signUpFormControl.password?.errors?.required && isWarningShown && (signUpFormControl.password.dirty || signUpFormControl.password.touched)">
            Password is required
          </span>
          <!--<span class="text-muted"
                *ngIf="signUpFormControl.password?.errors  && signUpFormControl.password?.errors?.pattern && (signUpFormControl.password.dirty || signUpFormControl.password.touched)">
            <ul>
              <li id="length" class="red">Password must be at least 8 character length</li>
              <li id="uppercase" class="red">Include at least one upper case character (A-Z)</li>
              <li id="lowercase" class="red">Include at least one lower case character (a-z)</li>
              <li id="numbers" class="red">Include a number (0-9)</li>
              <li id="symbols" class="red">Include a symbol (~!@#$%&*_)</li>
            </ul>
          </span>-->
          <span class="text-muted red" [innerHTML]="SignUpForm.get('password').errors['passwordStrength']"
                *ngIf="signUpFormControl.password?.errors  && signUpFormControl.password?.hasError('passwordStrength') && (signUpFormControl.password.dirty || signUpFormControl.password.touched)">
          </span>
        </div>
      </div>

      <div class="">
        <div class="form-group mrgn-10">
          <label class="custom_check tandcpp">
            By creating an account you are agreeing to our Terms and Conditions and Privacy Policy
            <input type="checkbox" checked="checked" class="chk-agree-condition" name="accountRemember" formControlName="accountRemember" autocomplete="off">
            <span class="checkmark"></span>
          </label>
          <span>
          </span>
          <span class="text-muted-warning"
                *ngIf="signUpFormControl.accountRemember?.errors?.required && isWarningShown && (signUpFormControl.accountRemember.dirty || signUpFormControl.accountRemember.touched)">
            Please check Terms and Conditions and Privacy Policy
          </span>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="signup__btn-next" [ngClass]="{'disabled': SignUpForm.invalid === true}">NEXT</button>
        <div class="signup-donthaveact"><a routerLink="['auth/login']">Already have an account?</a></div>
      </div>
    </form>
  </div>
</div>
<div class="step_side_image_container">
  <div class="signup_image_container">
    <img class="step-one-img" src="../../../../../assets/img/signup/step-one.svg">
  </div>
</div>
