
export class SyncedExpClaims {
    id: string;
    reportName: string;
    userId?: string;
    organizationId?: string;
    invoiceId?:string;
    createdDate?:Date;
    createdBy?: string;
    reportDate?: Date;
    status?:number;
}