import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { xeroSignUpConnection } from 'src/environments/environment';
import { LoginModel } from '../../../../core/model/login-model'; 
import { PasswordPatternConst } from '../../../bookkeeping/data/common';
import { PasswordStrengthValidator } from '../../../bookkeeping/validators/password-strength-validator';

@Component({
  selector: 'app-signup-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {
  SignUpForm: FormGroup;
  public isFormValid = false;
  isWarningShown: boolean = false;
  signUpModel: LoginModel | null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  get signUpFormControl() {
    return this.SignUpForm.controls;
  }
  
  ngOnInit(): void {

    // Create the form
    this.SignUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      //password: ['', Validators.compose([Validators.required, Validators.pattern(PasswordPatternConst)])],
      password: ['',  [Validators.required, PasswordStrengthValidator]],
      accountRemember: [true, [Validators.requiredTrue]],
    });
  }

  nextStep() {
    this.SignUpForm.markAllAsTouched();
    this.isFormValid = false;
    this.signUpModel = {
      email: this.signUpFormControl.email.value.toLowerCase(),
      password: this.signUpFormControl.password.value,
      accountRemember: this.signUpFormControl.accountRemember.value
    }
    if (this.SignUpForm.invalid) {
      this.isWarningShown = true;
      return;
    } else {
      sessionStorage.setItem("newuser", JSON.stringify(this.signUpModel));
      this.router.navigate(['/signup/step-two']);
    }
  }

  signUpWithXero() {
    window.location.replace(xeroSignUpConnection.xeroOAuth2SignUpLink);
  }
}
