import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { RegistrationLayoutComponent } from './layout/registration-layout/registration-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { SignupLayoutComponent } from './layout/signup-layout/signup-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    /// this will be not in used after we complete work on SD-198
    path: 'register',
    // canActivate: [NoAuthGuard], // Should be replaced with actual auth guard
    component: RegistrationLayoutComponent,
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'signup',
    component: SignupLayoutComponent,
    loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'signed-in-redirect',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard], // Should be replaced with actual auth guard
    canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    // children: [
    //   {
    //     path: 'dashbaord-layout',
    //     loadChildren: () =>
    //       import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    //   }
    // ]
  },
  {
    path: 'report',
    component: ContentLayoutComponent,
    // canActivate: [AuthGuard], // Should be replaced with actual auth guard
    // canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule)
    // children: [
    //   {
    //     path: 'dashbaord-layout',
    //     loadChildren: () =>
    //       import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    //   }
    // ]
  },
  {
    path: 'planning',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/planning/planning.module').then(m => m.PlanningModule)
  },
  {
    path: 'emails',
    component: ContentLayoutComponent,
    // canActivate: [AuthGuard], // Should be replaced with actual auth guard
    // canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/emails/emails.module').then(m => m.EmailsModule)
  },
  {
    path: 'paperwork',
    component: ContentLayoutComponent,
    // canActivate: [AuthGuard], // Should be replaced with actual auth guard
    // canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/paperwork/paperwork.module').then(m => m.PaperworkModule)
  },
  {
    path: 'automate',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/automate/automate.module').then(m => m.AutomateModule)
  },
  {
    path: 'payable',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard], // Should be replaced with actual auth guard
    canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/payable/payable.module').then(m => m.PayableModule)
  },
  {
    path: 'suppliers',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard], // Should be replaced with actual auth guard
    canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)
  },
  {
    path: 'inbox',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard], // Should be replaced with actual auth guard
    canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/bookkeeping/bookkeeping.module').then(m => m.BookkeepingModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'setting',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard], // Should be replaced with actual auth guard
    canActivateChild: [AuthGuard], // Should be replaced with actual auth guard
    loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule)
    // children: [
    //   {
    //     path: 'dashbaord-layout',
    //     loadChildren: () =>
    //       import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    //   }
    // ]
  },
  // Redirect signed in user to the '/dashboards/tasks'
  // Fallback when no prior routes is matched
  {
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes
    )
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
