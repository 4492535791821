import { CurrencyCode } from "../../enums/currency-code";
import { InvoiceLine } from "./invoice-line";
import { DocumentType } from "../../enums/document-type";
import { InvoicePaymentStatus } from "../../enums/payment-status";

export class CreatedInvoiceWithLines {

  id: string = null;
  organisationId: string = '';
  type: DocumentType = DocumentType["Supplier Invoice"];
  date: Date = null;
  dueDate: Date = null;
  invoiceNumber: string = '';
  supplierId: string = '';
  supplierName: string = '';
  amountDue: number = 0;
  totalTax: number = 0;
  subTotal: number = 0;
  currency: CurrencyCode = CurrencyCode.AUD;
  pdfUrl: string = '';
  invoiceLines: InvoiceLine[] = [];
  InvoiceOrCreditNote: string = DocumentType["Supplier Invoice"];
  paymentStatus?: InvoicePaymentStatus;
  paidBy?: string = '';
  expenseReportId?: string = '';
  expenseReportName?: string = '';
  paymentAccountNumber: string;
  paymentDate?: Date = null;
  unreconciledReportIds: string = '';
}

