import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReportFilter } from 'src/app/data/model/reportFilter';
import { SidebarType } from 'src/app/modules/bookkeeping/enums/sidebar-type';

@Component({
  selector: 'app-pagesidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss']
})
export class PageSidebarComponent implements OnInit {
  @Input() savedFilters: ReportFilter[] = [];
  @Input() isInbox: boolean = false;
  @Input() isBillsToPay: boolean = false;
  @Input() invoiceCount: number = 0;
  @Output() changeTabEvent = new EventEmitter<string>();
  @Output() changeFilterEvent = new EventEmitter<ReportFilter>();
  @Output() closeFilter = new EventEmitter<ReportFilter>();
  @Output() addFilter = new EventEmitter<any>();
  @Output() toggleSidebarEvt = new EventEmitter<SidebarType>();
  @Output() searchEvt = new EventEmitter<any>();
  displaySavedFilters: ReportFilter[] = [];
  isSidebar: boolean = false;
  isProcessedTab: boolean = false;
  isInProcessingTab: boolean = true;
  isProcessingError: boolean = false;
  isBillsToPayTab: boolean = true;
  isPaymentsTab: boolean = false;
  types = SidebarType;
  isShowMore: boolean = false;
  isShowLess: boolean = false;
  roleId: string;

  @ViewChild('searchtext') searchInput: ElementRef;

  ngOnInit() {
    this.roleId = localStorage.getItem("roleId");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.savedFilters && changes.savedFilters.currentValue) {
      this.savedFilters = changes.savedFilters.currentValue;
      this.displaySavedFilters = this.savedFilters.slice(0,5);
      if(this.savedFilters.length > 5) {
        this.isShowMore = true;
      }
    }
  }

  showMore(){
    this.displaySavedFilters = this.savedFilters;
    this.isShowMore = false;
    this.isShowLess = true;
  }

  showLess(){
    this.displaySavedFilters = this.savedFilters.slice(0,5);
    this.isShowMore = true;
    this.isShowLess = false;
  }

  changeTab(value: string) {
    if (value == "InProcessing") {
      this.isInProcessingTab = true;
      this.isProcessedTab = false;
      this.isProcessingError = false;
    }
    else if (value == "Processed") {
      this.isProcessedTab = true;
      this.isInProcessingTab = false;
      this.isProcessingError = false;
    }
    else if (value == "ProcessingError") {
      this.isProcessingError = true;
      this.isInProcessingTab = false;
      this.isProcessedTab = false;
    }
    else if(value == "BillsToPay"){
      this.isBillsToPayTab = true;
    }
    this.changeTabEvent.emit(value);
  }

  addFilters() {
    this.addFilter.emit();
  }

  selectFilters(filter: ReportFilter) {
    let index = this.savedFilters.findIndex(x => x.checked == true);
    if (index == -1) {
      filter.checked = true;
      this.changeFilterEvent.emit(filter);
    }
    else if (index >= 0 && (filter.checked == undefined || filter.checked == false)) {
      this.savedFilters[index].checked = false;
      filter.checked = true;
      this.changeFilterEvent.emit(filter);
    }
    else {
      filter.checked = false;
      this.closeFilter.emit(filter);
    }
  }

  updateSavedFilters(filter) {
    this.savedFilters = Object.assign({}, filter);
  }

  toggleSidebar(sidebar: SidebarType) {
    this.toggleSidebarEvt.emit(sidebar);
  }

  onSearchChange(event: any): void {
    this.searchEvt.emit(event);
  }
}