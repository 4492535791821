import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ApprovalUser } from '../../models/related/related';
import { ContactInvoiceShortData, ContactRelatedData, ContactRelatedDataRequest } from '../../models/related/related-contact';
import { SupplierAutoApprover } from '../../models/related/contact-auto-approver';
import { NewSupplier } from '../../models/supplier/new-supplier';
import { RelatedDataService } from '../../services/related-data.service';
import { SupplierAutoPublish } from '../../models/related/contact-auto-publish';

@Component({
  selector: 'app-related-contact',
  templateUrl: './related-contact.component.html',
  styleUrls: ['./related-contact.component.scss']
})
export class RelatedContactComponent extends BaseComponent implements OnInit {
  @Input() supplier: NewSupplier;
  @Input() billsToPayMode: boolean = false;
  @Output() clickInvoiceEvent = new EventEmitter<string>(); 

  isBillsExpanded: boolean = true;
  isAutomationsExpanded: boolean = false;
  isAccountingExpanded: boolean = true;
  showToolTipDelay: number = 400;
  link: string = null;
  relatedData = {} as ContactRelatedData;
  invoicePageSize: number = 5;
  invoicePage: number = 1;
  isSpinnerShown: boolean = false;
  outstandingInvoices = [] as ContactInvoiceShortData[];
  paidInvoices = [] as ContactInvoiceShortData[];
  outstandingTotal: number = null;
  isPaidInvoiceShown: boolean = false;
  isAutoPublishOn: boolean = false;
  isAutoApprovalOn: boolean = false;
  autoApprover: ApprovalUser = null;

  constructor(
    private readonly _relatedDataService: RelatedDataService,
    private readonly _organizationService: OrganizationService,
    private readonly _toasterMessageService: ToasterMessageService,
    private readonly _router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.isSpinnerShown = true;

    const requestData = {
      organizationId: this._organizationService.selectedOrganization,
      contactId: this.supplier.supplierId
    } as ContactRelatedDataRequest;

    this._relatedDataService.getContactRelatedData(requestData)
      .pipe(this.untilThis)
      .subscribe(
        (data: ContactRelatedData) => {
          this.isSpinnerShown = false;
          if (data) {
            this.relatedData = data;
            this.outstandingInvoices = this.relatedData?.invoices?.filter(i => i.status == 'AUTHORISED');
            this.paidInvoices = this.relatedData?.invoices?.filter(i => i.status == 'PAID');
            this.outstandingTotal = this.outstandingInvoices.reduce((a, b) => a + b.amountDue, 0);

            if (this.relatedData.organization?.autoApproverId) {
              this.autoApprover = this.relatedData.organization?.users?.find(u => u.userGuidId?.toLowerCase() == this.relatedData.organization?.autoApproverId?.toLowerCase());
            }

            this.isAutoApprovalOn = this.relatedData.organization?.isAutoApprovalOn;
            this.isAutoPublishOn = this.relatedData.organization?.isAutoPublishOn;
            this.prepareAccIntLink();
          }
        },
        (e: Error) => {
          this.isSpinnerShown = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  showInvoice(invoice: ContactInvoiceShortData) {
    if (this.billsToPayMode) {
      this.clickInvoiceEvent.emit(invoice.invoiceId);
    } else {
      this._router.navigate([`/payable/outstanding-amounts/${invoice.invoiceId}`]);
    }
  }

  changeAutoPublishSetting(event) {
    this.isAutoPublishOn = event?.target?.checked;

    if (this.relatedData?.organization) {
      this.relatedData.organization.isAutoPublishOn = this.isAutoPublishOn;
    }

    let autoPublishData = {
      contactId: this.supplier.supplierId,
      organizationId: this._organizationService.selectedOrganization,
      isAutoPublishOn: this.isAutoPublishOn
    } as SupplierAutoPublish;

    this.updateContactAutoPublishData(autoPublishData);
  }

  changeAutoApprovalSetting(event) {
    this.isAutoApprovalOn = event?.target?.checked;

    if (this.relatedData?.organization) {
      this.relatedData.organization.isAutoApprovalOn = this.isAutoApprovalOn;
    }

    let autoApprovalData = {
      autoApproverId: this.autoApprover?.userGuidId,
      contactId: this.supplier.supplierId,
      organizationId: this._organizationService.selectedOrganization,
      isAutoApprovalOn: this.isAutoApprovalOn
    } as SupplierAutoApprover;

    this.updateContactAutoApprovalData(autoApprovalData, this.autoApprover);
  }

  selectUserForAutoApproval(user: ApprovalUser) {
    let autoApprovalData = {
      autoApproverId: user?.userGuidId,
      contactId: this.supplier.supplierId,
      organizationId: this._organizationService.selectedOrganization,
      isAutoApprovalOn: this.isAutoApprovalOn
    } as SupplierAutoApprover;

    this.updateContactAutoApprovalData(autoApprovalData, user);
  }

  private updateContactAutoPublishData(publishData: SupplierAutoPublish) {
    this._relatedDataService.setSupplierAutoPublish(publishData)
    .pipe(this.untilThis)
    .subscribe(
      () => {},
      (err: Error) => {
        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.message);
      }
    );
  }

  private updateContactAutoApprovalData(approverData: SupplierAutoApprover, approver: ApprovalUser) {
    this._relatedDataService.setSupplierAutoApprover(approverData)
    .pipe(this.untilThis)
    .subscribe(
      () => {
        this.autoApprover = approver;
        this.relatedData.organization.autoApproverId = approver?.userGuidId;
      },
      (err: Error) => {
        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.message);
      }
    );
  }

  private prepareAccIntLink() {
    if (this.relatedData?.organization?.isQBO) {
      this.link = `https://app.qbo.intuit.com/app/vendordetail?nameId=${this.relatedData?.organization?.referenceId}`;
    } else {
      this.link = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${this.relatedData?.organization?.shortCode}&redirecturl=/Contacts/View/${this.supplier.supplierId}`;
    }
  }
}
