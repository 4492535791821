import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ScreenBrakepoints } from 'src/app/data/model/screen-brakepoints';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  isShowSideNav = false;

  private _existingPaymentSubject = new BehaviorSubject<[]>(null);
  existingPaymentState$ = this._existingPaymentSubject.asObservable();

  private _existingPaymentSidebarSubject = new BehaviorSubject<boolean>(false);
  existingPaymentSidebarState$ = this._existingPaymentSidebarSubject.asObservable();

  private _existingPaymentRefreshSubject = new BehaviorSubject<boolean>(false);
  existingPaymentRefreshState$ = this._existingPaymentRefreshSubject.asObservable();
  
  private _screenBrakepointSubject = new BehaviorSubject<ScreenBrakepoints>(null);
  screenBrakepoint$ = this._screenBrakepointSubject.asObservable();

  constructor() { }

  sendPaymentRows(supplier: any) {
    this._existingPaymentSubject.next(supplier);
  }

  getPaymentRows() {
    return this._existingPaymentSubject.asObservable();
  }

  showHideSidebar(showhide: boolean) {
    this._existingPaymentSidebarSubject.next(showhide);
  }

  refreshAfterPayment(refresh: boolean) {
    this._existingPaymentSidebarSubject.next(refresh);
  }

  setScreenBrakepoint(screenBrakepoint: ScreenBrakepoints) {
    this._screenBrakepointSubject.next(screenBrakepoint);
  }

  ngOnDestroy() {
    this._existingPaymentSubject.unsubscribe();
    this._existingPaymentSidebarSubject.unsubscribe();
  }
}
