import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { NewSupplier } from '../../models/supplier/new-supplier';
import { BankAccount } from '../../models/account/bank-account';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { SharedService } from '../../services/shared-service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { CurrencyCode } from '../../enums/currency-code';
import { PaymentAccount } from '../../models/account/payment-account';
import { EnumItem } from '../../models/enum-item';
import { enumNumberSelector } from 'src/app/core/util/enum-selector';
import { PaymentMethod } from '../../enums/payment-method';
import { fromCamelCase } from 'src/app/core/util/camel-case-converter';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { take } from 'rxjs/operators';
import { BankAccountService } from 'src/app/data/service/bank-account.service';
import { RecognizedSupplierService } from 'src/app/modules/payable/services/recognized-supplier.service';
import { BillsToPaySupplierDetailsRequest } from '../../models/invoice/bills-to-pay-invoice-details-request';
import { forkJoin } from 'rxjs';
import { xeroParamsForConnection, QuickBookConnection } from 'src/environments/environment';
import { TaxRate } from '../../models/taxRate';

enum Pannels {
  DetailsTab,
  ActivityTab,
  RelatedTab,
  SplitLinesPanel,
  ContactForm
}

@Component({
  selector: 'app-supplier-contancts',
  templateUrl: './supplier-contancts.component.html',
  styleUrls: ['./supplier-contancts.component.scss']
})
export class SupplierContanctsComponent extends BaseComponent implements OnInit {
  @Input() currentPannel: Pannels;
  @Input() supplier: NewSupplier;
  @Input() accounts: PaymentAccount[] = [];
  @Input() account: BankAccount;
  @Input() billsToPayMode: boolean = false;
  @Input() supplierTabMode: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() invoiceColorNumber: number = 1;
  @Output() isClosed = new EventEmitter<boolean>();
  @Output() newSupplier = new EventEmitter<NewSupplier>();
  @Output() closeFormEvent = new EventEmitter<string>();
  @Output() contactIsSavedEvent = new EventEmitter<boolean>();

  private readonly  bsbPattern = /\d{3}-\d{3}/i;
  private readonly  routingPattern = /\d{9}/i;
  private readonly  accountNumberPattern = /^[0-9]*$/i;

  showToolTipDelay: number = 400;
  suppliersGroup: any = [];
  bankAccount: BankAccount = new BankAccount(this._organizationService.selectedOrganization);
  isPaymentAccountShown: boolean = true;
  bankName: string = '';
  defaultCurrency: CurrencyCode;
  isVisible: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  loading = false;
  selectedGroupId = [];
  paymentMethods: EnumItem[] = enumNumberSelector(PaymentMethod, fromCamelCase);
  panels = Pannels;
  currencies: string[] = [];
  taxRates: TaxRate[] = [];
  countryCode: string = "AU";
  constructor(
    private _suppliersService: SuppliersService,
    private _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
    private _sharedService: SharedService,
    public spinnerService: SpinnerService,
    private _invoiceService: InvoiceService,
    private _bankAccountService: BankAccountService,
    private readonly _supplierRecognizeService: RecognizedSupplierService,
  ) {
    super();
  }

  ngOnInit() {
    this._organizationService.getOrganizationCountry(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.countryCode = response.countryCode;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
    if (this.supplierTabMode) {
      this._supplierRecognizeService.supplierContactFormSubject$
        .pipe(this.untilThis)
        .subscribe(
          (request: BillsToPaySupplierDetailsRequest) => {
            this.invoiceColorNumber = request.invoiceColorNumber;
            this.getAccounts();
            this.supplier = new NewSupplier(this._organizationService.selectedOrganization);
            this.supplier.supplierId = request.supplierId;
            this.getAllInitialData();
          }
        );
    } else {
      this.getAllInitialData();
    }
    if (this.currentPannel) {
      this.tabClick(this.currentPannel);
    }
    else {
      this.currentPannel = Pannels.DetailsTab;
    }
  }

  clickOnRelatedInvoice(invoiceId: string) {
    this.closeFormOnSupplier(invoiceId);
  }

  closeFormOnSupplier(invoiceId: string = null) {
    this.closeFormEvent.emit(invoiceId);
  }

  checkBSB() {
    if (!!this.bankAccount?.branchDetails && !this.bankAccount?.branchDetails.includes('-'))
      this.bankAccount.branchDetails = this.bankAccount.branchDetails?.slice(0, 3) + '-' + this.bankAccount.branchDetails?.slice(3);
  }

  subscribeToAccountUpdates() {
    this._bankAccountService.accountState$
      .pipe(this.untilThis)
      .subscribe(
        (account: BankAccount) => {
          this.bankAccount = account;
          this.checkBSB();
        }
      );
    if (this.account) {
      this._bankAccountService.updateBankAccount(this.account);
    }
  }
  onDeleteGroup(item: any) {
    this.selectedGroupId = Object.values(this.selectedGroupId).filter(g => g != item.typeId);
    this.saveContactForm('Contact Group');
  }

  onItemSelect(item: any) {
    this.selectedItems.push(item);
    this.selectedGroupId.push(item.typeId);
    this.saveContactForm('Contact Group')
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    items.forEach(element => {
      this.selectedGroupId.push(element.typeId);
    });

    this.saveContactForm('Contact Group')
  }

  submit(fieldName: string) {
    this.supplier.bsb = this.bankAccount.branchDetails ? this.bankAccount.branchDetails : this.supplier.bsb;
    this.supplier.accountNumber = this.bankAccount.accountNumber ? this.bankAccount.accountNumber : this.supplier.accountNumber;
    this.supplier.routingNumber = this.bankAccount.routingNumber ? this.bankAccount.routingNumber : this.supplier.routingNumber;
    this.supplier.accountNumberUS = this.bankAccount.accountNumberUS ? this.bankAccount.accountNumberUS : this.supplier.accountNumberUS;

    this.addNewSupplier(this.supplier, fieldName)
  }

  addNewSupplier(supplier: NewSupplier, fieldName: string = null) {
    this.loading = true;
    this.contactIsSavedEvent.emit(true);
    if (supplier?.bsb && supplier?.accountNumber) {
      supplier.bankAccountRequestModel.BSBNumber = supplier.bsb?.replace('-', '');
      supplier.bankAccountRequestModel.accountNumber = supplier.accountNumber;
      supplier.bankAccountRequestModel.branchDetails = supplier.bankAccountRequestModel.BSBNumber;
    }
    if (supplier?.routingNumber && supplier?.accountNumberUS) {
      supplier.bankAccountRequestModel.accountNumberUS = supplier.accountNumberUS;
      supplier.bankAccountRequestModel.routingNumber = this.bankAccount.routingNumber;
    }

    const newSupplier = JSON.parse(JSON.stringify(supplier));
    newSupplier.bsb = newSupplier.bsb.replace('-', '');
    if (!newSupplier.defaultAccount.id) newSupplier.defaultAccount = null;
    newSupplier.GroupId = this.selectedGroupId;
    if (this.supplier.supplierId != "") {
      newSupplier.id = this.supplier.supplierId;
      this.loading = true;

      this._suppliersService.updateSupplier(newSupplier)
        .pipe(this.untilThis)
        .subscribe(
          (result: any) => {
            this.loading = false;
            let domen = this._organizationService.selectedOrganizationDomen;

            if (result?.error && result?.error == 'ConnectionLost') {
              if (domen == 'QBO') {
                this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
                  `QuickBooks connection lost. Please <a  class=\"toastr-link\" href=\"${QuickBookConnection.QuickBookOauth2Link}\">refresh</a> connection`);
              } else {
                this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
                  `Xero connection lost. Please <a  class=\"toastr-link\" href=\"${xeroParamsForConnection.xeroOauth2Link}\">refresh</a> connection`);
              }
            } else {
              this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', `Supplier ${fieldName} Updated!`);
            }
          }
        )
    }
  }

  updateBankName(name: string) {
    this.bankName = name;
  }

  getSupplierGroup() {
    const groups$ = this._suppliersService.GetSupplierGroup(this._organizationService.selectedOrganization);
    const supplier$ = this._suppliersService.getSupplierById(this._organizationService.selectedOrganization, this.supplier.supplierId);
    const defaultCurrency$ = this._organizationService.getOrganizationDefaultCurrency(this._organizationService.selectedOrganization);

    const forkJoin$ = forkJoin({
      groups: groups$,
      supplier: supplier$,
      defaultCurrency: defaultCurrency$,
    });

    forkJoin$.subscribe(
      (forkData) => {
        this.suppliersGroup = forkData?.groups?.data || [];
        this.defaultCurrency = forkData?.defaultCurrency.currency;

        if (forkData.supplier?.bsb) {
          this.supplier.bsb = forkData.supplier?.bsb.slice(0, 3) + "-" + forkData.supplier?.bsb.slice(3);
          this.bankAccount.branchDetails =  this.supplier.bsb;
          this.bankAccount.accountNumber = forkData.supplier?.accountNumber;
        }
        if (forkData.supplier?.routingNumber) {
          this.bankAccount.routingNumber =  forkData.supplier?.routingNumber;
          this.bankAccount.accountNumberUS = forkData.supplier?.accountNumberUS;
        }

        this.supplier.currency = forkData.supplier?.currency && forkData.supplier?.currency?.toString() != '0' ? forkData.supplier?.currency : this.defaultCurrency;
        this.supplier.name = forkData.supplier?.name;
        this.supplier.firstName = forkData.supplier?.firstName;
        this.supplier.lastName = forkData.supplier?.lastName;
        this.supplier.taxNumber = forkData.supplier?.taxNumber;
        this.supplier.accountName = forkData.supplier?.accountName;
        this.supplier.email = forkData.supplier?.email;
        this.supplier.aliases = forkData.supplier?.aliases;
        this.supplier.hexColorClass = forkData.supplier?.hexColorClass;

        if (forkData.supplier?.contactGroups != null && forkData.supplier?.contactGroups.length > 0) {
          let itemArray = [];
          var contactGroupsArr = forkData.supplier.contactGroups.split(',');
          contactGroupsArr.forEach(element => {
            if (element != "") {
              let cntGrp = this.suppliersGroup.find(x => x.typeId == element.toLocaleLowerCase());
              itemArray.push(cntGrp);
            }
          });
          this.selectedItems = itemArray;
          this.selectedGroupId = itemArray.map(g => g.typeId);
        }

        if (forkData.supplier?.defaultAccount != null) {
          let defAcc = this.accounts.find(x => x.code.toLowerCase() == forkData.supplier?.defaultAccount.toString().toLowerCase());

          if (defAcc)
            this.supplier.defaultAccount = defAcc;
        }
        if (forkData.supplier?.accountsPayableTaxType != null) {
          let defRate = this.taxRates.find(x => x.taxType.toLowerCase() == forkData.supplier?.accountsPayableTaxType.toString().toLowerCase());
          if (defRate) {
            this.supplier.accountsPayableTaxType = defRate.taxType;
            this.supplier.accountsPayableTaxName = defRate.name;
          }
        }
      }
    );
  }

  doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    try{
      xhr.send();
      if (xhr.status == 404) {
        return false;
      } else {
          return true;
      }
    }
    catch {
      return false;
    }
  }

  onSelectAccount(event: TypeaheadMatch) {
    this.supplier.defaultAccount = event.item;
    this.saveContactForm('Default Account')
  }

  onSelectTaxRate(event: TypeaheadMatch) {
    this.supplier.accountsPayableTaxName = event.item.name;
    this.supplier.accountsPayableTaxType = event.item.taxType;
    this.saveContactForm('Default Tax Rate')
  }

  cancel() {
    this.isClosed.emit(false);
    this._sharedService.closeActionBar(false);
  }

  togglePaymentAccount() {
    this.isPaymentAccountShown = !this.isPaymentAccountShown;
  }

  updateBSB(bsb: string) {
    this.bankAccount.branchDetails = bsb.length === 3 ? bsb + '-' : bsb;

    this.getBankName(bsb);
  }

  updateRouting(routingNumber: string) {
    this.bankAccount.routingNumber = routingNumber;
  }

  getBankName(bsb: string) {
    if (bsb) {
      if (!!bsb.match(this.bsbPattern)) {
        this._invoiceService.getBankNameByBSB(bsb)
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.bankName = result.name || '';
            }
          )
      } else {
        this.bankName = '';
      }
    }
  }

  tabClick(tab: Pannels) {
    switch (tab) {
      case Pannels.DetailsTab:
        this.currentPannel = Pannels.DetailsTab;
        break;
      case Pannels.ActivityTab:
        this.currentPannel = Pannels.ActivityTab;
        break;
        case Pannels.RelatedTab:
          this.currentPannel = Pannels.RelatedTab;
          break;
      default:
        this.currentPannel = Pannels.DetailsTab;
        break;
    }
  }

  saveContactForm(fieldName: string) {
    if (this.isEditable) {
      this.submit(fieldName);
    }
  }

  saveBSBAccountPair() {
    if (this.bankAccount?.branchDetails && this.bankAccount?.accountNumber
      && this.bsbPattern.test(this.bankAccount?.branchDetails) && this.accountNumberPattern.test(this.bankAccount?.accountNumber))
    {
      this.saveContactForm('BSB and Account Number');
    }
  }

  saveRoutingAccountPair() {
    if (this.bankAccount?.routingNumber && this.bankAccount?.accountNumberUS
      && this.routingPattern.test(this.bankAccount?.routingNumber) && this.accountNumberPattern.test(this.bankAccount?.accountNumberUS))
    {
      this.saveContactForm('Routing Number and Account Number');
    }
  }

  showActivityPanel() {
    if (this.currentPannel == Pannels.ActivityTab) {
      this.currentPannel = Pannels.DetailsTab;
    } else {
      this.currentPannel = Pannels.ActivityTab;
    }
  }

  private getAccounts() {
    this._bankAccountService.getPaymentAccounts(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (accounts: PaymentAccount[]) => {
          this.accounts = accounts;
        });
  }

  private getAllInitialData() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'typeId',
      textField: 'typeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getSupplierGroup();
    this.getOrganizationCurrencies();
    this.checkBSB();
    this.getBankName(this.bankAccount.branchDetails);
    this.subscribeToAccountUpdates();
    this.getTaxRates()
  }

  private getOrganizationCurrencies() {
    this._organizationService.getOrganizationCurrencies(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (data: string[]) => {
          this.currencies = [...data];
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  getTaxRates() {
    this._organizationService.getTaxRates(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (taxRates: TaxRate[]) => {
          this.taxRates = [...taxRates];
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
