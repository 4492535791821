import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepOneComponent } from './steps/step-one/step-one.component';
import { StepTwoComponent } from './steps/step-two/step-two.component';
import { StepThreeComponent } from './steps/step-three/step-three.component'
import { SignupRoutingModule } from './signup.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XeroSignUpComponent } from './steps/xero-sign-up/xero-sign-up.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SignupWizzardComponent } from './wizzard/signup-wizzard/signup-wizzard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { ModalModule } from "ngx-bootstrap/modal";
@NgModule({
  declarations: [
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    XeroSignUpComponent,
    SignupWizzardComponent
  ],
  imports: [
    CommonModule, SignupRoutingModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    FormsModule,
    MatTooltipModule,
    MatRadioModule,
    ModalModule.forRoot()
  ],
  exports: [
    StepOneComponent,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SignUpModule { }
