import { Injectable } from '@angular/core';
import { TagToInvoices } from 'src/app/modules/bookkeeping/models/tag/tag-to-invoice';
import { Tag } from '../model/tag';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private readonly _httpService: HttpInternalService) { }

  getTagsByOrganizationId(organisationId: string) {
    return this._httpService.getRequest<Tag[]>('Tags/GetTagsByOrganizationId', {organisationId: organisationId});
  }

  createTag(tag: Tag) {
    return this._httpService.postRequest<Tag>('Tags/CreateTag', tag);
  }

  updateTag(tag: Tag) {
    return this._httpService.putRequest('Tags/UpdateTag', tag);
  }

  addTagToInvoices(tagToInvoices: TagToInvoices) {
    return this._httpService.postRequest('Tags/AddTagToInvoices', tagToInvoices);
  }

  deleteTagFromInvoices(tagId: string, invoiceId: string, billsToPayMode: boolean = false) {
    return this._httpService.deleteRequest('Tags/DeleteTagFromInvoice', {tagId: tagId, invoiceId: invoiceId, billsToPayMode: billsToPayMode});
  }
}
