export function stringToDate(date: any): Date {
    if (!date)
        return date;

    const receivedDate = date.split("-");
    return new Date(Number(receivedDate[0]), Number(receivedDate[1]) - 1, Number(receivedDate[2].substring(0, 2)));
}

export function dateToString(date: Date): any {
    return !!date ? `${date.getFullYear()}-${addLeadingZero(date.getMonth() + 1)}-${addLeadingZero(date.getDate())}` : date;
}

function addLeadingZero(value: number, minCount: number = 10): string {
    return value < minCount ? `0${value}` : `${value}`;
}