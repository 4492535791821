<div class="sidenav_in">
  <div class="reportg reportg-custom">
    <div class="close-row">
      <div class="close-icon" matTooltip="Close Settings Sidebar" [matTooltipShowDelay]="showToolTipDelay"
           (click)="cancel()">
        <img src="../../../../../assets/img/close-icon.svg" alt="">
      </div>
    </div>
    <div class="heading report__main__heading">{{ selectedReportName }}</div>
    <h4 class="report__drop__title">Create Bill From Email</h4>
    <div class="input-group mb-3">
      <input type="text" class="form-control email" aria-describedby="basic-addon2" [value]="organizationEmail">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="copyEmail()">
          <i class="fas fa-copy"></i>
        </button>
      </div>
    </div>
    <span class="gray-line">&nbsp;</span>
    <h3 class="">Taxes</h3>
    <h5 class="report__drop__title">Do you want to track tax on you expenses?</h5>
    <ng-select placeholder="Select Tax Basis"
               class="custom"
               [items]="taxBasisItem"
               [searchable]="false"
               [clearable]="false"
               bindLabel="name"
               bindValue="id"
               [(ngModel)]="selectedTaxBasis">
    </ng-select>
    <ng-container *ngIf="selectedTaxBasis == 2">
      <h5 class="report__drop__title">What is your default tax rate on expenses?</h5>
      <ng-select placeholder="Select Tax Rate"
                 class="custom"
                 [items]="taxRates"
                 [searchable]="false"
                 [clearable]="false"
                 bindLabel="name"
                 bindValue="id"
                 [loading]="taxRatesLoading"
                 [(ngModel)]="selectedTaxRateId">
      </ng-select>
    </ng-container>
    <ng-container *ngIf="selectedTaxBasis == 3 ">
      <h5 class="report__drop__title">What is your default tax INCLUSIVE rate?</h5>
      <ng-select placeholder="Select Tax Rate"
                 class="custom"
                 [items]="taxRates"
                 [searchable]="false"
                 [clearable]="false"
                 bindLabel="name"
                 bindValue="id"
                 [loading]="taxRatesLoading"
                 [(ngModel)]="selectedTaxRateId">
      </ng-select>
      <h5 class="report__drop__title">What is your default tax EXCLUSIVE rate?</h5>
      <ng-select placeholder="Select Tax Rate"
                 class="custom"
                 [items]="taxRatesExclusive"
                 [searchable]="false"
                 [clearable]="false"
                 bindLabel="name"
                 bindValue="id"
                 [loading]="taxRatesLoading"
                 [(ngModel)]="selectedTaxExclusiveRateId">
      </ng-select>
    </ng-container>
    <ng-container *ngIf="!IsQuickBook">
      <span class="gray-line">&nbsp;</span>
      <h3 class="report__main__heading">Additional Tracking</h3>
      <h5 class="report__drop__title">What other fields would you like to track on expenses?</h5>
      <ng-select placeholder="None Selected"
                 class="custom"
                 [items]="trackingCategories"
                 [multiple]="true"
                 bindLabel="name"
                 [closeOnSelect]="false"
                 bindValue="id"
                 [loading]="trackingCategoriesLoading"
                 [(ngModel)]="selectedTrackingCategoriesIds"
                 (add)="checkTrackingCategoriesNumber()">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
        </ng-template>
      </ng-select>
    </ng-container>
      <div class="reportg_btn reportnew">
        <button (click)="save()" class="run report__run__button" [disabled]="loading">
          <span *ngIf="!loading" class="btn-text">Save</span>
          <span class="spinner-wrapper" *ngIf="loading"><mat-spinner [diameter]="18"></mat-spinner></span>
          <span *ngIf="loading" class="btn-text">&nbsp;Saving</span>
        </button>
        <a (click)="cancel()" class="cancel report__cancel__button">
          Cancel
        </a>
      </div>
  </div>
