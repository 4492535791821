<mat-form-field style="width:100% !important" [floatLabel]="'never'">
  <mat-label>{{placeholder}}</mat-label>
  <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
    <input matStartDate #dateRangeStart formControlName="start" (focus)="picker.open()">
    <input matEndDate #dateRangeEnd formControlName="end" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
           (focus)="picker.open()">
  </mat-date-range-input>
  <button class="cal-clear-btn" mat-button *ngIf="dateRangeStart.value && dateRangeEnd.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="dateRangeStart.value = ''; dateRangeEnd.value = ''; dateRangeChange(null, null)">
    <mat-icon>x</mat-icon>
  </button>
  <mat-date-range-picker #picker md-open-on-focus></mat-date-range-picker>
</mat-form-field>  
