import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { InvoiceShort } from './models/invoice/invoice-short';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { InvoiceStatus } from './enums/invoice-status';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { SidebarType } from './enums/sidebar-type';
import { debounceTime, finalize, switchMap, take, } from 'rxjs/operators';
import { UserService } from 'src/app/data/service/user.service';
import { User } from './models/user/user';
import { ErrorsService } from 'src/app/data/service/errors.service';
import { ErrorRecord } from './models/error/error-record';
import { ActivatedRoute, Router } from '@angular/router';
import { BookkeepingFilter } from './models/filter/bookkeeping-filter';
import { Location } from '@angular/common';
import { InboxInvoices } from './models/invoice/inbox-invoices';
import { SearchService } from 'src/app/data/service/search.service';
import { forkJoin, Subject } from 'rxjs';
import { dateToString } from 'src/app/core/util/date-converter';
import { Title } from '@angular/platform-browser';
import { SortTableParameters } from './models/filter/sort-table-parameters';
import { saveAs } from 'file-saver';
import { Tag } from 'src/app/data/model/tag';
import { TagsService } from 'src/app/data/service/tags.service';
import { TagToInvoices } from './models/tag/tag-to-invoice';
import { ArchiveRequest, InvoiceKey } from './models/invoice/archive-request';
import { DeletedTagFromInvoice } from './models/tag/deleted-tag';
import { TrackingCategory } from './models/tracking-categories.ts/trackingCategory';
import { BankAccountService } from 'src/app/data/service/bank-account.service';
import { PaymentAccount } from './models/account/payment-account';
import { BookkeepingFilterComponent } from './components/bookkeeping-filter/bookkeeping-filter.component';
import { ErrorRecordType } from './models/error/error-record-type';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ScreenBrakepoints } from 'src/app/data/model/screen-brakepoints';
import { SharedService } from 'src/app/core/service/shared.service';
import { ManageIntercom } from '../../core/util/manage-intercom';
import { FilterService } from 'src/app/data/service/filter.service';
import { ReportFilter } from 'src/app/data/model/reportFilter';
import * as $ from "jquery";
import { FilterSidebarComponent } from './filter-sidebar/filter-sidebar-component';
import { ConfirmationDialogService } from 'src/app/shared/confirmation-dialog/confirmation-dialog.service';
import { InboxInvoicesStorageService } from './services/inbox-invoices-storage.service';
import { environment } from 'src/environments/environment';
import { PageSidebarComponent } from '../shared/components/page-sidebar/page-sidebar.component';

@Component({
  selector: 'app-bookkeeping',
  templateUrl: './bookkeeping.component.html',
  styleUrls: ['./bookkeeping.component.scss']
})
export class BookkeepingComponent extends BaseComponent implements OnInit {
  private _searchTerms = new Subject<string>();
  searchText: string = '';
  inProcessingInvoices: InvoiceShort[] = [];
  processedInvoices: InvoiceShort[] = [];
  storedInvoices: InvoiceShort[] = [];
  storedErrorInvoices: ErrorRecord[] = [];
  filteredInProcessingInvoices: InvoiceShort[] = [];
  filteredProcessedInvoices: InvoiceShort[] = [];

  isSidebarVisible: boolean = false;
  isInProcessingTab: boolean = true;
  isProcessedTab: boolean = false;
  isProcessingError: boolean = false;
  isSettingsSidebar: boolean = false;
  isUploadSidebar: boolean = false;
  loading: boolean = false;
  columnMode = ColumnMode;
  statuses = InvoiceStatus;
  types = SidebarType;
  isSuperUser: boolean = false;
  user = {} as User;
  processingErrorRecords = [] as ErrorRecord[];
  isFilterShown: boolean = false;
  showToolTipDelay: number = 400;
  filterParams = new BookkeepingFilter();

  processedPage: number = 1;
  processedPageSize: number = 25;
  processedTotalAmount: number = 0;

  inProcessingPage: number = 1;
  inProcessingPageSize: number = 1000;
  inProcessingTotalAmount: number = 0;

  filterStr: string = '';
  filteredTotalAmount: number = 0;
  sortDirection: string = null;

  matTooltipShowDelay: number = 400;
  tagList = [] as Tag[];
  filteredTagList = [] as Tag[];
  isAddTagMenuShown: boolean = false;
  tagSearchTerm: string = null;
  isAddTagMenuItemShown: boolean = false;
  organizationId: string = null;
  isThreeDotsMenuShown: boolean = false;
  filterDataLoading: boolean = false;
  isExportMenuShown: boolean = false;
  exportOption: number = 1;

  trackingCategories: TrackingCategory[] = [];
  selectedTrackingCategoriesIds: string[] = [];
  selectedTrackingCategories: TrackingCategory[] = [];
  accounts: PaymentAccount[] = [];
  emptyMessage: string = null;
  selectedSavedFilters: ReportFilter[] = [];
  savedFilters = [] as ReportFilter[];
  isShowSavedFilterList: boolean = false;
  showFilterPanel: boolean = false;
  selectedFilterId: string = "";
  isEditMode: boolean = false;
  isSelectedTab: boolean = false;
  searchCriterias: string[] = [];
  defaultFilter: ReportFilter = {
    id: '',
    name: 'All',
    type: 0,
    isPinned: true,
    searchCriteria: '',
    createdBy: '',
    isSelectedTab: true
  };
  screenBrakepoints = ScreenBrakepoints;
  screenBrakepoint: ScreenBrakepoints = ScreenBrakepoints.MinWidth769px;
  showDetailPanel: boolean = false;
  checkedProcessedInvoices: InvoiceShort[] = [];
  checkedInProcessingInvoices: InvoiceShort[] = [];

  @ViewChild(BookkeepingFilterComponent) filterComponent: BookkeepingFilterComponent;
  @ViewChild(FilterSidebarComponent) filterSidebarComponent: FilterSidebarComponent;
  @ViewChild(PageSidebarComponent) pageSidebarComponent: PageSidebarComponent;

  constructor(
    private _invoicesService: InvoiceService,
    private _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
    private _userService: UserService,
    private _errorsService: ErrorsService,
    private _activatedRoute: ActivatedRoute,
    private readonly _location: Location,
    private readonly _searchService: SearchService,
    private readonly _titleService: Title,
    private readonly _invoiceService: InvoiceService,
    private readonly _tagsService: TagsService,
    private readonly _bankAccountService: BankAccountService,
    private readonly _sharedService: SharedService,
    private readonly _filterService: FilterService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _inboxInvoicesStorage: InboxInvoicesStorageService,
    private router: Router,
  ) {
    super();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._titleService.setTitle("Synced | Inbox");
  }

  ngOnInit() {

     
    this.savedFilters.push(this.defaultFilter);
    this.selectedSavedFilters.push(this.defaultFilter);
    this.getTrackingCategories();

    this._sharedService.screenBrakepoint$
      .pipe(this.untilThis)
      .subscribe(
        (breakpoint) => {
          this.screenBrakepoint = breakpoint;
        });

    this.organizationId = this._organizationService.selectedOrganization;
    this.getInProcessingInvoices();
    this.getProcessedInvoices();
    if (this._activatedRoute.snapshot.queryParamMap.keys?.length > 0) {
      setTimeout(() => { this.getSearchState(this.router.url.split('?').length > 0 ? this.router.url.split('?')[1] : '') }, 4000);
    }

    this.getProcessingErrors();

    this.user = JSON.parse(this._userService.upUserDetails) as User;
    this.isSuperUser = this.user.role == 1;

    this.getOrganizationTags();
    this.setSearch();
    this.getAllSavedFilters();
  }

  getInProcessingInvoices() {
    this.loading = true;
    this._invoicesService.getInboxInvoicesUploadedByUser(this._organizationService.selectedOrganization, this.inProcessingPage, this.inProcessingPageSize, 'desc', false, this.searchText)
      .pipe(this.untilThis)
      .subscribe(
        (invoices: InboxInvoices) => {
          //this.storedInvoices = invoices.invoices;
          this.inProcessingInvoices = invoices.invoices;
          this.inProcessingTotalAmount = invoices.totalAmount;
          if (this.inProcessingInvoices && this.inProcessingInvoices.length > 0) {
            this.prepareProcessedInvoices(this.inProcessingInvoices);

            if (this.isFilterShown) {
              this.filteredInProcessingInvoices = this.filterInProcessingInvoices(this.inProcessingInvoices);
            } else {
              this.filteredInProcessingInvoices = this.inProcessingInvoices;
            }
          }
          this.loading = false;
          console.log(this.filteredInProcessingInvoices.length);
          this.setTableEmptyMessage();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  getProcessedInvoices() {
    this.loading = true;
    this._invoicesService.getInboxInvoicesUploadedByUser(
      this._organizationService.selectedOrganization,
      this.processedPage,
      this.processedPageSize,
      this.sortDirection,
      true, this.searchText)
      .pipe(this.untilThis)
      .subscribe(
        (invoices: InboxInvoices) => {
          this.processedInvoices = invoices?.invoices;
          //this.storedInvoices = invoices?.invoices;
          this.processedTotalAmount = invoices?.totalAmount;

          if (this.processedInvoices && this.processedInvoices?.length > 0) {
            this.prepareProcessedInvoices(this.processedInvoices);
            this.filteredProcessedInvoices = [...this.processedInvoices];
          }
          this.loading = false;
        },
        (e: Error) => {
          this.loading = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  createNewTag() {
    this.isAddTagMenuItemShown = false;

    let tag = {
      backColor: null,
      textColor: null,
      tagName: this.tagSearchTerm
    } as Tag;

    tag.organizationId = this.organizationId;

    this._tagsService.createTag(tag)
      .pipe(take(1))
      .subscribe(
        (createdTag: Tag) => {
          tag = { ...createdTag };
          this.tagList = [...this.tagList, tag];
          this.filteredTagList = [...this.tagList];
          this.addTagToInvoices(tag, false);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  addTagToInvoices(tag: Tag, showWarning: boolean = false) {
    let checkedInvoices = [] as InvoiceShort[];

    if (this.isInProcessingTab) {
      checkedInvoices = this.filteredInProcessingInvoices.filter(i => i.checked && !i.tags?.some(t => t.tagId == tag.tagId));
    } else {
      checkedInvoices = this.filteredProcessedInvoices.filter(i => i.checked && !i.tags?.some(t => t.tagId == tag.tagId));
    }

    if (checkedInvoices?.length == 0) {
      if (showWarning) {
        this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Please, select invoices first!');
      }
      return;
    }

    let invoiceIds = [] as string[];
    checkedInvoices.forEach(invoice => {
      if (!invoice.tags?.some(t => t.tagId == tag.tagId)) {
        invoiceIds.push(invoice.id.toString());
      }
    });

    let tagToInvoices = {
      invoiceIds: invoiceIds,
      tagId: tag.tagId
    } as TagToInvoices;

    this._tagsService.addTagToInvoices(tagToInvoices)
      .pipe(take(1))
      .subscribe(
        () => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Tag added to selected invoices!');
          checkedInvoices.forEach(invoice => {
            if (invoice.tags) {
              invoice.tags = [...invoice.tags, tag];
            } else {
              invoice.tags = [tag];
            }
          });

          if (this.isInProcessingTab) {
            this.filteredInProcessingInvoices = [...this.filteredInProcessingInvoices];
          } else {
            this.filteredProcessedInvoices = [...this.filteredProcessedInvoices];
            this.loading = false;
          }

        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  deleteTag(deletedTag: DeletedTagFromInvoice) {
    this._tagsService.deleteTagFromInvoices(deletedTag.tagId, deletedTag.invoiceId)
      .pipe(this.untilThis)
      .subscribe(
        () => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', `Tag '${deletedTag.tagName}' removed from the invoice!`);

          if (deletedTag.invoiceProcessedStatus) {
            let updatedInvoice = this.filteredProcessedInvoices.find(i => i.id.toString() == deletedTag.invoiceId);
            updatedInvoice.tags = updatedInvoice?.tags.filter(t => t.tagId != deletedTag.tagId);
            this.filteredProcessedInvoices = [...this.filteredProcessedInvoices];
          } else {
            let updatedInvoice = this.filteredInProcessingInvoices.find(i => i.id.toString() == deletedTag.invoiceId);
            updatedInvoice.tags = updatedInvoice?.tags.filter(t => t.tagId != deletedTag.tagId);
            this.filteredInProcessingInvoices = [...this.filteredInProcessingInvoices];
          }
          this.loading = false;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  filterTags(event) {
    this.tagSearchTerm = event;
    this.filteredTagList = this.tagList.filter(t => t.tagName.toLowerCase().includes(this.tagSearchTerm?.toLowerCase()));
    let exactlyMatchTags = this.filteredTagList.filter(t => t.tagName.toLowerCase() == this.tagSearchTerm?.toLowerCase());

    if (exactlyMatchTags?.length == 0) {
      this.isAddTagMenuItemShown = true;
    } else {
      this.isAddTagMenuItemShown = false;
    }
  }

  clearTagSearchValue() {
    setTimeout(() => {
      this.tagSearchTerm = null;
      this.filteredTagList = this.tagList;
    }, 250);
  }

  showAddTagMenu() {
    this.isAddTagMenuShown = true;
  }

  menuClosed() {
    setTimeout(() => {
      this.isAddTagMenuShown = false;
      this.isExportMenuShown = false;
    }, 100);
  }

  rowCheckboxClickEvent() {
    this.checkedInProcessingInvoices = this.filteredInProcessingInvoices.filter(i => i.checked == true);
    this.checkedProcessedInvoices = this.filteredProcessedInvoices.filter(i => i.checked == true);

    if (this.checkedInProcessingInvoices?.length > 0 || this.checkedProcessedInvoices?.length > 0) {
      this.isThreeDotsMenuShown = true;
    } else {
      this.isThreeDotsMenuShown = false;
    }
  }

  toggleSidebar(sidebar: SidebarType) {
    if (sidebar === SidebarType.settings)
      this.isSettingsSidebar = !this.isSettingsSidebar;
    else if (sidebar === SidebarType.upload)
      this.isUploadSidebar = !this.isUploadSidebar;

    this.isSidebarVisible = !this.isSidebarVisible;
  }

  downloadCheckedInvoicesAsZip(checkedInvoices: InvoiceShort[]) {
    let invoiceKeys = [] as InvoiceKey[];

    checkedInvoices.forEach(element => {
      if (element.invoicePdfUrl) {
        invoiceKeys.push(
          {
            invoicePdfUrlId: element.invoicePdfUrl,
            invoiceName: `${element.supplierName} ${element.invoiceNumber.replace('/', '')} ${element.accountName ?? ''}`
          } as InvoiceKey
        );
      }
    });

    if (invoiceKeys?.length == 0)
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Selected invoices have no attachments');

    let request = {
      organizationId: this.organizationId,
      invoices: invoiceKeys
    } as ArchiveRequest;

    this._toasterMessageService.displayToasterState(ToasterTypes.Info, 'Info', 'Archive is being prepared for download. Please, wait a final message');

    this._invoiceService.downloadZipArchive(request)
      .pipe(this.untilThis)
      .subscribe(
        (archive: Blob) => {
          let date = new Date();
          const blob = new Blob([archive], { type: 'application/zip' });
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Archive ready for download!')
          saveAs(blob, `invoices ${date.toLocaleDateString()}.zip`);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
        }
      );
  }

  downloadCheckedInvoices() {
    let checkedInvoices = [] as InvoiceShort[];

    if (this.isInProcessingTab) {
      checkedInvoices = this.filteredInProcessingInvoices.filter(inv => inv.checked);
    } else {
      checkedInvoices = this.filteredProcessedInvoices.filter(inv => inv.checked);
    }

    if (checkedInvoices?.length > 1) {
      this.downloadCheckedInvoicesAsZip(checkedInvoices);
      return;
    } else {
      checkedInvoices.forEach(invoice => {
        if (invoice.invoicePdfUrl) {
          this._invoiceService.downloadFile(invoice.invoicePdfUrl, '')
            .pipe(take(1))
            .subscribe(
              (file: Blob) => {
                const blob = new Blob([file], { type: 'application/pdf' });
                saveAs(blob, `${invoice.supplierName} ${invoice.invoiceNumber}.pdf`);
              },
              (e: Error) => {
                this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
              }
            );
        }
      }
      );
    }
  }

  changeTab(tab: string) {
    // this.toggleFilterSearch(false);
    this.resetSearch();
    this.storedInvoices = [];
    if (tab === 'InProcessing') {

      this.isInProcessingTab = true
      this.isProcessedTab = false;
      this.isProcessingError = false;
      this.filteredInProcessingInvoices = [...this.filterInProcessingInvoices(this.inProcessingInvoices)];
    }
    else if (tab === 'Processed') {
      this.isInProcessingTab = false;
      this.isProcessedTab = true;
      this.isProcessingError = false;
      this.filteredProcessedInvoices = [...this.filterInProcessingInvoices(this.processedInvoices)];
    }
    else if (tab === 'ProcessingError') {
      this.isInProcessingTab = false;
      this.isProcessedTab = false;
      this.isProcessingError = true;
    }
  }

  toggleFilterSearch(event: ReportFilter) {
    this.loading = true;
    this.getSearchState(event.searchCriteria);
    this.savedFilters.filter(x => x.id == event.id);
    let indexToUpdate = this.savedFilters.findIndex(item => item.id === event.id);
    if(indexToUpdate > -1) {
      this.savedFilters[indexToUpdate] = event;
    }
    else {
      this.savedFilters.push(event);
    }
    this.selectedSavedFilters.forEach(x => x.isSelectedTab = false);
    if (!this.isEditMode) {
      event.isSelectedTab = true;
      this.selectedSavedFilters.push(event);
    }
    else {
      indexToUpdate = this.selectedSavedFilters.findIndex(x => x.id == event.id);
      event.isSelectedTab = true;
      this.selectedSavedFilters[indexToUpdate] = event;
    }
    this.isEditMode = false;
    this.toggleFilterSidebar();
    this.filterInvoices(event.searchCriteria);
  }

  sortTable(sortData: SortTableParameters) {
    if (sortData.columnName != 'Supplier') {
      return;
    }

    this.filteredProcessedInvoices = null;
    this.loading = true;
    this.processedPage = 1;

    if (this.sortDirection == null || this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }

    if (this.isFilterShown) {
      let filterStr = `${this.filterStr}&page=${1}&pageSize=${this.processedPageSize}&sort=${this.sortDirection}`;
      this._location.replaceState(`/inbox${filterStr}&filterId=${this.selectedFilterId}`);
      this._searchTerms.next(`${filterStr}&organizationId=${this._organizationService.selectedOrganization}`);
    } else {
      this.getProcessedInvoices();
    }
  }

  filterInvoices(filterStr: string) {
    this.processedPage = 1;
    this.isFilterShown = true;
    if (this.sortDirection == null)
      this.sortDirection = "asc";
    if (this.isInProcessingTab) {
      this.filteredInProcessingInvoices = [...this.filterInProcessingInvoices(this.inProcessingInvoices)];
      this.setTableEmptyMessage();
      this.loading = false;
    }

    if (this.isProcessedTab) {
      this.filterStr = filterStr;
      if (filterStr != "") {
        filterStr = `${filterStr}&page=${1}&pageSize=${this.processedPageSize}&sort=${this.sortDirection}`;
        filterStr = filterStr.replace("status=false", "status=true");
      }
      else {
        filterStr = `?page=${1}&pageSize=${this.processedPageSize}&sort=${this.sortDirection}`;
      }
      this._location.replaceState(`/inbox${filterStr}&filterId=${this.selectedFilterId}`);
      this._searchTerms.next(`${filterStr}&organizationId=${this._organizationService.selectedOrganization}`);
      // this.loading = false;
    }
  }

  pageSelect(page: number) {
    this.processedPage = page;
    if (this.isFilterShown) {
      let queryParamsStr = `${this.filterStr}&page=${this.processedPage}&pageSize=${this.processedPageSize}&sort=${this.sortDirection}`;
      this._location.replaceState(`/inbox${queryParamsStr}&filterId=${this.selectedFilterId}`);
      this._searchTerms.next(`${queryParamsStr}&organizationId=${this._organizationService.selectedOrganization}`);
    } else {
      this.getProcessedInvoices();
    }
  }

  private setTableEmptyMessage() {
    this.emptyMessage = this.isFilterShown ? 'The filter values selected show no records' : 'You\'re all up to date. No documents to process 👍';
  }

  private getTrackingCategories() {
    const trackingCategories$ = this._organizationService.getOrganizationTrackingCategoriesWithOptions(this._organizationService.selectedOrganization);
    const selectedTrackingCategoriesIds$ = this._organizationService.getOrganizationTrackingCategories(this._organizationService.selectedOrganization);
    const accounts$ = this._bankAccountService.getPaymentAccounts(this._organizationService.selectedOrganization);

    const forkJoin$ = forkJoin({
      trackingCategories: trackingCategories$,
      selectedTrackingCategoriesIds: selectedTrackingCategoriesIds$,
      accounts: accounts$,
    });

    forkJoin$.subscribe(
      (data) => {
        this.trackingCategories = data.trackingCategories;
        this.selectedTrackingCategoriesIds = data.selectedTrackingCategoriesIds;
        this.selectedTrackingCategories = this.trackingCategories.filter(category => this.selectedTrackingCategoriesIds.some(id => category.id == id));
        this.accounts = data.accounts.sort((a, b) => a.name.localeCompare(b.name)) || [];
      }
    );
  }

  private setSearch() {
    this._searchTerms.pipe(
      this.untilThis,
      debounceTime(500),
      switchMap((filterStr: string) => {
        this.filterDataLoading = true;
        return this._searchService.searchInvoices(filterStr);
      })
    ).subscribe({
      next: (data: InboxInvoices) => {
        let list = data.invoices;
        if (this.filterParams.tags && this.filterParams.tags?.length > 0 && this.filterParams.tags[0] != '') {
          if (this.filterParams.tags.includes('Unassigned tags')) {
            list = this.filteredProcessedInvoices.filter(i => !i.tags || i.tags?.length == 0);
          } else {
            list = this.filteredProcessedInvoices.filter(i => this.filterParams.tags.some(t => i.tags && i.tags.length > 0 ? i.tags.map(p => p.tagName).includes(t) : null));
          }
        }

        if (this.filterParams.trackingOptions1 && this.filterParams.trackingOptions1.length > 0 && this.filterParams.trackingOptions1[0] != '') {
          if (this.filterParams.trackingOptions1.includes('Unassigned tracking')) {
            list = list.filter(i => i.invoiceLines.length > 0 ? i.invoiceLines.some(line => line.trackingOptions?.length == 0) : true);
          } else {
            list = list.filter(i => i.invoiceLines.some(line => line.trackingOptions?.length > 0 ? line.trackingOptions.some(option => this.filterParams.trackingOptions1.includes(option)) : false));
          }
        }
        this.filteredProcessedInvoices = list;
        this.prepareProcessedInvoices(this.filteredProcessedInvoices);

        if (this.filterParams.categories?.length > 0 && this.filterParams.categories[0] != '') {
          this.filteredProcessedInvoices.forEach(item => {
            if (item.invoiceLines?.length > 0) {
              item.invoiceLines = item.invoiceLines?.filter(line => this.filterParams.categories.includes(line.accountName.replace('&', '%26')));
            }
          });
        }

        this.filteredTotalAmount = data.totalAmount;
        this.isFilterShown = true;
        this.loading = false;
        this.filterDataLoading = false;
        this.setTableEmptyMessage();
      },
      error: (e: Error) => {
        this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        this.loading = false;
        this.filterDataLoading = false;
      }
    });
  }

  private prepareProcessedInvoices(array: InvoiceShort[]) {
    let count = 1;
    array.forEach(element => {
      let stringToSplit = element.supplierName;

      if (stringToSplit) {
        let x = stringToSplit.split(" ");
        if (x.length == 1) {
          element.shortCode = x[0].charAt(0);
        }
        if (x.length == 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0);
        }
        if (x.length > 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0) + x[2].charAt(0);
        } if (x.length == 0) {
          element.shortCode = "NA";
        }
      }

      element.rowCount = count;
      count = count + 1;
      if (count == 7) {
        count = 1;
      }

      element.invoiceLines?.forEach(line => {
        line.accountName = this.accounts.find(a => a.id == line.accountId)?.name;

        if (line.trackingOptions && line.trackingOptions.length == 1) {
          line.trackingOptions = this.selectedTrackingCategories.length > 0 ? [this.selectedTrackingCategories[0].options.find(c => c.id == line.trackingOptions[0])?.name] : [];
        } else if (line.trackingOptions && line.trackingOptions.length == 2) {
          const category = this.selectedTrackingCategories.find(c => c.options.some(o => o.id === line.trackingOptions[0]));
          const index = this.selectedTrackingCategories.indexOf(category);

          line.trackingOptionNames = ['', ''];

          if (index == 0) {
            line.trackingOptionNames[0] = this.selectedTrackingCategories[0]?.options?.find(o => o.id == line.trackingOptions[0])?.name;
            line.trackingOptionNames[1] = this.selectedTrackingCategories[1]?.options?.find(o => o.id == line.trackingOptions[1])?.name;
          } else {
            const options = [...line.trackingOptions];
            line.trackingOptionNames[0] = this.selectedTrackingCategories[0]?.options?.find(o => o.id == line.trackingOptions[1])?.name;
            line.trackingOptionNames[1] = this.selectedTrackingCategories[1]?.options?.find(o => o.id == line.trackingOptions[0])?.name;

            line.trackingOptions[0] = options[1];
            line.trackingOptions[1] = options[0];
          }
        }
      });
    });
  }

  private filterInProcessingInvoices(invoices: InvoiceShort[]) {
    let list = [] as InvoiceShort[];
    invoices = JSON.parse(JSON.stringify(invoices));

    if (this.filterParams.suppliers && this.filterParams.suppliers?.length > 0) {
      list = invoices.filter(i => this.filterParams.suppliers?.some(s => i?.supplierName?.includes(s)));
    } else {
      list = invoices;
    }

    list = list.filter(i =>
      new Date(i?.date) >= (!!this.filterParams.invoiceDateRange?.slice(0, 1)[0] ? this.filterParams.invoiceDateRange[0].setHours(0, 0, 0) : new Date(i?.date))
      && new Date(i?.date) <= (!!this.filterParams.invoiceDateRange?.slice(1, 2)[0] ? this.filterParams.invoiceDateRange[1].setHours(0, 0, 0) : new Date(i?.date)));

    if (this.filterParams.categories && this.filterParams.categories?.length > 0 && this.filterParams.categories[0] != '') {
      list = list.filter(i => this.filterParams.categories.some(c => i.invoiceLines?.length > 0 ? i.invoiceLines?.some(line => this.filterParams.categories.map(x => x.replace(/%20/g, ' ').replace(/%26/g, '&')).includes(line.accountName)) : false));
    }

    if (this.filterParams.tags && this.filterParams.tags?.length > 0 && this.filterParams.tags[0] != '') {
      if (this.filterParams.tags.includes('Unassigned tags')) {
        list = list.filter(i => !i.tags || i.tags?.length == 0);
      } else {
        list = list.filter(i => this.filterParams.tags.some(t => i.tags && i.tags.length > 0 ? i.tags.map(p => p.tagName.replace(/%20/g, ' ').replace(/%26/g, '&')).includes(t) : null));
      }
    }

    if (this.filterParams.trackingOptions1 && this.filterParams.trackingOptions1.length > 0 && this.filterParams.trackingOptions1[0] != '') {
      if (this.filterParams.trackingOptions1.includes('Unassigned tracking')) {
        list = list.filter(i => i.invoiceLines.length > 0 ? i.invoiceLines.some(line => line.trackingOptions?.length == 0) : true);
      } else {
        list = list.filter(i => i.invoiceLines.some(line => line.trackingOptions?.length > 0 ? line.trackingOptions.some(option => this.filterParams.trackingOptions1.map(x => x.replace(/%20/g, ' ').replace(/%26/g, '&')).includes(option)) : false));
      }
    }

    if (this.filterParams.trackingOptions2 && this.filterParams.trackingOptions2.length > 0 && this.filterParams.trackingOptions2[0] != '') {
      list = list.filter(i => i.invoiceLines.some(line => line.trackingOptions?.length > 0 ? line.trackingOptions.some(option => this.filterParams.trackingOptions2.map(x => x.replace(/%20/g, ' ').replace(/%26/g, '&')).includes(option)) : false));
    }
    // if (this.filterParams.categories?.length > 0 && this.filterParams.categories[0] != '') {
    //   list.forEach(item => {
    //     if (item.invoiceLines?.length > 0) {
    //       item.invoiceLines = item.invoiceLines?.filter(line => this.filterParams.categories.includes(line.accountName));
    //     }
    //   });
    // } else if (this.filterParams.trackingOptions1?.length > 0 && this.filterParams.trackingOptions1[0] != '') {
    //   list.forEach(item => {
    //     if (item.invoiceLines?.length > 0) {
    //       item.invoiceLines = item.invoiceLines?.filter(line => line.trackingOptions?.some(name => this.filterParams.trackingOptions1.includes(name)));
    //     }
    //   });
    // } else if (this.filterParams.trackingOptions2?.length > 0 && this.filterParams.trackingOptions2[0] != '') {
    //   list.forEach(item => {
    //     if (item.invoiceLines?.length > 0) {
    //       item.invoiceLines = item.invoiceLines?.filter(line => line.trackingOptions?.some(name => this.filterParams.trackingOptions2.includes(name)));
    //     }
    //   });
    // }

    return list;
  }

  private getProcessingErrors() {
    this._errorsService.getErrorRecordsByOrganizationId(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (recordsData: ErrorRecord[]) => {
          this.processingErrorRecords = recordsData.filter(rd => rd.errorRecordType == ErrorRecordType.Email);
        },
        (e: Error) => {
          this.loading = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
        }
      )
  }

  private getSearchState(filterCriteria: string) {
    this.isFilterShown = true;

    let dateStart: Date;
    let dateEnd: Date;
    let dateOption: string = "";
    if (filterCriteria != undefined && filterCriteria != '') {
      this.searchCriterias = filterCriteria.replace("?", "").split("&");

      dateOption = this.searchCriterias.filter(x => x.includes('dateOption'))[0]?.split('=')[1];
      let dateStartStr = this.searchCriterias.filter(x => x.includes('dateStart'))[0]?.split('=')[1];
      let dateEndStr = this.searchCriterias.filter(x => x.includes('dateEnd'))[0]?.split('=')[1];

      if (dateOption == "0") {
        dateStartStr = dateToString(new Date());
        dateEndStr = dateToString(new Date());
      }
      else if (dateOption == "1") {
        var curr = new Date;
        var first = curr.getDate() - curr.getDay();
        var last = first + 6;

        dateStartStr = dateToString(new Date(first));
        dateEndStr = dateToString(new Date(last));
      }
      else if (dateOption == "2") {
        var date = new Date();
        dateStartStr = dateToString(new Date(date.getFullYear(), date.getMonth(), 1));
        dateEndStr = dateToString(new Date(date.getFullYear(), date.getMonth() + 1, 0));
      }
      else if (dateOption == "3") {
        var date = new Date();
        dateStartStr = null
        dateEndStr = dateToString(new Date());
      }
      const supplierNameStr = this.searchCriterias.filter(x => x.includes('suppliers')).length > 0 ? this.searchCriterias.filter(x => x.includes('suppliers')).map(x => x.replace(/suppliers=/g, "")) : [];
      const categoryStr = this.searchCriterias.filter(x => x.includes('categories')).length > 0 ? this.searchCriterias.filter(x => x.includes('categories')).map(x => x.replace(/categories=/g, "")) : [];
      const track1Str = this.searchCriterias.filter(x => x.includes('track1')).length > 0 ? this.searchCriterias.filter(x => x.includes('track1')).map(x => x.replace(/track1=/g, "").replace(/%20/g, " ")) : [];
      const track2Str = this.searchCriterias.filter(x => x.includes('track2')).length > 0 ? this.searchCriterias.filter(x => x.includes('track2')).map(x => x.replace(/track2=/g, "")) : [];
      const tagsStr = this.searchCriterias.filter(x => x.includes('tags')).length > 0 ? this.searchCriterias.filter(x => x.includes('tags')).map(x => x.replace(/tags=/g, "").replace(/%20/g, " ")) : [];
      const statusStr = this.searchCriterias.filter(x => x.includes('status')).length > 0 ? this.searchCriterias.filter(x => x.includes('status'))[0].split('=')[1] : false;
      const pageStr = this.searchCriterias.filter(x => x.includes('page')).length > 0 ? this.searchCriterias.filter(x => x.includes('page'))[0]?.split('=')[1] : 0;
      const filterId = this.searchCriterias.filter(x => x.includes('filterId'))[0]?.split('=')[1];
      this.processedPage = +pageStr;
      this.processedPage = this.processedPage == 0 || isNaN(this.processedPage) ? 1 : this.processedPage;

      if (dateStartStr) {
        dateStart = new Date(dateStartStr);
      } else {
        dateStart = null;
      }

      if (dateEndStr && dateEndStr != 'null' && dateEndStr != 'undefined') {
        dateEnd = new Date(dateEndStr);
      } else {
        dateEnd = null;
      }


      this.filterParams.suppliers = supplierNameStr && supplierNameStr.length > 0 && supplierNameStr[0] != '' ? supplierNameStr : null;
      this.filterParams.tags = tagsStr && tagsStr.length > 0 && tagsStr[0] != '' ? tagsStr : null;
      this.filterParams.invoiceDateRange = dateStart && dateStart ? [dateStart, dateEnd] : null;
      this.filterParams.categories = categoryStr && categoryStr.length > 0 && categoryStr[0] != '' ? categoryStr : null;
      this.filterParams.trackingOptions1 = track1Str && track1Str.length > 0 && track1Str[0] != '' ? track1Str : null;
      this.filterParams.trackingOptions2 = track2Str && track2Str.length > 0 && track2Str[0] != '' ? track2Str : null;
      this.filterParams.status = !!statusStr && statusStr == 'true' ? true : false;
      this.selectedFilterId = filterId;
      this.setFilterOnSearchState();
      // this.isProcessedTab = this.filterParams.status;
      // this.isInProcessingTab = !this.filterParams.status;
    }
    else {
      this.filterParams.suppliers = null;
      this.filterParams.tags = null;
      this.filterParams.invoiceDateRange = null;
      this.filterParams.categories = null;
      this.filterParams.trackingOptions1 = null;
      this.filterParams.trackingOptions2 = null;
      this.selectedFilterId = null;
    }

    let supplierParam = this.filterParams.suppliers?.length > 0 ? this.filterParams.suppliers?.map(s => `suppliers=${s.replace('&', '%26')}&`).join('').slice(0, -1) : 'suppliers=';
    let track1Param = this.filterParams.trackingOptions1?.length > 0 && this.filterParams.trackingOptions1[0] != "Unassigned tracking" ? this.filterParams.trackingOptions1?.map(s => `track1=${s}&`).join('').slice(0, -1) : 'track1=';
    let track2Param = this.filterParams.trackingOptions2?.length > 0 ? this.filterParams.trackingOptions2?.map(s => `track2=${s}&`).join('').slice(0, -1) : 'track2=';
    let categoryParam = this.filterParams.categories?.length > 0 ? this.filterParams.categories?.map(c => `categories=${c.replace('&', '%26')}&`).join('').slice(0, -1) : 'categories=';
    let tagPart = this.filterParams.tags?.length > 0 ? this.filterParams.tags?.map(t => `tags=${t}&`).join('').slice(0, -1) : 'tags=';
    let dateStartParam = this.filterParams.invoiceDateRange ? `dateStart=${dateToString(this.filterParams.invoiceDateRange[0]) ?? ''}` : 'dateStart=';
    let dateEndParam = this.filterParams.invoiceDateRange ? `dateEnd=${dateToString(this.filterParams.invoiceDateRange[1]) ?? ''}` : 'dateEnd=';
    let statusParam = `status=${this.isProcessedTab ? 'true' : 'false'}`;

    this._location.replaceState(`/inbox?${supplierParam}&dateOption=${dateOption}&${dateStartParam}&${dateEndParam}&${categoryParam}&${tagPart}&${track1Param}&${track2Param}&${statusParam}`);

    if (this.isProcessedTab) {
      let filterStr = `?${supplierParam}&${dateStartParam}&${dateEndParam}&${categoryParam}&${tagPart}&${track1Param}&${track2Param}&${statusParam}`;
      this.filterStr = filterStr;
      let queryParamsStr = `${filterStr}&page=${this.processedPage}&pageSize=${this.processedPageSize}&organizationId=${this._organizationService.selectedOrganization}`;
      this._searchService.searchInvoices(queryParamsStr)
        .pipe(this.untilThis)
        .subscribe(
          (data: InboxInvoices) => {
            let list = data.invoices;

            if (this.filterParams.tags && this.filterParams.tags?.length > 0 && this.filterParams.tags[0] != '') {
              if (this.filterParams.tags.includes('Unassigned tags')) {
                list = list.filter(i => !i.tags || i.tags?.length == 0);
              }
            }

            if (this.filterParams.trackingOptions1 && this.filterParams.trackingOptions1.length > 0 && this.filterParams.trackingOptions1[0] != '') {
              if (this.filterParams.trackingOptions1.includes('Unassigned tracking')) {
                list = list.filter(i => i.invoiceLines.length > 0 ? i.invoiceLines.some(line => line.trackingOptions?.length == 0) : true);
              }
            }

            this.filteredProcessedInvoices = list;
            this.prepareProcessedInvoices(this.filteredProcessedInvoices);

            if (this.filterParams.categories?.length > 0 && this.filterParams.categories[0] != '') {
              this.filteredProcessedInvoices.forEach(item => {
                if (item.invoiceLines?.length > 0) {
                  item.invoiceLines = item.invoiceLines?.filter(line => this.filterParams.categories.includes(line.accountName));
                }
              });
            }

            this.filteredTotalAmount = data.totalAmount;
            this.setTableEmptyMessage();
            this.loading = false;
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
          });
    }
    else {
      this.filterInvoices(filterCriteria);
    }
  }

  setFilterOnSearchState() {
    if (this.selectedFilterId) {
      this.savedFilters.forEach(x => x.isSelectedTab = false);
      var selectedFilter = this.savedFilters.find(x => x.id == this.selectedFilterId);
      if (selectedFilter) {
        selectedFilter.isSelectedTab = true;
        var isFilterAdded = this.selectedSavedFilters.find(x => x.id == selectedFilter.id);
        if (!isFilterAdded) {
          this.selectedSavedFilters.push(selectedFilter);
        }
      }
      else {
        this.selectedSavedFilters = [];
        this.selectedSavedFilters.push(this.defaultFilter);
      }
    }
  }

  private getOrganizationTags() {
    this._tagsService.getTagsByOrganizationId(this.organizationId)
      .pipe(take(1))
      .subscribe(
        (tags: Tag[]) => {
          tags.forEach(x => x.group = 1);
          this.tagList = tags;
          this.filteredTagList = this.tagList;
          this.tagList.splice(0, 0, { tagId: '0', tagName: 'Unassigned', backColor: '', textColor: '', organizationId: this._organizationService.selectedOrganization, group: 0 });
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  toggleSavedFilters() {
    this.isShowSavedFilterList = !this.isShowSavedFilterList;
  }

  appendSearchCriteria(filter) {
    var query = "";
    if (filter.searchCriteria) {
      query = `${filter.searchCriteria}&filterId=${this.selectedFilterId}`;
    }
    else {
      query = `?filterId=${this.selectedFilterId}`
    }
    return query;
  }
  addSelectedFilter(filter) {
    this.resetSearch();
    this.loading = true;
    setTimeout(() => {
      this.selectedFilterId = filter.id;
      var params = this.appendSearchCriteria(filter);
      this._location.replaceState(`/inbox${params}`);
      this.getSearchState(filter.searchCriteria);
      this.selectedSavedFilters.forEach(x => x.isSelectedTab = false);
      filter.isSelectedTab = true;
      this.isFilterShown = true;
      if (this.selectedSavedFilters.indexOf(filter) == -1) {
        this.selectedSavedFilters.push(filter);
      }
      this.toggleSavedFilters();
      this.loading = false;
    }, 1000);
  }


  addFilter() {
    this.showFilterPanel = true;
    this.toggleSavedFilters();
  }

  toggleFilterSidebar() {
    this.showFilterPanel = !this.showFilterPanel;
    this.isEditMode = false;
  }

  getAllSavedFilters() {
    this._filterService.getAllSavedFilters("Filter/GetAllSavedFilters", 1, this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (filters) => {
          this.savedFilters = filters;
          filters.filter(x => x.isPinned == true).forEach(x => this.selectedSavedFilters.push(x));
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  onFilterTabSelect(filter) {
    this.resetSearch();
    this.loading = true;
    var params = this.appendSearchCriteria(filter);
    this._location.replaceState(`/inbox${params}`);
    this.getSearchState(filter.searchCriteria);
    this.selectedSavedFilters.forEach(x => x.isSelectedTab = false);
    filter.isSelectedTab = true;
    if (filter.name == 'All') {
      this.isFilterShown = false;
      if (this.isInProcessingTab == true) {
        this.getInProcessingInvoices();
      }
      else if (this.isProcessedTab == true) {
       this.getProcessedInvoices();
      }
    }
    else {
      this.isFilterShown = true;
    }
    // setTimeout(() => {
    //   this.loading = false;
    // }, 400);
  }
  editFilter(filter) {
    this.selectedFilterId = filter.id;
    this.showFilterPanel = true;
    $("#hdFilterId").val(filter.id);
    this.filterSidebarComponent.GetFilterData();
    this.isEditMode = true;
  }

  closeFilter(filter) {
    this.loading = true;
    this.isFilterShown = false;
    this.selectedSavedFilters = this.selectedSavedFilters.filter(item => item !== filter);
    this.onFilterTabSelect(this.selectedSavedFilters[0]);
  }

  deletedFilterRemove() {
    this.selectedSavedFilters = Object.values(this.selectedSavedFilters).filter(g => g.id != $("#hdFilterId").val());
    this.savedFilters = Object.values(this.savedFilters).filter(g => g.id != $("#hdFilterId").val());
    this.onFilterTabSelect(this.selectedSavedFilters[0]);
  }

  showExportMenu() {
    this.isExportMenuShown = true;
  }

  exportRecords() {
    let checkedInvoices = [] as InvoiceShort[];


    if (this.isInProcessingTab) {
      checkedInvoices = this.filteredInProcessingInvoices;
    }
    else {
      checkedInvoices = this.filteredProcessedInvoices;
    }

    if (this.exportOption == 1) {
      checkedInvoices = Object.values(checkedInvoices.filter(inv => inv.checked));
      const blob = new Blob([this.saveDataInCSV(checkedInvoices)], { type: "csv" })
      saveAs(blob, "Invoices.csv");
    }
    else {
      this._invoicesService.getInboxInvoicesUploadedByUser(this._organizationService.selectedOrganization, 1, 10000, 'asc', null, null)
        .pipe(this.untilThis)
        .subscribe(
          (invoices: InboxInvoices) => {
            checkedInvoices = invoices.invoices;
            const blob = new Blob([this.saveDataInCSV(checkedInvoices)], { type: "csv" })
            saveAs(blob, "Invoices.csv");
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          }
        )
    }


  }

  saveDataInCSV(data: InvoiceShort[]) {
    if (data.length == 0) {
      return '';
    }

    let propertyNames = ['Supplier', 'Date', 'Category', 'Date Submitted', 'Type', 'Due Date', 'Status'];
    if (this.selectedTrackingCategories.length > 0) {
      this.selectedTrackingCategories.forEach((item) => {
        propertyNames.push(item.name);
      })
    }
    propertyNames.push('Subtotal');
    propertyNames.push('Tax');
    propertyNames.push('Total');
    propertyNames.push('Tags');
    propertyNames.push('Invoice Link');
    let rowWithPropertyNames = propertyNames.join(',') + '\n';
    let csvContent = rowWithPropertyNames;

    let rows: string[] = [];

    data.forEach((item) => {
      let values: string[] = [];

      values.push(item.supplierName != undefined && item.supplierName != null ? item.supplierName.replace('\n', ' ').replace('\n\r', ' ').replace(',', ' ') : '');
      values.push(item.date != undefined && item.date != null ? item.date.toString() : '');
      values.push(item.accountName);
      values.push(item.createdAt != undefined && item.createdAt != null ? item.createdAt.toString() : '');
      values.push(item.type != undefined && item.type != null ? item.type.toString() : '');
      values.push(item.dueDate != undefined && item.dueDate != null ? item.dueDate.toString() : '');
      values.push(!item.isProcessed ? 'Review' : 'Processed');
      if (this.selectedTrackingCategories.length == 1) {
        if (item.invoiceLines.length > 0 && item.invoiceLines[0].trackingOptions.length > 0) {
          values.push(item.invoiceLines[0].trackingOptions.join(',').toString());
        }
        else {
          values.push('');
        }
      }
      values.push(item.subtotal != undefined && item.subtotal != null ? item.subtotal.toFixed(2).toString() : '');
      values.push(item.totalTax != undefined && item.totalTax != null ? item.totalTax.toFixed(2).toString() : '');
      values.push(item.amountDue != undefined && item.amountDue != null ? item.amountDue.toFixed(2).toString() : '');
      values.push(item.tags != undefined && item.tags != null ? item.tags.map(function (d) { return d.tagName }).join(';') : '');
      values.push(environment.apiURL + "Invoices/downloadInvoice?id=" + item.invoicePdfUrl + "&organisationId=" + this._organizationService.selectedOrganization);

      rows.push(values.join(','));
    });
    csvContent += rows.join('\n');

    return csvContent;
  }

  showDeleteConfirm() {
    this._confirmationDialogService.confirm('Confirm', 'Do you want to delete the invoice?', 'Yes', 'No', '')
      .then((confirm) => {
        if (confirm) {
          this.deleteInvoice();
        }
      });
  }

  deleteInvoice() {
    let checkedInvoices = [] as InvoiceShort[];

    if (this.isInProcessingTab) {
      checkedInvoices = this.filteredInProcessingInvoices.filter(inv => inv.checked);
    }
    let flg: boolean = false;

    checkedInvoices.forEach(invoice => {
      this._invoiceService.deleteInvoice(invoice.id.toString())
        .pipe(take(1))
        .subscribe(
          () => {
            this.filteredInProcessingInvoices = Object.values(this.filteredInProcessingInvoices).filter(g => g.id != invoice.id);
            this.checkedInProcessingInvoices = [];
            this.isThreeDotsMenuShown = false;
            this._inboxInvoicesStorage.deleteInvoiceIdFromStorage(invoice.id?.toString());
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', "Some Error Occurred");
          }
        );
    }
    );
  }

  onSearchBox(event) {
    if (this.storedInvoices.length == 0) {
      if (this.isInProcessingTab == true) {
        this.storedInvoices = this.filteredInProcessingInvoices;
      }
      else if (this.isProcessedTab == true) {
        this.storedInvoices = this.filteredProcessedInvoices;
      }
    }
    if (event.key != null && event.key != '' && !this.isFilterShown) {
      this.searchText = event.target.value.toLowerCase();
      if (this.searchText != null && (this.searchText == '' || (this.searchText != '' && this.searchText.length > 2))) {
        if (this.isInProcessingTab == true) {
          this.getInProcessingInvoices();
        }
        else if (this.isProcessedTab == true) {
          this.getProcessedInvoices();
        }
      }
    }
    else if (event.key != null && event.key != '' && this.isFilterShown) {
      this.searchText = event.target.value.toLowerCase();
      if (this.searchText != null && (this.searchText == '' || (this.searchText != '' && this.searchText.length > 2))) {
        if (this.storedInvoices.length == 0) {
          if (this.isInProcessingTab == true) {
            this.storedInvoices = this.filteredInProcessingInvoices;
          }
          else if (this.isProcessedTab == true) {
            this.storedInvoices = this.filteredProcessedInvoices;
          }
        }
        var result = this.storedInvoices.filter(x =>
          x.invoiceNumber?.toLowerCase().includes(event.target.value.toLowerCase()) || x.supplierName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
          x.accountName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
          x.categories?.filter(c => c?.toLowerCase().includes(event.target.value.toLowerCase()))
          || (x.tags.length > 0 && x.tags?.filter(t => t?.tagName?.toLowerCase().includes(event.target.value.toLowerCase())).length > 0 &&
            x.tags?.filter(t => t?.tagName?.toLowerCase().includes(event.target.value.toLowerCase())))
        );
        this.filteredInProcessingInvoices = result;
        this.filteredProcessedInvoices = result;
      }
      //var result = this.storedInvoices.filter(x =>
      //  x.invoiceNumber?.toLowerCase().includes(event.target.value.toLowerCase()) || x.supplierName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      //  x.accountName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      //  x.categories?.filter(c => c?.toLowerCase().includes(event.target.value.toLowerCase()))
      //  || (x.tags.length > 0 && x.tags?.filter(t => t?.tagName?.toLowerCase().includes(event.target.value.toLowerCase())).length > 0 &&
      //    x.tags?.filter(t => t?.tagName?.toLowerCase().includes(event.target.value.toLowerCase())))
      //);
      //this.filteredInProcessingInvoices = result;
      //this.filteredProcessedInvoices = result;
    }
  }
  resetSearch() {
    this.pageSidebarComponent.searchInput.nativeElement.value = "";
    this.searchText = "";
    if (this.storedInvoices.length !== 0) {
      if (this.isInProcessingTab == true) {
        this.inProcessingInvoices = this.storedInvoices;
      }
      else if (this.isProcessedTab == true) {
        this.inProcessingInvoices = this.storedInvoices;
      }
    }
  }

  onSearchChange(event: any): void {
    console.log(event.target.value)
    var x = event.key;
    var isClear = (event.type == 'click' && event.target.value != '');
    if (x == "Enter" || isClear) {
      this.searchText = isClear ? '' : event.target.value;
      if (this.storedInvoices.length == 0) {
        if (this.isInProcessingTab == true) {
          this.storedInvoices = this.filteredInProcessingInvoices;
        }
        else if (this.isProcessedTab == true) {
          this.storedInvoices = this.filteredProcessedInvoices;
        }
      }
      if (((event.key != null && event.key != '') || event.key == undefined) && !this.isFilterShown) {
        if(event.key != undefined)
          this.searchText = event.target.value.toLowerCase();
        else
          this.searchText = "";
        if (this.searchText != null && (this.searchText == '' || (this.searchText != '' && this.searchText.length > 2))) {
          if (this.isInProcessingTab == true) {
            this.storedInvoices = this.inProcessingInvoices;
          }
          else if (this.isProcessedTab == true) {
            this.storedInvoices = this.processedInvoices;
          }
          if (this.isInProcessingTab == true) {
            this.getInProcessingInvoices();
          }
          else if (this.isProcessedTab == true) {
            this.getProcessedInvoices();
          }
        }
      }
      else if (((event.key != null && event.key != '') || event.key == undefined) && this.isFilterShown) {
        if(event.key != undefined)
          this.searchText = event.target.value.toLowerCase();
        else
          this.searchText = "";
        if (this.searchText != null && (this.searchText == '' || (this.searchText != '' && this.searchText.length > 2))) {
          if (this.isInProcessingTab == true) {
            this.getInProcessingInvoices();
          }
          else if (this.isProcessedTab == true) {
            this.getProcessedInvoices();
          }
          var result = this.storedInvoices;
          if(this.searchText != "") {
            result = this.storedInvoices.filter(x =>
              x.invoiceNumber?.toLowerCase().includes(event.target.value.toLowerCase()) || x.supplierName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
              x.accountName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
              x.categories?.filter(c => c?.toLowerCase().includes(event.target.value.toLowerCase()))
              || (x.tags.length > 0 && x.tags?.filter(t => t?.tagName?.toLowerCase().includes(event.target.value.toLowerCase())).length > 0 &&
                x.tags?.filter(t => t?.tagName?.toLowerCase().includes(event.target.value.toLowerCase())))
            );
          }
          this.filteredInProcessingInvoices = result;
          this.filteredProcessedInvoices = result;
        }
      }
    }

  }
} 
