import { Injectable } from '@angular/core';
import { Note } from '../model/note';
import { HttpInternalService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private readonly _httpService: HttpInternalService) { }

  getNotesByOrganizationId(recordId: string, organizationId: string) {
    return this._httpService.getRequest<Note[]>('Notes/GetNotesByOrganizationIdAsync', { recordId: recordId, organizationId: organizationId });
  }

  createNote(note: Note) {
    return this._httpService.postRequest<Note>('Notes/CreateNote', note);
  }

  CreateNotes(note: Note) {
    return this._httpService.postRequest<Note>('Notes/CreateNotes', note);
  }

}
