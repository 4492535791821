import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { PaymentAccount } from '../../models/account/payment-account';
import { InvoiceLine } from '../../models/invoice/invoice-line';
import { TaxRate } from '../../models/taxRate';
import { TrackingCategory } from '../../models/tracking-categories.ts/trackingCategory';
import { round } from 'src/app/core/util/round';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { Guid } from 'guid-typescript';
import { sumOfArrayByProperty } from 'src/app/core/util/sum-of-array-by-property';
import { InvoiceTotals } from '../../models/invoice/invoice-totals';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { UpdatedLines } from '../../models/invoice/updated-lines';

@Component({
  selector: 'app-add-lines',
  templateUrl: './add-lines.component.html',
  styleUrls: ['./add-lines.component.scss']
})
export class AddLinesComponent implements AfterViewInit {
  @Output() moveBack = new EventEmitter<void>();
  @Output() updateLines = new EventEmitter<UpdatedLines>();
  @Input() accounts: PaymentAccount[] = [];
  @Input() invoiceLines: InvoiceLine[] = [];
  @Input() taxRates: TaxRate[] = [];
  @Input() selectedTaxRateId: string;
  @Input() trackingCategories: TrackingCategory[] = [];
  @Input() supplierName: string = 'Supplier';
  @Input() invoiceAmountDue: number = 0;
  invoiceTotals: InvoiceTotals = new InvoiceTotals(0, 0, 0);
  isTouched: boolean = false;
  isJustAddedLine: boolean = false;
  showToolTipDelay: number = 400;
  
  constructor(
    private _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
  ) { }

  ngOnInit() {
    this.updateInvoiceTotals(); 
  }

  deleteInvoiceLine(line: InvoiceLine) {
    const index = this.invoiceLines.indexOf(line);
    this.invoiceLines.splice(index, 1);

    this.updateInvoiceTotals();
  }

  addInvoiceLine() {
    this.isJustAddedLine = true;
    let tempAmountDue = this.invoiceAmountDue;// - this.invoiceLines.reduce((a, b) => a + b.amountDue, 0);
    let previousLineTaxRateId = this.invoiceLines?.length > 0 ? this.invoiceLines[this.invoiceLines.length - 1].taxId : this.selectedTaxRateId;
    let previousLineAccountId = this.invoiceLines?.length > 0 ? this.invoiceLines[this.invoiceLines.length - 1].accountId : null;
    let taxRate = this.taxRates.find(t => t.id == previousLineTaxRateId).rate;
    let tempNetAmount = round(100 * tempAmountDue / (100 + taxRate));

    let newLine = new InvoiceLine(
      this._organizationService.selectedOrganization,
      Guid.create().toString(),
      previousLineTaxRateId,
      [] as string[],
      0,0,
      //round(tempNetAmount * this.taxRates.find(t => t.id === previousLineTaxRateId).rate / 100),
      tempNetAmount,
      null,
      previousLineAccountId,
      1
    );

    this.invoiceLines = [...this.invoiceLines, newLine];
    this.updateInvoiceTotals();
  }

  updateTable(invoiceLine: InvoiceLine) {
    this.updateInvoiceLineById(invoiceLine);
    this.updateInvoiceTotals();
  }

  updateInvoiceLineById(invoiceLine: InvoiceLine) {
    const index = this.invoiceLines.indexOf(this.invoiceLines.find(l => l.id === invoiceLine.id));
    this.invoiceLines[index] = invoiceLine;
  }

  updateInvoiceTotals() {
    this.invoiceTotals.subTotal = sumOfArrayByProperty(this.invoiceLines, 'subTotal');
    this.invoiceTotals.totalTax = sumOfArrayByProperty(this.invoiceLines, 'totalTax');
    this.invoiceTotals.amountDue = this.invoiceTotals.subTotal + this.invoiceTotals.totalTax;
    //sumOfArrayByProperty(this.invoiceLines, 'amountDue');
  }

  submit() {
    if (this.invoiceLines.some(v => isNaN(v.amountDue) || v.amountDue == 0 || v.amountDue == null || v.amountDue == undefined)) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', "Please, provide all amount values");
    } else {
      for (let i = 0; i < this.invoiceLines?.length; i++) {
        this.invoiceLines[i].organisationId = this._organizationService.selectedOrganization;
      }

      this.updateLines.emit({ invoiceLines: this.invoiceLines, invoiceTotals: this.invoiceTotals } as UpdatedLines);
      this.moveBack.emit();
    }
  }

  moveBackClick() {
    this.moveBack.emit();
  }
  calculateDivHeight() {
    var floatingdivHeight = parseInt(document.defaultView.getComputedStyle(document.getElementById('floating-div'), "").getPropertyValue("height"));
    var windowheight = window?.innerHeight;
    let totalheight = windowheight - floatingdivHeight - 300;
    return {
      'height': totalheight + 'px',
      'overflow': 'auto'
    }
  }

  ngAfterViewInit() {
    this.calculateDivHeight()
  }
}
