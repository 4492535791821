import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule  } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-custom-date-time-picker',
  templateUrl: './custom-date-time-picker.component.html',
  styleUrls: ['./custom-date-time-picker.component.scss']
})
export class CustomDateTimePickerComponent implements OnInit {
  @Input() selectedDateRange: DateRange<Date> = null;
  @Input() pickerID: string;
  @Input() placeholder: string;
  @Output() getDateRange = new EventEmitter<DateRange<Date>>(); 
  @Output() closeDatePicker = new EventEmitter<void>(); 
  @ViewChild('buttondate') matdatePicker: ElementRef;
  dateRangeStart: Date = null;
  dateRangeEnd: Date = null;
  dateStart: string = null;
  dateEnd: string = null;
  broker: boolean = false;
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  }); 

  constructor() { }

  ngOnInit() {
    if(this.selectedDateRange != null) {
      this.range.setValue(this.selectedDateRange);
    }
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if(dateRangeStart == null && dateRangeEnd == null){
      this.dateRangeStart = null;
      this.dateRangeEnd = null;
      this.clearDate();
    }
    else { //(this.dateRangeStart == null && this.dateRangeEnd == null) {
      this.dateRangeStart = new Date(dateRangeStart.value);
      this.dateRangeEnd = new Date(dateRangeEnd.value);
    }

    if (this.dateRangeStart <= this.dateRangeEnd) {
      this.selectedDateRange = new DateRange(this.dateRangeStart, this.dateRangeEnd);
    }
    
    this.setDateRange();
  }

  cancelDateRange() {
    this.closeDatePicker.emit();
  }

  clearDate(){
    this.selectedDateRange = new DateRange(null, null);
    this.range.reset();
    this.setDateRange();  
  }

  setDateRange() {
    this.getDateRange.emit(this.selectedDateRange);
  }
}
