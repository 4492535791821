import { environment } from 'src/environments/environment';

export class ManageIntercom {
    constructor() {
    }
    
  public static updateintercode(launcher:boolean = true){
    const currentDate = new Date();
    const unixTimestamp = Math.floor(currentDate.getTime() / 1000);
    (<any>window).Intercom('boot', {
      app_id: environment.Intercom_app_id,
      name: localStorage.getItem('UserName'),
      email: localStorage.getItem('Email'),
     // hide_default_launcher: launcher,
      created_at: unixTimestamp
    });
   }
}
