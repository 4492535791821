import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'positiveNumber' })
export class PositiveNumberPipe implements PipeTransform {
  transform(value: number): number { 
    if (value < 0) {
      return Math.abs(value) ;
    }
    return value;
  }
}
