import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Guid } from "guid-typescript";
import { InvoiceShort } from '../models/invoice/invoice-short';

@Injectable({
  providedIn: 'root'
})
export class InboxInvoicesStorageService {
  private _invoices = [] as string[];
  private currentIndex: number = 0;

  hasNextInvoices$ = new BehaviorSubject<boolean>(false);
  hasPreviousInvoices$ = new BehaviorSubject<boolean>(false);;

  constructor() { }

  getStatus(id: string) {
    let invoiceIdsList = localStorage.getItem('InvoceIdsStorage');

    if (invoiceIdsList) {
      this._invoices = [...JSON.parse(invoiceIdsList)] as string[];
    }

    if (this._invoices?.length > 0) {
      this.currentIndex = this._invoices.findIndex(i => i === id);
      this.hasNextInvoices$.next(this.currentIndex < this._invoices.length - 1);
      this.hasPreviousInvoices$.next(this.currentIndex > 0);
    }
  }

  fillInvoicesArray(array: InvoiceShort[], currentId: string) {
    this._invoices =  array.map(i => i.id.toString());
    this.currentIndex = this._invoices.findIndex(i => i === currentId);

    localStorage.setItem('InvoceIdsStorage', JSON.stringify(this._invoices));

    this.hasNextInvoices$.next(this.currentIndex < this._invoices.length - 1);
    this.hasPreviousInvoices$.next(this.currentIndex > 0);
  }

  deleteInvoiceIdFromStorage(id: string) {
    let invoiceIdsList = localStorage.getItem('InvoceIdsStorage');

    if (invoiceIdsList) {
      this._invoices = [...JSON.parse(invoiceIdsList)] as string[];
    }
    
    if (this._invoices?.length > 0) {
      this._invoices =  this._invoices.filter(i => i != id);
      localStorage.setItem('InvoceIdsStorage', JSON.stringify(this._invoices));
    }
  }

  getNextInvoiceId(id: string) {
    let invoiceIdsList = localStorage.getItem('InvoceIdsStorage');

    if (invoiceIdsList) {
      this._invoices = [...JSON.parse(invoiceIdsList)] as string[];
    }

    if (this._invoices?.length > 0) {
      this.currentIndex = this._invoices.findIndex(i => i === id);
    }

    if (this.currentIndex < this._invoices.length - 1) {
      this.currentIndex += 1;
      return this._invoices[this.currentIndex];
    }

    this.hasNextInvoices$.next(this.currentIndex < this._invoices.length - 1);
    return;
  }

  getInvoiceArrayIndex(id: string) {
    let invoiceIdsList = localStorage.getItem('InvoceIdsStorage');

    if (invoiceIdsList) {
      this._invoices = [...JSON.parse(invoiceIdsList)] as string[];
      return this._invoices?.findIndex(i => i === id);
    }

    return 0;
  }

  getPreviousInvoiceId(id: string) {
    let invoiceIdsList = localStorage.getItem('InvoceIdsStorage');

    if (invoiceIdsList) {
      this._invoices = [...JSON.parse(invoiceIdsList)] as string[];
    }

    if (this._invoices?.length > 0) {
      this.currentIndex = this._invoices.findIndex(i => i === id);
    }

    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      return this._invoices[this.currentIndex];
    }

    this.hasPreviousInvoices$.next(this.currentIndex > 0);
    return;
  }
}
