import { Component, EventEmitter, Input, OnInit, Output, HostListener, ElementRef } from '@angular/core';
import { Converter } from 'src/app/core/util/number-converter';
import { round } from 'src/app/core/util/round';
import { PaymentAccount } from '../../models/account/payment-account';
import { InvoiceLine } from '../../models/invoice/invoice-line';
import { TaxRate } from '../../models/taxRate';
import { TrackingCategory } from '../../models/tracking-categories.ts/trackingCategory';

@Component({
  selector: 'app-add-lines-one-line',
  templateUrl: './add-lines-one-line.component.html',
  styleUrls: ['./add-lines-one-line.component.scss']
})
export class AddLinesOneLineComponent implements OnInit {
  @Output() updateLine = new EventEmitter<InvoiceLine>();
  @Output() deleteLine = new EventEmitter<InvoiceLine>();

  @Input() accounts: PaymentAccount[] = [];
  @Input() invoiceLine: InvoiceLine;
  @Input() taxRates: TaxRate[] = [];
  @Input() selectedTaxRateId: string;
  @Input() trackingCategories: TrackingCategory[] = [];
  @Input() index: number = 0;
  @Input() isAddedLine: boolean = false;

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.isTouched = this.isAddedLine ? false : true;
    } else {
      this.isTouched = this.isAddedLine ? true : false;
      this.isAddedLine = false;
    }
  }

  selectedTrackingCategories = [null, null];
  converter = new Converter();
  showToolTipDelay: number = 400;
  isTouched: boolean = false;

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
    this.updateTaxRate(this.selectedTaxRateId);
    for (let i = 0; i < this.invoiceLine.trackingOptions?.length; i++)
      this.selectedTrackingCategories[i] = this.invoiceLine.trackingOptions[i];
  } 

  updateInvoiceLine() {
    this.updateLine.emit(this.invoiceLine);
  }

  deleteInvoiceLine() {
    this.deleteLine.emit(this.invoiceLine);
  }

  updateInvoiceLineTotals(event:any) {
  
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value;

    if (!reg.test(input)) {
      this.invoiceLine.amountDue = Number(Number(input).toFixed(2))
    }
    else {
      this.invoiceLine.amountDue = Number(input);// this.converter.stringToFloat(amountDue);
    }
    this._updateSubTotalAndTotalTax();
    this.updateInvoiceLine();
  }

  updateTaxRate(id: string) {
    this.selectedTaxRateId = id;
    this.invoiceLine.taxId = this.selectedTaxRateId;

    this._updateSubTotalAndTotalTax();
    this.updateInvoiceLine();
  }

  updateAccountCode(accountId: string) {
    this.invoiceLine.accountId = accountId;
    this.updateInvoiceLine();
  }

  onSelectCategory(option: string, index: number) {
    this.invoiceLine.trackingOptions[index] = option;

    this.updateInvoiceLine();
  }

  accountSearch(term: string, item: PaymentAccount): boolean {
    return item.name.toLowerCase().includes(term);
  }

  private _updateSubTotalAndTotalTax() {
    const rate = this._getTaxRateById(this.selectedTaxRateId);

    this.invoiceLine.totalTax = round((this.invoiceLine.amountDue - (100 * this.invoiceLine.amountDue) / (100 + rate)));
    this.invoiceLine.subTotal = round((this.invoiceLine.amountDue * 100) / (100 + rate));
  }

  private _getTaxRateById(id: string): number | null {
    return this.taxRates.find(tr => tr.id === id)?.rate;
  }
}
