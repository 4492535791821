<div class="header">
    <div class="head">
        <div class="mobnav_icon" (click)="sharedService.isShowSideNav=!sharedService.isShowSideNav">
            <div class="mobnav_button">
                <div id="mobnav_button_icon" class="mobnav_button-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div id="orgdropdown" [ngClass]="isDropdownOpen ? 'dropdown open' : isSearchBarShown ? 'dropdown dropdown-hide' : 'dropdown'" (click)="$event.stopPropagation();openDropdown();">
            <span class="arrow" style="cursor: pointer;" *ngIf="organizationList?.length > 1">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1.14343L7 8L14 1.14343L12.8335 0L7 5.71475L1.16653 0L0 1.14343Z" fill="#1C1C1C" />
                </svg>
            </span>

            <div class="caption">{{this.organizationService.selectedOrganizationName}}</div>
            <div class="list" *ngIf="organizationList?.length > 1" style="z-index:10000">
                <div class="search-item" (click)="$event.stopPropagation()">
                    <input class="search-input" [ngModel]="searchValue" (ngModelChange)="searchOrganization($event)"  />
                    <span class="placeholder">
                        <img src="../../../assets/img/header/search-icon.svg" alt="">
                        <span>Search Organizations</span>
                    </span>
                </div>
                <div class="no-results" *ngIf="sortedOrganizationList?.length == 0" (click)="$event.stopPropagation()">No Results Found</div>
                <ng-container *ngFor="let org of sortedOrganizationList">
                  <div [ngClass]="this.organizationService.selectedOrganizationName === org.organisationName ? 'item selected' : 'item'"
                       (click)="selected(org.organisationID);">
                    {{org.organisationName}}
                  </div>
                </ng-container>
                
            </div>
        </div>
    </div>
    
    <div class="search-bar-container">
        <div class="mobile-mode" [ngClass]="isSearchBarShown ? 'hide' : ''">
            <div class="search-icon" (click)="clickShowSearchBarIcon()" matTooltip="Show Search Bar" [matTooltipShowDelay]="showToolTipDelay">
                <img src="../../../assets/img/header/search-icon.svg" alt="">
            </div>
        </div>

        <div class="search-form" [ngClass]="isSearchBarShown || isSearchResultsShown ? 'show' : ''">
            <input class="search-bar" type="search" placeholder="Search for bills, items, supplier names"
                [ngClass]="isSearchResultsShown ? 'search-bar-active' : ''" [(ngModel)]="searchTerm"
                (ngModelChange)="mainSearch($event)" #searchBar="ngModel">
            <div class="search-result" *ngIf="isSearchResultsShown">
                <div class="title">Bills</div>
                <div class="no-result" *ngIf="searchResponse?.bills?.length == 0">
                    No bills matching a search query
                </div>
                <section class="bills" #billsSection>
                    <!-- Row -->
                    <div class="result-row" *ngFor="let bill of searchResponse.bills" (click)="goToInvoiceDetails(bill)">
                        <div class="column-1">
                            <div class="logo {{bill.hexColorClass}}" >
                                <span>{{bill.title | shortLogo}}</span>
                            </div>
                        </div>
                        <div class="column-2" [ngClass]="(bill.subtitle && bill.subtitle != '') || bill.lineItem ? '' : 'align-center'">
                            <div class="name" [innerHTML]="selectMathcedText(bill.title, searchTerm)"></div>
                            <div class="ref" *ngIf="bill.subtitle && bill.subtitle != ''" [innerHTML]="selectMathcedText(bill.subtitle, searchTerm)"></div>
                            <div class="item-line" *ngIf="bill.lineItem">
                                <div class="item-desc" [innerHTML]="selectMathcedText(bill.lineItem?.description, searchTerm)"></div>
                                <div>{{bill.lineItem?.quantity}}</div>
                                <div>${{bill.lineItem?.amount | number: '1.0-2'}}</div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="show-more" *ngIf="billPagesSize * billPage < searchResponse?.numberOfBills" (click)="showMoreBills()">Show More</div>
                <hr>
                <div class="title">Suppliers</div>
                <div class="no-result" *ngIf="searchResponse?.suppliers?.length == 0">
                    No suppliers matching a search query
                </div>
                <section class="suppliers" #suppliersSection>
                    <!-- Row -->
                    <div class="result-row" style="align-items: center;" *ngFor="let supplier of searchResponse.suppliers" (click)="goToSupplierDetails(supplier)">
                        <div class="column-1">
                            <div class="logo {{supplier.hexColorClass}}">
                                <span>{{supplier.title | shortLogo}}</span>
                            </div>
                        </div>
                        <div class="column-2">
                            <div class="name" [innerHTML]="selectMathcedText(supplier.title, searchTerm)"></div>
                            <div class="ref">
                                <span class="contact" [innerHTML]="selectMathcedText(supplier.subtitle, searchTerm)"></span>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="show-more" *ngIf="supplierPageSize * supplierPage < searchResponse?.numberOfSuppliers" (click)="showMoreSuppliers()">Show More</div>
            </div>
        </div>

        <div class="close-icon" [ngClass]="isSearchBarShown ? 'show' : ''" (click)="clickCloseSearchBarIcon()"
            matTooltip="Hide Search Bar" [matTooltipShowDelay]="showToolTipDelay">
            <img src="../../../assets/img/close-icon.svg" alt="">
        </div>
    </div>

    <div class="sides">
        <div class="upload-button" [ngClass]="isSearchBarShown ? 'hide-button' : ''">
            <div class="circle" (click)="toggleSidebar(types.upload)">
                <span>+</span>
            </div>
        </div>
        <div></div>
        <div>
            <div class="user_image" mat-button [matMenuTriggerFor]="menu">
                <span class="user_logo">{{currentUser?.name | shortLogo}}</span>
            </div>
            <mat-menu #menu="matMenu" class="user_menu">
              <div class="user_name">{{currentUser?.name}}</div>
              <hr class="line">
              <button mat-menu-item class="menu_item" matTooltip="Go to Help Center" [matTooltipShowDelay]="showToolTipDelay"
                      (click)="redirectToHelpCenter()">
                <img src="../../../assets/img/header/help_center.svg" alt="">
                <span>Help Center</span>
              </button>
              <!--<hr class="line">
              <button mat-menu-item class="menu_item" matTooltip="Go to Support" [matTooltipShowDelay]="showToolTipDelay"
                      (click)="openSupport()">
                <img src="../../../assets/img/header/support_agent.svg" alt="">
                <span>Support</span>
              </button>-->
              <hr class="line">
              <button mat-menu-item class="menu_item" (click)="signOut()">
                <img src="../../../assets/img/header/log_out.svg" alt="">
                <span>Logout</span>
              </button>
            </mat-menu>
        </div>
    </div>
</div>

<app-sidebar [isSidebarVisible]=isSidebarVisible [isSettingsSidebar]=isSettingsSidebar [isUploadSidebar]=isUploadSidebar
  (isClosed)="toggleSidebar($event)">
</app-sidebar>
