import { CurrencyCode } from "../../enums/currency-code";
import { BankAccount } from "../account/bank-account";
import { PaymentAccount } from "../account/payment-account";
import { SupplierAliasCreated } from "./supplier-alias-created";

export class NewSupplier {
  name: string;
  taxNumber: string;
  groupId?: string;
  defaultAccount: PaymentAccount;
  firstName: string;
  lastName: string;
  email: string;
  currency: CurrencyCode;
  accountName: string;
  bsb: string;
  accountNumber: string;
  organizationId: string;
  supplierId:string;
  contactGroups?: string;
  bankAccountRequestModel?: BankAccount;
  aliases?: SupplierAliasCreated[];
  hexColorClass?: string;
  accountsPayableTaxName?: string;
  accountsPayableTaxType?: string;
  routingNumber?: string;
  accountNumberUS?: string;
  constructor(
    organizarionId: string,
    name: string = '',
    taxNumber: string = '',
    defaultAccount: PaymentAccount = new PaymentAccount(),
    firstName: string = '',
    lastName: string = '',
    email: string = '',
    currency: CurrencyCode = CurrencyCode.AUD,
    accountName: string = '',
    BSB: string = '',
    accountNumber: string = '',
    group: string = '',
    aliases: SupplierAliasCreated[] = null,
    hexColorClass: string = '',
    accountsPayableTaxType: string = '',
    accountsPayableTaxName : string ='',
    routingNumber: string = '',
    accountNumberUS: string = ''
    ) {
    this.organizationId = organizarionId;
    this.name = name;
    this.taxNumber = taxNumber;
    this.groupId = group;
    this.defaultAccount = defaultAccount;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.currency = currency;
    this.accountName = accountName;
    this.bsb = BSB;
    this.accountNumber = accountNumber;
    this.bankAccountRequestModel = new BankAccount(organizarionId);
    this.aliases = aliases;
    this.hexColorClass = hexColorClass;
    this.accountsPayableTaxType = accountsPayableTaxType;
    this.accountsPayableTaxName = accountsPayableTaxName;
    this.routingNumber = routingNumber;
    this.accountNumberUS = accountNumberUS;
  }
}
