import { Injectable } from "@angular/core";
import { HttpInternalService } from "./http.service";
import { ReportFilter } from "../model/reportFilter";
import { CreatedInvoice } from "src/app/modules/bookkeeping/models/invoice/created-invoice";
import { objectToFormData } from "src/app/core/util/FormData-converter";
import { take } from "rxjs/operators";
import { Invoice } from "src/app/modules/bookkeeping/models/invoice/invoice";
import { ToasterMessageService } from "src/app/shared/toaster-message/toaster-message.service";
import { ToasterTypes } from "src/app/core/enum/toaster-type.enum";
import { OrganizationService } from "./organization.service";

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    constructor(private _httpService: HttpInternalService,
      private organizationService: OrganizationService,
      private _toasterMessageService:ToasterMessageService) { }

    SaveFilter(url: string, reportFiltersDTO) {
        return this._httpService.postRequest<string>('Filter/CreateReportFilter',reportFiltersDTO);
    }
    GetFilter(url: string, Id) {
        return this._httpService.getRequest<any>(`${url + '?Id=' + Id}`);
    }
    getAllSavedFilters(url: string, type, organizationId){
        return this._httpService.getRequest<any>(`${url + '?type=' + type + '&organizationId=' + organizationId}`);
    }
    getLatestFilterName(url: string, type, organizationId) {
        return this._httpService.getRequest<any>(`${url + '?type=' + type + '&organizationId=' + organizationId}`);
    }
    DeleteFilter(url: string, Id) {
        return this._httpService.deleteRequest<any>(`${url + '?Id=' + Id}`);
    }


    async uploadExcel(invoice: CreatedInvoice) {
      const formData = objectToFormData(invoice);

      return await this._httpService.postPromiseRequest('Reporting/uploadExcel', formData)

      // .subscribe(
      //     (invoice: Invoice) => {
      //       this._toasterMessageService.displayToasterState(ToasterTypes.Success,"Upload File Success","Success");
      //       // // if(invoice.error != undefined && invoice.error == 'LimitExceed'){
      //       // //   this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
      //       // //     `You’ve exceeded your monthly document limit. Upgrade your subscription. <a  class=\"toastr-link\" href=\"/setting/billing\">Upgrade</a>`);
      //       // // }
      //       // // else{
      //       // if (invoice != null) {
      //       //   // this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Document submitted for processing');
      //       //     this._recognizedInvoiceService.send(invoice);
      //       //     this.writePairsToLocalStorage(invoice);
      //       //   }
      //       // // }
      //      // this.organizationService.selectedOrganization(invoice);
      //     },
      //     (e: Error) => {
      //       alert("Error");
      //     //  this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
      //     }
      //   );
    }

    async uploadImportExcel(invoice: any) {
      const formData = objectToFormData(invoice);

      return await this._httpService.postPromiseRequest('Reporting/uploadImportExcel', formData)
    }
}
