import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base',
  template: '',
})
export class BaseComponent implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  readonly untilThis = <T>(source: Observable<T>) =>
    source.pipe(takeUntil(this.unsubscribe$));

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}