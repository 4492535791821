import { TrackingCategoryOption } from "../tracking-categories.ts/tracking-category-option";
import { TrackingCategory } from "../tracking-categories.ts/trackingCategory";

export class BookkeepingFilter {
  suppliers?: string[] = null;
  invoiceDateRange?: Date[] = null;
  invoiceDateRangeOption?: string = null;
  categories?: string[] = null;
  trackingOptions1?: string[] = null;
  trackingOptions2?: string[] = null;
  tags?: string[] = null;
  status?: boolean = false;
  accounts?: string[] = null;
}
