import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appDateTextInput]'
})
export class DateTextInputDirective {
    private _element: HTMLInputElement;

    constructor(elRef: ElementRef) {
        this._element = elRef.nativeElement;
    }

    @HostListener('focus') onFocus() {
        this._element.type = "date";
    }

    @HostListener('blur') onBlur() {
        this._element.type = !this._element.value ? 'text' : 'date';
    }
}