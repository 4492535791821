import { Component, Injectable, Input, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs'

import { SpinnerService } from "./spinner.service"

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent implements OnInit, OnDestroy {   

  spinnerStateListener: Subscription;  

  constructor(private spinner: NgxSpinnerService,
    private spinnerService: SpinnerService) {    
  }   

  ngOnInit() {     
    this.registerSpinnerStateListener();
  }

  private registerSpinnerStateListener() {
    this.spinnerStateListener = this.spinnerService.spinnerState.subscribe((spinnerState) => {
      spinnerState.show ?  this.spinner.show() : this.spinner.hide();     
    });
  } 

  ngOnDestroy(): void {  
    this.spinnerStateListener.unsubscribe();  
  }
}
