import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { ErrorsService } from 'src/app/data/service/errors.service';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ConfirmationDialogService } from 'src/app/shared/confirmation-dialog/confirmation-dialog.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { EmailErrorRecord } from '../../models/error/email-error-record';
import { EmailErrorRecordData } from '../../models/error/email-error-record-data';
import { ErrorRecord } from '../../models/error/error-record';
import { ErrorRecordType } from '../../models/error/error-record-type';

@Component({
  selector: 'app-processing-error',
  templateUrl: './processing-error.component.html',
  styleUrls: ['./processing-error.component.scss']
})
export class ProcessingErrorComponent extends BaseComponent implements OnInit {
  @Input() records = [] as ErrorRecord[];
  errorRecords = [] as ErrorRecord[];
  organizationId: string = '';
  emailErrorRecords = [] as EmailErrorRecord[];
  showToolTipDelay: number = 400;
  loading: boolean = false;

  constructor(
    private _errorsService: ErrorsService,
    private _organizationService: OrganizationService,
    private _toasterMessageService: ToasterMessageService,
    private _confirmationDialogService: ConfirmationDialogService,
    private renderer: Renderer2,
    private _invoiceService: InvoiceService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.loading = true;
    if (this.records) {
      this.emailErrorRecords = this.records
        .filter(r => r.errorRecordType === ErrorRecordType.Email)
        .map(r => {
          if (r.jsonData) {
              const temp = {
                ...JSON.parse(r.jsonData)
              } as EmailErrorRecordData; 
              return {
                ...r,
                emailData: { ...temp }
              } as EmailErrorRecord;
          }
        })
        .sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0);
      this.loading = false;
    }
  }

  deleteRecord(record: EmailErrorRecord) {
    this._errorsService.deleteErrorRecordsByOrganizationId(record.id)
      .pipe(this.untilThis)
      .subscribe({
        next: () => {
          this.errorRecords = this.errorRecords.filter(r => r.id !== record.id);
          this.emailErrorRecords = this.emailErrorRecords.filter(r => r.id !== record.id);
        },
        error:(e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
        }
      });
  }

  showDeleteRecordConfirmation(record: EmailErrorRecord) {
    this._confirmationDialogService.confirm('Confirm', 'Do you want to delete the record?', 'Yes', 'No', '')
      .then((confirm) => {
        if (confirm) {
          this.deleteRecord(record);
        }
      });
  }

  downloadFile(pdfUrl: string) {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this._invoiceService.getDownloadInvoiceUrl(pdfUrl,""));
    link.setAttribute('download', pdfUrl);
    link.click();
    link.remove();
  }
}
