<div class="section">
  <div class="number">{{index + 1}})</div>
  <div class="account">
    <div class="account-name">{{getAccountName()}}</div>
    <div class="track">{{getTaxRateName()}}</div>
    <div class="track" *ngIf="trackingCategories?.length > 0">{{getTrackingCategory(invoiceLine.trackingOptions[0])}}</div>
    <div class="track" *ngIf="trackingCategories?.length > 1">{{getTrackingCategory(invoiceLine.trackingOptions[1])}}</div>
    <div class="details" *ngIf="invoiceLine?.description">{{invoiceLine?.description}}</div>
  </div>
  <div class="amount">
    <div class="total">{{invoiceLine.subTotal | number}}</div>
    <div class="taxTotal">{{invoiceLine.totalTax | number}}</div>
  </div>
</div>
