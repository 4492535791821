import { Guid } from "guid-typescript";

export class InvoiceLine {
    id?: string;
    organisationId?: string;
    accountId: string | null;
    accountName?: string | null;
    amountDue?: number;
    totalTax?: number;
    subTotal?: number;
    description?: string;
    quantity?: number;
    taxId: string;
    trackingOptions: string[];
    trackingOptionNames?: string[];

    constructor(
        organisationId: string,
        id: string = null,
        taxRateId: string = '',
        trackingOptions: string[] = [],
        amountDue: number = null,
        totalTax: number = 0,
        subTotal: number = null,
        description: string = '',
        accountId: string | null = null,
        quantity: number = 1) {
        this.organisationId = organisationId;
        this.id = id;
        this.taxId = taxRateId;
        this.trackingOptions = trackingOptions;
        this.amountDue = amountDue;
        this.totalTax = totalTax;
        this.subTotal = subTotal;
        this.accountId = accountId;
        this.description = description;
        this.quantity = quantity;
    }
}