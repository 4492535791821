<div class="step-side-form-container">
    <div class="signup-form-container">
      <form class="signup-form" role="form" name="SignUpForm">
        <div class="step-header">Confirming Your Account</div>
        <div class="spinner"></div>
      </form>
    </div>
  </div>
  <div class="step_side_image_container">
    <div class="signup_image_container">
      <img class="step-one-img" src="../../../../../assets/img/signup/step-two.svg">
    </div>
  </div>
  